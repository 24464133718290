import { useEffect } from 'react';
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import Footer from '../../components/Footer';
import Promo from './components/Promo';
import Info from './components/Info';
import Programs from './components/Programs';
import Start from './components/Start';
import Platform from '../../components/platform/Platform';
import Faq from './components/Faq';
import Preims from './components/Preims';
import Preims2 from './components/Preims2';
import Gift from './components/Gift';

import './style.css';
import Studentres from './components/Studentres';
import Author from './components/Author';

export const L2023_10_Lessons_sms = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value='Уроки стройности — 3 фитнес-программы Кати Усмановой на ваш выбор с выгодой 93%'/>
        <DocumentDescription value='Выгода 93% на 3 фитнес-программы на ваш выбор от  Кати Усмановой. Доступ к программам на 6 месяцев и бонусные подарки при покупке.' />
        <div className='lessons'>
            <Start renderer={renderer} date={date} />
            <Footer />
        </div>
      </>
    );
};