import { useEffect, useRef, useState } from "react";
import { Box, HStack, VStack, Image } from "@chakra-ui/react";
import { GLOBAL_CONFIG_KEY } from "@com.marathonium/web2-utils";
import _get from "lodash/get";

import QuizOrig from "./react-quiz-component/Quiz";

export function Quiz({ configUrl, onFinish, onLastComplete, onStepComplete, hook, ...props }) {
  const [data, setData] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    fetch(configUrl)
      .then(r => r.json().catch(setError))
      .then(setData)
      .catch(setError);
  }, [configUrl]);

  if (error) {
    console.error("error", error);
    return null;
  }

  const quizWrapperRef = useRef();

  return data ? (
    <Box ref={quizWrapperRef} w="100%" maxW="500px" backgroundColor="#EBEBEB" px={["16px", "40px"]} py={["16px", "30px"]}>
      <QuizOrig
        hook={hook}
        allowNavigation
        disableSynopsis
        revealAnswerOnSubmit
        onFinish={onFinish}
        customResultPage={() => null}
        customQuestionText={({ activeQuestion, rawMarkup }) => {
          return (
            <Box
              as="h3"
              fontFamily="CeraProMedium"
              fontSize="20px"
              color="#000000"
              mb="35px"
              dangerouslySetInnerHTML={rawMarkup(`${activeQuestion && activeQuestion.question}`)}
            />
          );
        }}
        customNextButton={({
          nextQuestion,
          currentQuestionIndex,
          answerSelectionTypeState,
          showNextQuestionButton,
          activeQuestion,
          userInput,
          questions,
          getSelectedAnswerFor,
          getInfoFor,
        }) => {
          const answersLength = _get(activeQuestion, "answers.length");
          const required = _get(activeQuestion, "required");

          const hasAnswer =
            userInput[currentQuestionIndex] &&
            (answerSelectionTypeState === "single"
              ? userInput[currentQuestionIndex] !== undefined
              : userInput[currentQuestionIndex].length > 0);

          function goToNextQuestion() {
            if (currentQuestionIndex + 1 === questions.length) {
              typeof onLastComplete === "function" && onLastComplete(userInput[currentQuestionIndex]);
            }

            typeof onStepComplete === "function" &&
              onStepComplete({
                question: getInfoFor(currentQuestionIndex + 1),
                answer: getSelectedAnswerFor(currentQuestionIndex + 1),
              });

            nextQuestion(currentQuestionIndex);

            quizWrapperRef.current.scrollIntoView();
          }

          return (
            <>
              <NextLessonAutoClicker
                isSingle={answerSelectionTypeState === "single"}
                showNextQuestionButton={showNextQuestionButton}
                nextQuestion={nextQuestion}
                currentQuestionIndex={currentQuestionIndex}
                answersLength={answersLength}
                goToNextQuestion={goToNextQuestion}
              />
              {answersLength === 1 && answerSelectionTypeState !== "input" ? null : (
                <Box
                  as="button"
                  type="button"
                  onClick={goToNextQuestion}
                  borderRadius="20px"
                  bgColor="#FB4F54"
                  color="#ffffff"
                  fontFamily="CeraProMedium"
                  fontSize="20px"
                  height="65px"
                  width="100%"
                  textAlign="center"
                  marginTop="45px"
                  disabled={required && !hasAnswer}
                  opacity={!required || hasAnswer ? 1 : 0.5}
                  cursor={!required || hasAnswer ? "pointer" : "not-allowed"}
                >
                  Далее
                </Box>
              )}
            </>
          );
        }}
        customAnswerButtons={({
          answer,
          answerSelectionType,
          index,
          allowNavigation,
          checkSelectedAnswer,
          answersPic,
          questionType,
          revealAnswerOnSubmit,
          onSelectAnswer,
          onClickAnswer,
          currentQuestionIndex,
          userInput,
          setUserInput,
        }) => {
          if (answerSelectionType === "input") {
            return (
              <Box
                as="input"
                autoFocus
                type="text"
                value={userInput[currentQuestionIndex]}
                onChange={e => {
                  const userInputCopy = [...userInput];
                  userInputCopy[currentQuestionIndex] = e.target.value;
                  console.log("userInputCopy", userInputCopy);
                  setUserInput(userInputCopy);
                }}
                w="100%"
                h="65px"
                borderRadius="20px"
                textAlign="center"
                fontFamily="CeraProMedium"
                fontSize="20px"
                color="#000000"
                outline="none"
              />
            );
          }

          const hasAnswerPic = answersPic && answersPic[index + 1];
          return (
            <Box
              cursor="pointer"
              onClick={() => (revealAnswerOnSubmit ? onSelectAnswer(index) : onClickAnswer(index))}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
              minHeight="65px"
              w="100%"
              gap="10px"
              p="0"
              borderRadius="20px"
              overflow="hidden"
              border="none"
              backgroundColor="#ffffff"
              lineHeight="1.25"
              mb="25px"
              outline={allowNavigation && checkSelectedAnswer(index + 1) ? "2px solid #FB4F54" : null}
            >
              {hasAnswerPic && (
                <Image
                  src={answersPic[index + 1]}
                  alt="answer"
                  width="105px"
                  height="65px"
                  objectFit="fill"
                  backgroundColor="#fafafa"
                  className="answerBtnPic"
                />
              )}
              <Box pr="30px" pl={!hasAnswerPic ? "30px" : 0} fontFamily="CeraProMedium" fontSize="20px" color="#000000" textAlign="left">
                {questionType === "text" && answer}
              </Box>
            </Box>
          );
        }}
        customProgress={({ currentQuestionIndex, questions, nextQuestion }) => {
          return (
            <VStack spacing="20px" mb="45px">
              <Box w="100%" pos="relative">
                {currentQuestionIndex > 0 && (
                  <Image
                    cursor="pointer"
                    onClick={() => nextQuestion(currentQuestionIndex - 2)}
                    src={`${_get(window, [GLOBAL_CONFIG_KEY, "cdn_host"], process.env.REACT_APP_CDN_HOST)}/icons/arrow-left.svg`}
                    w="32px"
                    h="32px"
                    pos="absolute"
                    left={0}
                  />
                )}
                <Image
                  h="45px"
                  w="100%"
                  m="0 auto"
                  src={`${_get(window, [GLOBAL_CONFIG_KEY, "cdn_host"], process.env.REACT_APP_CDN_HOST)}/icons/logo2024.svg`}
                />
              </Box>

              <HStack w="100%" spacing="10px">
                {new Array(Math.ceil((questions.length || 0) / 4)).fill().map((i, idx) => {
                  return (
                    <Box
                      key={idx}
                      w="100%"
                      h="5px"
                      bgColor="red"
                      borderRadius="100px"
                      backgroundColor={currentQuestionIndex >= idx * 4 ? "#000000" : "rgba(0, 0, 0, 0.31)"}
                    />
                  );
                })}
              </HStack>
            </VStack>
          );
        }}
        quiz={data}
        {...props}
      />
    </Box>
  ) : null;
}

function NextLessonAutoClicker({ isSingle, showNextQuestionButton, answersLength, goToNextQuestion }) {
  useEffect(() => {
    if (!showNextQuestionButton || (!isSingle && answersLength > 1)) {
      return;
    }

    goToNextQuestion();
  }, [showNextQuestionButton, isSingle, answersLength, goToNextQuestion]);

  return null;
}
