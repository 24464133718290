import { useEffect, useState } from "react";
import { LoginWidget, checkUserInGroup, addUserToGroup, getUserCountByGroup, ShowForSegment, HideForSegment, checkUserInSegment, getUser } from "@com.marathonium/web2-ui";
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";

import './styles.css';
import styles from './prizes.module.css';
import Hidden from "./hidden";
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import Already from "./already";
import Footer from "../../components/Footer";


const prizes = [
    {
        id: '04539fbe-6641-40eb-81c7-d3098f5bebfb',
        name: 'Кухонные электронные весы',
        description: <><p>Поздравляем! Вы выиграли <b>кухонные электронные весы</b>. Это ваш незаменимый помощник, если вы поддерживаете дефицит питания или&nbsp;просто любите точные дозировки.</p></>,
        limit: window.lps_settings.limits.prize1,
    },
    {
        id: '3938718e-1ef2-4a7d-8ca9-071c1a91e98a',
        name: 'Умные весы Futula Smart Scale 3',
        description: <><p>Поздравляем! Вы выиграли <b>умные весы Futula Smart Scale&nbsp;3</b>. Они помогут следить за&nbsp;фигурой с&nbsp;удовольствием. Смотрятся шикарно, точно измеряют вес и&nbsp;не&nbsp;только. Весы дают информацию о&nbsp;состоянии организма, основываясь на&nbsp;10&nbsp;важных показателях. Все параметры показываются и&nbsp;сохраняются в&nbsp;специальном приложении на&nbsp;смартфон, в&nbsp;котором можно увидеть свой прогресс.</p></>,
        limit: window.lps_settings.limits.prize2,
    },
    {
        id: '3df84538-b177-4b59-9e7e-53ac4cbab965',
        name: 'Apple Watch SE 2022',
        description: <><p>Поздравляем! Вы выиграли <b>Apple Watch SE 2022</b>! Это мощное устройство для здорового образа жизни. Когда вы надеваете эти часы, они становятся настоящим партнёром по&nbsp;фитнесу, который учитывает каждое ваше движение и&nbsp;сообщает полезные данные о&nbsp;вашем здоровье.</p></>,
        limit: window.lps_settings.limits.prize3,
    },
    {
        id: 'a4295d12-dfa1-41df-a8c5-f73c4da33694',
        name: 'Денежный приз 1000 р',
        description: <><p>Поздравляем! Вы выиграли <b>1 000 рублей</b>, чтобы немного побаловать себя. Купите себе что-то приятное и&nbsp;сделайте вложение в&nbsp;своё настроение.</p></>,
        limit: window.lps_settings.limits.prize4,
    },
    {
        id: '5658a458-1bef-4f45-a4e9-9253d060ebf2',
        name: 'Apple iPhone 15 Pro 256 ГБ',
        description: <><p>Поздравляем! Вы выиграли <b>Apple iPhone 15&nbsp;Pro</b>. Мечта всех девушек&nbsp;— и&nbsp;вот теперь она ваша! Ведь это не просто смартфон&nbsp;— это гаджет, который спроектирован, чтобы впечатлять! А&nbsp;ещё это ваша личная фотостудия: независимо от&nbsp;того, снимаете ли вы пейзажи, портреты или кулинарные шедевры, эти камеры превратят каждую фотографию в&nbsp;произведение искусства. Арт-фотографии, селфи, семейные снимки&nbsp;— каждый кадр будет настоящим произведением искусства. Будьте в&nbsp;тренде. </p></>,
        limit: window.lps_settings.limits.prize5,
    },
    {
        id: 'fd74fbb6-8bbf-4723-a0fe-8f664258f117',
        name: 'Наушники Apple AirPods Max',
        description: <><p>Поздравляем! Вы выиграли <b>наушники Apple AirPods Max</b>. Стильный аксессуар с&nbsp;нереальным качеством звука. Надевайте на&nbsp;тренировку, в&nbsp;дорогу или&nbsp;медитируйте с&nbsp;полным погружением.</p></>,
        limit: window.lps_settings.limits.prize6,
    },
    {
        id: '659f5a1f-a7dc-4ee7-866c-3f56e1220f76',
        name: 'Ролл для МФР',
        description: <><p>Поздравляем! Вы выиграли <b>ролл для МФР</b>. Это массажный ролик для&nbsp;фитнеса, йоги или пилатеса&nbsp;— ваш новый фитнес-помощник для красивого тела. Он поможет расслабить мышцы, снять напряжение, проработать проблемные зоны и&nbsp;добавит видимый эффект вашим тренировкам.</p></>,
        limit: window.lps_settings.limits.prize7,
    },
    {
        id: '27be1426-ac67-45d8-93e9-bce015db9b42',
        name: 'Беспроводная портативная колонка JBL Clip 4 ',
        description: <><p>Поздравляем! Вы выиграли <b>беспроводную портативную колонку JBL&nbsp;Clip&nbsp;4</b>. Это небольшая и&nbsp;компактная портативная колонка с&nbsp;высоким качеством звучания. Внешне она похожа на&nbsp;большой брелок и&nbsp;занимает мало места. Теперь вы сможете любое место превратить в вечеринку, сделать встречи с&nbsp;друзьями ярче и&nbsp;тренироваться дома под классный звук.</p></>,
        limit: window.lps_settings.limits.prize8,
    },
    {
        id: 'a3d72236-e194-4162-9c51-1879cdadb048',
        name: 'Пароварка электрическая Tefal VC',
        description: <><p>Поздравляем! Вы выиграли <b>электрическую пароварку Tefal VC&nbsp;145130</b>. Она поможет вам экономить время на&nbsp;приготовлении пищи каждый день и&nbsp;питаться правильно. Вам достаточно придумать, что вы будете есть и&nbsp;закинуть нужные продукты. А&nbsp;сэкономленное время лучше потратьте на&nbsp;себя или тренировку.</p></>,
        limit: window.lps_settings.limits.prize9,
    },
    {
        id: '906381ce-187c-459e-a7e7-2d75732d1638',
        name: 'Набор фитнес-резинок из 6 шт.',
        description: <><p>Поздравляем! Вы выиграли <b>набор фитнес-резинок</b>. Ваш новый фитнес-помощник для красивого тела. Резинки для нагрузок разной интенсивности помогут разнообразить привычные тренировки и&nbsp;заниматься везде, где вам удобно. Набор настолько компактен, что не&nbsp;займет много места в&nbsp;рюкзаке или чемодане. </p></>,
        limit: window.lps_settings.limits.prize10,
    },
    {
        id: 'df198384-410d-4554-b049-10a0477a2032',
        name: 'Фитнес-рюкзак',
        description: <><p>Поздравляем! Вы выиграли <b>фитнес-рюкзак</b>. Он поможет вам переносить все необходимое для тренировок и&nbsp;других активностей. В&nbsp;нём есть вместительные карманы, прочные материалы и&nbsp;внутреннее удобство. Собирайтесь на&nbsp;тренировку с&nbsp;удовольствием.</p></>,
        limit: window.lps_settings.limits.prize11,
    },
    {
        id: '8256dc04-b517-4312-9f63-3ded89845558',
        name: 'Фен-стайлер Dyson',
        description: <><p>Поздравляем! Вы выиграли <b>фен-стайлер Dyson</b>. Это мечта всех девушек! Мощный, но при этом тихий, невероятно удобный и&nbsp;качественный фен. Теперь вы сможете сушить волосы в&nbsp;2&nbsp;раза быстрее, делать шикарные укладки и&nbsp;всегда выглядеть отлично.</p></>,
        limit: window.lps_settings.limits.prize12,
    },
    {
        id: '5c2450cf-21b5-4b89-9631-e96aa9dd8e0a',
        name: 'Массажёр для шеи',
        description: <><p>Поздравляем! Вы выиграли <b>массажёр для&nbsp;шеи</b>. Удобный массажёр со&nbsp;сферической головкой расслабит ваши мышцы всего за&nbsp;10&nbsp;минут и&nbsp;позволит вам быстро перезагрузится.</p></>,
        limit: window.lps_settings.limits.prize13,
    },
    {
        id: '3b67dca2-8ab0-4303-9021-88547a593a91',
        name: 'Аппарат для приготовления коллагеновых масок',
        description: <><p>Поздравляем! Вы выиграли <b>аппарат для приготовления коллагеновых масок</b>. Теперь вы сможете самостоятельно обеспечить своей коже профессиональный уход. Маски можно с&nbsp;легкостью готовить дома, используя любые овощи, фрукты, ягоды и&nbsp;другие полезные наполнители.</p></>,
        limit: window.lps_settings.limits.prize14,
    },
    {
        id: '7b6375bf-ce6c-4f3e-9804-90ecc5feedc5',
        name: 'Блендер-смузи Fresh juice mixbottle',
        description: <><p>Поздравляем! Вы выиграли <b>блендер-смузи Fresh juice mixbottle</b>. Теперь с&nbsp;лёгкостью готовьте любимые напитки в&nbsp;любое время и&nbsp;в&nbsp;любом месте: на&nbsp;работе, в&nbsp;спортзале или во&nbsp;время прогулки. Придерживайтесь здорового питания везде!</p></>,
        limit: window.lps_settings.limits.prize15,
    },
    {
        id: '137fa9cd-2799-45d1-9941-023f1fc5a36c',
        name: 'Спортивный бокс',
        description: <><p>Поздравляем! Вы выиграли <b>спортивный бокс</b>. Теперь у&nbsp;вас есть полный набор фитнес-принадлежностей для полноценных тренировок: фитнес-резинки, массажный мяч, блок для йоги и&nbsp;шейкер. Тренируйтесь с&nbsp;комфортом!</p></>,
        limit: window.lps_settings.limits.prize16,
    },
    {
        id: '6ee06e9a-b286-4431-86be-9bb0b97e565e',
        name: 'Электронный гриль Tefal Optigrill+ GC712D34',
        description: <><p>Поздравляем! Вы выиграли <b>электронный гриль Tefal Optigrill+ GC712D34</b>. Считайте, что наняли профессионального шеф-повара на&nbsp;дом. Его сенсор определяет, сколько кусочков и&nbsp;какой толщины вы положили, и&nbsp;автоматически подбирает оптимальную температуру. Вам будет нужно только выбрать желаемый режим на&nbsp;дисплее.</p></>,
        limit: window.lps_settings.limits.prize17,
    },
    {
        id: 'd1033725-a5a4-4675-a077-32ea13da5bb9',
        name: 'Увлажнитель воздуха Xiaomi Humidifier 2 Lite',
        description: <><p>Поздравляем! Вы выиграли <b>увлажнитель воздуха Xiaomi Humidifier 2 Lite</b>. Тихий, стильный, компактный и&nbsp;очень функциональный увлажнитель воздуха идеально справляется со&nbsp;своей задачей. Это то, что вам обязательно пригодится в&nbsp;отопительный сезон.</p></>,
        limit: window.lps_settings.limits.prize18,
    },
    {
        id: 'b71d8d53-2c9b-4e8b-9210-80d1aeb3426e',
        name: 'Бонус «Детокс» с Катей Усмановой',
        description: <><p>Поздравляем! Вы выиграли <b>марафон «Детокс» с&nbsp;Катей Усмановой</b>. Информация поможет пересмотреть свой рацион, понять, какие продукты вызывают тяжесть и&nbsp;как быстро очистить организм. Добавьте себе лёгкости и&nbsp;воздушности в&nbsp;самоощущении!</p></>,
        limit: window.lps_settings.limits.prize19,
    },
    {
        id: '4c3bc7f1-546e-473a-b1a9-0305150c8889',
        name: 'План питания от Марины Макиши',
        description: <><p>Поздравляем! Вы выиграли <b>«План питания» от&nbsp;Марины Макиши</b>. Набор уроков, лекций и&nbsp;рецептов от&nbsp;врача-диетолога Марины Макиши. Вы разберетесь со&nbsp;своим питанием, научитесь относиться к&nbsp;еде осознанно. Узнаете простые правила похудения, которые помогут на&nbsp;пути к&nbsp;телу мечты.</p></>,
        limit: window.lps_settings.limits.prize20,
    },
]

export const L2023_11_Chpbox = () => {
    const [modalActive, setModalActive] = useState(false)
    const [activePrize, setActivePrize] = useState({})
    const [isSelected, setIsSelected] = useState(false)
    const [alreadyShow, setAlreadyShow] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    function randomNumber(min, max) { 
        return Math.round(Math.random() * (max - min) + min);
    }

    useEffect(()=> {
        {prizes.map((i, idx)=>{
            checkUserInGroup(i.id).then(res => {
                // console.log(res == null)
                if(res !== null) {
                    // console.log(res)
                    setAlreadyShow(true)
                }
            })
            if(idx + 1 == prizes.length) {
                setTimeout(()=>{
                    setIsLoading(false)
                }, 2000)
            }
        })}
        
    }, [])
    
    function givePrize() {
        setIsSelected(true)
        
        checkUserInSegment(window.lps_settings.segments.segment_chp_prizes).then(res => {
            // console.log(res)
        if (!res) {
            return false;
        }
            Promise.all(prizes.map((i,idx) => getUserCountByGroup(i.id))).then((values) => {

                const availablePrizes = prizes.filter((i, idx) => {
                    return values[idx] < i.limit;
                });
                
                if (availablePrizes.length === 0) {
                    return false;
                }
                
                const randomPriceIdx = randomNumber(0, availablePrizes.length - 1);
                const prize = availablePrizes[randomPriceIdx];

                addUserToGroup(prize.id).then((res)=>{
                    setModalActive(true)
                    setActivePrize(prize) 
                }).catch((e)=>{
                    setModalActive(true)
                    setActivePrize({
                        id: 'b71d8d53-2c9b-4e8b-9210-80d1aeb3426e',
                        name: 'Бонус «Детокс» с Катей Усмановой',
                        description: <><p>Поздравляем! Вы выиграли <b>марафон «Детокс» с&nbsp;Катей Усмановой</b>. Информация поможет пересмотреть свой рацион, понять, какие продукты вызывают тяжесть и&nbsp;как быстро очистить организм. Добавьте себе лёгкости и&nbsp;воздушности в&nbsp;самоощущении!</p></>,
                        limit: window.lps_settings.limits.prize19,
                    })
                    console.log(e.message)
                })                            

                return prize;
              })
            //   .then((prize) => console.log(prize));
        
      });
    }

    function Boxes() {
        return (
            <>
                {!modalActive ?
                                <>
                                    <div className={styles.title1}>ВАС ЖДЁТ ГАРАНТИРОВАННЫЙ&nbsp;ПРИЗ</div>
                                    <div className={styles.title2}>ПРИЗОВЫЕ<br/> БОКСЫ</div>
                                    <div className={`${styles.prizes_block} ${isSelected && styles.prizes_block__active}`}>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/box.png" alt="" />
                                        </div>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/box.png" alt="" />
                                        </div>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/box.png" alt="" />
                                        </div>
                                    </div>
                                    <div className={styles.title3}>Прямо сейчас открывайте один из&nbsp;призовых<br/> боксов и&nbsp;получайте свой подарок!</div>
                                    </>
                                    :
                                    <>  
                                    <div className={styles.win}>
                                        <div className={styles.win_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/box_open.png" alt="" /></div>
                                        <div className={styles.win_name}>
                                            {activePrize.name}
                                        </div>
                                        <div className={styles.win_desc}>
                                            {activePrize.description}
                                        </div>
                                        <div className={styles.win_more}>Подробная информация о&nbsp;получении подарка у&nbsp;вас&nbsp;на&nbsp;почте.</div>
                                    </div>
                                    </>
                            }
            </>
        )
    }

    function LoginForm() {
        return (
          <>
            <Authorized>
                <HideForSegment id={window.lps_settings.segments.segment_chp_prizes}>
                    <Hidden/>
                </HideForSegment>

                <ShowForSegment id={window.lps_settings.segments.segment_chp_prizes}>
                
                {isLoading
                ? <div className={styles.loader_block}><div className={styles.loader}><div></div><div></div><div></div><div></div></div></div>
                : <>
                    {alreadyShow
                        ? <Already />
                        : <Boxes/>
                    }
                  </>
                }
                                
                </ShowForSegment>
                
        {!modalActive && 
        <div className={styles.ucan}>
            <div className={`${styles.promo_img} ${styles.promo_img_1}`}>
                <div></div>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_1.png" alt="" />
                <p>Apple Watch SE 2022</p>
            </div>
            <div className={`${styles.promo_img} ${styles.promo_img_2}`}>
                <div></div>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_2.png" alt="" />
                <p>Фен-стайлер Dyson</p>
            </div>
            <div className={`${styles.promo_img} ${styles.promo_img_3}`}>
                <div></div>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_3.png" alt="" />
                <p>Электронные весы</p>
            </div>
            <div className={`${styles.promo_img} ${styles.promo_img_4}`}>
                <div></div>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_4.png" alt="" />
                <p>iPhone 15 Pro</p>
            </div>
            <div className={`${styles.promo_img} ${styles.promo_img_5}`}>
                <div></div>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_5.png" alt="" />
                <p>Портативная<br/> колонка</p>
            </div>
            <div className={`${styles.promo_img} ${styles.promo_img_6}`}>
                <div></div>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_6.png" alt="" />
                <p>Apple AirPods Max</p>
            </div>
            <div className={`${styles.promo_img} ${styles.promo_img_7}`}>
                <div></div>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_7.png" alt="" />
                <p>Электронный гриль</p>
            </div>
            <div className={`${styles.promo_img} ${styles.promo_img_8}`}>
                <div></div>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_8.png" alt="" />
                <p>Денежные призы</p>
            </div>
        </div>
        }
            
            </Authorized>
            <Anonymous>
                <div className={styles.prizes_ghost_title}>Чтобы получить доступ к&nbsp;боксам Черной Пятницы нужно авторизоваться</div>
                <div className="velvet_prizes_login"><LoginWidget /></div>
            </Anonymous>
          </>
        );
      }

    return (
        <>
        <DocumentTitle value='Призовые боксы'/>
        <DocumentDescription value='' />
        <div className={styles.prizes}>
            <div className={styles.prizes_main}>
               <LoginForm />
            </div>
            
        </div>
        <div className={styles.line}>
                <div className={styles.line_text}>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                </div>    
            </div>
        <Footer version={2} />
        </>
    );
};