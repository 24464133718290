import { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper";

import styles from "./reviews.module.css";

const Reviews = ({ scroll }) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [items, setItems] = useState([
    { src: "https://gymteam.kinescopecdn.net/img/l/2024/um2/reviewx_6.jpg" },
    { src: "https://gymteam.kinescopecdn.net/img/l/2024/um2/reviewx_4.jpg" },
    { src: "https://gymteam.kinescopecdn.net/img/l/2024/um2/reviewx_8.jpg" },
    { src: "https://gymteam.kinescopecdn.net/img/l/2024/um2/reviewx_5.jpg" },
    { src: "https://gymteam.kinescopecdn.net/img/l/2024/um2/reviewx_2.jpg" },
    { src: "https://gymteam.kinescopecdn.net/img/l/2024/um2/reviewx_7.jpg" },
    { src: "https://gymteam.kinescopecdn.net/img/l/2024/um2/reviewx_3.jpg" },
    { src: "https://gymteam.kinescopecdn.net/img/l/2024/um2/reviewx_1.jpg" },
  ]);

  const [isModal, setIsModal] = useState(false);
  const [activeSlide, setActiveSlide] = useState(null);

  return (
    <div className={styles.reviews}>
      <div className="container">
        <div className={styles.reviews_title}>
          Посмотри, что пишут о&nbsp;программе
          <br /> участницы 1&nbsp;потока и&nbsp;<em>убедись
          <br /> <span>в&nbsp;её эффективности</span></em>
        </div>

        <div className={styles.block}>
          <Swiper
            speed={800}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            slidesPerView={1}
            spaceBetween={20}
            // autoHeight={true}
            breakpoints={{
              576: {
                slidesPerView: 3,
              },
            }}
            loop={true}
            onSwiper={(swiper) => {
              setTimeout(() => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;

                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              });
            }}
            modules={[Navigation]}
            className={`${styles.swiper}`}
          >
            {items.map((item, idx) => (
              <SwiperSlide
                className={styles.slide}
                key={item.idx}
                onClick={() => (
                  setActiveSlide(items[idx].src), setIsModal(true)
                )}
              >
                <img src={item.src} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className={styles.nav}>
            <div ref={navigationPrevRef} className={styles.slider_btn_prev}>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/arrow_slide.svg"
                alt=""
              />
            </div>
            <div ref={navigationNextRef} className={styles.slider_btn_next}>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/arrow_slide.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        {/* <div className={styles.btn} onClick={scroll}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                    <p>Записаться на&nbsp;курс</p>
                </div> */}

        <div className={`${styles.modal} ${isModal && styles.modal_active}`}>
          <div className={styles.close_btn} onClick={() => setIsModal(false)}>
            <svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.1949 1.48389L1.33203 15.3468M1.33203 1.48389L15.1949 15.3468"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className={styles.modal_img}>
            <img src={activeSlide} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
