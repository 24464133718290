import Countdown from 'react-countdown';
import styles from './promo3.module.css';

const Promo3 = ({scroll, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className="container promo2new promo3new">

                <div className={styles.promo2}>
                    <div className={styles.promo2_left}>
                        <div className={styles.promo2_left_top}>
                            <div className={styles.promo2_title}>Создай<br/> идеальную фигуру</div>
                            <div className={styles.promo2_subtitle}>по&nbsp;Методу Усмановой</div>
                            <div className={styles.promo2_text}>28&nbsp;дней комплексной работы над телом через продуманные тренировки, сбалансированное питание, психологию и&nbsp;правильное окружение</div>
                            <div className={styles.main_bottom_right_btn} onClick={scroll}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                                <p>Хочу приобрести</p>
                                <p>Хочу приобрести</p>
                            </div>
                        </div>
                        {/* <div className={styles.promo2_timer}>
                            <p>Продажи закроются через:</p>
                            <Countdown date={Date.now() + date} renderer={renderer} /> 
                        </div> */}
                        {/* <div className={styles.promo2_circle_mob}>Количество мест<br/> ограничено</div> */}
                        <div className={styles.promo2_circle}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/circle2.svg" alt="" />
                        </div>
                        
                    </div>

                    <div className={styles.promo2_right}>
                        <div className={styles.promo2_right_top}>
                            <p>Новая программа<br/> Кати&nbsp;Усмановой</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/promo4.png" alt="" />
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/promo2_bg2.png" alt="" />
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/promo4.png" alt="" />
                        </div>
                        <div className={styles.promo2_prize}>
                            <div className={styles.promo2_prize_info}>
                                <p>Участвуй в&nbsp;конкурсе до/после</p>
                                <p><span>и&nbsp;выиграй тур в&nbsp;Дубай</span></p>
                            </div>
                            <div className={styles.promo2_prize_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/method/promo_prize2.jpg" alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Promo3;