import React, { useEffect } from 'react';
import { DocumentDescription, DocumentTitle } from '../components/DocumentMeta';
import About from './components/About';
import Promo from './components/Promo';
import Footer from '../components/Footer';
import Marafons from './components/Marafons';
import Courses from './components/Courses';
import Author from './components/Author';
import Studentres from './components/Studentres';
import Faq from './components/Faq';
import { RedirectPrograms } from '../components/redirect/RedirectProgram';

import './style.css';
import Platform from '../components/platform/Platform';

export const L2022_12_Mainpage = () => {
    
    useEffect(() => {
        document.body.id = 'rasprozarka_2305'
        document.body.parentElement.id = 'htmlrasprozarka_2305'
    }, [])

    const scroll = () => {
        const section = document.querySelector( '.scrollhere' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    const scrollStart = () => {
        const section = document.querySelector( '.scrollstart' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    return (
        <>
        <RedirectPrograms />
        <DocumentTitle value='Все онлайн-тренировки Екатерины Усмановой в GymTeam'/>
        <DocumentDescription value='Все курсы и марафоны Кати Усмановой в GymTeam — программы онлайн-фитнеса для дома и зала. Онлайн-тренировки, чтобы быстро похудеть, накачать ягодицы и пресс, сжечь жир и избавиться от целлюлита.' />
        <div className='mainpage'>
          <Promo scroll={scroll} />
          <About/>
          <Marafons/>
          <Courses/>
          <Author/>
          <Platform />
          <Studentres scrollStart={scrollStart} />
          <Faq scroll={scroll} />
          <Footer/>
        </div>
        </>
    );
};