import styles from './mission.module.css';

const Mission = ({scroll}) => {
    return (
        <>
        <div className={styles.mission}>
            <div className="container">
                <div className={styles.mission_top}>
                    <div className={styles.mission_top__left}>
                        <div className={styles.mission_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini/mission_img.jpg" alt="" />
                        </div>
                    </div>
                    <div className={styles.mission_top__right}>
                        <div className={styles.mission_title}>У&nbsp;Кати Усмановой<br/> больше 100&nbsp;бикини <span>Она знает, как выглядеть в&nbsp;них потрясающе</span></div>
                        <div className={styles.mission_subtitle}>Почти 8&nbsp;лет она создаёт программы для девушек, которые не&nbsp;боятся исполнять свои мечты о&nbsp;красивой и&nbsp;подтянутой фигуре. <span>Уже более 250&nbsp;000 участниц тренируются с&nbsp;Катей, ведь она:</span></div>
                    </div>
                </div>
                <div className={styles.mission_bottom}>
                    <div className={styles.mission_bottom__block}>
                        <div>Создатель платформы <img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini/logo.svg" alt="" /></div>
                        <div><strong>Чемпионка России и&nbsp;мира</strong><br/> по&nbsp;жиму лёжа</div>
                        <div>Профессиональный фитнес-<br/>тренер <strong>с&nbsp;опытом более&nbsp;15&nbsp;лет</strong></div>
                        <div>Вице-чемпионка мира<br/> и&nbsp;<strong>чемпионка России по&nbsp;фитнес-<br/>бикини</strong></div>
                        <div><strong>Автор первых в&nbsp;России</strong><br/> масштабных марафонов<br/> стройности</div>
                        <div>Мама 2-х&nbsp;детей. <strong>Всего за&nbsp;100&nbsp;дней после первых родов похудела на&nbsp;20&nbsp;кг</strong> и&nbsp;вернулась в&nbsp;прежнюю форму</div>                        
                    </div>
                </div>
                <div className={styles.mission_more}>И это&nbsp;не&nbsp;всё! Катя Усманова мотивирует<br/> на&nbsp;результат <b>и&nbsp;всегда дарит подарки</b>&nbsp;🎁</div>
            </div>
        </div>
        </>
    )
}

export default Mission;