import Countdown from "react-countdown";
import styles from "./gifts.module.css";
import { useState } from "react";

const Gifts = ({ scroll, renderer }) => {
  const [date, setDate] = useState(Date.now() + 900000);

  return (
    <div className={styles.block4}>
      <div className="container">
        <div className={styles.b1}>
          <div className={styles.b1_t0}>
            БЕЗ ПОДАРКОВ НЕ&nbsp;ОСТАВИМ НИКОГО!
          </div>
          <div className={styles.b1_t1}>
            Успей присоединиться <span>в течение 15 минут</span>
            <br /> чтобы принять участие в&nbsp;розыгрыше iPhone
            <br /> 15&nbsp;Pro и&nbsp;получить 5&nbsp;полезных гайдов!
          </div>
          <div className={styles.b1_t2}>
            Оплатив сейчас, ты&nbsp;получишь возможность выиграть один
            <br /> из&nbsp;пяти iPhone 15&nbsp;Pro, а&nbsp;также гарантированно
            получить
            <br /> 5&nbsp;полезных гайдов в&nbsp;подарок.
          </div>
          <div className={styles.timer1}>
            <Countdown date={date} renderer={renderer} />
          </div>
        </div>

        <div className={styles.b2}>
          <div className={styles.b2_t1}>Бонус #1</div>
          <div className={styles.b2_t2}>
            ВЫИГРАЙ
            <br /> IPHONE 15&nbsp;PRO!
          </div>
          <div className={styles.b2_t3}>
            20&nbsp;сентября в&nbsp;12:00 мск разыграем целых
            <br /> <b>5&nbsp;iPhone 15&nbsp;Pro</b> среди всех купивших!
          </div>
          <div className={styles.b2_t4}>
            Победителей выберем рандомно&nbsp;&mdash; используй свой шанс!
          </div>
        </div>

        <div className={styles.b3}>
          <div className={styles.b3_t0}>Бонус #2</div>
          <div className={styles.b3_t1}>Подарков много не&nbsp;бывает!</div>
          <div className={styles.b3_t2}>
            Дарим 5&nbsp;полезных
            <br /> гайдов при покупке
          </div>
          <div className={styles.b3_t3}>
            С&nbsp;ними ты&nbsp;научишься тратить меньше времени
            на&nbsp;готовку, снизишь
            <br /> потребление сахара и&nbsp;сделаешь тренировки эффективнее
          </div>
          <div className={styles.b3_block}>
            <div className={styles.b3_item}>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/um2/g_1.png"
                alt=""
              />
              <div className={styles.b3_item_t0}>Гайд</div>
              <div className={styles.b3_item_t1}>
                &laquo;Альтернатива: чем заменить упражнения, которые вызывают
                дискомфорт&raquo;
              </div>
              <div className={styles.b3_item_t2}>
                Ты&nbsp;научишься подбирать программу тренировки под себя!
                Тяжёло делать выпады? Не&nbsp;может стоять в&nbsp;планке?
                Используй любое из&nbsp;альтернативных упражнений, которое
                задействует ту&nbsp;же группу мышц и&nbsp;занимайся
                в&nbsp;удовольствие.
              </div>
            </div>
            <div className={styles.b3_item}>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/um2/g_2.png"
                alt=""
              />
              <div className={styles.b3_item_t0}>Инструкция</div>
              <div className={styles.b3_item_t1}>
                &laquo;Как избавиться от&nbsp;тяги к&nbsp;сладкому
                и&nbsp;перестать быть сладкоежкой&raquo;
              </div>
              <div className={styles.b3_item_t2}>
                Не&nbsp;можешь пройти мимо конфет и&nbsp;тортиков? Разберёшься,
                что стоит за&nbsp;твоей тягой к&nbsp;быстрым
                углеводам&nbsp;&mdash; а&nbsp;это целых 6&nbsp;причин!
                &mdash;&nbsp;и&nbsp;получишь целый комплекс действий, как
                снизить тягу к&nbsp;сладкому.
              </div>
            </div>
            <div className={styles.b3_item}>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/um2/g_3.png"
                alt=""
              />
              <div className={styles.b3_item_t0}>Инструкция</div>
              <div className={styles.b3_item_t1}>
                Как снять боль в&nbsp;мышцах после тренировки
              </div>
              <div className={styles.b3_item_t2}>
                Боишься даже начать тренироваться, потому что предвидишь, что
                на&nbsp;следующее утро не&nbsp;сможешь встать с&nbsp;постели?
                Мы&nbsp;предлагаем комплекс из&nbsp;5&nbsp;шагов, как
                предотвратить боль в&nbsp;мышцах и&nbsp;рекомендации, что
                делать, если всё тело болит. Это повысит эффективность твоей
                программы и&nbsp;даст тебе результат быстрее.
              </div>
            </div>
            <div className={styles.b3_item}>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/um2/g_4.png"
                alt=""
              />
              <div className={styles.b3_item_t0}>
                Инструкция по&nbsp;питанию
              </div>
              <div className={styles.b3_item_t1}>
                Как составить сбалансированный рацион
              </div>
              <div className={styles.b3_item_t2}>
                Узнаешь главные принципы сбалансированного питания
                и&nbsp;научишься строить на&nbsp;них свой рацион: питаться
                сытно, использовать &laquo;правило тарелки&raquo;
                на&nbsp;практике и&nbsp;выбирать продукты по&nbsp;упаковке.
              </div>
            </div>
            <div className={styles.b3_item}>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/um2/g_5.png"
                alt=""
              />
              <div className={styles.b3_item_t0}>Гайд</div>
              <div className={styles.b3_item_t1}>
                Всё о&nbsp;целлюлите: что это, почему возникает и&nbsp;как
                от&nbsp;него избавиться
              </div>
              <div className={styles.b3_item_t2}>
                Узнаешь о&nbsp;преимуществах и&nbsp;недостатках разных методов
                по&nbsp;борьбе с&nbsp;целлюлитом и&nbsp;сможешь выбрать тот, что
                подходит тебе. Также получишь универсальный чек-лист
                по&nbsp;избавлению от&nbsp;&laquo;апельсиновой корки&raquo;.
              </div>
            </div>
          </div>
          <div className={styles.promo_btn} onClick={scroll}>
            <img
              src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg"
              alt=""
            />
            <p>
              Получить бонусы
              <br /> бесплатно
            </p>
          </div>
        </div>

        {/* <div className={styles.sticky}>
                    <div className={styles.sticky_left}>
                        <div className={styles.sticky_sticky}>
                            <div className={styles.pre_1}>Бонус #2</div>
                            <div className={styles.s1}>Подарков много не&nbsp;бывает!</div>
                            <div className={styles.s2}>Дарим<br/> 5&nbsp;полезных<br/> гайдов при покупке</div>
                            <div className={styles.s3}>С&nbsp;ними ты&nbsp;научишься тратить меньше времени<br/> на&nbsp;готовку, снизишь потребление сахара и&nbsp;сделаешь<br/> тренировки эффективнее</div>
                            <div className={styles.promo_btn} onClick={scroll}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                                <p>Получить бонусы<br/> бесплатно</p>
                            </div>                            
                        </div>
                    </div>
                    <div className={styles.sticky_right}>
                        <div className={styles.sticky_item}>
                            <span>Гайд</span>
                            <p>&laquo;Альтернатива: чем заменить упражнения, которые вызывают дискомфорт&raquo;</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/um2/gift_1.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Инструкция</span>
                            <p>&laquo;Как избавиться от&nbsp;тяги к&nbsp;сладкому и&nbsp;перестать быть сладкоежкой&raquo;</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/um2/gift_2.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Инструкция</span>
                            <p>Как снять боль в&nbsp;мышцах после тренировки</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/um2/gift_3.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Инструкция по&nbsp;питанию:</span>
                            <p>Как составить сбалансированный рацион</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/um2/gift_4.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Гайд</span>
                            <p>&quot;Всё о&nbsp;целлюлите: что это, почему возникает и&nbsp;как от&nbsp;него избавиться</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/um2/gift_5.png" alt="" />
                        </div>
                    </div>
                    <div className={styles.st_mob}>
                        <div className={styles.promo_btn} onClick={scroll}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                            <p>Получить бонусы<br/> бесплатно</p>
                        </div>
                        <div className={styles.infos}>Успей забрать набор программ<br/> и&nbsp;получить ВСЕ бонусы</div>
                        <div className={styles.timer1}>
                            <Countdown date={Date.now() + 900000} renderer={renderer} />
                        </div>
                    </div>
                </div> */}
      </div>
    </div>
  );
};

export default Gifts;
