import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper";

import styles from './reviews.module.css';

const Reviews = ({scroll}) => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    return (
        <>
        <div className={styles.reviews}>
            <div className="container">
                <div className={styles.reviews_pretitle}>На случай, если вы ещё сомневаетесь,</div>
                <div className={styles.reviews_title}>просто почитайте, что&nbsp;говорят<br/> наши ученицы</div>
                {window.innerWidth >= 576 ?
                <div className={styles.reviews_items}>
                    <div className={styles.reviews_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker2.png" alt="" /> 
                        <p>Чтобы ягодицы и&nbsp;ноги не&nbsp;налились после марафона?! Да&nbsp;такое просто невозможно!</p>
                    </div>
                    <div className={styles.reviews_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker3.png" alt="" /> 
                        <p>Да, попа не&nbsp;главное, но&nbsp;как чертовски приятно осознавать, что она у&nbsp;тебя стала идеальная!</p>
                    </div>
                    <div className={styles.reviews_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker2.png" alt="" /> 
                        <p>Катюша даже из&nbsp;бочки сделает стройную красотку, проверено на&nbsp;мне!</p>
                    </div>
                    <div className={styles.reviews_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker3.png" alt="" /> 
                        <p>Тренер в&nbsp;спортзале был в&nbsp;шоке с&nbsp;моего результата&nbsp;— сама под&nbsp;руководством Кати я&nbsp;сделала это!</p>
                    </div>
                    <div className={styles.reviews_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker3.png" alt="" /> 
                        <p>Из унылой попы в&nbsp;Джей&nbsp;Ло!</p>
                    </div>
                    <div className={styles.reviews_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker2.png" alt="" /> 
                        <p>Я сошла с&nbsp;картинки из&nbsp;карты желаний</p>
                    </div>
                    <div className={styles.reviews_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker3.png" alt="" /> 
                        <p>Каждый день ловлю взгляды мужчин</p>
                    </div>
                    <div className={styles.reviews_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker2.png" alt="" /> 
                        <p>Глазам не&nbsp;верю&nbsp;— это&nbsp;моя&nbsp;🍑</p>
                    </div>
                    <div className={styles.reviews_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker2.png" alt="" /> 
                        <p>Мои ягодицы заслуживают Оскара!</p>
                    </div>
                    <div className={styles.reviews_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker3.png" alt="" /> 
                        <p>Катя&nbsp;– главный конструктор красивых ягодиц.</p>
                    </div>
                    <div className={styles.reviews_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker2.png" alt="" /> 
                        <p>Мой парень заметил меня благодаря выдающейся попе</p>
                    </div>
                    <div className={styles.reviews_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker3.png" alt="" /> 
                        <p>Мое бикини ещё никогда так на мне не&nbsp;сидело</p>
                    </div>
                    <div className={styles.reviews_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker3.png" alt="" /> 
                        <p>Это самая эффективная и&nbsp;продуманная программа из&nbsp;всех</p>
                    </div>
                    <div className={styles.reviews_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker2.png" alt="" /> 
                        <p>Не&nbsp;могу пройти мимо зеркала, не&nbsp;полюбовавшись на&nbsp;попу</p>
                    </div>
                    <div className={styles.reviews_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker3.png" alt="" /> 
                        <p>Когда ожидание и&nbsp;реальность совпали на&nbsp;100%</p>
                    </div>
                    <div className={styles.reviews_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker2.png" alt="" /> 
                        <p>Пойду на&nbsp;конкурс самые красивые ягодицы👌</p>
                    </div>
                </div>

                :
                <>
                <Swiper
                    speed={800}
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    slidesPerView={'auto'}
                    spaceBetween={20}
                    onSwiper={(swiper) => {
                        setTimeout(() => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current
                        swiper.params.navigation.nextEl = navigationNextRef.current
                        
                        swiper.navigation.destroy()
                        swiper.navigation.init()
                        swiper.navigation.update()
                        })
                    }}
                    modules={[Navigation]}
                    className={`swiper_gt ${styles.swiper}`}
                >
                    <SwiperSlide className={styles.slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker2.png" alt="" /> 
                        <p>Чтобы ягодицы и&nbsp;ноги не&nbsp;налились после марафона?! Да&nbsp;такое просто невозможно!</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker3.png" alt="" /> 
                        <p>Да, попа не&nbsp;главное, но&nbsp;как чертовски приятно осознавать, что она у&nbsp;тебя стала идеальная!</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker2.png" alt="" /> 
                        <p>Катюша даже из&nbsp;бочки сделает стройную красотку, проверено на&nbsp;мне!</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker3.png" alt="" /> 
                        <p>Тренер в&nbsp;спортзале был в&nbsp;шоке с&nbsp;моего результата&nbsp;— сама под&nbsp;руководством Кати я&nbsp;сделала это!</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker3.png" alt="" /> 
                        <p>Из унылой попы в&nbsp;Джей&nbsp;Ло!</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker2.png" alt="" /> 
                        <p>Я сошла с&nbsp;картинки из&nbsp;карты желаний</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker3.png" alt="" /> 
                        <p>Каждый день ловлю взгляды мужчин</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker2.png" alt="" /> 
                        <p>Глазам не&nbsp;верю&nbsp;— это&nbsp;моя&nbsp;🍑</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker2.png" alt="" /> 
                        <p>Мои ягодицы заслуживают Оскара!</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker3.png" alt="" /> 
                        <p>Катя&nbsp;– главный конструктор красивых ягодиц.</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker2.png" alt="" /> 
                        <p>Мой парень заметил меня благодаря выдающейся попе</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker3.png" alt="" /> 
                        <p>Мое бикини ещё никогда так на мне не&nbsp;сидело</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker3.png" alt="" /> 
                        <p>Это самая эффективная и&nbsp;продуманная программа из&nbsp;всех</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker2.png" alt="" /> 
                        <p>Не&nbsp;могу пройти мимо зеркала, не&nbsp;полюбовавшись на&nbsp;попу</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker3.png" alt="" /> 
                        <p>Когда ожидание и&nbsp;реальность совпали на&nbsp;100%</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/marker2.png" alt="" /> 
                        <p>Пойду на&nbsp;конкурс самые красивые ягодицы👌</p>
                    </SwiperSlide>
                </Swiper>
                <div className={styles.slider_nav}>
                    <div ref={navigationPrevRef} className={styles.slider_btn_prev}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/arrow_slide.svg" alt="" />
                    </div>
                    <div ref={navigationNextRef} className={styles.slider_btn_next}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/arrow_slide.svg" alt="" />
                    </div>
                </div>
                </>
                }
                <div className={styles.reviews_title2}>Готовы так же?</div>
                <div className={styles.reviews_btn} onClick={scroll}>ХОЧУ ЯГОДИЦЫ PUSH-UP</div>
            </div>
        </div>
    </>
    )
}

export default Reviews;