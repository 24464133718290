import styles from './knows.module.css';

const Knows = ({scroll}) => {
    return (
        <div className={styles.knows}>
            <div className="container">
                <div className={styles.knows_title}>СЕЗОН БИКИНИ НАЧАЛСЯ</div>
                <div className={styles.knows_subtitle}>включаем режим ускоренной<br/> подготовки, <b>чтобы НЕ:</b></div>
                <div className={styles.knows_block}>
                    <div className={styles.knows_item}>
                        <div className={styles.knows_item__info}>
                            <div className={styles.knows_number}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini/01.svg" alt="" /></div>
                            <div className={styles.knows_item__info_title}>Пытаться похудеть <b>за&nbsp;неделю до&nbsp;отпуска</b></div>
                        </div>
                    </div>
                    <div className={styles.knows_item}>
                        <div className={styles.knows_item__info}>
                            <div className={styles.knows_number}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini/02.svg" alt="" /></div>
                            <div className={styles.knows_item__info_title}><b>Ругать себя,</b> что надо было начинать раньше</div>
                        </div>
                    </div>
                    <div className={styles.knows_item}>
                        <div className={styles.knows_item__info}>
                            <div className={styles.knows_number}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini/03.svg" alt="" /></div>
                            <div className={styles.knows_item__info_title}>Ходить по&nbsp;пляжу <b>в&nbsp;слитном купальнике и&nbsp;парео</b></div>
                        </div>
                    </div>
                    <div className={styles.knows_item}>
                        <div className={styles.knows_item__info}>
                            <div className={styles.knows_number}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini/04.svg" alt="" /></div>
                            <div className={styles.knows_item__info_title}>Искать оправдания, почему опять <b>не&nbsp;получилось влезть в&nbsp;летнее платье</b></div>
                        </div>
                    </div>
                    <div className={styles.knows_item}>
                        <div className={styles.knows_item__info}>
                            <div className={styles.knows_number}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini/05.svg" alt="" /></div>
                            <div className={styles.knows_item__info_title}><b>Прятать свои недостатки</b> в&nbsp;оверсайзе</div>
                        </div>
                    </div>
                    <div className={styles.knows_item}>
                        <div className={styles.knows_item__info}>
                            <div className={styles.knows_number}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini/06.svg" alt="" /></div>
                            <div className={styles.knows_item__info_title}>Пить чаи для&nbsp;похудения <b>или&nbsp;БАДы</b></div>
                        </div>
                    </div>
                </div>
                <div className={styles.knows_more}>Откройте лето вместе с&nbsp;Катей Усмановой</div>
            </div>
        </div>
    )
}

export default Knows;