import { useEffect } from 'react';
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import Footer from '../../components/Footer';
import Promo from './components/Promo';
import Author from './components/Author';
import Programs1 from './components/Programs';
import Studentres from './components/Studentres';
import Start from './components/Start';
import Info from './components/Info';
import Platform from '../../components/platform/Platform';
import Faq from './components/Faq';

import './style.css';

export const L2023_12_Rkhappy = () => {
    useEffect(() => {
      document.body.id = 'rasprozarka_2305'
      document.body.parentElement.id = 'htmlrasprozarka_2305'
    }, [])

    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value='Специальные условия на 24 часов — 5 фитнес-программ Кати Усмановой с выгодой 90%'/>
        <DocumentDescription value='Только 24 часов! Выгода 90% на 5 фитнес-программ Кати Усмановой. Доступ к программам на 1 год и старт сразу после оплаты' />
        <div className='woweffect'>
            <Promo scroll={scroll} renderer={renderer} date={date} />
            <Author />
            <Info />
            <Programs1 scroll={scroll} />
            <Studentres scroll={scroll} />
            <Start renderer={renderer} date={date} />
            <Platform version={2} />
            <Faq />
            <Footer />
        </div>
      </>
    );
};