import styles from './info.module.css';

const Info = () => {
    return (
        <div className={styles.info}>
            <div className="container">
                <div className={styles.info_title}>По нашим программам занимаются девушки всех возрастов и&nbsp;любого уровня подготовки</div>
                <div className={styles.info_block}>
                    <div className={styles.info_item}>
                        <div className={styles.info_info}>
                            <div className={styles.info_text}>У&nbsp;вас <b>мало свободного времени</b> и&nbsp;вы&nbsp;предпочитаете заниматься фитнесом в&nbsp;удобное для вас время дома.</div>
                        </div>
                    </div>
                    <div className={styles.info_item}>
                        <div className={styles.info_info}>
                            <div className={styles.info_text}><b>Вы&nbsp;— мама</b>, и&nbsp;ищите гибкую и&nbsp;удобную альтернативу тренажёрному залу, чтобы&nbsp;поддерживать свою физическую форму с&nbsp;экономией времени.</div>
                        </div>
                    </div>
                    <div className={styles.info_item}>
                        <div className={styles.info_info}>
                            <div className={styles.info_text}>Вы <b>часто путешествуете</b>, и&nbsp;вам нужны фитнес-программы, которые можно выполнять где угодно, имея только доступ к интернету.</div>
                        </div>
                    </div>
                    <div className={styles.info_item}>
                        <div className={styles.info_info}>
                            <div className={styles.info_text}>Вы только начинаете свой путь к&nbsp;здоровому образу жизни и&nbsp;ищите поддержку и&nbsp;руководство для&nbsp;достижения своих целей.</div>
                        </div>
                    </div>
                    <div className={styles.info_item}>
                        <div className={styles.info_info}>
                            <div className={styles.info_text}>Вы ищите <b>доступные и&nbsp;эффективные фитнес-программы.</b></div>
                        </div>
                    </div>
                    <div className={styles.info_item}>
                        <div className={styles.info_info}>
                            <div className={styles.info_text}>Вы предпочитаете тренироваться в&nbsp;<b>уютной домашней обстановке</b>, без&nbsp;необходимости посещать групповые занятия или&nbsp;тренажерные залы.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Info;