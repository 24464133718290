import { SalesWidget } from "@com.marathonium/web2-ui";
import styles from './start.module.css';

const Start = ({isLine}) => {
    return (
        <div className={('scrollhere ') + styles.start}>
            {isLine && 
            <div className={styles.line}>
                <div className={styles.line_text}>
                <p>РЕШИТЕ САМИ, КАКОЙ БУДЕТ ВАША ТРЕНИРОВКА СЕГОДНЯ</p> <img src="https://gymteam.kinescopecdn.net/img/l/2023/butttraining/marker.svg" alt="" />
                <p>РЕШИТЕ САМИ, КАКОЙ БУДЕТ ВАША ТРЕНИРОВКА СЕГОДНЯ</p> <img src="https://gymteam.kinescopecdn.net/img/l/2023/butttraining/marker.svg" alt="" />
                <p>РЕШИТЕ САМИ, КАКОЙ БУДЕТ ВАША ТРЕНИРОВКА СЕГОДНЯ</p> <img src="https://gymteam.kinescopecdn.net/img/l/2023/butttraining/marker.svg" alt="" />
                <p>РЕШИТЕ САМИ, КАКОЙ БУДЕТ ВАША ТРЕНИРОВКА СЕГОДНЯ</p> <img src="https://gymteam.kinescopecdn.net/img/l/2023/butttraining/marker.svg" alt="" />
                </div>    
            </div>
            }
            <div className="container">
                <div className={styles.start_block}>
                    <div className={styles.start_title}>Заберите универсальные<br/> тренировки на&nbsp;ягодицы<br/> прямо сейчас</div>
                    <div className={styles.start_subtitle}>и пусть ваша попа горит от&nbsp;тренировок,<br/> а&nbsp;не от&nbsp;того, что не&nbsp;готова к&nbsp;лету</div>
                    <div className="butttraining_start">
                        <SalesWidget id={window.lps_settings.forms.universal_id} />
                    </div>
                    <div className={styles.start_credit}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/inshape/tnkf.svg" alt="" />
                        <div className={styles.start_credit__title}>Рассрочка без&nbsp;процентов от&nbsp;799&nbsp;₽ в&nbsp;месяц</div>
                        <div className={styles.start_credit__text}>
                            <p>Вы можете оформить заказ в&nbsp;рассрочку.<br/> На&nbsp;6&nbsp;месяцев без&nbsp;процентов и&nbsp;предварительного взноса (только&nbsp;для&nbsp;граждан&nbsp;РФ).</p>
                        </div>
                    </div>
                    <div className={styles.start_parts}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/pregnancy/start_icon.png" alt="" />
                        <div className={styles.start_parts__title}>Оплатите Долями<br/> без&nbsp;банка, переплат и&nbsp;рисков</div>
                        <div className={styles.start_parts__text}>Не надо ждать до&nbsp;зарплаты — разделите платёж <strong>на&nbsp;4&nbsp;части<br/> от&nbsp;1&nbsp;197,5&nbsp;₽</strong>. Всего на&nbsp;6&nbsp;недель.</div>
                        <div className={styles.start_parts_block}>
                            <div className={styles.start_parts_item}>
                                <div className={styles.start_parts_number}><p>1</p></div>
                                <p>от&nbsp;1&nbsp;197,5&nbsp;₽<br/> <span>сегодня</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number2].join(' ')}><p>2</p></div>
                                <p>от&nbsp;1&nbsp;197,5&nbsp;₽<br/> <span>через 2&nbsp;недели</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number3].join(' ')}><p>3</p></div>
                                <p>от&nbsp;1&nbsp;197,5&nbsp;₽<br/> <span>через 4&nbsp;недели</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number4].join(' ')}><p>4</p></div>
                                <p>от&nbsp;1&nbsp;197,5&nbsp;₽<br/> <span>через 6&nbsp;недель</span></p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.start_cards}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini3/card_icon.png" alt="" />
                        <p>Платите с&nbsp;любой карты&nbsp;— выпущенной российским или&nbsp;иностранным банком.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Start;