import Countdown from 'react-countdown';
import styles from './block1.module.css';
import styles2 from './block1_progs.module.css';

const Block1 = ({renderer}) => {
    return (
        <div className={styles.block1}>
            <div className="container">
                <div className={styles.t1}>Только в&nbsp;течение 15&nbsp;минут вам<br/> <span>доступны 2&nbsp;ценных бонуса</span></div>
                <div className={styles.st1}>При оплате в&nbsp;течение 15&nbsp;минут вы&nbsp;получите в&nbsp;подарок курс<br/> &laquo;Восстановление после родов&raquo; и&nbsp;5&nbsp;полезных гайдов</div>
                <div className={styles.timer1}>
                    <Countdown date={Date.now() + 900000} renderer={renderer} />
                </div>
                <div className={styles.block}>
                    <div className={styles.block_left}>
                        <div className={styles.block_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/gift.png" alt="" />
                        </div>
                        <div className={styles.block_pretitle}>Бонус 1</div>
                        <div className={styles.block_title}>Курс &laquo;Восстановление после родов&raquo;</div>
                        <div className={styles.block_text}>Незаменимая программа для тех, у&nbsp;кого недавно появился малыш и&nbsp;для тех, кто только начинает своё знакомство с&nbsp;фитнесом</div>
                        <div className={styles2.dts}>
                            <div className={styles2.dts_item}>
                                <div className={styles2.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/pr1.svg" alt="" /></div>
                                <div className={styles2.dts_item_text}>Доступ&nbsp;&mdash; 9&nbsp;месяцев</div>
                            </div>
                            <div className={styles2.dts_item}>
                                <div className={styles2.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/pr2.svg" alt="" /></div>
                                <div className={styles2.dts_item_text}>Старт&nbsp;&mdash; сразу после оплаты</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.block_right}>
                        {/* <div className={styles.block_phone}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/phone.png" alt="" />
                            <div className={styles.block_phone_1}>
                                <div>1</div>
                                <div>Ответы на&nbsp;любые<br/> вопросы по&nbsp;тренировкам</div>
                            </div>
                            <div className={styles.block_phone_2}>
                                <div>2</div>
                                <div>Поддержка<br/> и&nbsp;мотивация</div>
                            </div>
                            <div className={styles.block_phone_3}>
                                <div>3</div>
                                <div>Общение<br/> с&nbsp;единомышленницами</div>
                            </div>
                        </div> */}

                        <div className={styles2.programs_item}>
                            <div className={styles2.programs_info}>
                                <div className={styles2.programs_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/4.jpg" alt="" />
                                    <div className={styles2.programs_img__info}>
                                        <div className={styles2.programs_tags}>
                                            <div className={styles2.programs_tag}>Восстановление</div>
                                        </div>
                                        <div className={styles2.programs_img__info_title}><p>курс</p> <span>Восстановление после родов</span></div>
                                        <div className={styles2.programs_img__info_price}>7 900 <span>₽</span></div>
                                    </div>
                                </div>
                                <div className={styles2.programs_text}>
                                    <ul>
                                        <li><b>Длительность:</b> 60&nbsp;дней</li>
                                        <li><b>Инвентарь:</b> коврик, гантели 2&nbsp;кг и&nbsp;фитнес-резинки с&nbsp;сопротивлением до&nbsp;5&nbsp;кг, утяжелители 1,5-3&nbsp;кг&nbsp;&mdash; по&nbsp;желанию</li>
                                        <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                        <li><b>График тренировок:</b> через день</li>
                                    </ul>
                                    <div className={styles2.programs_more}>
                                        <input type="checkbox" id="p99" name="p99" />
                                        <label htmlFor="p99">Для кого подойдёт <p>Вы&nbsp;недавно стали мамой и&nbsp;стремитесь вернуться к&nbsp;своей прежней форме. Хотите избавиться от&nbsp;лишних килограммов и&nbsp;восстановить тонус мышц после беременности.</p></label>
                                    </div>
                                    <div className={styles2.programs_more}>
                                            <input type="checkbox" id="pn99" name="pn99" />
                                            <label htmlFor="pn99">Результаты после курса 
                                                <div>
                                                <ul>
                                                    <li>Похудеете и&nbsp;восстановите форму</li>
                                                    <li>Укрепите мышцы брюшного пресса и&nbsp;тазового дна</li>
                                                    <li>Подтянете тело</li>
                                                </ul>
                                                <p>Курс поможет вернуться после родов в&nbsp;прежнюю форму: сбросить лишние килограммы, подтянуть тело и&nbsp;укрепить мышцы тазового дна. Включает рекомендации по&nbsp;питанию для кормящих мам.</p>
                                                </div>
                                            </label>
                                        </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Block1;