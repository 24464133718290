import React, { useEffect } from 'react';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Quiz from './components/Quiz';

export const L2024_08_Quiz1 = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])
    
    return (
      <>
        <DocumentTitle value=''/>
        <DocumentDescription value='' />
        <div className=''>
            <Quiz />
        </div>
      </>
    );
};