import styles from './info.module.css';

const Info = () => {
    return (
        <div className={styles.info}>
            <div className="container">
                <div className={styles.info_title}>3 участницы,</div>
                <div className={styles.info_subtitle}>получившие самое большое количество<br/> голосов от вас, заберут МЕГА ПРИЗЫ</div>
                <div className={styles.info_block}>
                    <div className={styles.info_item}>
                        <div className={styles.info_info}>
                            <div className={styles.info_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/place_1.png" alt="" /></div>
                            <div className={styles.info_text}>За 1&nbsp;место&nbsp;– 100&nbsp;000&nbsp;₽</div>
                        </div>
                    </div>
                    <div className={styles.info_item}>
                        <div className={styles.info_info}>
                            <div className={styles.info_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/place_2.png" alt="" /></div>
                            <div className={styles.info_text}>За 2&nbsp;место&nbsp;– 70&nbsp;000&nbsp;₽</div>
                        </div>
                    </div>
                    <div className={styles.info_item}>
                        <div className={styles.info_info}>
                            <div className={styles.info_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/place_3.png" alt="" /></div>
                            <div className={styles.info_text}>За 3&nbsp;место&nbsp;– 50&nbsp;000&nbsp;₽</div>
                        </div>
                    </div>
                </div>
                <div className={styles.info_more}>ИТОГИ ОТКРЫТОГО ГОЛОСОВАНИЯ</div>
                {/* <div className={styles.info_arrow}><img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/info_arrow.png" alt="" /></div> */}
            </div>
        </div>
    )
}

export default Info;