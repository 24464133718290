import { useRef, useState } from 'react';
import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation} from 'swiper'
import 'swiper/css'
import "swiper/css/grid";
import "swiper/css/navigation";

import styles from './author.module.css';

const Author = () => {
    const [items, setItems] = useState([
        {src: 'https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/slide_1.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/slide_2.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/slide_3.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/slide_4.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/slide_5.jpg'}
    ]);

    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    return (
        <div className={styles.author}>
            <div className="container">
                <div className={styles.author_block}>
                    <div className={styles.author_left}>
                    <Swiper 
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        loop
                        onSwiper={(swiper) => {
                            setTimeout(() => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current
                            swiper.params.navigation.nextEl = navigationNextRef.current
                            
                            swiper.navigation.destroy()
                            swiper.navigation.init()
                            swiper.navigation.update()
                            })
                        }}
                        slidesPerView={1}
                        modules={[Navigation]}
                    >
                        <div className={styles.slider_nav}>
                            <div ref={navigationPrevRef} className={styles.slider_btn_prev}></div>
                            <div ref={navigationNextRef} className={styles.slider_btn_next}></div>
                        </div>
                        {items.map(item => 
                        <SwiperSlide key={item.src}>
                            <img src={item.src} alt="" style={{borderRadius: '100%'}} />
                        </SwiperSlide>
                        )}
                    </Swiper>
                    </div>
                    <div className={styles.author_right}>
                        <div className={styles.author_title}>О КАТЕ УСМАНОВОЙ</div>
                        <div className={styles.author_text}>Катя Усманова — вице-чемпионка мира и&nbsp;чемпионка России по&nbsp;фитнес-бикини, чемпионка мира по&nbsp;жиму лёжа, автор уникальных методик по&nbsp;созданию красивого женского тела и&nbsp;мама, которая точно знает, как&nbsp;быстро вернуться в&nbsp;форму после родов.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Author;