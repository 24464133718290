import { useEffect } from 'react';
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import Faq from './components/Faq';
import Footer from '../../components/Footer';
import Promo from './components/Promo';
import Start from './components/Start';
import Studentres from './components/Studentres';
import Gift from './components/Gift';
import Programs from './components/Programs';
import Platform from '../../components/platform/Platform';
import Prize from './components/Prize';
import Chance from './components/Chance';
import Knows from './components/Knows';
import Mission from './components/Mission';

import './style.css';

export const L2023_06_Bikinifest2 = () => {

    useEffect(() => {
        document.body.id = 'rasprozarka_2305'
        document.body.parentElement.id = 'htmlrasprozarka_2305'
    }, [])

    const scroll = () => {
        const section = document.querySelector( '.scrollhere' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };

    return (
        <>
        <DocumentTitle value='Бикини-фест – 8 фитнес-программ в одном комплекте'/>
        <DocumentDescription value='Выгода 92% на 8 фитнес-программ Екатерины Усмановой. Старт сразу после оплаты' />
        <div className='bikinifest2_2306'>
            <Promo scroll={() => scroll('.scrollhere')} renderer={renderer} date={date} />
            <Knows />
            <Mission />
            {/* <Prize /> */}
            <Chance />
            <Programs renderer={renderer} date={date} />
            <Start renderer={renderer} date={date} />
            <Gift scroll={() => scroll('.scrollhere')} />
            <Platform />
            <Studentres />
            <Faq />
            <Footer />
        </div>
        </>
    );
};