import { useEffect } from 'react';
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import Footer from '../../components/Footer';
import Start from './components/Start';

import './style.css';

export const L2023_08_Woweffect2_sms = () => {
    useEffect(() => {
      document.body.id = 'rasprozarka_2305'
      document.body.parentElement.id = 'htmlrasprozarka_2305'
    }, [])

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value='Вау-эффект – фитнес-программы на ягодицы со скидкой до 80%'/>
        <DocumentDescription value='Выгода до 80% на ягодичные тренировки для дома и зала от Екатерины Усмановой. Старт сразу после оплаты' />
        <div className='woweffect'>
            <Start renderer={renderer} date={date} />
            <Footer />
        </div>
      </>
    );
};