import Countdown from 'react-countdown';
import { SalesWidget } from "@com.marathonium/web2-ui";
import styles from './start.module.css';

const Start = ({date, renderer, renderer2}) => {
    return (
        <>
        <div className={('scrollhere ') + styles.start}>
            <div className="container">

                <div className={styles.start_block}>
                    <div className={styles.start_block_title}>Успейте забрать универсальный<br/> набор бестселлеров Кати Усмановой<br/> <span>с&nbsp;выгодой&nbsp;87%</span></div>
                    {/* <div className={styles.promo_subtitle2}>
                        <span>87%</span>
                        <p>Для первых <span>100</span> купивших действует<br/> наибольшая выгода. Далее цена повысится.</p>
                    </div> */}
                    {/* <div className={styles.start_remains}>Осталось мест по суперцене: {window.lps_settings.other.count_og}</div> */}

                    {/* <div className={styles.start_block_subtitle}>Специальное предложение сгорит через:</div> */}
                    {/* <div className={styles.promo_countdown}>
                            <div className={styles.promo_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer2} />
                            </div>
                    </div> */}
                    
                    
                    <div className={styles.inf}>
                        <div className={styles.inf_item}>
                            <p>Старт тренировок</p>
                            <p>сразу после оплаты</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/calendar.png" alt="" />
                        </div>
                        <div className={styles.inf_item}>
                            <p>Доступ к&nbsp;программам:</p>
                            <p>9&nbsp;месяцев</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/clock.png" alt="" />
                        </div>
                    </div>

                    <div className={styles.total}>
                        <p>Набор программ Кати Усмановой</p>
                        <span>3&nbsp;490&nbsp;₽</span>
                        <em>27&nbsp;290&nbsp;₽</em>
                    </div>
                    
                    <div className={styles.start_list}>
                        <div className={styles.start_list_item}>
                            <p><b>Марафон</b> Стройность 1.0</p>
                            <p>5 000 ₽</p>
                        </div>
                        <div className={styles.start_list_item}>
                            <p><b>Марафон</b> «Упругой попы 1.0»</p>
                            <p>5 000 ₽</p>
                        </div>
                        <div className={styles.start_list_item}>
                            <p><b>Марафон</b> «Плоского живота»</p>
                            <p>5 000 ₽</p>
                        </div>
                        <div className={styles.start_list_item}>
                            <p><b>Марафон</b> «Антицеллюлитный»</p>
                            <p>7 500 ₽</p>
                        </div>
                        <div className={styles.start_list_item}>
                            <p>Универсальные тренировки на&nbsp;ягодицы</p>
                            <p>4 790 ₽</p>
                        </div>
                        <div className={styles.plus}>Плюс бонусы:</div>
                        <div className={styles.promo_countdown}>
                            <p>Бонусы сгорят через:</p>
                            <div className={styles.promo_countdown_timer}>
                                <Countdown date={Date.now() + 900000} renderer={renderer} />
                            </div>
                        </div>
                        <div className={styles.start_list_item}>
                            <p>+ курс &laquo;Восстановление после родов&raquo;</p>
                            <p></p>
                        </div>
                        <div className={styles.start_list_item}>
                            <p>+ 5&nbsp;полезных гайдов</p>
                            <p></p>
                        </div>
                    </div>

                    <div className="rasprozharka2024_start">
                        <SalesWidget id={window.lps_settings.forms.rasprozharka2024_og_v2_id} buttonText="ОФОРМИТЬ ЗАКАЗ" />
                    </div>
                    <p>Плати с&nbsp;любой карты&nbsp;— выпущенной российским или&nbsp;иностранным банком.</p>
                    
                    <div className={styles.pay}>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Тинькофф-рассрочка</div>
                                <div className={styles.pay_item_text}>Плати потом&nbsp;&mdash; первый платёж через 30&nbsp;дней от&nbsp;581&nbsp;Р в&nbsp;месяц. Без переплат, без процентов, до&nbsp;6&nbsp;месяцев. Только для граждан&nbsp;РФ.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_1.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>По QR-коду</div>
                                <div className={styles.pay_item_text}>Отсканируй камерой телефона QR-код и&nbsp;перейди по&nbsp;ссылке в&nbsp;приложение банка, чтобы подтвердить оплату. Удобно со&nbsp;SberPay.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_2.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Долями</div>
                                <div className={styles.pay_item_text}>Раздели платёж на&nbsp;4&nbsp;равные части <b>от&nbsp;872,5&nbsp;₽</b>. Всего на&nbsp;6&nbsp;недель. Без ожидания одобрения банка, переплат и&nbsp;рисков.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_3.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>PayPal</div>
                                <div className={styles.pay_item_text}>Введи только электронный адрес и&nbsp;пароль&nbsp;&mdash; не&nbsp;нужно вводить номер карты. Безопасный способ платы, доступный клиентам банков не&nbsp;РФ.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_6.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Картой банка&nbsp;РФ<br/> или зарубежного</div>
                                <div className={styles.pay_item_text}>Пользуйся своей картой, где&nbsp;бы ты&nbsp;ни&nbsp;находился. Для оплаты картой иностранного банка, выбери оплату через Stripe.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_4.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Наличными в&nbsp;терминалах</div>
                                <div className={styles.pay_item_text}>Оплати свой заказ наличными через ближайший терминал. Быстро и&nbsp;удобно, без использования кредитных карт и&nbsp;необходимости раскрывать личные данные.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_5.png" alt="" />
                            </div>
                        </div>
                    </div>

                </div>

                
            </div>
        </div>
        </>
    )
}

export default Start;