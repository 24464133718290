import { SalesWidget } from "@com.marathonium/web2-ui";
import Countdown from 'react-countdown';
import styles from './start.module.css';

const Start = ({date, renderer}) => {
    return (
        <div className={('scrollhere ') + styles.start}>
            <div className="container">
                <div className={styles.start_block_title}>Успейте записаться на&nbsp;марафон<br/> по&nbsp;самой выгодной цене и&nbsp;получить<br/> бонусы на&nbsp;пути к&nbsp;стройности</div>
                <div className={styles.promo_prices}>
                        <div className={styles.promo_oldprice}>20&nbsp;000&nbsp;₽</div>
                        <div className={styles.promo_price}>2&nbsp;990&nbsp;₽</div>
                        <div className={styles.promo_discount}>-85%</div>
                </div>
                <div className={styles.promo_date}><img src="https://gymteam.kinescopecdn.net/img/l/2024/marathonsf/st1.png" alt="" /> <b>Доступ к материалам</b>&nbsp;— 3&nbsp;месяца</div>
                <div className={styles.promo_date}><img src="https://gymteam.kinescopecdn.net/img/l/2024/marathonsf/st2.png" alt="" /> <b>Дата старта</b>&nbsp;— 19&nbsp;февраля</div>


                <div className={styles.start_block}>
                    <div className={styles.start_countdown}>
                            <p>До окончания специального предложения осталось:</p>
                            <div className={styles.start_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                    </div>
                    <div className="marathonsf_start">
                        <SalesWidget id={window.lps_settings.forms.marathonsf_id} />
                    </div>
                    <p>Платите с&nbsp;любой карты&nbsp;— выпущенной российским или&nbsp;иностранным банком.</p>

                    {/* <div className={styles.start_new}>
                        <div className={styles.start_credit}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/tink_icon.png" alt="" />
                            <div className={styles.start_credit__title}>Рассрочка без&nbsp;процентов<br/> от&nbsp;581&nbsp;<span>₽</span>&nbsp;в&nbsp;месяц (недоступно при покупке только 1&nbsp;цикла)</div>
                            <div className={styles.start_credit__text}>
                                <p>Вы можете оформить заказ в&nbsp;рассрочку. На&nbsp;6&nbsp;месяцев без&nbsp;процентов и&nbsp;предварительного взноса (только&nbsp;для&nbsp;граждан&nbsp;РФ).</p>
                            </div>
                        </div>
                        <div className={styles.start_parts}>
                            <div className={styles.start_parts_mark}><img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/3/mark2.png" alt="" /></div>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/3/start_icon_black.png" alt="" />
                            <div className={styles.start_parts__title}>Оплатите Долями<br/> без&nbsp;банка, переплат и&nbsp;рисков (недоступно при покупке только 1&nbsp;цикла)</div>
                            <div className={styles.start_parts__text}>Не надо ждать до&nbsp;зарплаты — разделите платёж <strong>на&nbsp;4&nbsp;части от 872,5&nbsp;<span>₽</span></strong>. Всего на&nbsp;6&nbsp;недель (только&nbsp;для&nbsp;граждан&nbsp;РФ).</div>
                            <div className={styles.start_parts_block}>
                                <div className={styles.start_parts_item}>
                                    <div className={styles.start_parts_number}><p>1</p></div>
                                    <p>от 872,5&nbsp;<b>₽</b> <span>сегодня</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number2].join(' ')}><p>2</p></div>
                                    <p>от 872,5&nbsp;<b>₽</b> <span>через 2&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number3].join(' ')}><p>3</p></div>
                                    <p>от 872,5&nbsp;<b>₽</b> <span>через 4&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number4].join(' ')}><p>4</p></div>
                                    <p>от 872,5&nbsp;<b>₽</b> <span>через 6&nbsp;недель</span></p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    
                        
                    {/* <div className={styles.mob_fix}>
                    <div className={styles.start_new}>
                        <div className={styles.start_credit}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/tink_icon.png" alt="" />
                            <div className={styles.start_credit__title}>Рассрочка без&nbsp;процентов<br/> от&nbsp;581&nbsp;<span>₽</span>&nbsp;в&nbsp;месяц (недоступно при покупке только 1&nbsp;цикла)</div>
                            <div className={styles.start_credit__text}>
                                <p>Вы можете оформить заказ в&nbsp;рассрочку. На&nbsp;6&nbsp;месяцев без&nbsp;процентов и&nbsp;предварительного взноса (только&nbsp;для&nbsp;граждан&nbsp;РФ).</p>
                            </div>
                        </div>
                        <div className={styles.start_parts}>
                            <div className={styles.start_parts_mark}><img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/3/mark2.png" alt="" /></div>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/3/start_icon_black.png" alt="" />
                            <div className={styles.start_parts__title}>Оплатите Долями<br/> без&nbsp;банка, переплат и&nbsp;рисков (недоступно при покупке только 1&nbsp;цикла)</div>
                            <div className={styles.start_parts__text}>Не надо ждать до&nbsp;зарплаты — разделите платёж <strong>на&nbsp;4&nbsp;части от 872,5&nbsp;<span>₽</span></strong>. Всего на&nbsp;6&nbsp;недель (только&nbsp;для&nbsp;граждан&nbsp;РФ).</div>
                            <div className={styles.start_parts_block}>
                                <div className={styles.start_parts_item}>
                                    <div className={styles.start_parts_number}><p>1</p></div>
                                    <p>от 872,5&nbsp;<b>₽</b> <span>сегодня</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number2].join(' ')}><p>2</p></div>
                                    <p>от 872,5&nbsp;<b>₽</b> <span>через 2&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number3].join(' ')}><p>3</p></div>
                                    <p>от 872,5&nbsp;<b>₽</b> <span>через 4&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number4].join(' ')}><p>4</p></div>
                                    <p>от 872,5&nbsp;<b>₽</b> <span>через 6&nbsp;недель</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div> */}
                </div>

                
            </div>
        </div>
    )
}

export default Start;