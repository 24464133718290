import Countdown from 'react-countdown';
import styles from './promo2.module.css';

const Promo2 = ({scroll, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className="container promo2new">

                <div className={styles.promo2}>
                    <div className={styles.promo2_left}>
                        <div className={styles.promo2_left_top}>
                            <div className={styles.promo2_title}>Создай<br/> идеальную фигуру</div>
                            <div className={styles.promo2_subtitle}>по&nbsp;Методу Усмановой</div>
                            <div className={styles.promo2_text}>28&nbsp;дней комплексной работы над телом через продуманные тренировки, сбалансированное питание, психологию и&nbsp;правильное окружение</div>
                        </div>
                        <div className={styles.promo2_timer}>
                            <p>Продажи закроются через:</p>
                            <Countdown date={Date.now() + date} renderer={renderer} /> 
                        </div>
                        <div className={styles.promo2_circle_mob}>Количество мест<br/> ограничено</div>
                        <div className={styles.main_bottom_right_btn} onClick={scroll}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                            <p>Успеть<br/> в&nbsp;первый поток</p>
                            <p>Успеть<br/> в&nbsp;первый поток</p>
                        </div>
                        <div className={styles.promo2_circle}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/circle.svg" alt="" />
                        </div>
                        
                    </div>

                    <div className={styles.promo2_right}>
                        <div className={styles.promo2_right_top}>
                            <p>Новая программа<br/> Кати&nbsp;Усмановой</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/promo2_img.png" alt="" />
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/promo2_bg2.png" alt="" />
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/promo2_img2.png" alt="" />
                        </div>
                        <div className={styles.promo2_prize}>
                            <div className={styles.promo2_prize_info}>
                                <p>Участвуй в&nbsp;конкурсе до/после</p>
                                <p><span>и&nbsp;выиграй тур в&nbsp;Дубай</span></p>
                            </div>
                            <div className={styles.promo2_prize_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/method/promo_prize.png" alt="" /></div>
                        </div>
                    </div>
                </div>

                {/* <div className={styles.main}>
                    <div className={styles.main_top}>
                        <div className={styles.main_top_left}>
                            <div className={styles.main_top_left_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/promo_1.jpg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/promo_3.jpg" alt="" />
                            </div>
                            <div className={styles.main_top_left_text}>​​За <span>15&nbsp;лет опыта</span> и&nbsp;работы<br/> с&nbsp;<span>300&nbsp;000+ участницами</span> проекта<br/> Катя нашла идеальную формулу стройности и&nbsp;представляет:</div>
                        </div>
                        <div className={styles.main_top_right}>
                            <p>Продажи закроются через:</p>
                            <Countdown date={Date.now() + date} renderer={renderer} /> 
                        </div>
                    </div>

                    <div className={styles.main_bottom}>
                        <div className={styles.main_bottom_left}>
                            <div className={styles.main_bottom_left_t1}>стартуем</div>
                            <div className={styles.main_bottom_left_t2}>23</div>
                            <div className={styles.main_bottom_left_t3}>апреля</div>
                        </div>
                        <div className={styles.main_bottom_right}>
                            <div className={styles.main_bottom_right_title}>Метод<br/> Усмановой</div>
                            <div className={styles.main_bottom_right_text}>Как похудеть к&nbsp;лету и&nbsp;больше<br/> не&nbsp;набирать вес, если вам уже&nbsp;30+</div>
                            <div className={styles.main_bottom_right_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/promo_main.png" alt="" />
                            </div>
                            <div className={styles.main_bottom_right_btn} onClick={scroll}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                                <p>Успеть<br/> в&nbsp;первый поток</p>
                                <p>Успеть<br/> в&nbsp;первый поток</p>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className={styles.mob}>
                    <div className={styles.mob_left}>
                        <div className={styles.main_bottom_left}>
                            <div className={styles.main_bottom_left_t1}>стартуем</div>
                            <div className={styles.main_bottom_left_t2}>23</div>
                            <div className={styles.main_bottom_left_t3}>апреля</div>
                        </div>
                        <div className={styles.main_top_right}>
                            <p>Продажи закроются через:</p>
                            <Countdown date={Date.now() + date} renderer={renderer} /> 
                        </div>
                    </div>
                    <div className={styles.mob_right}>
                    </div>
                </div> */}
            </div>
        </div>
        
    )
}

export default Promo2;