import { useEffect, useState } from 'react';
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import { LoginWidget, AuthWidget, checkUserInGroup, getUserCountByGroup } from "@com.marathonium/web2-ui";
import Footer from '../../components/Footer';
import Promo from './components/Promo';
import { Modal } from './components/Modal';
import { Nomination } from './components/Nomination';
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";
import styles from './main.module.css';
import './style.css'

const nom1 = [
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/misshot/6.jpg',
        name: 'Инна',
        weight: '79 → 72',
        chest: '103 → 91',
        waist: '93 → 83',
        hips: '123 → 114',
        id: '422500d6-7bff-406f-8cf8-336f9b487236',
        points: '170',
        place: 1
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/misshot/5.jpg',
        name: 'Евгения',
        weight: '85,6 → 75,3',
        chest: '118 → 100',
        waist: '101 → 84',
        hips: '117 → 107',
        id: '794fcf44-c802-4a1a-b095-5b716392e6b1',
        points: '142',
        place: 2
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/misshot/7.jpg',
        name: 'Валерия',
        weight: '68,8 → 59',
        chest: '93 → 88',
        waist: '75 → 66',
        hips: '104 → 96',
        id: 'fb48d948-26be-436a-8a00-702f31ab574a',
        points: '132',
        place: 3
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/misshot/2.jpg',
        name: 'Екатерина',
        weight: '62 → 58',
        chest: '83 → 85',
        waist: '77 → 66',
        hips: '102 → 96',
        id: '4bdf50a2-cde4-4e15-bd07-9083dccda8b0',
        points: '122',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/misshot/1.jpg',
        name: 'Иветта',
        weight: '82,5 → 69,6',
        chest: '116 → 98',
        waist: '90 → 75',
        hips: '110 → 100',
        id: '91c5249d-42c1-46fa-82d4-6fc1d76edf91',
        points: '83',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/misshot/10.jpg',
        name: 'Анастасия',
        weight: '74 → 67',
        chest: '102 → 96',
        waist: '98 → 82',
        hips: '107 → 98',
        id: 'e8eb2b4e-5072-4e6d-bd1b-043dc5a9c8b8',
        points: '47',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/misshot/4.jpg',
        name: 'Оксана',
        weight: '86,9 → 74,9',
        chest: '109 → 98,5',
        waist: '99 → 83',
        hips: '113 → 103',
        id: '985074b7-3645-4ca9-81f6-07ea848053d5',
        points: '21',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/misshot/3.jpg',
        name: 'Яна',
        weight: '78 → 72',
        chest: '106 → 103',
        waist: '87 → 84',
        hips: '109 → 104',
        id: '36d014b0-06aa-455a-a9fe-f53cbaddec81',
        points: '18',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/misshot/8.jpg',
        name: 'Анастасия ',
        weight: '54,5 → 48.4',
        chest: '92 → 86',
        waist: '70 → 61',
        hips: '92 → 89',
        id: '4da902d6-c3b7-4c29-9739-7f676db3138b',
        points: '6',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/misshot/9.jpg',
        name: 'Ольга',
        weight: '78 → 71,2',
        chest: '104 → 100',
        waist: '91 → 79',
        hips: '107 → 102',
        id: '8e2d6516-9493-45ea-a611-82bb17234ad9',
        points: '1',
    },    
]
export const L2024_08_Misshot = () => {
    const [isModal,  setIsModal] = useState(false)
    const [inGroupOne, setInGroupOne] = useState(null)
    const [inGroupTwo, setInGroupTwo] = useState(null)
    const [inGroupThree, setInGroupThree] = useState(null)
    const [inGroupFour, setInGroupFour] = useState(null)
    const [votesOne, setVotesOne] = useState([])
    const [votesTwo, setVotesTwo] = useState([])
    const [votesThree, setVotesThree] = useState([])
    const [votesFour, setVotesFour] = useState([])

    const [alreadyVote1, setAlreadyVote1] = useState(false)
    const [alreadyVote2, setAlreadyVote2] = useState(false)
    const [alreadyVote3, setAlreadyVote3] = useState(false)

    useEffect(() => {
        document.body.id = 'rasprozarka_2305'
        document.body.parentElement.id = 'htmlrasprozarka_2305'

        checkVote1()
    }, [])

    const checkVote1 = () => {
        Promise.all(nom1.map((i) => checkUserInGroup(i.id))).then((result) => {
            result.map((res) => {
                if(res) {
                    setAlreadyVote1(true)
                }
            })
        })
    }
    // const checkVote2 = () => {
    //     Promise.all(nom2.map((i) => checkUserInGroup(i.id))).then((result) => {
    //         result.map((res) => {
    //             if(res) {
    //                 setAlreadyVote2(true)
    //             }
    //         })
    //     })
    // }
    // const checkVote3 = () => {
    //     Promise.all(nom3.map((i) => checkUserInGroup(i.id))).then((result) => {
    //         result.map((res) => {
    //             if(res) {
    //                 setAlreadyVote3(true)
    //             }
    //         })
    //     })
    // }

    // const getVotes = (nomination, number) => {
    //     Promise.all(nomination.map((i) => getUserCountByGroup(i.id))).then((result) => {
    //         number === 1 && setVotesOne(result)
    //         number === 2 && setVotesTwo(result)
    //         number === 3 && setVotesThree(result)
    //         number === 4 && setVotesFour(result)
    //     })
    // }

    function LoginForm() {
        return (
          <>
            <Authorized>
                <Nomination nomination={nom1} texts={1} setIsModal={setIsModal} alreadyVote={alreadyVote1} checkVote={checkVote1} />
                {/* <Nomination nomination={nom2} texts={2} setIsModal={setIsModal} alreadyVote={alreadyVote2} checkVote={checkVote2} /> */}
                {/* <Nomination nomination={nom3} texts={3} setIsModal={setIsModal} alreadyVote={alreadyVote3} checkVote={checkVote3} /> */}


                {/* {!inGroupOne && <Nomination nomination={nom1} texts={1} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesOne} />}
                {!inGroupTwo && <Nomination nomination={nom2} texts={2} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesTwo} />}
                {!inGroupThree && <Nomination nomination={nom3} texts={3} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesThree} />}
                {!inGroupFour && <Nomination nomination={nom4} texts={4} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesFour} />}

                {inGroupOne && inGroupTwo && inGroupThree && inGroupFour && 
                <>
                <div className={styles.voted}>
                    <div className={styles.voted_title}>ОТКРЫТОЕ<br/> ГОЛОСОВАНИЕ ЗАВЕРШЕНО</div>
                    <div className={styles.voted_subtitle}>Спасибо вам за участие в выборе победительниц!</div>
                    <div className={styles.voted_subtitle}>Мы подведем итоги 4 сентября</div>
                </div>
                <Nomination nomination={nom1} texts={1} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesOne} />
                <Nomination nomination={nom2} texts={2} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesTwo} />
                <Nomination nomination={nom3} texts={3} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesThree} />
                <Nomination nomination={nom4} texts={4} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesFour} />
                </>
                } */}
                
                {/* <div className={styles.oferta}>
                    <a href="https://usmanovateam.ru/misshot_competition2023" target='_blank'>КОНКУРСНАЯ ОФЕРТА</a>
                </div> */}
            </Authorized>
            <Anonymous>
                <div className={'scrollhere ' + styles.auth}>
                    <div className={styles.auth_title}>Чтобы участовать в&nbsp;голосовании<br/> нужно авторизоваться</div>
                    <div className={styles.auth_widget + ' auth_safaricontest'}><LoginWidget /></div>
                </div>
            </Anonymous>
            {isModal && <Modal />}
          </>
        );
    }

    return (
        <>
        <DocumentTitle value='Мисс Жара 2024 - открытое голосование'/>
        <DocumentDescription value='Участвуй в выборе победительниц конкурса на лучшие результаты ДО/ПОСЛЕ по Методу Усмановой' />
        <div className='misshot_2024'>
            <Promo />
            <Nomination nomination={nom1} texts={1} setIsModal={setIsModal} alreadyVote={alreadyVote1} checkVote={checkVote1} />
            {/* <LoginForm /> */}
            {/* <div className={styles.done}>
                <div className={styles.done_block}>
                    <div className={styles.done_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/done.png" alt="" /></div>
                    <div className={styles.done_title}>ОТКРЫТОЕ ГОЛОСОВАНИЕ<br/> ЗАВЕРШЕНО</div>
                    <div className={styles.done_subtitle}>Мы подведем итоги 17&nbsp;ноября</div>
                    <div className={styles.done_subtitle2}>Спасибо вам за&nbsp;участие в&nbsp;выборе победительниц!</div>
                </div>
            </div> */}
            <Footer isColor={'#E7E6ED'} />
        </div>
        </>
    );
};