import { useEffect, useState } from 'react';
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import { LoginWidget, AuthWidget, checkUserInGroup, getUserCountByGroup } from "@com.marathonium/web2-ui";
import Footer from '../../components/Footer';
import Promo from './components/Promo';
import { Modal } from './components/Modal';
import { Nomination } from './components/Nomination';
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";
import styles from './main.module.css';
import './style.css'

const nom1 = [
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/v1.jpg',
        name: 'Маргарита',
        weight: '65 → 58',
        chest: '90 → 84',
        waist: '75 → 65',
        hips: '99 → 95',
        id: 'e247e90a-2a6d-4102-9edf-a4f8a4670415',
        place: 1,
        points: 288,
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/v4.jpg',
        name: 'Ольга',
        weight: '84 → 68',
        chest: '99 → 88',
        waist: '84 → 73',
        hips: '110 → 100',
        id: 'f15f19ae-78d9-4979-8d91-9e2350e200e3',
        points: 283,
        place: 2
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/v2.jpg',
        name: 'Василиса',
        weight: '102,1 → 89',
        chest: '110 → 106',
        waist: '91 → 80',
        hips: '120 → 112',
        id: '67936aad-412e-42ca-aba6-2860dcf3ccf8',
        points: 153,
        place: 3
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/v6.jpg',
        name: 'Екатерина',
        weight: '64 → 56,9',
        chest: '87 → 80',
        waist: '68 → 62',
        hips: '104 → 93',
        id: '235305a5-e77e-4551-8974-7bcdca678c51',
        points: 151,
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/v5.jpg',
        name: 'Ольга',
        weight: '70 → 60',
        chest: '96 → 93',
        waist: '90 → 76',
        hips: '96 → 94',
        id: '562b7403-e7de-499e-b803-4589623bd1d8',
        points: 113,
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/v3.jpg',
        name: 'Екатерина',
        weight: '67,8 → 64',
        chest: '96 → 87',
        waist: '85 → 76',
        hips: '105 → 102',
        id: '8bbc3a4d-5edd-47d6-bf77-edea2e65ba60',
        points: 33,
    },
    
]
export const L2024_08_Missstroinost24 = () => {
    const [isModal,  setIsModal] = useState(false)
    const [inGroupOne, setInGroupOne] = useState(null)
    const [inGroupTwo, setInGroupTwo] = useState(null)
    const [inGroupThree, setInGroupThree] = useState(null)
    const [inGroupFour, setInGroupFour] = useState(null)
    const [votesOne, setVotesOne] = useState([])
    const [votesTwo, setVotesTwo] = useState([])
    const [votesThree, setVotesThree] = useState([])
    const [votesFour, setVotesFour] = useState([])

    const [alreadyVote1, setAlreadyVote1] = useState(false)
    const [alreadyVote2, setAlreadyVote2] = useState(false)
    const [alreadyVote3, setAlreadyVote3] = useState(false)

    useEffect(() => {
        document.body.id = 'rasprozarka_2305'
        document.body.parentElement.id = 'htmlrasprozarka_2305'

        checkVote1()
    }, [])

    const checkVote1 = () => {
        Promise.all(nom1.map((i) => checkUserInGroup(i.id))).then((result) => {
            result.map((res) => {
                if(res) {
                    setAlreadyVote1(true)
                }
            })
        })
    }
    // const checkVote2 = () => {
    //     Promise.all(nom2.map((i) => checkUserInGroup(i.id))).then((result) => {
    //         result.map((res) => {
    //             if(res) {
    //                 setAlreadyVote2(true)
    //             }
    //         })
    //     })
    // }
    // const checkVote3 = () => {
    //     Promise.all(nom3.map((i) => checkUserInGroup(i.id))).then((result) => {
    //         result.map((res) => {
    //             if(res) {
    //                 setAlreadyVote3(true)
    //             }
    //         })
    //     })
    // }

    // const getVotes = (nomination, number) => {
    //     Promise.all(nomination.map((i) => getUserCountByGroup(i.id))).then((result) => {
    //         number === 1 && setVotesOne(result)
    //         number === 2 && setVotesTwo(result)
    //         number === 3 && setVotesThree(result)
    //         number === 4 && setVotesFour(result)
    //     })
    // }

    function LoginForm() {
        return (
          <>
            <Authorized>
                <Nomination nomination={nom1} texts={1} setIsModal={setIsModal} alreadyVote={alreadyVote1} checkVote={checkVote1} />
                {/* <Nomination nomination={nom2} texts={2} setIsModal={setIsModal} alreadyVote={alreadyVote2} checkVote={checkVote2} /> */}
                {/* <Nomination nomination={nom3} texts={3} setIsModal={setIsModal} alreadyVote={alreadyVote3} checkVote={checkVote3} /> */}


                {/* {!inGroupOne && <Nomination nomination={nom1} texts={1} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesOne} />}
                {!inGroupTwo && <Nomination nomination={nom2} texts={2} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesTwo} />}
                {!inGroupThree && <Nomination nomination={nom3} texts={3} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesThree} />}
                {!inGroupFour && <Nomination nomination={nom4} texts={4} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesFour} />}

                {inGroupOne && inGroupTwo && inGroupThree && inGroupFour && 
                <>
                <div className={styles.voted}>
                    <div className={styles.voted_title}>ОТКРЫТОЕ<br/> ГОЛОСОВАНИЕ ЗАВЕРШЕНО</div>
                    <div className={styles.voted_subtitle}>Спасибо вам за участие в выборе победительниц!</div>
                    <div className={styles.voted_subtitle}>Мы подведем итоги 4 сентября</div>
                </div>
                <Nomination nomination={nom1} texts={1} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesOne} />
                <Nomination nomination={nom2} texts={2} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesTwo} />
                <Nomination nomination={nom3} texts={3} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesThree} />
                <Nomination nomination={nom4} texts={4} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesFour} />
                </>
                } */}
                
                {/* <div className={styles.oferta}>
                    <a href="https://usmanovateam.ru/misshot_competition2023" target='_blank'>КОНКУРСНАЯ ОФЕРТА</a>
                </div> */}
            </Authorized>
            <Anonymous>
                <div className={'scrollhere ' + styles.auth}>
                    <div className={styles.auth_title}>Чтобы участовать в&nbsp;голосовании<br/> нужно авторизоваться</div>
                    <div className={styles.auth_widget + ' auth_safaricontest'}><LoginWidget /></div>
                </div>
            </Anonymous>
            {isModal && <Modal />}
          </>
        );
    }

    return (
        <>
        <DocumentTitle value='Мисс Стройность 2024 - открытое голосование'/>
        <DocumentDescription value='Участвуй в выборе победительниц конкурса на лучшие результаты ДО/ПОСЛЕ по Методу Усмановой' />
        <div className='missstroinost_2024'>
            <Promo />
            <Nomination nomination={nom1} texts={1} setIsModal={setIsModal} alreadyVote={alreadyVote1} checkVote={checkVote1} />
            {/* <LoginForm /> */}
            {/* <div className={styles.done}>
                <div className={styles.done_block}>
                    <div className={styles.done_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/done.png" alt="" /></div>
                    <div className={styles.done_title}>ОТКРЫТОЕ ГОЛОСОВАНИЕ<br/> ЗАВЕРШЕНО</div>
                    <div className={styles.done_subtitle}>Мы подведем итоги 17&nbsp;ноября</div>
                    <div className={styles.done_subtitle2}>Спасибо вам за&nbsp;участие в&nbsp;выборе победительниц!</div>
                </div>
            </div> */}
            <Footer version={2} isColor={'#0D0D0D'} />
        </div>
        </>
    );
};