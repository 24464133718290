import { SalesWidget } from "@com.marathonium/web2-ui";
import Countdown from 'react-countdown';
import styles from './start.module.css';

const Start = ({date, renderer, number}) => {
    return (
        <div className={('scrollhere ') + styles.start}>
            <div className="container">
                <div className={styles.start_block}>
                    <div className={styles.start_pretitle}>Не упустите выгоду {number == 1 && <>95%!</>}</div>
                    <div className={styles.start_title}>
                        {number == 1 && <>Заберите фитнес<u>-</u>бестселлеры<br/> <span>за&nbsp;3&nbsp;390&nbsp;руб</span> вместо <i>73&nbsp;300&nbsp;руб</i><br/> и&nbsp;получите годовой доступ на&nbsp;весь комплект программ</>}
                        {number == 2 && <>Заберите фитнес<u>-</u>бестселлеры<br/> <span>за&nbsp;2&nbsp;990&nbsp;руб</span> вместо <i>47&nbsp;400&nbsp;руб</i><br/> и&nbsp;получите годовой доступ на&nbsp;весь комплект программ</>}
                        {number == 3 && <>Заберите фитнес<u>-</u>бестселлеры<br/> <span>за&nbsp;2&nbsp;190&nbsp;руб</span> вместо <i>30&nbsp;400&nbsp;руб</i><br/> и&nbsp;получите доступ на&nbsp;6&nbsp;месяцев на&nbsp;весь комплект программ</>}
                    </div>
                    <div className={styles.start_countdown}>
                        <p>До закрытия предложения</p>
                        <div className={styles.start_countdown_timer}>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                    </div>
                    
                    <div className="restart3_start">
                        {number == 1 && <SalesWidget id={window.lps_settings.forms.julysale_id} />}
                        {number == 2 && <SalesWidget id={window.lps_settings.forms.julyoffer_id} />}
                        {number == 3 && <SalesWidget id={window.lps_settings.forms.saleoffer_id} />}
                    </div>
                    <div className={styles.start_credit}>
                        <p>Платите с&nbsp;любой карты&nbsp;— выпущенной российским или&nbsp;иностранным банком.</p>
                        {number == 1 && <>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/inshape/tnkf.svg" alt="" />
                        <div className={styles.start_credit__title}>Рассрочка без&nbsp;процентов от&nbsp;{number == 1 && <>565&nbsp;₽&nbsp;в&nbsp;месяц</>}</div>
                        <div className={styles.start_credit__text}>
                            <p>Вы можете оформить заказ в&nbsp;рассрочку.<br/> На&nbsp;6&nbsp;месяцев без&nbsp;процентов и&nbsp;предварительного взноса (только&nbsp;для&nbsp;граждан&nbsp;РФ).</p>
                        </div>
                        </>}
                    </div>
                    {number == 1 && <>
                    <div className={styles.start_parts}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/pregnancy/start_icon.png" alt="" />
                        <div className={styles.start_parts__title}>Оплатите Долями<br/> без&nbsp;банка, переплат и&nbsp;рисков</div>
                        <div className={styles.start_parts__text}>Не надо ждать до&nbsp;зарплаты — разделите платёж <strong>на&nbsp;4&nbsp;части<br/> {number == 1 && <>от&nbsp;847,5&nbsp;₽</>}</strong>. Всего на&nbsp;6&nbsp;недель.</div>
                        <div className={styles.start_parts_block}>
                            <div className={styles.start_parts_item}>
                                <div className={styles.start_parts_number}><p>1</p></div>
                                <p>{number == 1 && <>от&nbsp;847,5&nbsp;₽</>}<br/> <span>сегодня</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number2].join(' ')}><p>2</p></div>
                                <p>{number == 1 && <>от&nbsp;847,5&nbsp;₽</>}<br/> <span>через 2&nbsp;недели</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number3].join(' ')}><p>3</p></div>
                                <p>{number == 1 && <>от&nbsp;847,5&nbsp;₽</>}<br/> <span>через 4&nbsp;недели</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number4].join(' ')}><p>4</p></div>
                                <p>{number == 1 && <>от&nbsp;847,5&nbsp;₽</>}<br/> <span>через 6&nbsp;недель</span></p>
                            </div>
                        </div>
                    </div>
                    </>}
                </div>
            </div>
        </div>
    )
}

export default Start;