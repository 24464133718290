import styles from './info.module.css';

const Info = () => {
    return(
        <div className={styles.info}>
            <div className="container">
                <div className={styles.info_title}>С&nbsp;таким наполнением программы результат вам гарантирован</div>
                <div className={styles.info_subtitle}><img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow2.svg" alt="" /> От&nbsp;вас&nbsp;&mdash; только заниматься!</div>
                <div className={styles.info_block}>
                    <div className={styles.info_item}>
                        <div className={styles.info_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/in_1.png" alt="" />
                        </div>
                        <div className={styles.info_item_info}>
                            <div className={styles.info_item_title}>Тренировки от&nbsp;Кати максимально эффективны<br/> для тех, кто старше&nbsp;30</div>
                            <div className={styles.info_item_text}>20&nbsp;тренировок по&nbsp;30-40 минут с&nbsp;понедельника по&nbsp;пятницу<br/> Требуемое оборудование: коврик, гантели, резинки и&nbsp;утяжелители</div>
                            <div className={styles.info_item_result}><span>Результат:</span> подтянутое тело и&nbsp;уверенность в&nbsp;себе</div>
                        </div>
                        <div className={styles.info_item_img}>
                            {/* <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/in_1.jpg" alt="" /> */}
                        </div>
                    </div>

                    <div className={styles.info_item}>
                        <div className={styles.info_item_img}>
                            {/* <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/in_1.jpg" alt="" /> */}
                        </div>
                        <div className={styles.info_item_info}>
                            <div className={styles.info_item_title}>Не&nbsp;только классно выглядеть, но&nbsp;и&nbsp;быть здоровой! Поэтому...</div>
                            <div className={styles.info_item_text}>Мы&nbsp;добавили 4&nbsp;комплекса на&nbsp;спину и&nbsp;шею от&nbsp;тренера-реабилитолога Юлии Усковой<br/> по&nbsp;15-20 минут каждый. Можно выполнять в&nbsp;течение дня в&nbsp;удобное время</div>
                            <div className={styles.info_item_result}><span>Результат:</span> легкость в&nbsp;спине и&nbsp;пояснице, предотвращение болезней<br/> (как у&nbsp;всех ваших знакомых, кому за&nbsp;30), классная попа и&nbsp;пресс<br/> (да-да, хочешь попу и&nbsp;пресс? Тренируй правильно спину)</div>
                        </div>
                        <div className={styles.info_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/in_2.png" alt="" />
                        </div>
                    </div>

                    <div className={styles.info_item}>
                        <div className={styles.info_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/in_3.png" alt="" />
                        </div>
                        <div className={styles.info_item_info}>
                            <div className={styles.info_item_title}>Придется снова голодать и&nbsp;отказываться<br/> от&nbsp;вкусняшек?</div>
                            <div className={styles.info_item_text}>Нет, это не&nbsp;про Метод Усмановой!<em/> Наш нутрициолог расскажет, как без подсчета калорий быть в&nbsp;форме и&nbsp;не&nbsp;страдать без сладкого. 2-х часовой урок от&nbsp;нутрициолога Анастасии Скоробогатовой о&nbsp;том, как питаться 4&nbsp;раза в&nbsp;день, не&nbsp;заморачиваться подсчетом калорий и&nbsp;не&nbsp;кидаться на&nbsp;пирожные. Кстати, еще будут рецепты вкусных завтраков, обедов и&nbsp;ужинов. Ммм..</div>
                            <div className={styles.info_item_result}><span>Результат:</span> подходящий именно вам формат питания для достижения<br/> и&nbsp;сохранения стройного тела без жестких ограничений</div>
                        </div>
                        <div className={styles.info_item_img}>
                            {/* <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/in_2.jpg" alt="" /> */}
                        </div>
                    </div>

                    <div className={styles.info_item}>
                        <div className={styles.info_item_img}>
                            {/* <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/in_1.jpg" alt="" /> */}
                        </div>
                        <div className={styles.info_item_info}>
                            <div className={styles.info_item_title}>И&nbsp;это еще не&nbsp;все!<em/> Вас поддержит психолог<br/> с&nbsp;уникальным подходом, который на&nbsp;уровне чувств поменяет ваше ощущение себя</div>
                            <div className={styles.info_item_text}>1&nbsp;прямой эфир от&nbsp;психолога Ирины Недоспасовой о&nbsp;том, как стресс и&nbsp;переживания влияют на&nbsp;набор веса и&nbsp;что с&nbsp;этим делать. Но&nbsp;мы&nbsp;сделаем больше&nbsp;&mdash; погрузимся в&nbsp;особую практику прямо во&nbsp;время эфира и&nbsp;попробуем сразу изменить свое восприятие еды.</div>
                            <div className={styles.info_item_result}><span>Результат:</span> спокойное отношение к&nbsp;вредной пище и&nbsp;избавление от&nbsp;тяги<br/> к&nbsp;ней на&nbsp;уровне эмоций</div>
                        </div>
                        <div className={styles.info_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/in_4.png" alt="" />
                        </div>
                    </div>
                </div>

                <div className={styles.info_end}>Вы&nbsp;успеете освоить Метод Усмановой<br/> в&nbsp;своем темпе за&nbsp;<span>4&nbsp;недели!</span></div>
            </div>
        </div>
    );
};

export default Info;