import styles from './new.module.css';

const New = () => {

    return (
        <div className={styles.new}>
            <div className="container">
                <div className={styles.block}>
                    <div className={styles.item}>
                        <div className={styles.item_n}>20+</div>
                        <div className={styles.item_t}>тренеров</div>
                        <div className={styles.item_st}>Более 20&nbsp;тренеров с&nbsp;опытом от&nbsp;10&nbsp;лет, которые записали для тебя тренировки</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_n}>1500+</div>
                        <div className={styles.item_t}>тренировок</div>
                        <div className={styles.item_st}>В&nbsp;таких направлениях как йога, пилатес, растяжка, восстановительная гимнастика, функциональные тренировки, кардио, силовые тренировки, фейсфитнес</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_n}>от&nbsp;15&nbsp;до&nbsp;50</div>
                        <div className={styles.item_t}>минут</div>
                        <div className={styles.item_st}>Тренировки длятся от&nbsp;15&nbsp;до&nbsp;50&nbsp;минут, чтобы ты&nbsp;могла качественно потренироваться и&nbsp;при этом не&nbsp;переутомиться</div>
                    </div>
                </div>
            </div>
            <div className={styles.trainers}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/stretch/trainers.jpg" alt="" />
            </div>
        </div>
    )
}

export default New;