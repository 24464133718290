import React, { useEffect } from 'react';

import './style2.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Start from './components/Start';
import Start2 from './components/Start2';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_03_Gymmarch_sms = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll2 = () => {
      const section = document.querySelector( '.scrollstart' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value='Курс для зала – фитнес-программа Кати Усмановой с выгодой до 20% и розыгрыш денег'/>
        <DocumentDescription value='Выгода до 20% на КУРС ДЛЯ ЗАЛА от Кати Усмановой. Розыгрыш денежных призов и доступ сразу после оплаты' />
        <div className='gymfest'>
            <Start2 renderer={renderer} date={date} scroll2={scroll2} />
            <Footer version={2} />
        </div>
      </>
    );
};