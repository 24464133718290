import styles from './points.module.css';

const Points = () => {
    return (
        <div className="container">
            <div className={styles.points}>
                <div className={styles.points_left}>
                    <div className={styles.points_title}>Марафоны созданы специально для&nbsp;тех, кто&nbsp;мечтает</div>
                    <div className={styles.points_items}>
                        <div className={styles.points_item}>Шикарно выглядеть в&nbsp;коротких шортах и&nbsp;обтягивающих платьях</div>
                        <div className={styles.points_item}>Создать стройные ножки</div>
                        <div className={styles.points_item}>Приподнять попу</div>
                        <div className={styles.points_item}>Убрать галифе</div>
                        <div className={styles.points_item}>Сформировать округлую форму</div>
                        <div className={styles.points_item}>Увеличить и&nbsp;сохранить объемы</div>
                        <div className={styles.points_item}>Привлекать взгляды мужчин</div>
                    </div>
                    <div className={styles.points_text}>А&nbsp;также если вы всё ещё верите, что форму попы нельзя изменить&nbsp;– наши ученицы доказывают обратное</div>
                </div>
                <div className={styles.points_right}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/points.png" alt="" />
                </div>
            </div>
        </div>
    )
}

export default Points;