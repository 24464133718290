import { useEffect, useState } from "react";
import { AuthWidget, checkUserInGroup, addUserToGroup, getUserCountByGroup, ShowForSegment, HideForSegment, checkUserInSegment, getUser } from "@com.marathonium/web2-ui";
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";
import styles from './form.module.css';

const Form2 = () => {
    const [inGroup, setInGroup] = useState()

    useEffect(()=> {
            
        checkUserInGroup(window.lps_settings.user_groups.metod_add).then(res => {
            if(res) {
                setInGroup(true)
            }
        })
        
    }, [])

    const addCustomer = () => {
        addUserToGroup(window.lps_settings.user_groups.metod_add)
        setInGroup(true)
    }

    function LoginForm() {
        return (
          <>
            <Authorized>  
            {inGroup ? 
                <div className={styles.form_title}>Спасибо!<br/> Ваша заявка уже принята</div>
                : 
                <>
                <h2 className={styles.form_title} style={{marginBottom: 10}} >СПЕЦПРЕДЛОЖЕНИЕ ЗАКРЫТО</h2>
                <h3 className={styles.form_title} style={{marginBottom: 10, fontSize: 26, marginTop: 0}} >Чтобы первыми узнать о&nbsp;старте нового потока, заполните форму ниже</h3>
                <div className={styles.form_btn} onClick={addCustomer}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                    <p>Оставить заявку</p>
                </div>
                </>
            }
            </Authorized>
            <Anonymous>
                <div className="metod_login">
                    <h2>СПЕЦПРЕДЛОЖЕНИЕ ЗАКРЫТО</h2>
                    <h3>Чтобы первыми узнать о&nbsp;старте нового потока, заполните форму ниже</h3>
                    <AuthWidget align="center" />
                </div>
            </Anonymous>
          </>
        );
    }

    return(
        <div className={('scrollhere ') + styles.form}>
            <div className="container">
                <LoginForm />
            </div>
        </div>
    );
};

export default Form2;