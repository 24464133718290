import { Center } from "@chakra-ui/react";
import { AuthWidget } from "@com.marathonium/web2-ui";

export const Checkauthwidget = () => {
	return (
		<>
			<div
				style={{
					background: "#eee",
					padding: "50px 0",
					display: "flex",
					justifyContent: "center",
				}}
			>
				<AuthWidget />
			</div>

			<div
				style={{ padding: "50px 0", display: "flex", justifyContent: "center" }}
			>
				<AuthWidget align="center" />
			</div>
		</>
	);
};
