import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Start2 from './components/Start2';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2023_12_Newyear2024_sms = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value='Новогодний sale – фитнес-программы Кати Усмановой со скидкой 91%'/>
        <DocumentDescription value='Выгода 91% на фитнес-программы от Кати Усмановой. Старт сразу после оплаты и розыгрыш iPhone 15' />
        <div className='newyear2024'>
          
        {(() => {
          var asdf = [];

          for (let i = 0; i <= 50; i++) {
            asdf.push(
              <div className='snowflake' key={i}></div>
            )
          }

          return asdf;
        })()}     
            <Start2 renderer={renderer} date={date} />
            <Footer version={2} />
        </div>
      </>
    );
};