import Countdown from 'react-countdown';
import styles from './promo.module.css';

const Promo = ({scroll, date, renderer, number}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_block}>
                    <div className={styles.promo_title}>
                        Это<br/> <span>не</span>&nbsp;предел
                    </div>
                    <div className={styles.promo_subtitle}>
                        Выбирайте максимум для&nbsp;своей фигуры&nbsp;—<br/> <b>полный комплект программ от&nbsp;Кати Усмановой</b>
                    </div>
                    
                    <div className={styles.promo_icons}>
                        <div className={styles.promo_icon}>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2023/restart/3/promo_1.png" alt="" /></div>
                            <p>
                                {number == 1 && <>10&nbsp;фитнес-программ<br/> <b>со&nbsp;скидкой&nbsp;95%</b></>}
                                {number == 2 && <>7&nbsp;фитнес-программ<br/> <b>со&nbsp;скидкой&nbsp;94%</b></>}
                                {number == 3 && <>5&nbsp;фитнес-программ<br/> <b>со&nbsp;скидкой&nbsp;93%</b></>}
                            </p>
                        </div>
                        <div className={styles.promo_icon}>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2023/restart/3/promo_2.png" alt="" /></div>
                            {number == 1 && <p><b>Доступ 1&nbsp;год</b></p>}
                            {number == 2 && <p><b>Доступ 1&nbsp;год</b></p>}
                            {number == 3 && <p><b>Доступ 6&nbsp;месяцев</b></p>}
                            
                        </div>
                        <div className={styles.promo_icon}>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2023/restart/3/promo_3.png" alt="" /></div>
                            <p><b>Старт сразу после<br/> оплаты</b></p>
                        </div>
                    </div>
                    <div className={styles.promo_btns}>
                        <div className={styles.promo_btn} onClick={scroll}><p>ЗАБРАТЬ ПРОГРАММЫ</p></div>
                        <div className={styles.start_countdown}>
                            <p>До закрытия<br/> спецпредложения🔥</p>
                            <div className={styles.start_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Promo;