import Countdown from "react-countdown";
import { SalesWidget } from "@com.marathonium/web2-ui";
import styles from "./start.module.css";
import { useState } from "react";

const Start = ({ renderer, scroll, date2, renderer2 }) => {
  const [date, setDate] = useState(Date.now() + 900000);

  return (
    <>
      <div className={"scrollhere " + styles.start}>
        <div className="container">
          <div className={styles.start_block}>
            <div className={styles.start_block_title}>
              Запишись на&nbsp;программу прямо
              <br /> сейчас и&nbsp;создай стройное тело
              <br /> уже этой осенью!
            </div>
            <div className={styles.start_block_subtitle}>
              Такой скидки больше не&nbsp;будет!
              <br /> А&nbsp;следующий поток начнется только через полгода
            </div>
            <div className={styles.prices}>
              <p>5&nbsp;990&nbsp;₽</p>
              <p>10&nbsp;000&nbsp;₽</p>
            </div>

            <div className={styles.remain} onClick={scroll}>
              {/* <div className={styles.remain_1}>
                350 <span>мест</span>
              </div> */}
              <div className={styles.remain_3}>
                <img
                  src="https://gymteam.kinescopecdn.net/img/l/2024/um2/blank.png"
                  alt=""
                />
                <p>-40%</p>
              </div>
              <div className={styles.remain_2}>
                <div className={styles.remain_2_1}>
                Предложение действует только до&nbsp;23:59. <span>Далее цена повысится</span>
                </div>
                
                {/* <div className={styles.remain_2_2}>
                  Осталось мест: <span>{window.lps_settings.other.count}</span>
                </div> */}
              </div>
              <div className={styles.promo_countdown_timer}>
                  <p>Предложение сгорит через:</p>
                  <Countdown date={date2} renderer={renderer2} />
                </div>
            </div>
            <div className={styles.n_dates}>
              <div className={styles.n_date}>
                <img
                  src="https://gymteam.kinescopecdn.net/img/l/2024/um2/pr2.svg"
                  alt=""
                />
                <p>Дата старта 2&nbsp;потока&nbsp;&mdash; 17&nbsp;сентября</p>
              </div>
            </div>

            <div className={styles.list}>
              <div className={styles.list_head}>
                <div className={styles.list_head_title}>
                  Метод Усмановой.&nbsp;2&nbsp;поток
                </div>
                <div className={styles.list_head_prices}>
                  <p>5&nbsp;990&nbsp;₽</p>
                  <p>10&nbsp;000&nbsp;₽</p>
                </div>
              </div>
              <ul>
                <li>
                  20&nbsp;домашних тренировок в&nbsp;формате &laquo;Повторяй
                  за&nbsp;Катей&raquo;
                </li>
                <li>
                  4&nbsp;тренировки на&nbsp;создание здорового тела
                  от&nbsp;реабилитолога
                </li>
                <li>6&nbsp;тренировок на&nbsp;омоложение лица</li>
                <li>5&nbsp;уроков от&nbsp;нутрициолога</li>
                <li>
                  Вебинар с&nbsp;ответами на&nbsp;вопросы от&nbsp;нутрициолога
                </li>
                <li>
                  Готовый план питания на&nbsp;2&nbsp;недели и&nbsp;рецепты
                  различных блюд
                </li>
                <li>
                  Образовательные уроки &laquo;Как создать здоровое тело&raquo;
                </li>
                <li>
                  Чат с&nbsp;кураторами и&nbsp;участницами на&nbsp;28&nbsp;дней
                </li>
                <li>Конкурс на&nbsp;лучшие результаты</li>
                <li>
                  Доступ 2&nbsp;месяца к&nbsp;материалам программы после
                  ее&nbsp;окончания
                </li>
              </ul>
              <div className={styles.list_gifts}>
                <p>+розыгрыш айфонов</p>
                <p>+5&nbsp;полезных гайдов</p>
              </div>
            </div>

            <div className={"scrollhere2 " + styles.promo_countdown}>
              <p>Все бонусы сгорят через:</p>
              <div className={styles.promo_countdown_timer}>
                <Countdown date={date} renderer={renderer} />
              </div>
            </div>

            <div className="um2main_start">
              <SalesWidget
                id={window.lps_settings.forms.um2main_4_id}
                buttonText="Оформить заказ"
              />
            </div>
            <p>
              Платите с&nbsp;любой карты&nbsp;— выпущенной российским
              или&nbsp;иностранным банком.
            </p>

            <div className={styles.pay}>
              <div className={styles.pay_item} onClick={scroll}>
                <div className={styles.pay_item_left}>
                  <div className={styles.pay_item_title}>
                    Рассрочка от&nbsp;Тинькофф
                  </div>
                  <div className={styles.pay_item_text}>
                    Платите потом&nbsp;&mdash; первый платёж через 30&nbsp;дней{" "}
                    <b>от&nbsp;998&nbsp;₽</b> в&nbsp;месяц. Без переплат, без
                    процентов, до&nbsp;6&nbsp;месяцев. Только для
                    граждан&nbsp;РФ.
                  </div>
                </div>
                <div className={styles.pay_item_right}>
                  <img
                    src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_1.png"
                    alt=""
                  />
                </div>
              </div>
              <div className={styles.pay_item} onClick={scroll}>
                <div className={styles.pay_item_left}>
                  <div className={styles.pay_item_title}>По QR-коду</div>
                  <div className={styles.pay_item_text}>
                    Отсканируйте камерой телефона QR-код и&nbsp;перейдите
                    по&nbsp;ссылке в&nbsp;приложение банка, чтобы подтвердить
                    оплату. Удобно со&nbsp;SberPay.
                  </div>
                </div>
                <div className={styles.pay_item_right}>
                  <img
                    src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_2.png"
                    alt=""
                  />
                </div>
              </div>
              <div className={styles.pay_item} onClick={scroll}>
                <div className={styles.pay_item_left}>
                  <div className={styles.pay_item_title}>Долями</div>
                  <div className={styles.pay_item_text}>
                    Разделите платёж на&nbsp;4&nbsp;равные части{" "}
                    <b>от&nbsp;1&nbsp;497,5&nbsp;₽</b>. Всего
                    на&nbsp;6&nbsp;недель. Без ожидания одобрения банка,
                    переплат и&nbsp;рисков.
                  </div>
                </div>
                <div className={styles.pay_item_right}>
                  <img
                    src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_3.png"
                    alt=""
                  />
                </div>
              </div>
              <div className={styles.pay_item} onClick={scroll}>
                <div className={styles.pay_item_left}>
                  <div className={styles.pay_item_title}>PayPal</div>
                  <div className={styles.pay_item_text}>
                    Введите только электронный адрес и&nbsp;пароль&nbsp;&mdash;
                    не&nbsp;нужно вводить номер карты. Безопасный способ платы,
                    доступный клиентам банков не&nbsp;РФ.
                  </div>
                </div>
                <div className={styles.pay_item_right}>
                  <img
                    src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_6.png"
                    alt=""
                  />
                </div>
              </div>
              <div className={styles.pay_item} onClick={scroll}>
                <div className={styles.pay_item_left}>
                  <div className={styles.pay_item_title}>
                    Картой банка&nbsp;РФ
                    <br /> или зарубежного
                  </div>
                  <div className={styles.pay_item_text}>
                    Можете пользоваться своей картой, где&nbsp;бы
                    вы&nbsp;ни&nbsp;находились. Для оплаты картой иностранного
                    банка, выберите оплату через Stripe.
                  </div>
                </div>
                <div className={styles.pay_item_right}>
                  <img
                    src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_4.png"
                    alt=""
                  />
                </div>
              </div>
              <div className={styles.pay_item} onClick={scroll}>
                <div className={styles.pay_item_left}>
                  <div className={styles.pay_item_title}>
                    Наличными в&nbsp;терминалах
                  </div>
                  <div className={styles.pay_item_text}>
                    Оплатите ваш заказ наличными через ближайший терминал.
                    Быстро и&nbsp;удобно, без использования кредитных карт
                    и&nbsp;необходимости раскрывать личные данные.
                  </div>
                </div>
                <div className={styles.pay_item_right}>
                  <img
                    src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_5.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Start;
