import React, { useEffect } from "react";
import { URLSearchParamsCatcher } from "@com.marathonium/web2-utils";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { theme } from "./theme";

import { L2022_11_Pregnancy } from "./2022-11-pregnancy";
import { L2022_11_Afterpregnancy } from "./2022-11-afterpregnancy";
import { L2022_11_Marafon_str1 } from "./2022-11-marafon-str1";
import { L2022_12_Marafon_abs } from "./2022-11-slimstomach";
import { L2022_12_Marafon_fatburning } from "./2022-12-fatburning";
import { L2022_12_Marafon_popa2 } from "./2022-12-marafonpopa2";
import { L2022_12_Marafon_popa1 } from "./2022-12-marafonpopa1";
import { L2022_12_Marafon_removefat } from "./2022-12-removefat";
import { L2022_12_Marafon_anticellulite } from "./2022-11-anticellulite";
import { TestWidget } from "./testwidget";
import { L2022_12_Coursepop2 } from "./2022-12-coursepop2";
import { L2022_12_Coursepop3 } from "./2022-12-coursepop3";
import { TransferPage } from "./2022-12-transfer";
import { L2022_12_Nutrition } from "./2022-12-nutrition";
import { L2023_01_gtv } from "./2023/gtv";
import { L2023_01_Gymteamsale } from "./2023/01-gymteamsale";
import { L2023_03_Softskill } from "./2023/03-softskill";
import { L2023_03_gtv_price } from "./2023/gtv/price";
import { Reftest } from "./2023/reftest";
import { Refregpage } from "./2023/reftest/refreg";
import { L2023_05_Ofertacontest } from "./2023/05-ofertacontest";
import { L2023_05_Referral } from "./2023/05-referral";
import { L2023_06_Rasprozarka10 } from "./2023/06-rasprozarka10";
import { L2023_06_Ofertacontestgym } from "./2023/06-ofertacontestgym";
import { L2023_06_Calculator } from "./2023/06-kbzucalc";
import { L2023_06_Universalpop } from "./2023/06-universalpop";
import { L2023_06_Stretchweek } from "./2023/06-stretchweek";
import { L2023_06_Gymlink } from "./2023/04-singlepages/gymlink";
import { L2023_06_Gymlink2 } from "./2023/04-singlepages/gymlink/index2";
import { L2023_06_Bikinifest2 } from "./2023/06-bikinifest2";
import { Legal_public } from "./components/oferts/Public";
import { Legal_confidentiality } from "./components/oferts/Confidentiality";
import { L2023_07_Butttraining2 } from "./2023/07-butttraining/index2";
import { L2023_02_Meltingprices } from "./2023/02-perezagruzka/index2";
import { L2023_02_Closedsale } from "./2023/02-perezagruzka/index3";
import { L2023_07_Restartzakaz2 } from "./2023/07-restart2/index2";
import { L2023_07_Restart3_julysale } from "./2023/07-restart3/index3";
import { L2023_07_Restart3_julyoffer } from "./2023/07-restart3/index4";
import { L2023_07_Restart3_saleoffer } from "./2023/07-restart3/index5";
import { L2023_08_Woweffect2_sms } from "./2023/08-woweffect2/index2";
import { L2023_08_Velvetbox } from "./2023/08-velvetbox";
import { L2023_04_afterbirthsms } from "./2023/04-afterbirth/index2";
import { L2022_12_Mainpage } from "./2022-12-mainpage";
import { L2023_09_Prolong } from "./2023/08-velvet3/index3";
import { L2023_08_Velvet4_sms } from "./2023/08-velvet4/index2";
import { L2023_08_Salemanager } from "./2023/08-velvet3/index2";
import { L2023_08_Bestfriends } from "./2023/08-bestfriends";
import { L2023_09_VelvetContestResult } from "./2023/09-velvetcontestresult";
import { L2023_09_Fatlost } from "./2023/09-fatburning";
import { L2023_09_Gymsms } from "./2023/09-gym/index2";
import { L2023_09_Weekends } from "./2023/09-weekends";
import { Guest } from "./2023/Guest";
import { L2023_09_Fatlost2 } from "./2023/09-fatburning/second";
import { L2023_09_Fatlost3 } from "./2023/09-fatburning/third";
import { L2023_09_Fatlost4 } from "./2023/09-fatburning/fourth";
import { L2023_09_Fatlost5 } from "./2023/09-fatburning/fifth";
import { L2023_10_Lessons_sms } from "./2023/10-lessons/index2";
import { L2023_09_Fatlost6 } from "./2023/09-fatburning/six";
import { L2023_09_Birthdaypartysms } from "./2023/09-birthdayparty/index2";
import { L2023_10_Newsms } from "./2023/10-new/index2";
import { L2023_11_Chpbox } from "./2023/11-chpbox";
import { L2023_11_Missvelvet2023 } from "./2023/11-missvelvet2023";
import { L2023_11_Chp3 } from "./2023/11-chp/landing/3";
import { L2023_11_Chpbox_kate } from "./2023/11-chpbox/index2";
import { L2023_11_Misshot } from "./2023/11-misshot";

import "./style.css";
import { L2023_11_Chp } from "./2023/11-chp";
import { L2023_11_Chp3_sms } from "./2023/11-chp/landing/3/index2";
import { L2023_11_Chp_gym } from "./2023/11-chp/landing/gym";
import { L2023_11_Chp4 } from "./2023/11-chp/landing/4";
import { L2023_11_Chp4_segment } from "./2023/11-chp/landing/4_1";
import { L2023_11_Chp4_kur } from "./2023/11-chp/landing/4_2";
import { L2023_11_Chp4_sms } from "./2023/11-chp/landing/4/index2";
import { L2023_11_Chp5 } from "./2023/11-chp/landing/5";
import { L2023_11_Chp5_1 } from "./2023/11-chp/landing/5_1";
import { L2023_11_Chp5_2 } from "./2023/11-chp/landing/5_2";
import { L2023_11_Chp6 } from "./2023/11-chp/landing/6";
import { L2023_11_Chp5_sms } from "./2023/11-chp/landing/5/index2";
import { L2023_11_Chp7 } from "./2023/11-chp/landing/7";
import { L2023_11_Chp6_sms } from "./2023/11-chp/landing/6/index2";
import { L2023_11_Chp_limited } from "./2023/11-chp/landing/limited";
import { L2023_11_Chp7_reserve } from "./2023/11-chp/landing/reserve";
import { L2023_11_Chp7_sms } from "./2023/11-chp/landing/7/index2";
import { L2023_11_Chp7_close } from "./2023/11-chp/landing/7/index3";
import { L2023_11_Chp8 } from "./2023/11-chp/landing/8";
import { L2023_12_Marafon } from "./2023/12-marafon";
import { L2023_11_Chp8_sms } from "./2023/11-chp/landing/8/index2";
import { L2023_12_Marafon_sms } from "./2023/12-marafon/index2";
import { L2023_12_Gym } from "./2023/12-decembergym";
import { L2023_12_Rkhappy } from "./2023/12-rkhappy";
import { L2023_12_Gym_sms } from "./2023/12-decembergym/index2";
import { L2023_12_Newyear2024 } from "./2023/12-newyear2024";
import { L2023_12_Foryou } from "./2023/12-foryou";
import { L2023_12_Newyear2024_sms } from "./2023/12-newyear2024/index2";
import { L2023_12_Newyear2024_sms2 } from "./2023/12-newyear2024/index3";
import { L2023_12_Holidays } from "./2023/12-holidays";
import { L2023_12_Holidays_sms } from "./2023/12-holidays/index2";
import { L2024_01_Fitmission } from "./2024/01-fitmission";
import { L2024_01_Fitbox } from "./2024/01-fitbox";
import { L2024_01_Fitmission_sms } from "./2024/01-fitmission/index2";
import { L2024_01_Slim_tg } from "./2024/01-fitmission/lmslim";
import { L2024_01_Popa_tg } from "./2024/01-fitmission/lmpopa";
import { L2024_01_Gym_tg } from "./2024/01-fitmission/lmgym";
import { L2024_01_Fitmission2 } from "./2024/01-fitmission2";
import { L2023_04_Culture } from "./2023/04-singlepages/culture";
import { L2024_01_Single_Gym } from "./2024/01-singlepages/gym";
import { L2024_01_Single_Pregnancy } from "./2024/01-singlepages/pregnancy";
import { L2024_01_Single_Afterpregnancy } from "./2024/01-singlepages/afterpregnancy";
import { L2024_01_Single_Prolong } from "./2024/01-singlepages/prolong";
import { L2024_01_Corporate } from "./2024/01-corporate";
import { L2024_01_Fitmission3 } from "./2024/01-fitmission3";
import { L2024_01_Fitbox_1 } from "./2024/01-fitbox/index2";
import { L2024_01_Fitbox_2 } from "./2024/01-fitbox/index3";
import { L2024_01_Fitmission3_sms } from "./2024/01-fitmission3/index2";
import { L2024_01_Fitmission3_2 } from "./2024/01-fitmission3/index3";
import { L2024_01_New } from "./2024/01-new";
import { L2024_01_Gym } from "./2024/01-gym";
import { L2024_01_Gym_sms } from "./2024/01-gym/index2";
import { L2024_02_Vpr } from "./2024/02-vpr";
import { L2024_02_Vpr_sms } from "./2024/02-vpr/index2";
import { L2024_02_Marathonsf } from "./2024/02-marathonsf";
import { L2024_02_Springawake } from "./2024/02-springawake";
import { L2024_02_Marathonsf_sms } from "./2024/02-marathonsf/index2";
import { L2024_02_Springawake2 } from "./2024/02-springawake2";
import { L2024_02_Springawake2_sms } from "./2024/02-springawake2/index2";
import { L2024_03_Womday } from "./2024/03-womday";
import { L2024_03_Womday_box } from "./2024/03-wdbox";
import { L2024_03_Womday2 } from "./2024/03-womday2";
import { L2024_03_8march } from "./2024/03-8march";
import { L2024_03_Womday_sms } from "./2024/03-womday/index2";
import { L2024_03_Womday2_sms } from "./2024/03-womday2/index2";
import { L2024_03_Gymmarch } from "./2024/03-gymmarch";
import { L2024_03_Gymmarch_sms } from "./2024/03-gymmarch/index2";
import { Checkauthwidget } from "./testwidget/index2";
import { L2024_03_Fitnesvesna } from "./2024/03-fitnesvesna";
import { L2024_03_Fitnesvesna2 } from "./2024/03-fitnesvesna2";
import { L2024_04_Missfitmission } from "./2024/04-missfitmission";
import { L2024_04_Usmanovatest } from "./2024/04-usmanovatest";
import { L2024_04_Upsale } from "./2024/04-upsale";
import { L2024_04_Method } from "./2024/04-method";
import { L2024_04_Oferta_metod } from "./2023/06-ofertacontestgym/index2";
import { L2024_04_Method_upg } from "./2024/03-womday2/index3";
import { L2024_04_Methodpay } from "./2024/04-method/index3";
import { L2024_04_Method_start } from "./2024/04-method_start";
import { L2024_04_Method_end } from "./2024/04-method/index4";
import { L2024_04_Missfitmission_result } from "./2024/04-missfitmission/index2";
import { L2024_05_Rasprozharka } from "./2024/05-rasprozharka";
import { L2023_09_Birthdayparty } from "./2023/09-birthdayparty";
import { L2023_09_Change } from "./2023/09-change";
import { L2023_09_Gym } from "./2023/09-gym";
import { L2023_10_Lessons } from "./2023/10-lessons";
import { L2023_10_New } from "./2023/10-new";
import { L2024_05_Rasprozharka_up } from "./2024/05-rasprozharka_up";
// import { L2024_05_Rasprozharka_2 } from './2024/05-rasprozharka2';
// import { L2024_05_Rasprozharka_sms } from './2024/05-rasprozharka/index2';
// import { L2024_05_Rasprozharka_3 } from './2024/05-rasprozharka3';
// import { L2024_05_Rasprozharka_4 } from './2024/05-rasprozharka4';
// import { L2024_05_Rasprozharka_4_2 } from './2024/05-rasprozharka4_2';
import { L2024_05_Rasprozharka_og } from "./2024/05-rasprozharka_og";
// import { L2024_05_Rasprozharka_og2 } from './2024/05-rasprozharka_og/index2';
// import { L2024_05_Rasprozharka_5 } from './2024/05-rasprozharka5';
// import { L2024_06_Missstroinost24 } from './2024/06-missstroinost24';
// import { L2024_06_Vpr } from './2024/06-vpr';
// import { L2024_06_Bikinifest } from './2024/06-bikinifest';
// import { L2024_06_Gymjune } from './2024/06-gymjune';
import { L2024_06_Order } from "./2024/06-order";
// import { L2024_07_Fitsummer } from './2024/07-fitsummer';
// import { L2024_07_Fitsummer_2 } from './2024/07-fitsummer2';
// import { L2023_10_New2 } from './2023/10-new2';
import { L2024_07_Doit } from "./2024/07-doit";
// import { L2024_07_Doit_start } from './2024/07-doit/index2';
// import { L2024_07_Actionsummer } from './2024/07-actionsummer';
// import { L2024_07_Mu } from './2024/07-mu';
// import { L2024_07_Mu2 } from './2024/07-mu2';
// import { L2024_07_Mu3 } from './2024/07-mu3';
import { L2024_07_Thanks } from "./2024/06-order/thanks";
// import { L2024_08_Salecoursegym } from './2024/08-sale_coursegym';
import { L2024_08_Missstroinost24 } from "./2024/08-missstroinost24";
import { L2024_08_QuizTest } from "./2024/08-quiz-test";
import { L2024_08_Quiz1 } from "./2024/08-quiz1";
// import { L2024_08_Leto24 } from './2024/08-leto24';
import { L2024_08_Quiz1_price } from "./2024/08-quiz1price";
import { L2024_08_Vitrina } from "./2024/08-vitrina";
import { L2024_07_Doit_start_2 } from "./2024/07-doit/index2_2";
// import { L2024_08_Leto2 } from './2024/08-velvet';
import { L2024_08_Allprogramms } from "./2024/08-allprogramms";
import { L2024_08_Misshot } from "./2024/08-misshot";
import { L2024_08_QuizTest3 } from "./2024/08-quiz-test/index3";
import { L2024_07_Um2_up } from "./2024/08-um2_upsale";
import { L2024_07_Doitreg } from "./2024/07-doitreg";
import { L2024_07_Um2 } from "./2024/08-um2";
import { L2024_08_Oferta_metod_p } from "./2024/08-um2/oferta_p";
import { L2024_08_Oferta_metod_c } from "./2024/08-um2/oferta_c";
import { MyRedirect } from "./2023/gtv/components/redirec";
import { L2024_08_QuizTest2 } from "./2024/08-quiz-test/index2";

function App() {
	useEffect(() => {
		const mode = localStorage.getItem("chakra-ui-color-mode");
		if (mode !== "light") {
			localStorage.setItem("chakra-ui-color-mode", "light");
			window.location.reload(false);
		}
	}, []);

	return (
		<ChakraProvider resetCSS theme={theme}>
			<URLSearchParamsCatcher />
			<BrowserRouter basename={process.env.PUBLIC_URL}>
				<Routes>
					<Route path="/afterpregnancy" element={<L2022_11_Afterpregnancy />} />
					<Route path="/pregnancy" element={<L2022_11_Pregnancy />} />
					<Route path="/marafonstr1" element={<L2022_11_Marafon_str1 />} />
					<Route path="/marafonabs" element={<L2022_12_Marafon_abs />} />
					<Route path="/fatburning" element={<L2022_12_Marafon_fatburning />} />
					<Route path="/marafonpopa1" element={<L2022_12_Marafon_popa1 />} />
					<Route path="/marafonpopa2" element={<L2022_12_Marafon_popa2 />} />
					<Route path="/removefat" element={<L2022_12_Marafon_removefat />} />
					<Route
						path="/anticellulite"
						element={<L2022_12_Marafon_anticellulite />}
					/>
					<Route path="/coursepop2" element={<L2022_12_Coursepop2 />} />
					<Route path="/coursepop3" element={<L2022_12_Coursepop3 />} />
					<Route path="/nutrition" element={<L2022_12_Nutrition />} />
					<Route path="/mainpage_old" element={<L2022_12_Mainpage />} />
					<Route path="/mainpage" element={<L2024_08_Vitrina />} />
					<Route path="/testwidget" element={<TestWidget />} />
					<Route path="/transfer" element={<TransferPage />} />
					<Route path="/fitmissionrk" element={<Navigate to="/restart" />} />
					<Route path="/gtv" element={<L2023_01_gtv />} />
					<Route path="/price" element={<L2023_03_gtv_price />} />
					<Route path="/popa2web" element={<Navigate to="/restart" />} />
					<Route path="/gymteamsale" element={<L2023_01_Gymteamsale />} />
					<Route
						path="/missutcontest_results"
						element={<Navigate to="/restart" />}
					/>
					<Route path="/softskills" element={<L2023_03_Softskill />} />
					<Route path="/newprogram" element={<Navigate to="/restart" />} />
					<Route path="/blossom" element={<Navigate to="/restart" />} />
					<Route path="/legendary" element={<Navigate to="/restart" />} />
					<Route path="/formula" element={<Navigate to="/restart" />} />
					<Route path="/reconstruction" element={<Navigate to="/restart" />} />
					<Route path="/culture" element={<Navigate to="/restart" />} />
					<Route path="/reftest" element={<Reftest />} />
					<Route path="/refregpage" element={<Refregpage />} />
					<Route
						path="/rasprozarkazakazkurs"
						element={<Navigate to="/restart" />}
					/>
					<Route path="/rasprozarkark" element={<Navigate to="/restart" />} />
					<Route path="/rkrasprozarka" element={<Navigate to="/restart" />} />
					<Route
						path="/rasprozarkaprizuk"
						element={<Navigate to="/restart" />}
					/>
					<Route path="/ofertacontest" element={<L2023_05_Ofertacontest />} />
					<Route
						path="/ofertacontestgym"
						element={<L2023_06_Ofertacontestgym />}
					/>
					<Route
						path="/rasprozarkazakaz"
						element={<Navigate to="/restart" />}
					/>
					<Route path="/rasprozarka" element={<Navigate to="/restart" />} />
					<Route path="/coursegym1" element={<Navigate to="/coursegym" />} />
					<Route path="/coursegym" element={<L2023_06_Rasprozarka10 />} />
					<Route path="/gym" element={<Navigate to="/septembersale" />} />
					<Route path="/referral" element={<L2023_05_Referral />} />
					<Route path="/calculator" element={<L2023_06_Calculator />} />
					<Route path="/universalpop" element={<L2023_06_Universalpop />} />
					<Route path="/summerstr" element={<Navigate to="/stretching" />} />
					<Route path="/gymzakaz" element={<Navigate to="/restart" />} />
					<Route path="/summerorder" element={<Navigate to="/restart" />} />
					<Route path="/stretching" element={<L2023_06_Stretchweek />} />
					<Route
						path="/stretchingpromo"
						element={<Navigate to="/stretching" />}
					/>
					<Route path="/bikinifest" element={<Navigate to="/restart" />} />
					<Route path="/bikinifestrk" element={<L2023_06_Bikinifest2 />} />
					<Route path="/bikinizakaz" element={<Navigate to="/restart" />} />
					<Route path="/festzakaz" element={<Navigate to="/restart" />} />
					<Route path="/gymlink" element={<L2023_06_Gymlink />} />
					<Route path="/gymlinkKate" element={<L2023_06_Gymlink2 />} />
					<Route path="/secret" element={<Navigate to="/restart" />} />
					<Route path="/universal" element={<Navigate to="/restart" />} />
					<Route path="/universalzakaz" element={<L2023_07_Butttraining2 />} />
					<Route path="/legal/public" element={<Legal_public />} />
					<Route
						path="/legal/confidentiality"
						element={<Legal_confidentiality />}
					/>
					<Route path="/restart" element={<Navigate to="/woweffect" />} />
					<Route path="/happy" element={<Navigate to="/restart" />} />
					<Route path="/restartzakaz" element={<L2023_07_Restartzakaz2 />} />
					<Route path="/meltingprices" element={<L2023_02_Meltingprices />} />
					<Route path="/closedsale" element={<Navigate to="/preview" />} />
					<Route path="/woweffect" element={<Navigate to="/velvet" />} />
					<Route path="/woweffectzakaz" element={<L2023_08_Woweffect2_sms />} />
					<Route path="/julysale" element={<L2023_07_Restart3_julysale />} />
					<Route path="/julyoffer" element={<L2023_07_Restart3_julyoffer />} />
					<Route path="/saleoffer" element={<L2023_07_Restart3_saleoffer />} />
					<Route path="/velvetbox" element={<L2023_08_Velvetbox />} />
					<Route path="/velvet" element={<Navigate to="/gym" />} />
					<Route path="/velvetsms" element={<L2023_08_Velvet4_sms />} />
					<Route
						path="/safaricontest"
						element={<Navigate to="/missutcontestresultsaugust2023" />}
					/>
					<Route
						path="missutcontestresultsaugust2023"
						element={<L2023_09_VelvetContestResult />}
					/>
					<Route path="/salemanager" element={<L2023_08_Salemanager />} />
					<Route path="/prolong" element={<L2023_09_Prolong />} />
					<Route path="/bestfriends" element={<L2023_08_Bestfriends />} />
					<Route path="/fatlost" element={<L2023_09_Fatlost />} />
					<Route path="/fatlost/1year" element={<L2023_09_Fatlost2 />} />
					<Route path="/fatlost/6month" element={<L2023_09_Fatlost3 />} />
					<Route path="/fatlost/3month" element={<L2023_09_Fatlost4 />} />
					<Route path="/fatlost/1month" element={<L2023_09_Fatlost5 />} />
					<Route path="/fatlost/1year15" element={<L2023_09_Fatlost6 />} />
					<Route path="/weekends" element={<L2023_09_Weekends />} />
					<Route
						path="/septembersale"
						element={<Navigate to="/blackfriday2023" />}
					/>
					<Route
						path="/birthday"
						element={<Navigate to="/blackfriday2023" />}
					/>
					<Route path="/smsbp" element={<L2023_09_Birthdaypartysms />} />
					<Route path="/change" element={<Navigate to="/blackfriday2023" />} />
					<Route path="/lessons" element={<Navigate to="/blackfriday2023" />} />
					<Route path="/smslessons" element={<L2023_10_Lessons_sms />} />
					<Route path="/anketa" element={<Guest />} />
					<Route path="/preview" element={<Navigate to="/fitnesvesna" />} />
					<Route
						path="/blackfriday2023"
						element={<Navigate to="/fitnesvesna" />}
					/>
					<Route path="/marafon" element={<Navigate to="/newyear2024" />} />
					<Route path="/strsms" element={<L2023_12_Marafon_sms />} />
					<Route path="/decembergym" element={<Navigate to="/jangym" />} />
					<Route path="/jangym" element={<Navigate to="/mainpage" />} />
					<Route path="/bfreserv" element={<Navigate to="/fitmission" />} />
					<Route path="/bflimited" element={<Navigate to="/fitmission" />} />
					<Route
						path="/bfclosebooking"
						element={<Navigate to="/fitmission" />}
					/>
					<Route
						path="/blackfridayrk2023"
						element={<Navigate to="/fitmission" />}
					/>
					<Route path="/bfexclusive" element={<Navigate to="/fitmission" />} />
					<Route path="/bf2023" element={<Navigate to="/fitmission" />} />
					<Route path="/closebf" element={<Navigate to="/fitmission" />} />
					<Route path="/bfchat" element={<Navigate to="/fitmission" />} />
					<Route path="/bfsms" element={<Navigate to="/fitmission" />} />
					<Route path="/bfbox" element={<Navigate to="/fitmission" />} />
					<Route path="/boxkate" element={<Navigate to="/fitmission" />} />
					<Route path="/missvelvet2023" element={<L2023_11_Missvelvet2023 />} />

					<Route path="/rkhappy" element={<L2023_12_Rkhappy />} />
					<Route path="/gymsms" element={<L2023_12_Gym_sms />} />
					<Route path="/newyear2024" element={<Navigate to="/fitnesvesna" />} />
					<Route path="/newsms" element={<L2023_12_Newyear2024_sms2 />} />
					<Route path="/foryou" element={<L2023_12_Foryou />} />
					<Route path="/managersale" element={<L2023_12_Newyear2024_sms />} />
					<Route path="/holidays" element={<Navigate to="/fitnesvesna" />} />
					<Route path="/hdsms" element={<L2023_12_Holidays_sms />} />

					<Route path="/fitmission" element={<Navigate to="/fitnesvesna" />} />
					<Route path="/fitsms" element={<L2024_01_Fitmission3_sms />} />
					<Route path="/lmslim" element={<L2024_01_Slim_tg />} />
					<Route path="/lmpopa" element={<L2024_01_Popa_tg />} />
					<Route path="/lmgym" element={<L2024_01_Gym_tg />} />

					<Route path="/single_gym" element={<L2024_01_Single_Gym />} />
					<Route
						path="/single_pregnancy"
						element={<L2024_01_Single_Pregnancy />}
					/>
					<Route
						path="/single_afterpregnancy"
						element={<L2024_01_Single_Afterpregnancy />}
					/>
					<Route path="/single_prolong" element={<L2024_01_Single_Prolong />} />

					<Route path="/corporate" element={<L2024_01_Corporate />} />

					<Route path="/new" element={<Navigate to="/rasprozharka2024" />} />
					<Route path="/gymfest" element={<Navigate to="/fitnesvesna" />} />
					<Route path="/smsgym" element={<L2024_01_Gym_sms />} />

					<Route path="/mom" element={<Navigate to="/bikinifest24" />} />
					<Route path="/momsms" element={<L2024_02_Vpr_sms />} />

					<Route path="/marathonsf" element={<Navigate to="/fitnesvesna" />} />
					<Route
						path="/marathonsf_form"
						element={<L2024_02_Marathonsf_sms />}
					/>
					<Route path="/springawake" element={<Navigate to="/fitnesvesna" />} />
					<Route
						path="/springawake_form"
						element={<L2024_02_Springawake2_sms />}
					/>

					<Route path="/womday" element={<Navigate to="/fitnesvesna" />} />
					<Route path="/fitdev_form" element={<L2024_03_Womday_sms />} />
					<Route path="/fitdev2_form" element={<L2024_03_Womday2_sms />} />

					<Route path="/wd_boxes" element={<L2024_03_Womday_box />} />

					<Route path="/8march" element={<L2024_03_8march />} />

					<Route path="/gymmarch" element={<Navigate to="/bikinifest24" />} />
					<Route path="/mgym_form" element={<L2024_03_Gymmarch_sms />} />

					<Route
						path="/fitnesvesna"
						element={<Navigate to="/rasprozharka2024" />}
					/>

					<Route path="/checkauthwidget" element={<Checkauthwidget />} />

					<Route
						path="/missfitmission"
						element={<Navigate to="/missfitmission_winners" />}
					/>
					<Route path="/um_special" element={<L2024_04_Upsale />} />
					<Route path="/metod2024" element={<L2024_04_Method />} />
					<Route path="/oferta_metod" element={<L2024_04_Oferta_metod />} />
					<Route path="/metod_upgrade" element={<L2024_04_Method_upg />} />
					<Route path="/metodpay" element={<Navigate to="/mainpage" />} />
					<Route path="/metod_start" element={<Navigate to="/mainpage" />} />
					<Route path="/metodend" element={<L2024_04_Method_end />} />

					<Route
						path="/missfitmission_winners"
						element={<L2024_04_Missfitmission_result />}
					/>

					<Route path="/usmanovatest" element={<L2024_04_Usmanovatest />} />

					<Route
						path="/rasprozharka2024"
						element={<Navigate to="/bikinifest24" />}
					/>
					<Route path="/v_love" element={<L2024_05_Rasprozharka_og />} />
					<Route
						path="/rasprozharka24v1"
						element={<Navigate to="/rasprozharka2024" />}
					/>
					<Route path="/so_gt1" element={<L2024_05_Rasprozharka_up />} />

					<Route path="/bikinifest24" element={<Navigate to="/gymjune" />} />
					<Route path="/gymjune" element={<Navigate to="/fit_summer" />} />

					<Route path="/order/:id" element={<L2024_06_Order />} />
					<Route path="/order/:id/:code" element={<L2024_06_Order />} />
					<Route path="/thanks" element={<L2024_07_Thanks />} />

					<Route
						path="/fit_summer"
						element={<Navigate to="/action_summer" />}
					/>

					<Route path="/do-it" element={<L2024_07_Doit />} />
					<Route path="/do-it-reg" element={<L2024_07_Doitreg />} />
					<Route path="/price-new" element={<L2024_07_Doit_start_2 />} />

					<Route
						path="/action_summer"
						element={<Navigate to="/prodli_leto24" />}
					/>

					<Route
						path="/sale_coursegym"
						element={<Navigate to="/prodli_leto24" />}
					/>

					<Route
						path="/missstroinost24"
						element={<L2024_08_Missstroinost24 />}
					/>

					<Route path="/quiz-test-3" element={<L2024_08_QuizTest2 />} />
					<Route path="/quiz-test" element={<L2024_08_QuizTest3 />} />

					{/* <Route path="/quiz-test2" element={<L2024_08_QuizTest2 />} /> */}

					<Route path="/quiz-test/step-1/" element={<L2024_08_Quiz1 />} />
					<Route
						path="/quiz-test/step-1/:q4&:q5"
						element={<L2024_08_Quiz1 />}
					/>
					<Route
						path="/quiz-test/step-1/price/"
						element={<L2024_08_Quiz1_price />}
					/>
					<Route
						path="/quiz-test/step-1/price/:q1&:q2&:q3&:q4&:q5"
						element={<L2024_08_Quiz1_price />}
					/>

					<Route path="/prodli_leto24" element={<Navigate to="/metod2" />} />
					<Route path="/prodli_leto2" element={<Navigate to="/metod2" />} />

					<Route path="/allprogramms" element={<L2024_08_Allprogramms />} />

					<Route path="/misshot" element={<L2024_08_Misshot />} />

					<Route path="/specoffer2_metod2" element={<L2024_07_Um2_up />} />

					<Route path="/oferta_metod_c" element={<L2024_08_Oferta_metod_c />} />
					<Route path="/oferta_metod_p" element={<L2024_08_Oferta_metod_p />} />
					<Route path="/metod2" element={<L2024_07_Um2 />} />
					<Route path="/presale2_metod2" element={<Navigate to="/metod2" />} />

					<Route
						path="/open_training"
						element={<MyRedirect url="https://usmanovateam.ru/open_training" />}
					/>
					<Route
						path="/webinar_show"
						element={
							<MyRedirect url="https://usmanovateam.ru/open_training/web" />
						}
					/>
				</Routes>
			</BrowserRouter>
		</ChakraProvider>
	);
}

export default App;
