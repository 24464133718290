import styles from './onepage.module.css';

const Popa = () => {
    return (
        <div className={`${styles.page} ${styles.page_popa}`}>
            <div className="container">
                <div className={styles.pretitle}>Бесплатно</div>
                <div className={styles.title}>5&nbsp;тренировок<br/> для&nbsp;УПРУГИХ<br/> ЯГОДИЦ</div>
                <div className={styles.text}>Методика от&nbsp;Кати&nbsp;Усмановой<br/> с&nbsp;эффективным результатом</div>
                <div className={styles.block}>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/fitmission/onepage/popa1.png" alt="" />
                        </div>
                        <div className={styles.item_text}>
                            <b>Старт</b> сразу<br/> после подписки
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/fitmission/onepage/popa2.png" alt="" />
                        </div>
                        <div className={styles.item_text}> 
                            <b>Проверенные упражнения</b><br/> для&nbsp;быстрого эффекта<br/> в&nbsp;домашних условиях
                        </div>
                    </div>
                </div>
                <div className={styles.btns}>
                    <div className={styles.btns_text}>Для&nbsp;тех, кто готов<br/> создать ягодицы<br/> с&nbsp;эффектом push-up</div>
                    <a className={styles.btn} href="https://fit.usmanovalove.ru/md/popahome_tg" target='_blank'>получить бесплатно</a>
                </div>
            </div>
        </div>
    )
}

export default Popa;