import Countdown from 'react-countdown';
import styles from './promo.module.css';

const Promo = ({scroll, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_block}>
                    <div className={styles.promo_block_left}>
                        {/* <div className={styles.promo_pretitle}>Специальное предложение только<br/> для подписчиков Ольги Гогаладзе</div> */}
                        <div className={styles.promo_title}>Полюбите тренировки и<br/> создайте стройное тело 
                        {/* <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/87.png" alt="" /></div> */}
                        </div>
                        <div className={styles.promo_subtitle}>Заберите универсальный комплект из&nbsp;5&nbsp;фитнес-<br/>программ от&nbsp;Кати Усмановой и&nbsp;приведите себя<br/> в&nbsp;форму уже этим летом</div>
                        {/* <div className={styles.promo_subtitle2}>
                            <span>87%</span>
                            <p>Для первых <span>100</span> купивших действует<br/> наибольшая выгода. Далее цена повысится.</p>
                        </div>  */}
                        <div className={styles.mob_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/promo_2.jpg" alt="" />
                            {/* <div className={styles.promo_remains}>
                                <div className={styles.promo_remains_2}>{window.lps_settings.other.count}</div>
                                <div className={styles.promo_remains_1}>мест осталось<br/> по&nbsp;суперцене:</div>
                            </div> */}
                        </div>
                        <div className={styles.btns}>
                            <div className={styles.promo_btn} onClick={scroll}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                                <p>Забрать комплект программ</p>
                            </div>
                            <div className={styles.btns_perc}>-87%</div>
                        </div>
                        {/* <div className={styles.promo_countdown}>
                            <p>Специальное предложение<br/> сгорит через:</p>
                            <div className={styles.promo_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div> */}
                        {/* <div className={styles.promo_remains}>
                            <div className={styles.promo_remains_1}>Осталось мест<br/> по&nbsp;суперцене:</div>
                            <div className={styles.promo_remains_2}>{window.lps_settings.other.count_og}</div>
                        </div> */}
                    </div>
                    <div className={styles.promo_block_right}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/promo_2.jpg" alt="" />
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Promo;