import styles from './marafons.module.css';

const Marafons = () => {
    return (
        <div className={('scrollhere ') + styles.marafons}>
            <div className="container">
                <div className={styles.marafons_title}>ОНЛАЙН-МАРАФОНЫ</div>
                <div className={styles.marafons_subtitle}>3 недели тренировок по&nbsp;20-30&nbsp;минут для&nbsp;быстрого результата.</div>
                <div className={styles.marafons_block}>
                    <div className={styles.marafons_item}>
                        <div className={styles.marafons_item__img}>
                            <a href='/l/marafonstr1' target="_blank">
                                <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/marafons_1.jpg" alt="" />
                            </a>
                            <p>Марафон стройной<br/> фигуры 1.0 <span>ХИТ</span></p>
                        </div>
                        <div className={styles.marafons_item__info}>
                            <div className={styles.marafons_item__start}>СТАРТ {window.lps_settings.dates.mainlands}<br/> <span>5&nbsp;000 &#8381;</span></div>
                            <div className={styles.marafons_item__level}>УРОВЕНЬ СЛОЖНОСТИ: ЛЕГКИЙ</div>
                            <ul>
                                <li><strong>Длительность:</strong> 21&nbsp;день</li>
                                <li><strong>Инвентарь:</strong> коврик</li>
                                <li><strong>Где заниматься:</strong> дома</li>
                                <li><strong>График тренировок:</strong> ежедневно</li>
                            </ul>
                            <p>Марафон для&nbsp;новичков и&nbsp;тех, кто уже&nbsp;обладает некоторым опытом. <strong>Программа поможет быстро похудеть</strong> и&nbsp;сформировать привычку правильно питаться и&nbsp;регулярно заниматься фитнесом.</p>
                            <a href='/l/marafonstr1' target="_blank" className={styles.marafons_btn}>Подробнее</a>
                        </div>
                    </div>
                    <div className={styles.marafons_item}>
                        <div className={styles.marafons_item__img}>
                            <a href='/l/marafonabs' target="_blank">
                                <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/marafons_2.jpg" alt="" />
                            </a>
                            <p>Марафон плоского<br/> живота</p>
                        </div>
                        <div className={styles.marafons_item__info}>
                            <div className={styles.marafons_item__start}>СТАРТ {window.lps_settings.dates.mainlands}<br/> <span>5&nbsp;000 &#8381;</span></div>
                            <div className={styles.marafons_item__level}>УРОВЕНЬ СЛОЖНОСТИ: ЛЕГКИЙ</div>
                            <ul>
                                <li><strong>Длительность:</strong> 21&nbsp;день</li>
                                <li><strong>Инвентарь:</strong> коврик</li>
                                <li><strong>Где заниматься:</strong> дома или в зале</li>
                                <li><strong>График тренировок:</strong> ежедневно</li>
                            </ul>
                            <p>Марафон направлен на&nbsp;проработку мышц пресса <strong>для создания красивого рельефа на&nbsp;животике</strong>, в&nbsp;уроки включены рекомендации по&nbsp;питанию по&nbsp;методу БУЧ.</p>
                            <a href='/l/marafonabs' target="_blank" className={styles.marafons_btn}>Подробнее</a>
                        </div>
                    </div>
                    <div className={styles.marafons_item}>
                        <div className={styles.marafons_item__img}>
                            <a href='/l/marafonpopa1' target="_blank">
                                <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/marafons_3.jpg" alt="" />
                            </a>
                            <p>Марафон красивой<br/> и&nbsp;упругой попы 1.0 <span>ХИТ</span></p>
                        </div>
                        <div className={styles.marafons_item__info}>
                            <div className={styles.marafons_item__start}>СТАРТ {window.lps_settings.dates.mainlands}<br/> <span>5&nbsp;000 &#8381;</span></div>
                            <div className={styles.marafons_item__level}>УРОВЕНЬ СЛОЖНОСТИ: СРЕДНИЙ</div>
                            <ul>
                                <li><strong>Длительность:</strong> 21&nbsp;день</li>
                                <li><strong>Инвентарь:</strong> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по&nbsp;1,5-3&nbsp;кг</li>
                                <li><strong>Где заниматься:</strong> дома или&nbsp;в&nbsp;зале</li>
                                <li><strong>График тренировок:</strong> ежедневно</li>
                            </ul>
                            <p>Курс идеально подойдёт новичкам и&nbsp;тем, кто делал длительный перерыв в&nbsp;тренировках. <strong>Вы сможете сделать ягодицы округлыми и&nbsp;упругими.</strong></p>
                            <a href='/l/marafonpopa1' target="_blank" className={styles.marafons_btn}>Подробнее</a>
                        </div>
                    </div>
                    <div className={styles.marafons_item}>
                        <div className={styles.marafons_item__img}>
                            <a href='/l/marafonpopa2' target="_blank">
                                <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/courser_1.jpg" alt="" />
                            </a>
                            <p>Марафон красивой<br/> и&nbsp;упругой попы 2.0</p>
                        </div>
                        <div className={styles.marafons_item__info}>
                            <div className={styles.marafons_item__start}>СТАРТ {window.lps_settings.dates.mainlands}<br/> <span>7&nbsp;900 &#8381;</span></div>
                            <div className={styles.marafons_item__level}>УРОВЕНЬ СЛОЖНОСТИ: СРЕДНИЙ</div>
                            <ul>
                                <li><strong>Длительность:</strong> 1&nbsp;месяц</li>
                                <li><strong>Инвентарь:</strong> коврик, набор резинок от&nbsp;минимального сопротивления до&nbsp;максимального, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по&nbsp;1,5-3&nbsp;кг</li>
                                <li><strong>Где заниматься:</strong> дома или&nbsp;в&nbsp;зале</li>
                                <li><strong>График тренировок:</strong> 4 раза в&nbsp;неделю</li>
                            </ul>
                            <p>Марафон для желающих <strong>качественно проработать ягодицы</strong> и&nbsp;заметно улучшить их&nbsp;форму. В&nbsp;уроки включены рекомендации по&nbsp;питанию с&nbsp;примерами.</p>
                            <a href='/l/marafonpopa2' target="_blank" className={styles.marafons_btn}>Подробнее</a>
                        </div>
                    </div>
                    <div className={styles.marafons_item}>
                        <div className={styles.marafons_item__img}>
                            <a href='/l/removefat' target="_blank">
                                <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/marafons_4.jpg" alt="" />
                            </a>
                            <p>Марафон убираем<br/> лишний жир за&nbsp;30&nbsp;дней <span>ХИТ</span></p>
                        </div>
                        <div className={styles.marafons_item__info}>
                            <div className={styles.marafons_item__start}>СТАРТ {window.lps_settings.dates.mainlands}<br/> <span>7&nbsp;900 &#8381;</span></div>
                            <div className={styles.marafons_item__level}>УРОВЕНЬ СЛОЖНОСТИ: СРЕДНИЙ</div>
                            <ul>
                                <li><strong>Длительность:</strong> 1&nbsp;месяц</li>
                                <li><strong>Инвентарь:</strong> коврик, набор резинок от&nbsp;минимального сопротивления до&nbsp;максимального, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по&nbsp;1,5-3&nbsp;кг</li>
                                <li><strong>Где заниматься:</strong> дома или&nbsp;в&nbsp;зале</li>
                                <li><strong>График тренировок:</strong> через день</li>
                            </ul>
                            <p>Марафон подойдёт тем, кто хочет <strong>быстро сбросить лишний вес, сделать тело более подтянутым</strong> и&nbsp;рельефным. В&nbsp;программе также — рекомендации по&nbsp;питанию и&nbsp;уроки самомассажа.</p>
                            <a href='/l/removefat' target="_blank" className={styles.marafons_btn}>Подробнее</a>
                        </div>
                    </div>
                    <div className={styles.marafons_item}>
                        <div className={styles.marafons_item__img}>
                            <a href='/l/anticellulite' target="_blank">
                                <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/marafons_5.jpg" alt="" />
                            </a>
                            <p>Антицеллюлитный<br/> марафон <span>ХИТ</span></p>
                        </div>
                        <div className={styles.marafons_item__info}>
                            <div className={styles.marafons_item__start}>СТАРТ {window.lps_settings.dates.mainlands}<br/> <span>7&nbsp;500 &#8381;</span></div>
                            <div className={styles.marafons_item__level}>УРОВЕНЬ СЛОЖНОСТИ: ЛЕГКИЙ</div>
                            <ul>
                                <li><strong>Длительность:</strong> 30&nbsp;дней</li>
                                <li><strong>Инвентарь:</strong> коврик, набор резинок от минимального сопротивления до&nbsp;максимального, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по&nbsp;1,5-3&nbsp;кг. Для массажа потребуются вакуумная банка, скребок гуаша или&nbsp;обычная столовая ложка</li>
                                <li><strong>Где заниматься:</strong> дома</li>
                                <li><strong>График тренировок:</strong> через день</li>
                            </ul>
                            <p>Марафон для тех, кто хочет <strong>избавиться от&nbsp;целлюлита и&nbsp;сделать фигуру более&nbsp;подтянутой.</strong></p>
                            <a href='/l/anticellulite' target="_blank" className={styles.marafons_btn}>Подробнее</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marafons;