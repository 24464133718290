import Countdown from "react-countdown";
import styles from "./promo.module.css";
import { useState } from "react";

const Promo = ({ date, date2, scroll, renderer }) => {
  // const [date, setDate] = useState(Date.now() + 1200000)
  const [play, setPlay] = useState(false);
  const url = play ? "?autoplay=1" : "";

  return (
    <div className={styles.promo}>
      <div className="container">
        {/* <div className={styles.header}>
                    <div className={styles.header_logo}><img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/logo.svg" alt="" /></div>
                    <div className={styles.header_link} onClick={scroll}>Записаться на&nbsp;второй поток</div>
                </div> */}
        <div className={styles.main}>
          <div className={styles.main_left}>
            <div className={styles.text}>
              <div className={styles.t1}>
                <p>Запишись на&nbsp;2&nbsp;поток </p>
                <p>Метода Усмановой</p>
                <p>с&nbsp;выгодой&nbsp;40%</p>
              </div>
              <div className={styles.t2}>
                <b>Создай идеальную фигуру за&nbsp;28&nbsp;дней.</b>
                <br /> Работаем над телом комплексно: тренировки,
                <br /> питание, самомассаж и&nbsp;мотивация
              </div>
              {/* <div className={styles.prices}>
                                <p>5&nbsp;990&nbsp;₽</p>
                                <p>4&nbsp;990&nbsp;₽</p>
                            </div> */}
              <div className={styles.remain_info}>
                <div>-40%</div>
                <div>действует только до&nbsp;23:59</div>
              </div>
              <div className={styles.btns}>
                <div className={styles.btn} onClick={scroll}>
                  <img
                    src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg"
                    alt=""
                  />
                  <p>Записаться</p>
                </div>
                {/* <div className={styles.remain_count}>
                  осталось мест: <span>{window.lps_settings.other.count}</span>
                </div> */}
                <div className={styles.countdown}>
                            <p>Предложение<br/> сгорит через:</p>
                            <div className={styles.countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
              </div>
            </div>
            <div className={styles.img}>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/um2/promo2.png"
                alt=""
              />
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/um2/promo_mob3.png"
                alt=""
              />
              {/* <div className={styles.prices}>
                                <p>4&nbsp;990&nbsp;₽</p>
                                <p>5&nbsp;990&nbsp;₽</p>
                            </div> */}
              <div className={styles.remain_info}>
                <div>-40%</div>
                <div>действует<br/> только до&nbsp;23:59</div>
              </div>
            </div>
          </div>
          <div className={styles.main_right}>
            {/* <div className={styles.countdown}>
                            <p>Предложение<br/> сгорит через:</p>
                            <div className={styles.countdown_timer}>
                                <Countdown date={Date.now() + date2} renderer={renderer} />
                            </div>
                        </div> */}
            {/* <div className={styles.right_text}>Наибольшая скидка только до&nbsp;23:59. <b>Такой цены больше не&nbsp;будет!</b></div> */}
            <div className={styles.btn} onClick={scroll}>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg"
                alt=""
              />
              <p>
                Записаться
                <br /> сейчас
              </p>
            </div>
            {/* <div className={styles.remain_count}>
              осталось мест: <span>{window.lps_settings.other.count}</span>
            </div> */}
            <div className={styles.countdown}>
                            <p>Предложение<br/> сгорит через:</p>
                            <div className={styles.countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
          </div>
        </div>

        <div className={styles.dates}>
          <div className={styles.dates_item}>
            <div className={styles.dates_item_img}>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/mu/icon1.png"
                alt=""
              />
            </div>
            <div className={styles.dates_item_text}>
              <p>Дата старта:</p>
              <p>17 сентября</p>
            </div>
          </div>
          <div className={styles.dates_item}>
            <div className={styles.dates_item_img}>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/mu/clock2.png"
                alt=""
              />
            </div>
            <div className={styles.dates_item_text}>
              <p>Длительность программы:</p>
              <p>28 дней</p>
            </div>
          </div>
        </div>

        {/* <div className={styles.tags}>
                    <div className={styles.tags_top}>
                        <div className={styles.tags_top_title}>Программа точно<br/> подойдёт тебе, если:</div>
                        <div className={styles.tags_top_imgs}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/um2/tag1.png" alt="" />
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/um2/tag2.png" alt="" />
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/um2/tag3.png" alt="" />
                        </div>
                    </div>
                    <div className={styles.tags_list}>
                        <p>Не&nbsp;получается сбросить вес</p>
                        <p className={styles.tags_list_pink}>Не&nbsp;хватает энергии и&nbsp;сил</p>
                        <p className={styles.tags_list_pink}>Кожа лица теряет упругость</p>
                        <p>Тянет на&nbsp;вредную еду</p>
                        <p>Нет мотивации заниматься спортом</p>
                        <p className={styles.tags_list_pink}>Не&nbsp;видишь результата от&nbsp;тренировок</p>
                        <p className={styles.tags_list_pink}>Хочешь выглядеть моложе</p>
                        <p>Болит шея, спина, колени</p>
                    </div>
                </div> */}

        <div className={styles.video}>
                    <div className={styles.video_text}>
                        <div className={styles.video_text_t1}>Смотри видео от&nbsp;Кати</div>
                        <div className={styles.video_text_t2}>Узнай, что тебя ждёт<br/> на 2&nbsp;потоке Методе Усмановой, и&nbsp;какие бонусы ждут участников 2&nbsp;потока</div>
                        <div className={styles.btn} onClick={scroll}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                            <p>Хочу на&nbsp;2&nbsp;поток</p>
                        </div>
                    </div>
                    <div className={styles.video_block}>
                        <div className={'metod2_preview' + (play ? ' hide' : '')} onClick={() => setPlay(true)}></div>
                        <iframe src={'https://kinescope.io/embed/awn9KS29QcLopQnoBPG86y'+url} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowFullScreen></iframe>
                    </div>
                    <div className={styles.btn} onClick={scroll}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                        <p>Хочу на&nbsp;2&nbsp;поток</p>
                    </div>
                </div>

        {/* <div className={styles.video_text_pt1}>
          Успей пройти бесплатную тренировку
          <br /> по&nbsp;Методу Усмановой
        </div>
        <div className={styles.video_text_pt2}>
          Недавно Катя провела для всех <b>40-минутную кардио-тренировку</b>{" "}
          из&nbsp;своей программы,
          <br /> которая включит все мышцы вашего тела, поможет сжечь лишний жир
          и&nbsp;сделать тело стройнее.
        </div>
        <div className={styles.video}>
          <div className={styles.video_text}>
            <div className={styles.video_text_t2}>
              Вы&nbsp;просили и&nbsp;мы&nbsp;выложили запись!
              <br /> <span>но&nbsp;она доступна только 24&nbsp;часа</span>
            </div>
            <ul>
              <li>Все в&nbsp;домашних условиях</li>
              <li>Без какого-либо оборудования</li>
              <li>Для любого уровня подготовки</li>
              <li>Детально объясняется вся техника</li>
              <li>Бонусом получишь заряд мотивации и&nbsp;энергии</li>
            </ul>
          </div>
          <div className={styles.video_block}>
            <div
              className={"metod2_preview" + (play ? " hide" : "")}
              onClick={() => setPlay(true)}
            ></div>
            <iframe
              src={"https://kinescope.io/embed/m8Y69WpHswaU4btd8EGYrb" + url}
              width="100%"
              height="100%"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className={styles.video_text_pt3}>
          <div className={styles.countdown}>
            <p>Тренировка пропадёт через:</p>
            <div className={styles.countdown_timer}>
              <Countdown date={Date.now() + date2} renderer={renderer} />
            </div>
          </div>
          <div className={styles.btn} onClick={scroll}>
            <img
              src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg"
              alt=""
            />
            <p>Записаться на&nbsp;Метод</p>
          </div>
        </div> */}

        <div className={styles.info}>
          <div className={styles.info_t1}>
            Мечтаешь получать удовольствие от&nbsp;своего отражения
            в&nbsp;зеркале, иметь красивую фигуру, выглядеть молодо
            и&nbsp;притягивать внимание мужчин?
          </div>
          <div className={styles.info_t2}>
            <p>
              Однако забот становится всё больше: дети, работа, домашний быт.{" "}
              <b>Времени на&nbsp;себя практически не&nbsp;остаётся,</b>{" "}
              а&nbsp;с&nbsp;возрастом ещё и&nbsp;начинает накапливаться лишний
              жир, кожа становится более дряблой, появляются морщины, дискомфорт
              в&nbsp;спине, ухудшается осанка, а&nbsp;энергии и&nbsp;сил
              остаётся всё меньше.
            </p>
            <p>
              <b>
                Тебе перестают нравиться свои фото и&nbsp;отражение
                в&nbsp;зеркале.
              </b>{" "}
              Любимые вещи больше не&nbsp;налезают, и&nbsp;ты&nbsp;чувствуешь,
              что теряешь привлекательность и&nbsp;начинаешь стесняться своего
              тела.
            </p>
          </div>
          <div className={styles.info_t3}>
            <div>Знакомо?</div>
            <p>
              Более половины девушек недовольны своей фигурой и&nbsp;внешним
              видом, но&nbsp;лишь малая часть готова с&nbsp;этим что‑то делать.
              И&nbsp;если ты&nbsp;сейчас читаешь этот текст, то&nbsp;ты&nbsp;уже
              огромная молодец. Значит, ты&nbsp;неравнодушна к&nbsp;себе
              и&nbsp;хочешь наконец изменить своё тело, увидеть стройную
              красотку в&nbsp;зеркале, надеть изящные наряды
              и&nbsp;почувствовать себя молодой, привлекательной
              и&nbsp;энергичной.
            </p>
          </div>
          <div className={styles.info_t4}>
            <img
              src="https://gymteam.kinescopecdn.net/img/l/2024/um2/info_2.png"
              alt=""
            />
            <p>
              <b>
                Для этого мы&nbsp;создали программу, где тебе не&nbsp;надо
                сидеть на&nbsp;экстремальных диетах,
              </b>{" "}
              питаясь 2&nbsp;раза в&nbsp;день куриной грудкой с&nbsp;овощами.
              Не&nbsp;надо каждый день упахиваться кардио тренировками, истязая
              себя и&nbsp;вредя здоровью сомнительными упражнениями.
              Мы&nbsp;создали программу, которая плавно адаптирует тебя
              к&nbsp;здоровому образу жизни.
            </p>
          </div>
          <div className={styles.info_t5}>
            С&nbsp;правильными тренировками и&nbsp;питанием ты&nbsp;влюбишься
            в&nbsp;такой образ жизни, и&nbsp;не&nbsp;просто достигнешь
            и&nbsp;сохранишь желанный результат, но&nbsp;и&nbsp;продолжишь
            улучшать его после прохождения программы.{" "}
            <b>
              Не&nbsp;важен твой возраст, не&nbsp;важен твой опыт
              в&nbsp;тренировках, важно лишь твоё желание.
            </b>
          </div>
        </div>

        <div className={styles.more}>
          <div className={styles.more_img}>
            <img
              src="https://gymteam.kinescopecdn.net/img/l/2024/mu/more.png"
              alt=""
            />
          </div>
          <div className={styles.more_text}>
            <p>
              <b>Метод Усмановой&nbsp;&mdash;</b> это комплексная программа
              тренировок и&nbsp;питания, направленная на&nbsp;создание красивого
              и&nbsp;стройного тела с&nbsp;заботой о&nbsp;его здоровье
              в&nbsp;любом возрасте.
            </p>
            <p>Почувствуй себя уверенной, красивой и&nbsp;желанной.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promo;
