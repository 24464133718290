import React, { useEffect } from "react";

import "./style.css";
import {
  DocumentDescription,
  DocumentTitle,
} from "../../components/DocumentMeta";
import Promo from "./components/Promo";
import Students from "./components/Students";
import Start from "./components/Start";
import Faq from "./components/Faq";
import Preims from "./components/Preims";
import Blocks from "./components/Blocks";
import Author from "./components/Author";
import Gifts from "./components/Gifts";
import Reviews from "./components/Reviews";
const Platform = React.lazy(() => import("../../components/platform/Platform"));
const Footer = React.lazy(() => import("../../components/Footer"));

export const L2024_07_Um2 = () => {
  useEffect(() => {
    document.body.id = "reset";
    document.body.parentElement.id = "html_reset";
  }, []);

  const scroll = () => {
    const section = document.querySelector(".scrollhere");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const scroll2 = () => {
    const section = document.querySelector(".scrollhere2");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  let nextMidnight = new Date();
  // let nextMidnight = new Date(2024,8,7,20,0,0);
  nextMidnight.setHours(24, 0, 0, 0);
  const now = new Date();
  const date = nextMidnight.getTime() - now.getTime();

  // let nextMidnight2 = new Date();
  let nextMidnight2 = new Date(2024, 8, 16, 15, 0, 0);
  // nextMidnight2.setHours(24, 0, 0, 0);
  const now2 = new Date();
  const date2 = nextMidnight2.getTime() - now2.getTime();

  const renderer = ({ hours, minutes, seconds, completed }) => {
    let hourzero = "";
    let minuteszero = "";
    let secondszero = "";
    if (hours < 10) {
      hourzero = "0";
    }
    if (minutes < 10) {
      minuteszero = "0";
    }
    if (seconds < 10) {
      secondszero = "0";
    }
    return (
      <div>
        <div>
          {hourzero}
          {hours} <span>:</span>
        </div>
        <div>
          {minuteszero}
          {minutes} <span>:</span>
        </div>
        <div>
          {secondszero}
          {seconds}
        </div>
      </div>
    );
  };

  const renderer2 = ({ days, hours, minutes, seconds, completed }) => {
    let hourzero = "";
    let minuteszero = "";
    let secondszero = "";
    if (hours < 10) {
      hourzero = "0";
    }
    if (minutes < 10) {
      minuteszero = "0";
    }
    if (seconds < 10) {
      secondszero = "0";
    }
    return (
      <div>
        {days > 0 && (
          <div>
            0{days} <span>дней</span>
          </div>
        )}
        <div>
          {hourzero}
          {hours} <span>часов</span>
        </div>
        <div>
          {minuteszero}
          {minutes} <span>минут</span>
        </div>
        <div>
          {secondszero}
          {seconds} <span>секунд</span>
        </div>
      </div>
    );
  };

  const renderer3 = ({ hours, minutes, seconds, completed }) => {
    let hourzero = "";
    let minuteszero = "";
    let secondszero = "";
    if (hours < 10) {
      hourzero = "0";
    }
    if (minutes < 10) {
      minuteszero = "0";
    }
    if (seconds < 10) {
      secondszero = "0";
    }
    return (
      <div>
        {/* <div>{hourzero}{hours} <span>:</span></div> */}
        <div>
          {minuteszero}
          {minutes} <span>:</span>
        </div>
        <div>
          {secondszero}
          {seconds}
        </div>
      </div>
    );
  };

  return (
    <>
      <DocumentTitle value="-40% на 2 поток «Метода Усмановой» и розыгрыш iPhone 15 Pro" />
      <DocumentDescription value="Выгода 40% на комплексную программу по работе с телом «Метод Усмановой». Чат с куратором, конкурсный поток и розыгрыш iPhone 15 Pro" />
      <div className="um2main">
        <Promo date={date} date2={date2} scroll={scroll} renderer={renderer2} />
        <Preims scroll={scroll} date={date} renderer={renderer2} />
        <Blocks scroll={scroll} />
        <Students />
        <Gifts date={date} scroll={scroll} renderer={renderer3} />
        <Author />
        <Reviews />
        <Start date2={date} renderer={renderer3} scroll={scroll2} renderer2={renderer} />
        {/* <Platform isColor={'#F6FAFF'} isRadius={32} /> */}
        <Faq scroll={scroll} />
        <Footer version={7} isColor={"#fff"} />
      </div>
    </>
  );
};
