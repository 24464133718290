import { useState } from "react";

import './styles.css';
import styles from './prizes.module.css';
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import Footer from "../../components/Footer";

const prizes = [
    {
        id: '3df84538-b177-4b59-9e7e-53ac4cbab965',
        name: <>Apple Watch SE&nbsp;2022</>,
        description: <><p>Поздравляем! Вы выиграли <b>Apple Watch SE 2022</b>! Это мощное устройство для здорового образа жизни. Когда вы надеваете эти часы, они становятся настоящим партнёром по&nbsp;фитнесу, который учитывает каждое ваше движение и&nbsp;сообщает полезные данные о&nbsp;вашем здоровье.</p></>
    },
    {
        id: '5658a458-1bef-4f45-a4e9-9253d060ebf2',
        name: <>Apple iPhone 15&nbsp;Pro 256&nbsp;ГБ</>,
        description: <><p>Поздравляем! Вы выиграли <b>Apple iPhone 15&nbsp;Pro</b>. Мечта всех девушек&nbsp;— и&nbsp;вот теперь она ваша! Ведь это не просто смартфон&nbsp;— это гаджет, который спроектирован, чтобы впечатлять! А&nbsp;ещё это ваша личная фотостудия: независимо от&nbsp;того, снимаете ли вы пейзажи, портреты или кулинарные шедевры, эти камеры превратят каждую фотографию в&nbsp;произведение искусства. Арт-фотографии, селфи, семейные снимки&nbsp;— каждый кадр будет настоящим произведением искусства. Будьте в&nbsp;тренде. </p></>
    },
    {
        id: 'fd74fbb6-8bbf-4723-a0fe-8f664258f117',
        name: 'Наушники Apple AirPods Max',
        description: <><p>Поздравляем! Вы выиграли <b>наушники Apple AirPods Max</b>. Стильный аксессуар с&nbsp;нереальным качеством звука. Надевайте на&nbsp;тренировку, в&nbsp;дорогу или&nbsp;медитируйте с&nbsp;полным погружением.</p></>
    }
]

export const L2023_11_Chpbox_kate = () => {
    const [modalActive, setModalActive] = useState(false)
    const [activePrize, setActivePrize] = useState({})
    const [isSelected, setIsSelected] = useState(false)

    function randomNumber(min, max) { 
        return Math.round(Math.random() * (max - min) + min);
    }
    
    function givePrize() {
        setIsSelected(true)
                
        const randomPriceIdx = randomNumber(0, prizes.length);
        const prize = prizes[randomPriceIdx];
                
        setModalActive(true)
        setActivePrize(prize)
      
        return prize;
    }

    function Boxes() {
        return (
            <>
                {!modalActive ?
                                <>
                                    <div className={styles.title1}>ВАС ЖДЁТ ГАРАНТИРОВАННЫЙ&nbsp;ПРИЗ</div>
                                    <div className={styles.title2}>ПРИЗОВЫЕ<br/> БОКСЫ</div>
                                    <div className={`${styles.prizes_block} ${isSelected && styles.prizes_block__active}`}>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/box.png" alt="" />
                                        </div>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/box.png" alt="" />
                                        </div>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/box.png" alt="" />
                                        </div>
                                    </div>
                                    <div className={styles.title3}>Прямо сейчас открывайте один из&nbsp;призовых<br/> боксов и&nbsp;получайте свой подарок!</div>
                                    </>
                                    :
                                    <>  
                                    <div className={styles.win}>
                                        <div className={styles.win_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/box_open.png" alt="" /></div>
                                        <div className={styles.win_name}>
                                            {activePrize.name}
                                        </div>
                                        <div className={styles.win_desc}>
                                            {activePrize.description}
                                        </div>
                                        <div className={styles.win_more}>Подробная информация о&nbsp;получении подарка у&nbsp;вас&nbsp;на&nbsp;почте.</div>
                                    </div>
                                    </>
                            }
            </>
        )
    }

    function LoginForm() {
        return (
          <>

            <Boxes/>
                
        {!modalActive && 
        <div className={styles.ucan}>
            <div className={`${styles.promo_img} ${styles.promo_img_1}`}>
                <div></div>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_1.png" alt="" />
                <p>Apple Watch SE 2022</p>
            </div>
            <div className={`${styles.promo_img} ${styles.promo_img_2}`}>
                <div></div>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_2.png" alt="" />
                <p>Фен-стайлер Dyson</p>
            </div>
            <div className={`${styles.promo_img} ${styles.promo_img_3}`}>
                <div></div>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_3.png" alt="" />
                <p>Электронные весы</p>
            </div>
            <div className={`${styles.promo_img} ${styles.promo_img_4}`}>
                <div></div>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_4.png" alt="" />
                <p>iPhone 15 Pro</p>
            </div>
            <div className={`${styles.promo_img} ${styles.promo_img_5}`}>
                <div></div>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_5.png" alt="" />
                <p>Портативная<br/> колонка</p>
            </div>
            <div className={`${styles.promo_img} ${styles.promo_img_6}`}>
                <div></div>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_6.png" alt="" />
                <p>Apple AirPods Max</p>
            </div>
            <div className={`${styles.promo_img} ${styles.promo_img_7}`}>
                <div></div>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_7.png" alt="" />
                <p>Электронный гриль</p>
            </div>
            <div className={`${styles.promo_img} ${styles.promo_img_8}`}>
                <div></div>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_8.png" alt="" />
                <p>Денежные призы</p>
            </div>
        </div>
        }
          </>
        );
      }

    return (
        <>
        <DocumentTitle value='Призовые боксы'/>
        <DocumentDescription value='' />
        <div className={styles.prizes}>
            <div className={styles.prizes_main}>
               <LoginForm />
            </div>
            
        </div>
        <div className={styles.line}>
                <div className={styles.line_text}>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                    <p>ЧЁРНАЯ ПЯТНИЦА <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/marker.svg" alt="" /></p>
                </div>    
            </div>
        <Footer version={2} />
        </>
    );
};