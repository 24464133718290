import styles from './prizes.module.css';

const Already = () => {
    return (
        <div className={styles.prizes_already}>
            <div className={styles.prizes_already_title}>Вы уже забрали свой приз</div>
            <div className={styles.prizes_already_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/womdaybox/prizes.png" alt="" /></div>
            {/* <div className={styles.prizes_already_text}>На вашей почте информация о&nbsp;том, как его получить</div> */}
        </div>
    )
}

export default Already;