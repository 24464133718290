import {Swiper, SwiperSlide} from 'swiper/react'
import { Navigation} from 'swiper'
import 'swiper/css'
import "swiper/css/grid";
import "swiper/css/navigation";
import styles from './reviews.module.css';
import { useRef } from 'react';

const Reviews = () => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    
    return (
        <div className={styles.reviews}>
            <div className="container">
                <div className={styles.reviews_title}>Знаменитости тоже доверяют<br/> тренировкам Кати</div>
                
                <div className={styles.slider_block}>
                    <div className={styles.slider_nav}>
                        <div ref={navigationPrevRef} className={styles.slider_btn_prev}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/arrow_left.svg" alt="" />
                        </div>
                        <div ref={navigationNextRef} className={styles.slider_btn_next}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/arrow_right.svg" alt="" />
                        </div>
                    </div>
                
                <Swiper 
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    onSwiper={(swiper) => {
                        setTimeout(() => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current
                        swiper.params.navigation.nextEl = navigationNextRef.current
                        
                        swiper.navigation.destroy()
                        swiper.navigation.init()
                        swiper.navigation.update()
                        })
                    }}
                    slidesPerView={1}
                    spaceBetween={32}
                    autoHeight
                    loop
                    modules={[Navigation]}
                    breakpoints={{
                        768: {
                            // slidesPerView: "auto",
                            slidesPerView: 4,
                            autoHeight: false
                        },
                    }}
                    className={styles.slider}
                >
                    <SwiperSlide className={styles.slider_slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/review_1.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide className={styles.slider_slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/review_2.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide className={styles.slider_slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/review_3.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide className={styles.slider_slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/review_4.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide className={styles.slider_slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/review_5.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide className={styles.slider_slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/review_6.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide className={styles.slider_slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/review_7.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide className={styles.slider_slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/review_8.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide className={styles.slider_slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/review_9.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide className={styles.slider_slide}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/review_10.png" alt="" />
                    </SwiperSlide>
                </Swiper>
                </div>
            </div>
        </div>
    )
}

export default Reviews;