import { useEffect } from 'react';
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import Footer from '../../components/Footer';
import Promo from './components/Promo';
import { Nomination } from './components/Nomination';

const nom1 = [
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom1_7.jpg',
        name: 'Лалита М.',
        weight: '63,5 → 59',
        chest: '95 → 89',
        waist: '82 → 71',
        hips: '101 → 95',
        id: 'dc8099b6-3823-4009-bce2-e1a25042aeba',
        votes: '436'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom1_6.jpg',
        name: 'Наталья П.',
        weight: '56 → 53,5',
        chest: '90 → 88',
        waist: '72 → 64',
        hips: '96 → 90',
        id: 'cd9182f7-1779-4123-a52b-fcba59193d50',
        votes: '354'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom1_3.jpg',
        name: 'Евгения Б.',
        weight: '54.5 → 52',
        chest: '96 → 82',
        waist: '71 → 60',
        hips: '98 → 93',
        id: '02efc765-0f81-4fb5-8a97-633b4d96ae2b',
        votes: '249'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom1_8.jpg',
        name: 'Саломея Б.',
        weight: '95,5 → 85,3',
        chest: '115 → 93',
        waist: '90 → 79',
        hips: '124 → 119',
        id: 'cd1c9926-a46d-4b41-9d1c-830e30557175',
        votes: '123'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom1_4.jpg',
        name: 'Татьяна Т.',
        weight: '58 → 55',
        chest: '90 → 88',
        waist: '70 → 75',
        hips: '90 → 87',
        id: 'a72ebd50-ef84-477f-a6a7-382c99e385c4',
        votes: '66'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom1_1.jpg',
        name: 'Ульяна А.',
        weight: '51.5 → 51',
        chest: '80 → 80',
        waist: '68 → 66',
        hips: '55 → 50',
        id: '7c344e4c-1f41-49d3-9136-641f1db9d605',
        votes: '65'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom1_2.jpg',
        name: 'Оксана Г.',
        weight: '62.7 → 61,5',
        chest: '91 → 90',
        waist: '73 → 68',
        hips: '98 → 97',
        id: '13b39cc5-ac5e-494e-8db3-8a4b0b86279d',
        votes: '60'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom1_5.jpg',
        name: 'Евгения П.',
        weight: '71 → 69',
        chest: '100 → 99',
        waist: '83 → 70',
        hips: '105 → 104',
        id: '3bb2a8aa-472f-4118-a234-9eeb649227d0',
        votes: '43'
    }
]
const nom2 = [
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom2_4.JPG',
        name: 'Анна П.',
        weight: '65 → 62',
        chest: '87 → 87',
        waist: '73 → 73',
        hips: '101 → 96',
        id: 'c31f5520-9a0e-46af-987d-db436006ebb2',
        votes: '513'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom2_2.jpg',
        name: 'Татьяна С.',
        weight: '61.8 → 59.3',
        chest: '95 → 90',
        waist: '76 → 67',
        hips: '95 → 96',
        id: 'd851ba48-b519-442e-a477-2b4aff6af304',
        votes: '426'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom2_5.JPG',
        name: 'Мария Л.',
        weight: '60.75 → 58.25',
        chest: '91 → 91',
        waist: '73 → 73',
        hips: '106 → 103',
        id: '0d0042c6-53d7-46e5-8406-2bbba6364de2',
        votes: '239'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom2_3.JPG',
        name: 'Ирина Л.',
        weight: '59 → 59',
        chest: '90 → 89',
        waist: '70 → 69',
        hips: '91 → 94',
        id: 'edf138f9-a02f-4342-bff6-9afdba666817',
        votes: '151'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom2_1.jpg',
        name: 'Мария Р.',
        weight: '56 → 58,5',
        chest: '87 → 87',
        waist: '70 → 70',
        hips: '89 → 89',
        id: 'f606af86-ca58-4cf4-b2e7-cad4e712b1a6',
        votes: '48'
    }
]
const nom3 = [
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom3_6.JPG',
        name: 'Юлия Д.',
        weight: '58 → 52',
        chest: '84 → 86',
        waist: '68 → 62',
        hips: '97 → 91',
        id: '819934c2-9f17-41c5-9f58-b188c476da75',
        votes: '536'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom3_4.JPG',
        name: 'Татьяна Г.',
        weight: '57,5 → 55,7',
        chest: '88 → 85',
        waist: '73 → 70',
        hips: '98 → 95',
        id: 'b2980010-73f8-48ee-81d9-246fdf7e3a78',
        votes: '471'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom3_2.jpg',
        name: 'Анастасия Н.',
        weight: '60 → 57',
        chest: '84 → 84',
        waist: '64 → 63',
        hips: '100 → 94',
        id: '7f1f3983-faac-4718-956e-a71c32ae7aad',
        votes: '91'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom3_3.jpg',
        name: 'Ксения Ч.',
        weight: '45 → 45',
        chest: '76 → 78',
        waist: '62 → 59',
        hips: '89 → 92',
        id: '58173d4e-8e92-460e-86de-61b190bf3c97',
        votes: '71'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom3_5.JPG',
        name: 'Юлия В.',
        weight: '58.4 → 54.7',
        chest: '83 → 84',
        waist: '80 → 76',
        hips: '98 → 96',
        id: 'cb0e7147-3a60-4ec8-bf43-572d45847a5a',
        votes: '51'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom3_1.jpg',
        name: 'Елена Т.',
        weight: '57 → 54',
        chest: '86 → 84',
        waist: '74 → 71',
        hips: '98 → 94',
        id: '481eb1b7-8e75-4b9d-9e3f-22c70d1da439',
        votes: '42'
    },
]
const nom4 = [
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_16.JPG',
        name: 'Светлана Ф.',
        weight: '78,4 → 67',
        chest: '105 → 102',
        waist: '88 → 69',
        hips: '112 → 104',
        id: '70cb2489-78a1-4b0b-b8cb-2e7ecd616347',
        votes: '610'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_9.jpg',
        name: 'Дарья Ч.',
        weight: '65,20 → 59,7',
        chest: '86 → 81,5',
        waist: '73 → 60',
        hips: '103 → 95,5',
        id: '3b4f8aa5-202f-4acb-bb4e-1617a61b202f',
        votes: '566'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_13.JPG',
        name: 'Алена К.',
        weight: '59,7 → 50,2',
        chest: '98 → 88',
        waist: '78 → 63',
        hips: '99 → 89',
        id: '06fe9825-baee-4c0c-8378-f02a6669c1c2',
        votes: '134'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_4.jpg',
        name: 'Виктория С.',
        weight: '60 → 58.2',
        chest: '88 → 81',
        waist: '65 → 62',
        hips: '99 → 97',
        id: 'd6733b44-08c9-4bfa-b4b9-94b02f19d69e',
        votes: '39'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_5.jpg',
        name: 'Лидия Р.',
        weight: '58.4 → 52.8',
        chest: '89 → 84',
        waist: '75 → 69',
        hips: '98 → 92',
        id: '702740e5-d954-402c-9462-196cd0db29a4',
        votes: '39'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_3.jpg',
        name: 'Юлия М.',
        weight: '57 → 52',
        chest: '93 → 87',
        waist: '76 → 63',
        hips: '95 → 90',
        id: '80bd9bb2-3c6d-420b-ae4b-25e088223fd7',
        votes: '31'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_10.jpg',
        name: 'Евгения К.',
        weight: '58,8 → 54',
        chest: '84 → 85',
        waist: '71 → 61',
        hips: '96 → 93',
        id: '91e46066-df79-4b30-b634-41b0180b98ff',
        votes: '27'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_17.JPG',
        name: 'Наталья С.',
        weight: '65 → 59',
        chest: '100 → 97',
        waist: '74 → 67',
        hips: '102 → 96',
        id: '8a2d61a0-c724-491f-83fc-5553e0fa82c9',
        votes: '27'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_18.JPG',
        name: 'Светлана К.',
        weight: '71 → 65',
        chest: '97 → 95',
        waist: '67 → 65',
        hips: '97 → 95',
        id: '0a0adf47-3468-4587-be64-25261a724c4c',
        votes: '21'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_1.jpg',
        name: 'Дженнета Д.',
        weight: '72,9 → 66,9',
        chest: '96 → 93',
        waist: '83 → 74',
        hips: '103 → 97',
        id: '6be72325-c64b-4452-add9-0535c359d085',
        votes: '21'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_2.jpg',
        name: 'Мария З.',
        weight: '65 → 61',
        chest: '92 → 86',
        waist: '79 → 70',
        hips: '98 → 94',
        id: 'dcc7f528-bc95-4b60-8794-07c2d4aedb36',
        votes: '19'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_6.jpg',
        name: 'Лана Ц.',
        weight: '64,2 → 59,2',
        chest: '88 → 86',
        waist: '72 → 65,5',
        hips: '103 → 97',
        id: '86b6b9f7-bfa9-4f20-80bf-c648c7dee54e',
        votes: '16'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_7.jpg',
        name: 'Лилия Л.',
        weight: '66 → 62',
        chest: '92 → 91',
        waist: '70 → 66',
        hips: '95 → 96',
        id: '8d3230b9-a815-4cf8-8aa8-35f21666b444',
        votes: '12'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_8.jpg',
        name: 'Елизавета О.',
        weight: '77 → 66',
        chest: '100 → 93',
        waist: '85 → 62',
        hips: '115 → 97',
        id: 'f0995ad1-ad7e-48cc-9d2e-b6e7a523bf13',
        votes: '12'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_15.JPG',
        name: 'Алина Х.',
        weight: '76 → 75',
        chest: '94 → 94',
        waist: '79 → 75',
        hips: '110 → 105',
        id: 'c8f06b20-7a48-42ed-9cb9-fe60e9a8fc43',
        votes: '8'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_11.JPG',
        name: 'Юлия	Н.',
        weight: '62 → 55',
        chest: '93 → 82',
        waist: '78 → 65',
        hips: '98 → 92',
        id: '63d6e369-ab15-40b8-a2da-1efd22b88411',
        votes: '7'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_12.JPG',
        name: 'Анна Т.',
        weight: '72 → 68',
        chest: '98 → 96',
        waist: '85 → 74',
        hips: '105 → 101',
        id: '66f76a57-2985-4136-840f-9570daf1e6f4',
        votes: '5'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/nom4_14.JPG',
        name: 'Анна К.',
        weight: '67 → 63',
        chest: '99 → 89',
        waist: '83 → 78',
        hips: '103 → 101',
        id: '9cc614b0-4099-49ce-add9-42d134319e49',
        votes: '5'
    },
]

export const L2023_09_VelvetContestResult = () => {

    useEffect(() => {
        document.body.id = 'rasprozarka_2305'
        document.body.parentElement.id = 'htmlrasprozarka_2305'
    }, [])

    return (
        <>
        <DocumentTitle value='Мисс Сафари 2023 - голосование фитнес-конкурса "До/После" Кати Усмановой'/>
        <DocumentDescription value='Мисс Сафари 2023 - открытое голосование за финалисток конкурса "До/После" фитнес-программ Кати Усмановой' />
        <div className='rasprozarka_2305'>
            <Promo />
            
            <Nomination nomination={nom1} texts={1}/>
            <Nomination nomination={nom2} texts={2}/>
            <Nomination nomination={nom3} texts={3}/>
            <Nomination nomination={nom4} texts={4}/>
            <div style={{paddingBottom: 40, backgroundColor: '#1A1717', textAlign: 'center'}}><a href="https://usmanovateam.ru/contestrasprozarka" target='_blank' style={{color: '#fff', fontSize: 18, textDecoration: 'underline'}}>Конкурсная оферта</a></div>
            <Footer />
        </div>
        </>
    );
};