import { useCallback, useEffect, useRef, useState } from 'react';
import {Swiper, SwiperSlide} from 'swiper/react'
import {Lazy, Navigation} from 'swiper'
import 'swiper/css'
import "swiper/css/grid";
import "swiper/css/navigation";
import LightGallery from 'lightgallery/react'
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import styles from './studentres.module.css';

const Studentres = () => {
    const [count, setCount] = useState(15)
    const [mobV, setMobV] = useState(false)
    const [items, setItems] = useState([
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/1.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/2.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/3.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/4.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/5.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/6.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/7.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/8.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/9.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/10.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/11.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/12.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/13.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/14.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/15.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/16.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/17.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/18.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/19.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/20.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/21.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/22.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/23.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/24.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/25.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/26.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/27.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka2/students/28.jpg'}
    ]);

    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    const getItems = useCallback(() => {
        return items.map((item, index) => {
            if(index+1 <= count) {
            return (
                <a key={item.src} className={styles.gallery_item} data-src={item.src}>
                    <img src={item.src} alt={item.src} loading="lazy" />
                </a>
            );
            }
        });
    }, [count]);

    const showMore = () => {
        if(count > 27) {
            setCount(15)
            const section = document.querySelector( '.scrollstart' );
            section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        } else {
            setCount(count + 15)
        }
    }
      
        useEffect(() => {
          function handleResize() {
            window.innerWidth < 1200 ? setMobV(true) : setMobV(false)
          }
          handleResize();
          window.addEventListener("resize", handleResize);
          return () => window.removeEventListener("resize", handleResize);
        }, []);
    
    return (
        <div className={styles.studentres + ' scrollstart'}>
            <div className="container">
                <div className={styles.studentres_title}>РЕЗУЛЬТАТЫ НАШИХ УЧЕНИЦ</div>
                <div className={styles.studentres_subtitle}>Это живое доказательство того, как эффективно работают<br/> программы Кати. <b>Хотите также?</b></div>
                {!mobV ? 
            <>
                <LightGallery
                    speed={500}
                    plugins={[lgThumbnail, lgZoom]}
                    mode="lg-fade"
                    elementClassNames="gallery_block__perezagruzka_2302"
                >
                {getItems()}
                </LightGallery>
                <button className={styles.studentres_btn} onClick={showMore}><p>{count>27 ? 'Скрыть изображения' : 'Показать еще'}</p></button>
            </>
            : 
            <>
                <Swiper 
                    lazy={true}
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    loop
                    onSwiper={(swiper) => {
                        setTimeout(() => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current
                        swiper.params.navigation.nextEl = navigationNextRef.current
                        
                        swiper.navigation.destroy()
                        swiper.navigation.init()
                        swiper.navigation.update()
                        })
                    }}
                    slidesPerView={1}
                    modules={[Navigation, Lazy]}
                >
                    <div className={styles.slider_nav}>
                        <div ref={navigationPrevRef} className={styles.slider_btn_prev}></div>
                        <div ref={navigationNextRef} className={styles.slider_btn_next}></div>
                    </div>
                    {items.map(item => 
                    <SwiperSlide key={item.src}>
                        <img data-src={item.src} alt="" className='swiper-lazy' />
                    </SwiperSlide>
                    )}
                </Swiper>
            </>
            }
            </div>
        </div>
    )
}

export default Studentres;