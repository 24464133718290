import Countdown from 'react-countdown';
import styles from './programs.module.css';

const Programs = ({date, renderer}) => {
    return (
            <div className={styles.programs}>
            <div className="container">
                <div className={styles.programs_pretitle}>Готовы создать свою лучшую форму?</div>
                <div className={styles.programs_title}><span>Заберите 8&nbsp;спортивных</span><br/> программ Кати Усмановой</div>
                <div className={styles.programs_subtitle}>Стоимость&nbsp;— <i>4&nbsp;490&nbsp;₽</i> ВМЕСТО&nbsp;<span>53&nbsp;400&nbsp;₽</span></div>
                <div className={styles.programs_start}>
                    <div className={styles.programs_start__item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini2/icon1.png" alt="" /> Старт — сразу после оплаты
                    </div>
                    <div className={styles.programs_start__item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini2/icon2.png" alt="" /> Доступ — 1 год
                    </div>
                </div>
                <div className={styles.start_countdown}>
                    <p>Спецпредложение<br/> сгорит через:</p>
                    <div className={styles.start_countdown_timer}>
                        <Countdown date={Date.now() + date} renderer={renderer} />
                    </div>
                </div>
                <div className={styles.programs_block}>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini2/programs/program1.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info__text}>
                                <div className={styles.programs_info__tags}>
                                    <div className={styles.programs_info__tag}>Похудеть</div>
                                    <div className={styles.programs_info__tag}>Придать телу рельеф</div>
                                </div>
                                <p><strong>Длительность:</strong> 21&nbsp;день</p>
                                <p><strong>Без инвентаря</strong></p>
                                <p><strong>Где заниматься:</strong> дома</p>
                                <p><strong>График тренировок:</strong> ежедневно</p>
                                <p>Марафон для новичков и&nbsp;тех, кто уже обладает некоторым опытом. <span>Программа поможет быстро похудеть</span> и&nbsp;сформировать привычку правильно питаться и&nbsp;регулярно заниматься фитнесом.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini2/programs/program2.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info__text}>
                                <div className={styles.programs_info__tags}>
                                    <div className={styles.programs_info__tag}>Накачать ягодицы</div>
                                </div>
                                <p><strong>Длительность:</strong> 21&nbsp;день</p>
                                <p><strong>Инвентарь:</strong> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по&nbsp;1,5-3&nbsp;кг</p>
                                <p><strong>Где заниматься:</strong> дома или в зале</p>
                                <p><strong>График тренировок:</strong> ежедневно</p>
                                <p>Марафон идеально подойдёт новичкам и&nbsp;тем, кто делал длительный перерыв в&nbsp;тренировках. <span>Вы сможете сделать ягодицы округлыми и упругими.</span></p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini2/programs/program3.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info__text}>
                                <div className={styles.programs_info__tags}>
                                    <div className={styles.programs_info__tag}>Для продвинутых</div>
                                    <div className={styles.programs_info__tag}>Накачать ягодицы</div>
                                </div>
                                <p><strong>Длительность:</strong> 30&nbsp;дней</p>
                                <p><strong>Инвентарь:</strong> коврик, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по&nbsp;1,5-3&nbsp;кг</p>
                                <p><strong>Где заниматься:</strong> дома или в зале</p>
                                <p><strong>Группы:</strong> классы массонабора и&nbsp;сушки с&nbsp;учетом проблемных зон</p>
                                <p><strong>График тренировок:</strong> 5&nbsp;раз в&nbsp;неделю</p>
                                <p>Марафон для тех, кто хочет <span>заметно улучшить форму ягодиц, не&nbsp;считая калории.</span> В&nbsp;программе интенсивные тренировки на&nbsp;верх и&nbsp;низ тела.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini2/programs/program4.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info__text}>
                                <div className={styles.programs_info__tags}>
                                    <div className={styles.programs_info__tag}>Укрепить пресс</div>
                                    <div className={styles.programs_info__tag}>Избавиться от животика</div>
                                </div>
                                <p><strong>Длительность:</strong> 21&nbsp;день</p>
                                <p><strong>Без инвентаря</strong></p>
                                <p><strong>Где заниматься:</strong> дома</p>
                                <p><strong>График тренировок:</strong> ежедневно</p>
                                <p>Марафон направлен на&nbsp;проработку мышц пресса <span>для создания красивого рельефа на&nbsp;животике,</span> в&nbsp;уроки включены рекомендации по&nbsp;питанию.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini2/programs/program5.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info__text}>
                                <div className={styles.programs_info__tags}>
                                    <div className={styles.programs_info__tag}>Избавиться от дряблости</div>
                                    <div className={styles.programs_info__tag}>Похудеть</div>
                                </div>
                                <p><strong>Длительность:</strong> 30&nbsp;дней</p>
                                <p><strong>Инвентарь:</strong> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по&nbsp;1,5-3&nbsp;кг. Для&nbsp;массажа потребуются вакуумная банка, скребок гуаша или&nbsp;обычная столовая ложка</p>
                                <p><strong>Где заниматься:</strong> дома</p>
                                <p><strong>График тренировок:</strong> через день</p>
                                <p>Марафон для тех, кто хочет <span>избавиться от&nbsp;целлюлита и&nbsp;сделать фигуру более подтянутой.</span></p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini2/programs/program6.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info__text}>
                                <div className={styles.programs_info__tags}>
                                    <div className={styles.programs_info__tag}>Похудеть</div>
                                    <div className={styles.programs_info__tag}>Наладить питание</div>
                                </div>
                                <p><strong>Длительность:</strong> 45&nbsp;дней</p>
                                <p><strong>Без тренировок и инвентаря</strong></p>
                                <p>Курс позволяет научиться <span>самостоятельно создавать рацион питания,</span> сбалансированного по&nbsp;белкам, жирам углеводам. Через 1,5&nbsp;месяца вам не&nbsp;понадобится диетолог.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini2/programs/program7.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info__text}>
                                <div className={styles.programs_info__tags}>
                                    <div className={styles.programs_info__tag}>Для продвинутых</div>
                                    <div className={styles.programs_info__tag}>Накачать ягодицы</div>
                                </div>
                                <p><strong>Длительность:</strong> 45&nbsp;дней</p>
                                <p><strong>Инвентарь:</strong> коврик, штанга/бодибар от&nbsp;8&nbsp;кг, фитнес-резинки с&nbsp;сопротивлением 10–22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по&nbsp;1,5–3&nbsp;кг</p>
                                <p><strong>Где заниматься:</strong> дома или&nbsp;в&nbsp;зале</p>
                                <p><strong>Группы:</strong> классы массонабора и&nbsp;сушки с&nbsp;учётом проблемных зон</p>
                                <p><strong>График тренировок:</strong> 5&nbsp;раз&nbsp;в&nbsp;неделю</p>
                                <p>Курс для тех, <span>кто хочет заметно улучшить форму ягодиц, не&nbsp;считая калории.</span> На&nbsp;каждый день программы расписано меню из&nbsp;5&nbsp;блюд.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini2/programs/program8.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info__text}>
                                <div className={styles.programs_info__tags}>
                                    <div className={styles.programs_info__tag}>Похудеть</div>
                                    <div className={styles.programs_info__tag}>Придать телу рельеф</div>
                                </div>
                                <p><strong>Длительность:</strong> 45&nbsp;дней</p>
                                <p><strong>Инвентарь:</strong> коврик, фитнес-резинки с сопротивлением 10-22 кг, разборные гантели до 15 кг, утяжелители 1,5-3 кг</p>
                                <p><strong>Где заниматься:</strong> дома</p>
                                <p><strong>Группы:</strong> умеренный, интенсивный и силовой классы</p>
                                <p><strong>График тренировок:</strong> 4 раза в неделю</p>
                                <p>Курс для тех, <span>кто устал от неэффективных диет</span> и никак не может избавиться от жира в проблемных зонах.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Programs;