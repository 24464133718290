import { useEffect, useState } from "react";
import { LoginWidget, checkUserInGroup, addUserToGroup, getUserCountByGroup, ShowForSegment, HideForSegment, checkUserInSegment, getUser } from "@com.marathonium/web2-ui";
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";

import './styles.css';
import styles from './prizes.module.css';
import Hidden from "./hidden";
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import Already from "./already";
import Footer from "../../components/Footer";


const prizes = [
    {
        id: 'f4122a06-85de-4b36-a3c2-f7cbc792042a',
        name: <>Apple iPhone&nbsp;15&nbsp;Pro</>,
        description: <><p>Вы&nbsp;выиграли Apple iPhone 15&nbsp;Pro. Мечта всех девушек&nbsp;&mdash; и&nbsp;вот теперь она ваша! Ведь это не&nbsp;просто смартфон&nbsp;&mdash; это ваша личная фотостудия. Арт-фотографии, селфи, семейные снимки&nbsp;&mdash; каждый кадр будет настоящим произведением искусства.</p></>,
        limit: window.lps_settings.limits.prize1,
    },
    {
        id: 'dafbba1f-bb56-4197-9aeb-3ebdbe6a847b',
        name: <>Apple AirPods Max</>,
        description: <><p>Вы&nbsp;выиграли наушники Apple AirPods Max. Стильный аксессуар с&nbsp;нереальным качеством звука. Надевайте на&nbsp;тренировку, в&nbsp;дорогу или медитируйте с&nbsp;полным погружением.</p></>,
        limit: window.lps_settings.limits.prize2,
    },
    {
        id: 'cd084318-d413-4dd4-98ec-50df79fa603f',
        name: <>Apple Watch&nbsp;SE&nbsp;2023</>,
        description: <><p>Вы&nbsp;выиграли Apple Watch SE&nbsp;2023. Это мощное устройство для здорового образа жизни. Когда вы&nbsp;надеваете эти часы, они становятся настоящим партнёром по&nbsp;фитнесу, который учитывает каждое ваше движение и&nbsp;сообщает полезные данные о&nbsp;вашем здоровье.</p></>,
        limit: window.lps_settings.limits.prize3,
    },
    {
        id: 'cc3695a2-f263-4f44-a1bf-27db31f6ed0d',
        name: <>Apple AirPods&nbsp;Pro (2-го&nbsp;поколения)</>,
        description: <><p>Вы&nbsp;выиграли наушники Apple AirPods Pro нового поколения. Стильный аксессуар с&nbsp;нереальным качеством звука. Надевайте на&nbsp;тренировку, в&nbsp;дорогу или медитируйте с&nbsp;полным погружением.</p></>,
        limit: window.lps_settings.limits.prize4,
    },
    {
        id: '3db41435-47f4-4f6b-9da5-8728f52085e6',
        name: <>Электронный гриль Kitfort КТ-1636</>,
        description: <><p>Вы&nbsp;выиграли электронный гриль Kitfort КТ-1636. Считайте, что наняли профессионального шеф-повара на&nbsp;дом. Его сенсор определяет, сколько кусочков и&nbsp;какой толщины вы&nbsp;положили, и&nbsp;автоматически подбирает оптимальную температуру. Вам будет нужно только выбрать желаемый режим на&nbsp;дисплее.</p></>,
        limit: window.lps_settings.limits.prize5,
    },
    {
        id: '926b30a0-bf6b-4a89-89e6-0e857e619c12',
        name: <>Соковыжималка шнековая электрическая</>,
        description: <><p>Вы&nbsp;выиграли шнековую соковыжималку VLK Profi-3500. Это отличный помощник для перехода на&nbsp;правильное питание. Готовьте смузи и&nbsp;пюре, регулируйте сами количество мякоти и&nbsp;даже загружайте в&nbsp;неё целые овощи и&nbsp;фрукты!</p></>,
        limit: window.lps_settings.limits.prize6,
    },
    {
        id: '652ddddc-ceab-49ba-bbb0-0ce669bcc348',
        name: <>Роллер-массажёр E-Wave</>,
        description: <><p>Вы&nbsp;выиграли роллер-массажёр для лица E-Wave с&nbsp;токами и&nbsp;нагревом. Теперь уход за&nbsp;кожей и&nbsp;поддержание красоты станет для вас еще проще: избавляйтесь от&nbsp;отёков, усиливайте действие косметических средств и&nbsp;выглядите на&nbsp;100% всегда!</p></>,
        limit: window.lps_settings.limits.prize7,
    },
    {
        id: '3829d604-6d54-44bc-ac76-a079402869af',
        name: <>Денежный приз<br/> 5&nbsp;000&nbsp;р</>,
        description: <><p>Вы&nbsp;выиграли 5&nbsp;000 рублей на&nbsp;карту. Потратьте на&nbsp;что-то приятное для себя! Купите соблазнительное бельё, сходите на&nbsp;массаж или в&nbsp;салон красоты.</p></>,
        limit: window.lps_settings.limits.prize8,
    },
    {
        id: 'ac4b4ca4-106a-41fb-b39e-982e9cc3640d',
        name: <>Увлажнитель воздуха Futula Humidifier&nbsp;H2S</>,
        description: <><p>Вы&nbsp;выиграли увлажнитель воздуха Futula Humidifier H2S. Тихий, стильный, компактный и&nbsp;очень функциональный прибор идеально справляется со&nbsp;своей задачей.</p></>,
        limit: window.lps_settings.limits.prize9,
    },
    {
        id: 'a9b5eab9-90dd-4e48-bf28-eb8d865c041d',
        name: <>Умные весы Futula Smart Scale&nbsp;3</>,
        description: <><p>Вы&nbsp;выиграли умные весы Futula Smart Scale&nbsp;3. Они помогут следить за&nbsp;фигурой с&nbsp;удовольствием. Точно измеряют вес, дают информацию о&nbsp;состоянии организма, основываясь на&nbsp;10&nbsp;важных показателях. Все параметры показываются и&nbsp;сохраняются в&nbsp;специальном приложении на&nbsp;смартфон, в&nbsp;котором можно увидеть свой прогресс.</p></>,
        limit: window.lps_settings.limits.prize10,
    },
    {
        id: 'beb5530c-2783-4060-a1ba-20021353848e',
        name: <>Фитнес-коврик</>,
        description: <><p>Вы&nbsp;выиграли фитнес-коврик. Он&nbsp;поможет вам провести тренировку максимально удобно и&nbsp;комфортно. Собирайтесь на&nbsp;фитнес с&nbsp;удовольствием.</p></>,
        limit: window.lps_settings.limits.prize11,
    },
    {
        id: '34e2bcee-a3f7-4c90-95f8-718d6ef17d07',
        name: <>Ролл для МФР</>,
        description: <><p>Вы&nbsp;выиграли ролл для МФР. Это массажный ролик для фитнеса, йоги или пилатеса. Он&nbsp;поможет расслабить мышцы, снять напряжение, проработать проблемные зоны и&nbsp;добавит видимый эффект вашим тренировкам.</p></>,
        limit: window.lps_settings.limits.prize12,
    },
    {
        id: 'e55ba9b3-1043-47d6-84c0-72d3aab5f9f6',
        name: <>Набор фитнес резинок</>,
        description: <><p>Вы&nbsp;выиграли набор фитнес-резинок. Резинки для нагрузок разной интенсивности помогут разнообразить привычные тренировки и&nbsp;заниматься везде, где вам удобно. Набор настолько компактен, что не&nbsp;займет много места в&nbsp;рюкзаке или чемодане.</p></>,
        limit: window.lps_settings.limits.prize13,
    },
    {
        id: '1d3adbf2-4f6a-477c-bd6a-da8d5f333c4b',
        name: <>План питания Макиши</>,
        description: <><p>Вы&nbsp;выиграли &laquo;План питания&raquo; от&nbsp;Марины Макиши. Набор уроков, лекций и&nbsp;рецептов от&nbsp;врача-диетолога Марины Макиши. Вы&nbsp;разберетесь со&nbsp;своим питанием, научитесь относиться к&nbsp;еде осознанно. Узнаете простые правила похудения, которые помогут на&nbsp;пути к&nbsp;телу мечты.</p></>,
        limit: window.lps_settings.limits.prize14,
    },
    {
        id: 'a1d180c9-77d6-4fc5-a4b0-a48eb6f4d61f',
        name: <>Книга рецептов Кати&nbsp;Усмановой</>,
        description: <><p>Вы&nbsp;выиграли книгу рецептов Кати Усмановой. Это сборник выверенной информации с&nbsp;примерами рационов для разного диапазона калорий, подробными рецептами блюд, описанием бжу и&nbsp;калоража каждого блюда, лайфхаками по&nbsp;приготовлению еды и&nbsp;другой полезной информацией.</p></>,
        limit: window.lps_settings.limits.prize15,
    },
    {
        id: 'ee2d50f2-4b71-4d8b-b632-8806c6cc53bc',
        name: <>Бонус Детокс</>,
        description: <><p>Вы&nbsp;выиграли марафон &laquo;Детокс&raquo; с&nbsp;Катей Усмановой. Информация поможет пересмотреть свой рацион, понять, какие продукты вызывают тяжесть и&nbsp;как быстро очистить организм. Добавьте себе лёгкости и&nbsp;воздушности в&nbsp;самоощущении!</p></>,
        limit: window.lps_settings.limits.prize16,
    },
]

const prizes2 = [
    {
        id: 'ac4b4ca4-106a-41fb-b39e-982e9cc3640d',
        name: <>Увлажнитель воздуха Futula Humidifier&nbsp;H2S</>,
        description: <><p>Вы&nbsp;выиграли увлажнитель воздуха Futula Humidifier H2S. Тихий, стильный, компактный и&nbsp;очень функциональный прибор идеально справляется со&nbsp;своей задачей.</p></>,
        limit: window.lps_settings.limits.prize9,
    },
    {
        id: 'a9b5eab9-90dd-4e48-bf28-eb8d865c041d',
        name: <>Умные весы Futula Smart Scale&nbsp;3</>,
        description: <><p>Вы&nbsp;выиграли умные весы Futula Smart Scale&nbsp;3. Они помогут следить за&nbsp;фигурой с&nbsp;удовольствием. Точно измеряют вес, дают информацию о&nbsp;состоянии организма, основываясь на&nbsp;10&nbsp;важных показателях. Все параметры показываются и&nbsp;сохраняются в&nbsp;специальном приложении на&nbsp;смартфон, в&nbsp;котором можно увидеть свой прогресс.</p></>,
        limit: window.lps_settings.limits.prize10,
    },
    {
        id: 'beb5530c-2783-4060-a1ba-20021353848e',
        name: <>Фитнес-коврик</>,
        description: <><p>Вы&nbsp;выиграли фитнес-коврик. Он&nbsp;поможет вам провести тренировку максимально удобно и&nbsp;комфортно. Собирайтесь на&nbsp;фитнес с&nbsp;удовольствием.</p></>,
        limit: window.lps_settings.limits.prize11,
    },
    {
        id: '34e2bcee-a3f7-4c90-95f8-718d6ef17d07',
        name: <>Ролл для МФР</>,
        description: <><p>Вы&nbsp;выиграли ролл для МФР. Это массажный ролик для фитнеса, йоги или пилатеса. Он&nbsp;поможет расслабить мышцы, снять напряжение, проработать проблемные зоны и&nbsp;добавит видимый эффект вашим тренировкам.</p></>,
        limit: window.lps_settings.limits.prize12,
    },
    {
        id: 'e55ba9b3-1043-47d6-84c0-72d3aab5f9f6',
        name: <>Набор фитнес резинок</>,
        description: <><p>Вы&nbsp;выиграли набор фитнес-резинок. Резинки для нагрузок разной интенсивности помогут разнообразить привычные тренировки и&nbsp;заниматься везде, где вам удобно. Набор настолько компактен, что не&nbsp;займет много места в&nbsp;рюкзаке или чемодане.</p></>,
        limit: window.lps_settings.limits.prize13,
    },
    {
        id: '1d3adbf2-4f6a-477c-bd6a-da8d5f333c4b',
        name: <>План питания Макиши</>,
        description: <><p>Вы&nbsp;выиграли &laquo;План питания&raquo; от&nbsp;Марины Макиши. Набор уроков, лекций и&nbsp;рецептов от&nbsp;врача-диетолога Марины Макиши. Вы&nbsp;разберетесь со&nbsp;своим питанием, научитесь относиться к&nbsp;еде осознанно. Узнаете простые правила похудения, которые помогут на&nbsp;пути к&nbsp;телу мечты.</p></>,
        limit: window.lps_settings.limits.prize14,
    },
    {
        id: 'a1d180c9-77d6-4fc5-a4b0-a48eb6f4d61f',
        name: <>Книга рецептов Кати&nbsp;Усмановой</>,
        description: <><p>Вы&nbsp;выиграли книгу рецептов Кати Усмановой. Это сборник выверенной информации с&nbsp;примерами рационов для разного диапазона калорий, подробными рецептами блюд, описанием бжу и&nbsp;калоража каждого блюда, лайфхаками по&nbsp;приготовлению еды и&nbsp;другой полезной информацией.</p></>,
        limit: window.lps_settings.limits.prize15,
    },
    {
        id: 'ee2d50f2-4b71-4d8b-b632-8806c6cc53bc',
        name: <>Бонус Детокс</>,
        description: <><p>Вы&nbsp;выиграли марафон &laquo;Детокс&raquo; с&nbsp;Катей Усмановой. Информация поможет пересмотреть свой рацион, понять, какие продукты вызывают тяжесть и&nbsp;как быстро очистить организм. Добавьте себе лёгкости и&nbsp;воздушности в&nbsp;самоощущении!</p></>,
        limit: window.lps_settings.limits.prize16,
    },
]

export const L2024_03_Womday_box = () => {
    const [modalActive, setModalActive] = useState(false)
    const [activePrize, setActivePrize] = useState({})
    const [isSelected, setIsSelected] = useState(false)
    const [alreadyShow, setAlreadyShow] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isRusPhone, setIsRusPhone] = useState(false)

    function randomNumber(min, max) { 
        return Math.round(Math.random() * (max - min) + min);
    }

    useEffect(()=>{
        getUser().then((res) => {
            if(res.phone.substr(0,2) == '+7' && res.phone.length == 12) {
                setIsRusPhone(true)
            }
        })
    }, [])

    useEffect(()=> {
        {prizes.map((i, idx)=>{
            checkUserInGroup(i.id).then(res => {
                // console.log(res == null)
                if(res !== null) {
                    // console.log(res)
                    setAlreadyShow(true)
                }
            })
            if(idx + 1 == prizes.length) {
                setTimeout(()=>{
                    setIsLoading(false)
                }, 2000)
            }
        })}
        
    }, [])
    
    function givePrize() {
        setIsSelected(true)
        
        checkUserInSegment(window.lps_settings.segments.segment_womdaybox).then(res => {
        if (!res) {
            return false;
        }

            if(isRusPhone) {
                Promise.all(prizes.map((i,idx) => getUserCountByGroup(i.id))).then((values) => {
                    const availablePrizes = prizes.filter((i, idx) => {
                        return values[idx] < i.limit;
                    });
                    
                    if (availablePrizes.length === 0) {
                        return false;
                    }
                    
                    const randomPriceIdx = randomNumber(0, availablePrizes.length - 1);
                    const prize = availablePrizes[randomPriceIdx];
                    
                    addUserToGroup(prize.id).then((res)=>{
                        setModalActive(true)
                        setActivePrize(prize) 
                    }) 
          
                    return prize;
                  })
                //   .then((prize) => console.log(prize));
            } else {
                Promise.all(prizes2.map((i,idx) => getUserCountByGroup(i.id))).then((values) => {
                    const availablePrizes = prizes2.filter((i, idx) => {
                        return values[idx] < i.limit;
                    });
                    
                    if (availablePrizes.length === 0) {
                        return false;
                    }
                    
                    const randomPriceIdx = randomNumber(0, availablePrizes.length - 1);
                    const prize = availablePrizes[randomPriceIdx];
                    
                    addUserToGroup(prize.id).then((res)=>{
                        setModalActive(true)
                        setActivePrize(prize) 
                    }) 
          
                    return prize;
                  })
                //   .then((prize) => console.log(prize));
            }
      });
    }

    function Boxes() {
        return (
            <>
                {!modalActive ?
                                <>
                                    <div className={styles.prizes_hide_top}>
                                        <div className={styles.title2}>ВЕСЕННИЕ<br/> БОКСЫ</div>
                                        <div className={styles.title3}>Один из&nbsp;гарантированных призов<br/> ждёт вас.Скорее открывайте один<br/> из&nbsp;боксов и&nbsp;узнайте, что спрятано<br/> внутри.</div>
                                    </div>
                                    <div className={`${styles.prizes_block} ${isSelected && styles.prizes_block__active}`}>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/womdaybox/prize.png" alt="" />
                                        </div>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/womdaybox/prize.png" alt="" />
                                        </div>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/womdaybox/prize.png" alt="" />
                                        </div>
                                    </div>
                                    </>
                                    :
                                    <>
                                    <div className={styles.win}>
                                        {/* <div className={styles.win_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/womdaybox/bant.png" alt="" /></div> */}
                                        <div className={styles.win_name}>
                                            {activePrize.name}
                                        </div>
                                        <div className={styles.win_desc}>
                                            <p style={{marginBottom: 10}}><b>Поздравляем!</b></p>
                                            {activePrize.description}
                                        </div>
                                        <div className={styles.win_more}>Подробная информация о&nbsp;получении подарка у&nbsp;вас&nbsp;на&nbsp;почте.</div>
                                    </div>
                                    </>
                            }
            </>
        )
    }

    function LoginForm() {
        return (
          <>
            <Authorized>
                <HideForSegment id={window.lps_settings.segments.segment_womdaybox}>
                    <Hidden/>
                </HideForSegment>

                <ShowForSegment id={window.lps_settings.segments.segment_womdaybox}>
                
                {isLoading
                ? <div className={styles.loader_block}><div className={styles.loader}><div></div><div></div><div></div><div></div></div></div>
                : <>
                    {alreadyShow
                        ? <Already />
                        : <Boxes/>
                    }
                  </>
                }
                                
                </ShowForSegment>
            
            </Authorized>
            <Anonymous>
                <div className={styles.prizes_ghost_title}>Чтобы получить доступ к&nbsp;боксам<br/> нужно авторизоваться</div>
                <div className="velvet_prizes_login"><LoginWidget /></div>
            </Anonymous>
          </>
        );
      }

    return (
        <div className={styles.cursor}>
        <DocumentTitle value='Призовые боксы'/>
        <DocumentDescription value='' />
        <div className={styles.prizes}>
            <div className={styles.prizes_main}>
               <LoginForm />
            </div>
            
        </div>
        <Footer version={3} />
        </div>
    );
};