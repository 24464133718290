import styles from './author.module.css';

const Author = () => {
    return (
        <div className={styles.author}>
            <div className="container">
                <div className={styles.author_block}>
                    <div className={styles.author_left}>
                    </div>
                    <div className={styles.author_right}>
                        <div className={styles.author_title}>Катя Усманова</div>
                        <div className={styles.author_subtitle}>ПРИЗНАННЫЙ ЭКСПЕРТ ПО&nbsp;СОЗДАНИЮ ОТТОЧЕННЫХ ФИГУР</div>
                        <div className={styles.author_mob_model}><img src="https://gymteam.kinescopecdn.net/img/l/2023/woweffect/author_model.png" alt="" /></div>
                        <div className={styles.author_text}>Уже более 250&nbsp;000 участниц тренируются с&nbsp;Катей, ведь&nbsp;она:</div>
                        <ul>
                            <li>Создатель платформы GymTeam</li>
                            <li>Вице-чемпионка мира и&nbsp;чемпионка России по&nbsp;фитнес-бикини</li>
                            <li>Чемпионка России и&nbsp;мира по&nbsp;жиму лёжа</li>
                            <li>Автор первых в&nbsp;России масштабных марафонов стройности</li>
                            <li>Профессиональный фитнес-тренер с&nbsp;опытом более&nbsp;15&nbsp;лет</li>
                            <li>Мама 2-х детей. Всего за&nbsp;100&nbsp;дней после первых родов похудела на&nbsp;20кг и&nbsp;вернулась в&nbsp;прежнюю форму</li>
                        </ul>
                        {/* <div className={styles.author_text}>В 2016 году <span>Катя первая на&nbsp;рынке предложила сегментировать тело&nbsp;—</span> запустила курс идеальных ягодиц</div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Author;