import Countdown from "react-countdown";
import styles from "./preims.module.css";

const Preims = ({ scroll, renderer, date }) => {
  return (
    <div className={styles.preims}>
      <div className={styles.title}>
        <span>7&nbsp;элементов</span> твоей
        <br /> успешной трансформации тела
      </div>
      <div className={styles.block}>
        <div className={styles.item}>
          <div className={styles.item_img}>
            <img
              src="https://gymteam.kinescopecdn.net/img/l/2024/um2/pr_8.jpg"
              alt=""
            />
          </div>
          <div className={styles.item_pretitle}>
            Каждый день новая тренировка с&nbsp;понедельника по&nbsp;пятницу
          </div>
          <div className={styles.item_title}>
            20&nbsp;домашних тренировок в&nbsp;формате &laquo;Повторяй
            за&nbsp;Катей&raquo;
          </div>
          <div className={styles.item_text}>
            Каждый будний день тебе открывается по&nbsp;1&nbsp;тренировке
            длительностью 30&ndash;40&nbsp;минут, чтобы проработать мышцы всего
            тела. Ты&nbsp;сможешь легко влиться в&nbsp;режим и&nbsp;находить
            время на&nbsp;короткие тренировки каждый день. Тебя ждёт сочетание
            силовых тренировок с&nbsp;пилатесом, кардио, функциональными
            3D-тренировками, укреплением внутренних мышц.
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.item_img}>
            <img
              src="https://gymteam.kinescopecdn.net/img/l/2024/um2/pr_2.jpg"
              alt=""
            />
          </div>
          <div className={styles.item_pretitle}>
            Все уроки открываются сразу
          </div>
          <div className={styles.item_title}>
            5&nbsp;уроков от&nbsp;нутрициолога
          </div>
          <div className={styles.item_text}>
            Научишься сама составлять под себя рацион питания, используя удобное
            &laquo;правило тарелки&raquo;. Такая система поможет тебе избавиться
            от&nbsp;лишнего веса и&nbsp;поддерживать форму. Ты&nbsp;заметишь,
            как улучшится качество кожи и&nbsp;самочувствие. Нет голодовкам
            и&nbsp;диетам! Нет подсчёту калорий! Учимся питаться вкусно, сытно
            и&nbsp;сбалансированно.
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.item_img}>
            <img
              src="https://gymteam.kinescopecdn.net/img/l/2024/um2/pr_3.jpg"
              alt=""
            />
          </div>
          <div className={styles.item_pretitle}>
            Выдаётся сразу в&nbsp;начале программы
          </div>
          <div className={styles.item_title}>
            Готовый план питания на&nbsp;2&nbsp;недели и&nbsp;рецепты блюд
          </div>
          <div className={styles.item_text}>
            Ты&nbsp;получишь готовый план питания на&nbsp;2&nbsp;недели.
            От&nbsp;тебя требуется только закупить продукты в&nbsp;магазине
            по&nbsp;нашему списку и&nbsp;готовить по&nbsp;рецептам из&nbsp;него.
            Также предоставляем 30+&nbsp;рецептов блюд на&nbsp;любой вкус.
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.item_img}>
            <img
              src="https://gymteam.kinescopecdn.net/img/l/2024/um2/pr_4.jpg"
              alt=""
            />
          </div>
          <div className={styles.item_pretitle}>
            1&nbsp;раз во&nbsp;время программы, дату выберем совместно
            с&nbsp;участниками
          </div>
          <div className={styles.item_title}>
            Вебинар с&nbsp;ответами на&nbsp;вопросы от&nbsp;нутрициолга
          </div>
          <div className={styles.item_text}>
            Во&nbsp;время марафона проведём живой вебинар с&nbsp;нутрициологом.
            Ты&nbsp;сможешь задать свои вопросы и&nbsp;разберёшься во&nbsp;всех
            нюансах питания.
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.item_img}>
            <img
              src="https://gymteam.kinescopecdn.net/img/l/2024/um2/pr_5.jpg"
              alt=""
            />
          </div>
          <div className={styles.item_pretitle}>
            1&nbsp;комплекс на&nbsp;неделю, выполняется каждый день
          </div>
          <div className={styles.item_title}>
            4&nbsp;комплекса на&nbsp;создание здорового тела
            от&nbsp;реабилитолога
          </div>
          <div className={styles.item_text}>
            Получи 4&nbsp;комплекса упражнений, которые всего
            за&nbsp;10&ndash;15 минут в&nbsp;день укрепят твоё тело, избавят
            от&nbsp;болей и&nbsp;помогут поддерживать хорошее самочувствие
            постоянно. Ты&nbsp;забудешь про боли в&nbsp;спине, шее, коленях
            и&nbsp;других частях тела.
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.item_img}>
            <img
              src="https://gymteam.kinescopecdn.net/img/l/2024/um2/pr_6.jpg"
              alt=""
            />
          </div>
          <div className={styles.item_pretitle}>
            Открываются сразу в&nbsp;начале программы
          </div>
          <div className={styles.item_title}>
            Образовательные уроки &laquo;Как создать здоровое тело&raquo;
          </div>
          <div className={styles.item_text}>
            Откроешь для себя секреты того, как устроено и&nbsp;живет наше тело.
            Ты&nbsp;узнаешь, почему появляются боли в&nbsp;разных участках тела
            и&nbsp;поймёшь, как от&nbsp;них избавиться и&nbsp;предотвратить
            появление.
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.item_img}>
            <img
              src="https://gymteam.kinescopecdn.net/img/l/2024/um2/pr_7.jpg"
              alt=""
            />
          </div>
          <div className={styles.item_pretitle}>
            Открываются сразу в&nbsp;начале программы
          </div>
          <div className={styles.item_title}>
            6&nbsp;техник массажа на&nbsp;омоложение лица для&nbsp;30+
          </div>
          <div className={styles.item_text}>
            Специалист по&nbsp;уходу за&nbsp;лицом покажет, как улучшить
            состояние кожи и&nbsp;укрепить лицевые мышцы. Ты&nbsp;узнаешь
            о&nbsp;6&nbsp;техниках массажа лица, которые помогут разгладить
            и&nbsp;подтянуть кожу, сделать овал лица более чётким, а&nbsp;кожу
            свежей.
          </div>
        </div>
      </div>

      <div className={styles.btn} onClick={scroll}>
        <img
          src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg"
          alt=""
        />
        <p>
          Принять участие
          <br /> с&nbsp;выгодой&nbsp;40%
        </p>
      </div>
      <div className={styles.prices}>
        <p>5&nbsp;990&nbsp;₽</p>
        <p>10&nbsp;000&nbsp;₽</p>
        <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/um2/blank.png" alt="" /> <span>-40%</span></div>
      </div>

      {/* <div className={styles.remain}>
        Осталось мест: <span>{window.lps_settings.other.count}</span>
      </div> */}
      <div className={styles.countdown}>
                            <p>Предложение сгорит через:</p>
                            <div className={styles.countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
    </div>
  );
};

export default Preims;
