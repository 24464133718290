import { useState } from 'react';
import styles from './faq.module.css';

const Faq = () => {
    const faqs = [
      {question: 'Какие преимущества даёт подписка на GymTeam по сравнению с предложениями других фитнес-платформ?', answer: 'С подпиской на GymTeam вы получаете мгновенный доступ к 37 фитнес-программам. Также вам будут доступны все новинки, которые появятся на платформе в период действия вашей подписки. Мы регулярно пополняем список программ.'},
      {question: 'Cмогу ли я заниматься, если живу не в России?', answer: 'Да. Тренировки доступны в любой точке мира. Вам понадобится смартфон, планшет или компьютер с доступом в интернет. Вы также можете использовать Smart TV.'},
      {question: 'Я оплатил(а) подписку, но мне ничего не открылось. Что делать?', answer: <p>Мы предоставляем доступ по&nbsp;вашему номеру телефона. Проверьте актуальность контактных данных, указанных при заказе, и&nbsp;напишите по&nbsp;адресу <a href='mailto:support@gymteam.ru'>support@gymteam.ru</a></p>},
      {question: 'Я новичок в спорте. Подойдут ли мне тренировки?', answer: 'Да. Программы GymTeam подходят для любого уровня подготовки и условий тренировок. Все занятия проходят в формате «повторяй за мной».'},
      {question: 'Какой инвентарь нужен для занятий?', answer: 'Вам потребуется минимальный набор инвентаря, а в большинстве программ его можно полностью заменить подручными средствами: вместо блоков для йоги — толстые книги, вместо эластичной ленты — пояс от халата, вместе гантелей — бутылки с водой.'},
      // {question: 'Какую подписку лучше выбрать? На 3 или 12 месяцев?', answer: <div><p>Подписка на 3&nbsp;месяца&nbsp;— оптимальный вариант, если вы хотите попробовать наши тренировки. Этого времени хватит, чтобы познакомиться с тренерами и направлениями, оценить все&nbsp;функции приложения GymTeam. И получить результат&nbsp;— красивое и&nbsp;здоровое тело, отличное самочувствие.</p><p>На 12&nbsp;месяцев&nbsp;— максимально выгодная. Вы не&nbsp;только получаете скидку при оформлении подписки, но&nbsp;и&nbsp;фиксируете цену на&nbsp;целый год. Сможете тренироваться в удовольствие и&nbsp;не&nbsp;думать о&nbsp;стоимости подписки, дополнительных платежах.</p></div>}
    ];
    
    const [clicked, setClicked] = useState("0")

    const handleToggle = (index) => {
        if (clicked === index) {
            setClicked("0");
        } else {
            setClicked(index);
        }
    }

    return(
        <div className={styles.faq}>
          <div className="container">
            <div className={styles.faq_block}>
              <div className={styles.faq_left}>
                <div className={styles.faq_title}>ОСТАЛИСЬ<br/> ВОПРОСЫ?</div>
                <div className={styles.faq_subtitle}>Напишите свой вопрос в&nbsp;удобный для вас<br/> мессенджер и&nbsp;мы ответим в&nbsp;ближайшее<br/> время.</div>
                <div className={styles.faq_more__links}>
                  <div className={styles.faq_more__link}>
                    <a href="https://vk.com/usmanovateam" target="_blank">
                      <img src="https://gymteam.kinescopecdn.net/img/l/2023/bestfriends/vk_icon.png" alt="" />
                    </a>
                  </div>
                  <div className={styles.faq_more__link}>
                    <a href="https://t.me/GymTeamSupportBot" target="_blank">
                      <img src="https://gymteam.kinescopecdn.net/img/l/2023/bestfriends/tg_icon.png" alt="" />
                    </a>
                  </div>
                </div>
              </div>
              <div className={styles.faq_right}>
                <div className={styles.accordion}>
                  {faqs.map((faq, index) => (
                    <div key={faq.answer} className={clicked == index + 1 ? styles.active : ''}>
                      <p onClick={()=>handleToggle(index+1)} className={styles.question}>{faq.question} </p>
                      <p className={styles.answer}>{faq.answer}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

export default Faq;