import { useState } from 'react';
import styles from './programs.module.css';

const Programs = () => {
    const [activeNumber, setActiveNumber] = useState(1)

    const scroll = () => {
        const section = document.querySelector('.bestfriends_programs').offsetTop;
        window.scrollTo({top: section - 155, behavior: "smooth"});
    };

    const changeHandler = (number) => {
        setActiveNumber(number)
        window.innerWidth < 576 && scroll()
    }

    return (
        <div className={styles.programs}>
            <div className={styles.block}>
                <div className={styles.programs_title}>Найдите свою фитнес-программу</div>
                <div className={styles.programs_numbers}>
                    <div className={styles.programs_number}>
                        <div>49</div>
                        <p>программ</p>
                    </div>
                    <div className={styles.programs_number}>
                        <div>20</div>
                        <p>тренеров экстра-класса</p>
                    </div>
                    <div className={styles.programs_number}>
                        <div>1&nbsp;500</div>
                        <p>тренировок</p>
                    </div>
                </div>
                <div className={styles.programs_block}>
                    <div className={styles.programs_block__left}>
                        <div className={`${styles.programs_block__item} ${activeNumber === 1 && styles.active}`} onClick={()=>changeHandler(1)}>СБРОСИТЬ ЛИШНИЙ ВЕС</div>
                        <div className={`${styles.programs_block__item} ${activeNumber === 2 && styles.active}`} onClick={()=>changeHandler(2)}>ПОЗАБОТИТЬСЯ О КРАСОТЕ ЛИЦА И&nbsp;ТЕЛА</div>
                        <div className={`${styles.programs_block__item} ${activeNumber === 3 && styles.active}`} onClick={()=>changeHandler(3)}>УКРЕПИТЬ ЗДОРОВЬЕ</div>
                        <div className={`${styles.programs_block__item} ${activeNumber === 4 && styles.active}`} onClick={()=>changeHandler(4)}>ОБРЕСТИ ДУШЕВНУЮ ГАРМОНИЮ</div>
                        <div className={`${styles.programs_block__item} ${activeNumber === 5 && styles.active}`} onClick={()=>changeHandler(5)}>НАБРАТЬ МЫШЕЧНУЮ МАССУ</div>
                    </div>
                    <div className={`${styles.programs_block__right} bestfriends_programs`}>
                        <div className={`${styles.programs_image} ${activeNumber === 1 && styles.active}`}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/bestfriends/2/1.jpg" alt="" />
                            <p>HIIT ФИТНЕС-БОКС КРОССФИТ ТАБАТА ФУНКЦИОНАЛЬНЫЕ&nbsp;ТРЕНИРОВКИ</p>
                        </div>
                        
                        <div className={`${styles.programs_image} ${activeNumber === 2 && styles.active}`}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/bestfriends/2/2.jpg" alt="" />
                            <p>ФЕЙСФИТНЕС УТРЕННЯЯ&nbsp;ЗАРЯДКА МИОФАСЦИАЛЬНОЕ&nbsp;РАССЛАБЛЕНИЕ ПИЛАТЕС СТРЕЙЧИНГ</p>
                        </div>
                        
                        <div className={`${styles.programs_image} ${activeNumber === 3 && styles.active}`}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/bestfriends/2/3.jpg" alt="" />
                            <p>ЗДОРОВАЯ&nbsp;СПИНА ЗДОРОВЫЕ&nbsp;СТОПЫ УКРЕПЛЕНИЕ&nbsp;ТАЗОВОГО&nbsp;ДНА ВОССТАНОВИТЕЛЬНАЯ&nbsp;ГИМНАСТИКА</p>
                        </div>
                        
                        <div className={`${styles.programs_image} ${activeNumber === 4 && styles.active}`}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/bestfriends/2/4.jpg" alt="" />
                            <p>КУДАЛИНИ-ЙОГА ТАЙ-ЧИ POWER-ЙОГА ЙОГА&nbsp;ДЛЯ&nbsp;НАЧИНАЮЩИХ</p>
                        </div>
                        
                        <div className={`${styles.programs_image} ${activeNumber === 5 && styles.active}`}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/bestfriends/2/5.jpg" alt="" />
                            <p>СИЛОВЫЕ&nbsp;ТРЕНИРОВКИ ФУНКЦИОНАЛЬНЫЕ&nbsp;ТРЕНИРОВКИ ТРЕНИРОВКИ&nbsp;НА&nbsp;МЫШЦЫ&nbsp;КОРА</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Programs;