import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Start2 from './components/Start2';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_02_Vpr_sms = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value='ДО и ПОСЛЕ родов - подготовка и восстановление с выгодой до 61%'/>
        <DocumentDescription value='Выгода до 61% на программы Кати Усмановой до и после родов. Старт сразу после оплаты и доступ до 2 лет' />
        <div className='vpr_2024'>
            <Start2 renderer={renderer} date={date} />
            <Footer version={3} isColor={'#202939'} />
        </div>
      </>
    );
};