import { SalesWidget } from "@com.marathonium/web2-ui";
import Countdown from 'react-countdown';
import styles from './start.module.css';
import Line from "./Line";

const Start = ({date, renderer}) => {
    return (
        <div className={('scrollhere ') + styles.start}>
            {/* <Line /> */}
            <div className="container">
                <div className={styles.start_block}>
                    <div className={styles.start_top}>
                        <div className={styles.start_title}>ОПЛАТИТЕ <span>3&nbsp;690&nbsp;₽</span><br/> ПРЯМО СЕЙЧАС</div>
                        <div className={styles.start_top_right}>
                            <div className={styles.start_countdown}>
                                <p>Ваша выгода сгорит через</p>
                                <div className={styles.start_countdown_timer}>
                                    <Countdown date={Date.now() + date} renderer={renderer} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.start_text}>и&nbsp;заберите 5&nbsp;фитнес-бестселлеров на&nbsp;ваш выбор с&nbsp;невероятной выгодой 89%, чтобы участвовать в&nbsp;розыгрыше призов</div>
                    
                    <div className="velvet_start4">
                        <SalesWidget id={window.lps_settings.forms.velvet4_id} />
                    </div>
                    <p>Платите с&nbsp;любой карты&nbsp;— выпущенной российским или&nbsp;иностранным банком.</p>
                    <div className={styles.start_new}>
                        <div className={styles.start_credit}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/inshape/tnkf.svg" alt="" />
                            <div className={styles.start_credit__title}>Рассрочка без&nbsp;процентов<br/> от&nbsp;615&nbsp;<span>₽</span>&nbsp;в&nbsp;месяц</div>
                            <div className={styles.start_credit__text}>
                                <p>Вы можете оформить заказ в&nbsp;рассрочку. На&nbsp;6&nbsp;месяцев без&nbsp;процентов и&nbsp;предварительного взноса (только&nbsp;для&nbsp;граждан&nbsp;РФ).</p>
                            </div>
                        </div>
                        <div className={styles.start_parts}>
                            <div className={styles.start_parts_mark}><img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/3/mark2.png" alt="" /></div>
                            <img src="https://gymteam.kinescopecdn.net/img/l/pregnancy/start_icon.png" alt="" />
                            <div className={styles.start_parts__title}>Оплатите Долями<br/> без&nbsp;банка, переплат и&nbsp;рисков</div>
                            <div className={styles.start_parts__text}>Не надо ждать до&nbsp;зарплаты — разделите платёж <strong>на&nbsp;4&nbsp;части<br/> от&nbsp;922,5&nbsp;<span>₽</span></strong>. Всего на&nbsp;6&nbsp;недель.</div>
                            <div className={styles.start_parts_block}>
                                <div className={styles.start_parts_item}>
                                    <div className={styles.start_parts_number}><p>1</p></div>
                                    <p>от&nbsp;922,5&nbsp;<b>₽</b><br/> <span>сегодня</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number2].join(' ')}><p>2</p></div>
                                    <p>от&nbsp;922,5&nbsp;<b>₽</b><br/> <span>через 2&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number3].join(' ')}><p>3</p></div>
                                    <p>от&nbsp;922,5&nbsp;<b>₽</b><br/> <span>через 4&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number4].join(' ')}><p>4</p></div>
                                    <p>от&nbsp;922,5&nbsp;<b>₽</b><br/> <span>через 6&nbsp;недель</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Start;