import styles from './preims.module.css';

const Preims = () => {
    return (
        <div className={styles.preims}>
            <div className="container">
                <div className={styles.preims_block}>
                    <div className={styles.preims_item}>
                        <div className={styles.preims_info}>
                            <div className={styles.preims_info__img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bestfriends/preims_1.png" alt="" /></div>
                            <div className={styles.preims_info__title}>Тренировки офлайн</div>
                            <p>Тренируйтесь там,<br/> где&nbsp;нет интернета</p>
                        </div>
                    </div>
                    <div className={styles.preims_item}>
                        <div className={styles.preims_info}>
                            <div className={styles.preims_info__img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bestfriends/preims_2.png" alt="" /></div>
                            <div className={styles.preims_info__title}>Картинка в&nbsp;картинке</div>
                            <p>Оставайтесь на&nbsp;связи<br/> во&nbsp;время занятий</p>
                        </div>
                    </div>
                    <div className={styles.preims_item}>
                        <div className={styles.preims_info}>
                            <div className={styles.preims_info__img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bestfriends/preims_3.png" alt="" /></div>
                            <div className={styles.preims_info__title}>Любимые тренировки</div>
                            <p>Создайте свой список<br/> понравившихся занятий</p>
                        </div>
                    </div>
                    <div className={`${styles.preims_item} ${styles.preims_item__long}`}>
                        <div className={styles.preims_info}>
                            <div className={styles.preims_info__img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bestfriends/preims_4.png" alt="" /></div>
                            <div className={styles.preims_info__title}>Включайте трансляцию на&nbsp;TV</div>
                            <p>Погрузитесь в&nbsp;мир фитнеса на&nbsp;большом экране</p>
                        </div>
                    </div>
                    <div className={`${styles.preims_item} ${styles.preims_item__long}`}>
                        <div className={styles.preims_info}>
                            <div className={styles.preims_info__img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bestfriends/preims_5.png" alt="" /></div>
                            <div className={styles.preims_info__title}>Скорость воспроизведения</div>
                            <p>Подстройте ритм тренировок под&nbsp;себя</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Preims;