import { SalesWidget } from "@com.marathonium/web2-ui";
import Countdown from 'react-countdown';
import styles from './start.module.css';

const Start2 = ({date, renderer}) => {
    return (
        <div className={('scrollhere ') + styles.start}>
            <div className="container">
                <div className={styles.start_info}>
                    <div className={styles.start_info_title}>Заберите 2&nbsp;тарифа<br/> курса на&nbsp;выбор</div>
                    <div className={styles.start_info_subtitle}>Курс для всех, кто уже тренируется или только<br/> планирует пойти в&nbsp;тренажёрку. Если вы не&nbsp;знаете,<br/> с&nbsp;чего начать или недовольны своими результатами</div>
                    {/* <div className={styles.start_info_date}><img src="https://gymteam.kinescopecdn.net/img/l/2023/decembergym/programs_1.png" alt="" /> <b>Старт</b>&nbsp;— сразу после оплаты</div> */}
                    {/* <div className={styles.start_info_date}><img src="https://gymteam.kinescopecdn.net/img/l/2023/decembergym/programs_2.png" alt="" /> <b>График</b>&nbsp;— 3&nbsp;раза в&nbsp;неделю</div> */}

                    {/* <div className={styles.start_info_block}>
                        <div className={styles.start_info_left}>
                            <div className={styles.start_info_title2}>Курс для&nbsp;зала полностью</div>
                            <div className={styles.start_info_tags}>
                                <div className={styles.start_info_tag}>Доступ: 5&nbsp;месяцев</div>
                                <div className={styles.start_info_tag}>Длительность: 4,5&nbsp;месяца</div>
                            </div>
                            <ul>
                                <li>Все 4&nbsp;блока тренировок</li>
                                <li>Меню на&nbsp;весь курс</li>
                                <li>Путеводитель «Оборудование и&nbsp;инвентарь в&nbsp;тренажёрном зале»</li>
                                <li>Сборник «Продукты с&nbsp;высоким содержанием белка для набора мышечной массы»</li>
                                <li>Гайд «Правила хорошего тона в&nbsp;тренажёрном зале»</li>
                                <li>Гайд «Как правильно читать показатели умных весов»</li>
                                <li>Инструкция «Одежда, обувь и&nbsp;аксессуары для фитнеса: как выбрать и&nbsp;ухаживать»</li>
                                <li>Инструкция «Дисциплина: как начать ходить в&nbsp;тренажёрный зал с&nbsp;удовольствием»</li>
                            </ul>
                            <div className={styles.start_info_result}><b>Результат:</b> Освоите правильную технику выполнения, наработаете силу и&nbsp;выносливость, увеличите мышечные объёмы. В&nbsp;итоге создадите спортивное, рельефное тело.</div>
                            <div className={styles.start_info_end}>
                                <p>Забирайте всю программу со&nbsp;скидкой 14%</p>
                                <div className={styles.start_info_prices}>
                                    <div className={styles.start_info_price}>6&nbsp;890&nbsp;₽</div>
                                    <div className={styles.start_info_price2}>7&nbsp;990&nbsp;₽</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.start_info_right}>
                            <div className={styles.start_info_title2}>Только первый цикл «Техника выполнения»</div>
                            <div className={styles.start_info_tags}>
                                <div className={styles.start_info_tag}>Доступ: 2&nbsp; месяца</div>
                                <div className={styles.start_info_tag}>Длительность: 1,5&nbsp;месяца</div>
                            </div>
                            <ul>
                                <li>1-й блок&nbsp;– 6&nbsp;тренировок</li>
                                <li>Меню на&nbsp;1&nbsp;блок тренировок</li>
                                <li>Путеводитель «Оборудование и&nbsp;инвентарь в&nbsp;тренажёрном зале»</li>
                                <li>Сборник «Продукты с&nbsp;высоким содержанием белка для набора мышечной массы»</li>
                                <li>Гайд «Правила хорошего тона в&nbsp;тренажёрном зале»</li>
                                <li>Гайд «Как правильно читать показатели умных весов»</li>
                            </ul>
                            <div className={styles.start_info_result}><b>Результат:</b> На&nbsp;низких весах освоите правильную технику выполнения, чтобы на&nbsp;этой базе продолжать тренинг&nbsp;— увеличивать силы, выносливость и&nbsp;наращивать объёмы</div>
                            <div className={styles.start_info_more}>
                                <p>После этого блока вы можете отдельно<br/> приобрести блоки:</p>
                                <ul>
                                    <li>–&nbsp;Увеличение силовых показателей</li>
                                    <li>–&nbsp;Наработка выносливости</li>
                                    <li>–&nbsp;Работа над&nbsp;мышечными объёмами</li>
                                </ul>
                            </div>
                            <div className={styles.start_info_end}>
                                <p>Забирайте 1&nbsp;цикл со&nbsp;скидкой&nbsp;20%</p>
                                <div className={styles.start_info_prices}>
                                    <div className={styles.start_info_price}>2&nbsp;790&nbsp;₽</div>
                                    <div className={styles.start_info_price2}>3&nbsp;490&nbsp;₽</div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>

                <div className={styles.start_block}>
                    <div className={styles.start_block_title}>Спеццена сгорит через:</div>
                    <div className={styles.start_countdown}>
                        <div className={styles.start_countdown_timer}>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                    </div>

                    <div className="decembergym_start">
                        <SalesWidget id={window.lps_settings.forms.decembergym_id} />
                    </div>
                    <p>Платите с&nbsp;любой карты&nbsp;— выпущенной российским или&nbsp;иностранным банком.</p>
                    <div className={styles.start_new}>
                        <div className={styles.start_credit}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/tink_icon.png" alt="" />
                            <div className={styles.start_credit__title}>Рассрочка без&nbsp;процентов<br/> от&nbsp;1&nbsp;149&nbsp;<span>₽</span>&nbsp;в&nbsp;месяц (недоступно при покупке только 1&nbsp;цикла)</div>
                            <div className={styles.start_credit__text}>
                                <p>Вы можете оформить заказ в&nbsp;рассрочку. На&nbsp;6&nbsp;месяцев без&nbsp;процентов и&nbsp;предварительного взноса (только&nbsp;для&nbsp;граждан&nbsp;РФ).</p>
                            </div>
                        </div>
                        <div className={styles.start_parts}>
                            <div className={styles.start_parts_mark}><img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/3/mark2.png" alt="" /></div>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/3/start_icon_black.png" alt="" />
                            <div className={styles.start_parts__title}>Оплатите Долями<br/> без&nbsp;банка, переплат и&nbsp;рисков (недоступно при покупке только 1&nbsp;цикла)</div>
                            <div className={styles.start_parts__text}>Не надо ждать до&nbsp;зарплаты — разделите платёж <strong>на&nbsp;4&nbsp;части от 1&nbsp;722,5&nbsp;<span>₽</span></strong>. Всего на&nbsp;6&nbsp;недель (только&nbsp;для&nbsp;граждан&nbsp;РФ).</div>
                            <div className={styles.start_parts_block}>
                                <div className={styles.start_parts_item}>
                                    <div className={styles.start_parts_number}><p>1</p></div>
                                    <p>от 1&nbsp;722,5&nbsp;<b>₽</b> <span>сегодня</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number2].join(' ')}><p>2</p></div>
                                    <p>от 1&nbsp;722,5&nbsp;<b>₽</b> <span>через 2&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number3].join(' ')}><p>3</p></div>
                                    <p>от 1&nbsp;722,5&nbsp;<b>₽</b> <span>через 4&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number4].join(' ')}><p>4</p></div>
                                    <p>от 1&nbsp;722,5&nbsp;<b>₽</b> <span>через 6&nbsp;недель</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
        </div>
    )
}

export default Start2;