import { useEffect, useState } from 'react';
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import { LoginWidget, checkUserInGroup, getUserCountByGroup } from "@com.marathonium/web2-ui";
import Footer from '../../components/Footer';
import Promo from './components/Promo';
import { Modal } from './components/Modal';
import { Nomination } from './components/Nomination';
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";
import styles from './main.module.css';
import './style.css'

const nom1 = [
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/1_7.jpg',
        name: 'Елена',
        points: '115',
        place: true,
        weight: '72 → 64',
        chest: '100 → 94',
        waist: '96 → 80',
        hips: '99 → 95',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/1_9.jpg',
        name: 'Анжелика',
        weight: '67,8 → 60',
        chest: '94 → 86',
        waist: '86 → 72',
        hips: '104 → 102',
        points: '103',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/1_8.jpg',
        name: 'Ольга',
        weight: '59 → 56,2',
        chest: '87 → 87',
        waist: '77 → 65',
        hips: '97 → 93',
        points: '84',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/1_1.jpg',
        name: 'Алёна',
        points: '55',
        weight: '75,3 → 70,6',
        chest: '104 → 98',
        waist: '88 → 76',
        hips: '110 → 104',
        points: '36',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/1_5.jpg',
        name: 'Елена',
        weight: '58 → 55',
        chest: '86 → 86',
        waist: '75 → 67',
        hips: '93 → 93',
        points: '23',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/1_2.jpg',
        name: 'Валентина',
        weight: '61 → 57',
        chest: '85 → 81',
        waist: '70 → 63',
        hips: '97 → 94',
        points: '19',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/1_4.jpg',
        name: 'Оксана',
        weight: '84,8 → 78,9',
        chest: '106 → 98',
        waist: '100 → 91',
        hips: '112 → 104',
        points: '17',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/1_3.jpg',
        name: 'Регина',
        weight: '71 → 65,7',
        chest: '98 → 92',
        waist: '79 → 72',
        hips: '104 → 101',
        points: '11',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/1_6.jpg',
        name: 'Алиса',
        weight: '53,4 → 52,3',
        chest: '92 → 91',
        waist: '72 → 75',
        hips: '82 → 92',
        points: '8',
    },
]
const nom2 = [
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/2_1.jpg',
        name: 'Екатерина',
        weight: '67 → 62,5',
        chest: '95 → 91',
        waist: '81 → 68',
        hips: '100 → 99',
        place: true,
        points: '179',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/2_6.jpg',
        name: 'Евгения',
        weight: '60 → 55,5',
        chest: '88 → 85',
        waist: '68 → 63',
        hips: '95 → 94',
        points: '92',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/2_3.jpg',
        name: 'Нина',
        weight: '57,9 → 54,3',
        chest: '33,5 → 32',
        waist: '27 → 25,5',
        hips: '36,5 → 36',
        points: '84',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/2_4.jpg',
        name: 'Марина',
        weight: '70 → 67,7',
        chest: '89 → 89',
        waist: '80 → 75',
        hips: '109 → 101',
        points: '38',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/2_7.jpg',
        name: 'Любовь',
        weight: '61 → 60',
        chest: '85 → 83',
        waist: '71 → 68',
        hips: '94 → 91',
        points: '31',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/2_5.jpg',
        name: 'Анастасия',
        weight: '61 → 59',
        chest: '93 → 88',
        waist: '79 → 76',
        hips: '97 → 96',
        points: '30',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/2_2.jpg',
        name: 'Ульяна',
        weight: '63,8 → 62,1',
        chest: '91 → 86',
        waist: '78 → 72',
        hips: '96 → 97',
        points: '10',
    },
]
const nom3 = [
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/3_6.jpg',
        name: 'Анна',
        weight: '64 → 57',
        chest: '100 → 90',
        waist: '85 → 72',
        hips: '98 → 91',
        place: true,
        points: '187',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/3_2.jpg',
        name: 'Людмила',
        weight: '79,1 → 68,7',
        chest: '110 → 97',
        waist: '92 → 78',
        hips: '113 → 107',
        points: '80',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/3_7.jpg',
        name: 'Татьяна',
        weight: '67 → 63,5',
        chest: '97 → 92',
        waist: '85 → 73',
        hips: '102 → 98',
        points: '71',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/3_8.jpg',
        name: 'Ольга',
        weight: '56 → 54,8',
        chest: '95 → 92',
        waist: '82 → 77',
        hips: '98 → 91',
        points: '36',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/3_5.jpg',
        name: 'Ольга',
        weight: '74,9 → 69',
        chest: '100 → 98',
        waist: '90 → 72',
        hips: '108 → 100',
        points: '25',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/3_10.jpg',
        name: 'Маржан',
        weight: '88 → 77',
        chest: '105 → 99',
        waist: '92 → 88',
        hips: '116 → 109',
        points: '22',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/3_9.jpg',
        name: 'Марина',
        weight: '69 → 61',
        chest: '95 → 89',
        waist: '80 → 72',
        hips: '109 → 102',
        points: '17',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/3_4.jpg',
        name: 'Тамара',
        weight: '89,2 → 81',
        chest: '110 → 101',
        waist: '91 → 82',
        hips: '115 → 108',
        points: '10',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/3_3.jpg',
        name: 'Любовь',
        weight: '74 → 70',
        chest: '95 → 90',
        waist: '79 → 72',
        hips: '103 → 99',
        points: '8',
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/3_1.jpg',
        name: 'Татьяна',
        weight: '61 → 56,7',
        chest: '92 → 87',
        waist: '81 → 75',
        hips: '97 → 90',
        points: '6',
    },    
]
export const L2024_04_Missfitmission_result = () => {
    const [isModal,  setIsModal] = useState(false)
    const [inGroupOne, setInGroupOne] = useState(null)
    const [inGroupTwo, setInGroupTwo] = useState(null)
    const [inGroupThree, setInGroupThree] = useState(null)
    const [inGroupFour, setInGroupFour] = useState(null)
    const [votesOne, setVotesOne] = useState([])
    const [votesTwo, setVotesTwo] = useState([])
    const [votesThree, setVotesThree] = useState([])
    const [votesFour, setVotesFour] = useState([])

    const [alreadyVote1, setAlreadyVote1] = useState(false)
    const [alreadyVote2, setAlreadyVote2] = useState(false)
    const [alreadyVote3, setAlreadyVote3] = useState(false)

    useEffect(() => {
        document.body.id = 'rasprozarka_2305'
        document.body.parentElement.id = 'htmlrasprozarka_2305'

        checkVote1()
        checkVote2()
        checkVote3()

        // getVotes(nom1, 1)
    }, [])

    const checkVote1 = () => {
        Promise.all(nom1.map((i) => checkUserInGroup(i.id))).then((result) => {
            result.map((res) => {
                if(res) {
                    setAlreadyVote1(true)
                }
            })
        })
    }
    const checkVote2 = () => {
        Promise.all(nom2.map((i) => checkUserInGroup(i.id))).then((result) => {
            result.map((res) => {
                if(res) {
                    setAlreadyVote2(true)
                }
            })
        })
    }
    const checkVote3 = () => {
        Promise.all(nom3.map((i) => checkUserInGroup(i.id))).then((result) => {
            result.map((res) => {
                if(res) {
                    setAlreadyVote3(true)
                }
            })
        })
    }

    return (
        <>
        <DocumentTitle value='Мисс Фитмиссия сезон 2024 - открытое голосование'/>
        <DocumentDescription value='Открытое голосование за номинанток конкурса «Мисс Фитмиссия 2024». Тренировки по фитнес-программам Кати Усмановой' />
        <div className='missvelvet_2305'>
            <Promo />
            <Nomination nomination={nom1} texts={1} setIsModal={setIsModal} alreadyVote={alreadyVote1} checkVote={checkVote1} />
            <Nomination nomination={nom2} texts={2} setIsModal={setIsModal} alreadyVote={alreadyVote2} checkVote={checkVote2} />
            <Nomination nomination={nom3} texts={3} setIsModal={setIsModal} alreadyVote={alreadyVote3} checkVote={checkVote3} />
            <Footer isColor={'#F4F5FA'} />
        </div>
        </>
    );
};