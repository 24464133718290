import Countdown from 'react-countdown';
import styles from './promo.module.css';

const Promo = ({scroll, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className={styles.rating}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/up/promo_1.jpg" alt="" />
            </div>
            <div className="container">
                <div className={styles.promo_block}>
                    <div className={styles.promo_pretitle}>ПРЕДЛОЖЕНИЕ ДЕЙСТВУЕТ <b>ТОЛЬКО 20&nbsp;МИНУТ</b></div>
                    <div className={styles.promo_title}>ПОДПИСКА НА&nbsp;50+<br/> СПОРТИВНЫХ ПРОГРАММ<br/> GYMTEAM <span>С&nbsp;ВЫГОДОЙ ДО&nbsp;58%</span></div>
                    <div className={styles.mob}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/promo_bg.jpg" alt="" />
                    </div>
                    <div className={styles.promo_text}>Собрали для тебя более 1500 тренировок<br/> от&nbsp;практикующих экспертов под любые цели,<br/> для любого возраста и&nbsp;уровня подготовки.<br/> Занимайся где угодно в&nbsp;удобное время</div>
                    <div className={styles.promo_btns}>
                        <div className={styles.promo_btn} onClick={scroll}>выбрать подписку</div>
                        <div className={styles.promo_countdown}>
                            <p>Предложение<br/> сгорит через:</p>
                            <div className={styles.promo_countdown_timer}>
                                <Countdown date={Date.now() + 1200000} renderer={renderer} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Promo;