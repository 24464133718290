import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Promo3 from './components/Promo3';
import Form2 from './components/Form2';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_04_Method_end = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    // let nextMidnight = new Date();
    let nextMidnight = new Date(2024,3,23,0,0,0);
    // nextMidnight.setHours(120, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({days, hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        let daysn = ''
        if(days > 0) {daysn = days}
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                {days > 0 && <div>{days} <span>д.</span></div>}
                <div>{hourzero}{hours} <span>ч.</span></div>
                <div>{minuteszero}{minutes} <span>м.</span></div>
                {/* <div>{secondszero}{seconds} </div> */}
                {/* {days > 0 && <div>{days} <span>{days > 1 ? 'дня' : 'день'}</span></div>}
                <div>{hourzero}{hours} <span>часов</span></div>
                <div>{minuteszero}{minutes} <span>минут</span></div>
                <div>{secondszero}{seconds}</div> */}
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value='Метод Усмановой — новая фитнес-программа на 28 дней по комплексной работе с телом'/>
        <DocumentDescription value='Новая программа Метод Усмановой: комплексная работа с телом через тренировки, питание и психологию. Чат с куратором и участие в конкурсном потоке «До/После»' />
        <div className='method '>
            <div className='method3'>
              <Promo3 renderer={renderer} date={date} scroll={scroll} />
              <Form2 />
              <Footer version={3} isColor={'#000000'} />
            </div>
        </div>
      </>
    );
};