import { addUserToGroup } from "@com.marathonium/web2-ui";
import styles from './nomination.module.css'
import { useState } from "react";

export const Nomination = ({nomination, checkVote, setIsModal, inGroup, votes, alreadyVote}) => {

    const [isModal2, setIsModal2] = useState(false)
    const [activeSlide, setActiveSlide] = useState(null)

    function getVote(id) {
        if(!alreadyVote) {
            setIsModal(true)
            addUserToGroup(id).then((res) => {
                checkVote()
                setTimeout(()=>{
                    setIsModal(false)
                }, 1000)
            })
        }
    }

    return (
        <div className={styles.nomination}>
            <div className="container">
            <div className={styles.nomination_title}>НАШИ ПОБЕДИТЕЛЬНИЦЫ<br/> Мисс Бархатный сезон</div>
            {/* <div className={styles.nomination_subtitle}>Оценивайте результат работы над качеством тела&nbsp;— уменьшение объема талии,<br/> избавление от&nbsp;целлюлита, подкачанный пресс, руки и&nbsp;ягодицы</div> */}
            {/* <div className={styles.nomination_subtitle2}>Чья фигура достойна вашего голоса?</div> */}
            
            <div className={styles.nomination_block}>
                {nomination.map((i, idx) => {
                    return(
                    <div className={styles.nomination_item} key={i.name}>
                        <div className={styles.nomination_item_info}>
                        {/* onClick={() => (setActiveSlide(i[idx].image), setIsModal(true))} */}
                            <div className={styles.nom_img} onClick={() => (setActiveSlide(i.image), setIsModal2(true))}><img src={i.image} alt="" /></div>
                            <div className={styles.nom_info}>
                            {i.place && <div className={styles.nom_place}>{i.place}</div>}
                                <div className={styles.nom_top}>
                                    <div className={styles.nom_name}>{i.name}</div>
                                    <div className={styles.nom_name}>Баллов: {i.points}</div>
                                    {/* <div className={`${alreadyVote ? styles.nom_btn_closed : styles.nom_btn}`} onClick={()=>getVote(i.id)}>Проголосовать</div> */}
                                </div>
                                <div className={styles.nom_sizes}>
                                    <div><img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/nom1.png" alt="" /> <b>Вес:</b>&nbsp;{i.weight}</div>
                                    <div><img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/nom2.png" alt="" /> <b>Талия:</b>&nbsp;{i.waist}</div>
                                    <div><img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/nom3.png" alt="" /> <b>Грудь:</b>&nbsp;{i.chest}</div>
                                    <div><img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/nom4.png" alt="" /> <b>Бедра:</b>&nbsp;{i.hips}</div>
                                </div>
                            </div>
                        </div>
                        
                        
                        
                        

                        {/* <h2>НАБРАНО ГОЛОСОВ: {votes ? votes[idx] : 0}</h2> */}
                    </div>
                    )
                })}
            </div>

            </div>

            <div className={`${styles.modal} ${isModal2 && styles.modal_active}`}>
                <div className={styles.close_btn} onClick={()=>setIsModal2(false)}>
                    <svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.1949 1.48389L1.33203 15.3468M1.33203 1.48389L15.1949 15.3468" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div className={styles.modal_img}>
                    <img src={activeSlide} alt="" />
                </div>
            </div>
        </div>
    );
};