import { LoginWidget, ShowForGroup, HideForGroup, addUserToGroup, getUser } from "@com.marathonium/web2-ui";
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";
import { useState } from "react";
import Countdown from 'react-countdown';
import {DocumentTitle, DocumentDescription} from '../../../components/DocumentMeta';

import styles from './style.module.css';
import Footer from "../../../components/Footer";

export const L2024_01_Single_Pregnancy = () => {

    const [phone, setPhone] = useState('')
    const getUserPhone = () => {
        getUser().then(res => {
            let phoneNumber = res.phone.split('')
            if(phoneNumber.length == 12) {
                let finalNumber = `${phoneNumber[0]}${phoneNumber[1]} ${phoneNumber[2]}${phoneNumber[3]}${phoneNumber[4]} ${phoneNumber[5]}${phoneNumber[6]}${phoneNumber[7]}-${phoneNumber[8]}${phoneNumber[9]}-${phoneNumber[10]}${phoneNumber[11]}`
                setPhone(finalNumber)
            } else {
                let finalNumber = `${phoneNumber[0]} ${phoneNumber[1]}${phoneNumber[2]}${phoneNumber[3]} ${phoneNumber[4]}${phoneNumber[5]}${phoneNumber[6]}-${phoneNumber[7]}${phoneNumber[8]}-${phoneNumber[9]}${phoneNumber[10]}`
                setPhone(finalNumber)
            }
        })
    }

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };

    const scroll = () => {
        const section = document.querySelector( '.scrollhere' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    function LoginForm() {
        return (
          <>
            <Authorized>
                <div className={styles.promo}>
                    <div className="container">
                        <div className={styles.promo_block}>
                            <div className={styles.promo_pretitle}>специальное предложение</div>
                            <div className={styles.promo_title}>КУРС ДЛЯ<br/> БЕРЕМЕННЫХ</div>
                            <div className={styles.promo_subtitle}>плавим цены на&nbsp;ваш заказ</div>
                            <div className={styles.promo_list}>
                                <ul>
                                    <li>тренировки для&nbsp;любого триместра на&nbsp;ваш выбор</li>
                                    <li>Скидка <b>24%</b></li>
                                    <li>Доступ <b>на&nbsp;1&nbsp;год</b></li>
                                </ul>
                            </div>
                            <HideForGroup id={window.lps_settings.user_groups.single_preg}>
                                <div className={styles.promo_text}>Эта лучшая возможность подготовиться<br/> к&nbsp;лёгким родам и&nbsp;сохранить красоту тела</div>
                                <div className={styles.promo_countdown_start}><Countdown date={Date.now() + date} renderer={renderer} /></div>
                                <div className={styles.promo_btn} onClick={() => {getUserPhone(); addUserToGroup(window.lps_settings.user_groups.single_preg);}}><p>ХОЧУ</p></div>
                            </HideForGroup>
                            <ShowForGroup id={window.lps_settings.user_groups.single_preg}>
                                <div className={styles.promo_thanks}>Спасибо</div>
                            </ShowForGroup>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Authorized>
            <Anonymous>
                <div className={styles.promo}>
                    <div className="container">
                        <div className={styles.promo_block}>
                            <div className={styles.promo_pretitle}>специальное предложение</div>
                            <div className={styles.promo_title}>КУРС ДЛЯ<br/> БЕРЕМЕННЫХ</div>
                            <div className={styles.promo_subtitle}>плавим цены на&nbsp;ваш заказ</div>
                            <div className={styles.promo_list}>
                                <ul>
                                    <li>тренировки для&nbsp;любого триместра на&nbsp;ваш выбор</li>
                                    <li>Скидка <b>24%</b></li>
                                    <li>Доступ <b>на&nbsp;1&nbsp;год</b></li>
                                </ul>
                            </div>
                            <div className={styles.promo_text}>Эта лучшая возможность подготовиться<br/> к&nbsp;лёгким родам и&nbsp;сохранить красоту тела</div>
                            <div className={styles.promo_countdown_start}><Countdown date={Date.now() + date} renderer={renderer} /></div>
                            <div className={styles.promo_btn} onClick={scroll}><p>ХОЧУ</p></div>
                        </div>
                    </div>
                </div>

                <div className={`scrollhere ${styles.main_login}`}>
                    <div className="container">
                        <div className={styles.main_form}>
                            <div className={styles.main_form__text2}>Ваш номер телефона:</div>
                            <div className={styles.main_form__login}><LoginWidget /></div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Anonymous>
          </>
        );
      }

    return (
        <div>
            <DocumentTitle value='Закрытая продажа КУРСА ДЛЯ БЕРЕМЕННЫХ с выгодой 24%'/>
            <DocumentDescription value='Закрытая продажа — скидка 24% на КУРС ДЛЯ БЕРЕМЕННЫХ Екатерины Усмановой. Старт сразу после оплаты' />
            <div className={styles.main}>
                <LoginForm onSuccess={getUserPhone()} />
            </div>
        </div>
    );
};