import styles from './video.module.css';

const Video = () => {
    return(
        <div className={styles.video}>
            <div className="container">
                <div className={styles.video_title}>Смотрите эксклюзивное видео<br/> от&nbsp;Кати и&nbsp;узнайте, какой <span>главный приз</span> она подготовила для участниц!</div>

                <div className={styles.video_block}>
                    <iframe src={'https://kinescope.io/embed/mrxXBCmi9HtF8PVH5bCgRD'} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowFullScreen></iframe>
                </div>
            </div>
        </div>
    );
};

export default Video;