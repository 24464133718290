import styles from './promo.module.css';

const Promo = ({scroll}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_title}>ОНЛАЙН-ТРЕНИРОВКИ<br/> ДЛЯ&nbsp;ДОМА И&nbsp;ЗАЛА<br/> <span>С&nbsp;ЕКАТЕРИНОЙ УСМАНОВОЙ</span></div>
                <div className={styles.promo_subtitle}>Месяц тренировок с&nbsp;чемпионкой по&nbsp;фитнес-бикини<br/> по&nbsp;цене трёх персональных занятий в&nbsp;зале</div>
                <div className={styles.btn} onClick={scroll}>Выбрать программу <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/arrow_right.svg" alt="" /></div>
            </div>
        </div>
    )
}

export default Promo;