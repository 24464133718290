import styles from './programs.module.css';

const Programs = ({scroll}) => {
    return (
        <div className={styles.programs}>
            <div className="container">
                <div className={styles.programs_title}>Забирайте комплект самых<br/> результативных тренировок<br/> на&nbsp;попу с&nbsp;выгодой 85% </div>
                <div className={styles.programs_subtitle}>1&nbsp;990&nbsp;₽ <span>вместо <i>12&nbsp;900&nbsp;₽</i></span></div>
                        <div className={`${styles.programs_block} ${styles.programs_block_cont}`}>
                            <div className={styles.programs_item}>
                                <div className={styles.programs_info}>
                                    <div className={styles.programs_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/programs_1.jpg" alt="" />
                                        <div className={styles.programs_img__info}>
                                            <div className={styles.programs_tags}>
                                                <div className={styles.programs_tag}>Накачать ягодицы</div>
                                            </div>
                                            <div className={styles.programs_img__info_title}>МАРАФОН <span>Упругой попы 1.0</span></div>
                                            <div className={styles.programs_img__info_price}>5 000 ₽</div>
                                        </div>
                                    </div>
                                    <div className={styles.programs_text}>
                                        <ul>
                                            <li><b>Длительность:</b> 21&nbsp;день</li>
                                            <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, 2&nbsp;утяжелителя по&nbsp;1,5-3&nbsp;кг</li>
                                            <li><b>Где заниматься:</b> дома или&nbsp;в&nbsp;зале</li>
                                            <li><b>График тренировок:</b> ежедневно</li>
                                        </ul>
                                        <div className={styles.programs_more}>
                                            <input type="checkbox" id="p3" name="p3" />
                                            <label htmlFor="p3">Для кого подойдёт <p>Марафон идеально подойдёт новичкам и&nbsp;тем, кто делал длительный перерыв в&nbsp;тренировках. <b>Вы сможете сделать ягодицы округлыми и&nbsp;упругими</b>.</p></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.programs_item}>
                                <div className={styles.programs_info}>
                                    <div className={styles.programs_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/programs_2.jpg" alt="" />
                                        <div className={styles.programs_img__info}>
                                            <div className={styles.programs_tags}>
                                                <div className={styles.programs_tag}>Для продвинутых</div>
                                                <div className={styles.programs_tag}>Накачать ягодицы</div>
                                            </div>
                                            <div className={styles.programs_img__info_title}>МАРАФОН <span>Упругой попы 2.0</span></div>
                                            <div className={styles.programs_img__info_price}>7 900 ₽</div>
                                        </div>
                                    </div>
                                    <div className={styles.programs_text}>
                                        <ul>
                                            <li><b>Длительность:</b> 30&nbsp;дней</li>
                                            <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по&nbsp;1,5-3&nbsp;кг</li>
                                            <li><b>Где заниматься:</b> дома или&nbsp;в&nbsp;зале</li>
                                            <li><b>График тренировок:</b> 4&nbsp;раза в&nbsp;неделю</li>
                                        </ul>
                                        <div className={styles.programs_more}>
                                            <input type="checkbox" id="p4" name="p4" />
                                            <label htmlFor="p4">Для кого подойдёт <p>Марафон для&nbsp;желающих <b>качественно проработать ягодицы</b> и&nbsp;заметно улучшить их форму.</p></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                <div className={styles.btn} onClick={scroll}>ЗАБРАТЬ КОМПЛЕКТ</div>
            </div>
        </div>
    )
}

export default Programs;