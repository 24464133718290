import Countdown from 'react-countdown';
import { SalesWidget } from "@com.marathonium/web2-ui";
import { useInstance } from "@com.marathonium/web2-utils";
import styles from './start.module.css';
import { useState } from 'react';

const Start = ({renderer}) => {
    const [chose, setChose] = useState(0)
    const [widget] = useInstance();

    const [date, setDate] = useState(Date.now() + 1200000)

    return (
        <>
        <div className={('scrollhere ') + styles.start}>
            <div className="container">

                <div className={styles.start_block}>
                    <div className={styles.start_block_title}>ЗАБЕРИ ЛЮБОЙ ИЗ&nbsp;3-Х НАБОРОВ<br/> ФИТНЕС-ПРОГРАММ<br/> <span>С&nbsp;ВЫГОДОЙ ДО&nbsp;92%</span></div>
                    <div className={styles.start_block_subtitle}>Наслаждайся красивым и&nbsp;стройным телом круглый год</div>
                    {/* <div className={styles.start_block_subtitle}>Наибольшая выгода только для первых <span>100 мест</span>.<br/> Далее цена повысится</div> */}
                    
                    <div className={styles.promo_countdown}>
                        <p>Твоя выгода сгорит через:</p>
                        <div className={styles.promo_countdown_timer}>
                            <Countdown date={date} renderer={renderer} />
                        </div>
                    </div>
                    <div className={styles.n_dates}>
                        <div className={styles.n_date}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr1.svg" alt="" />
                            <p>Доступ&nbsp;&mdash; до&nbsp;1&nbsp;года</p>
                        </div>
                        <div className={styles.n_date}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr2.svg" alt="" />
                            <p>Старт&nbsp;&mdash; 16&nbsp;октября</p>
                        </div>
                    </div>
                    {/* <div className={styles.start_remains}>Осталось мест по суперцене: <span>{window.lps_settings.other.count}</span></div> */}

                    <div className={styles.chose}>
                        <div className={`${styles.chose_item} + ${chose == 0 && styles.chose_selected}`} onClick={()=>{widget.resetOrder(); widget.selectByIndex(0); setChose(0)}}>
                            <div className={styles.chose_head}>
                                <div className={styles.chose_head_name}><b>Пакет программ</b> &laquo;Базовый&raquo;</div>
                                <div className={styles.chose_head_price}>2&nbsp;990&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Марафон &laquo;Стройность&nbsp;1.0&raquo;</div>
                                <div className={styles.chose_list_price}>5000&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Марафон &laquo;Упругой попы 1.0&raquo;</div>
                                <div className={styles.chose_list_price}>5000&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Марафон &laquo;Плоского живота&raquo;</div>
                                <div className={styles.chose_list_price}>5000&nbsp;₽</div>
                            </div>
                            {/* <div className={styles.chose_and}>+&nbsp;5&nbsp;гайдов в&nbsp;подарок</div> */}
                        </div>

                        <div className={`${styles.chose_item} + ${chose == 1 && styles.chose_selected}`} onClick={()=>{widget.resetOrder(); widget.selectByIndex(1); setChose(1)}}>
                            <div className={styles.chose_head}>
                                <div className={styles.chose_head_name}><b>Пакет программ</b> &laquo;Продвинутый&raquo;</div>
                                <div className={styles.chose_head_price}>3&nbsp;990&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Марафон &laquo;Стройность&nbsp;1.0&raquo;</div>
                                <div className={styles.chose_list_price}>5000&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Марафон &laquo;Упругой попы 1.0&raquo;</div>
                                <div className={styles.chose_list_price}>5000&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Марафон &laquo;Плоского живота&raquo;</div>
                                <div className={styles.chose_list_price}>5000&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Марафон &laquo;Антицеллюлитный&raquo;</div>
                                <div className={styles.chose_list_price}>7500&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Жиросжигающий курс</div>
                                <div className={styles.chose_list_price}>7000&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Набор тренировок &laquo;Универсальные тренировки на&nbsp;ягодицы&raquo;</div>
                                <div className={styles.chose_list_price}>4790&nbsp;₽</div>
                            </div>
                            {/* <div className={styles.chose_and}>+&nbsp;5&nbsp;гайдов в&nbsp;подарок</div> */}
                        </div>

                        <div className={`${styles.chose_item} + ${chose == 2 && styles.chose_selected}`} onClick={()=>{widget.resetOrder(); widget.selectByIndex(2); setChose(2)}}>
                        <div className={styles.chose_head}>
                                <div className={styles.chose_head_name}><b>Пакет программ</b> &laquo;Максимальный&raquo;</div>
                                <div className={styles.chose_head_price}>4&nbsp;990&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Марафон &laquo;Стройность&nbsp;1.0&raquo;</div>
                                <div className={styles.chose_list_price}>5000&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Марафон &laquo;Упругой попы 1.0&raquo;</div>
                                <div className={styles.chose_list_price}>5000&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Марафон &laquo;Плоского живота&raquo;</div>
                                <div className={styles.chose_list_price}>5000&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Марафон &laquo;Антицеллюлитный&raquo;</div>
                                <div className={styles.chose_list_price}>7500&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Жиросжигающий курс</div>
                                <div className={styles.chose_list_price}>7000&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Набор тренировок &laquo;Универсальные тренировки на&nbsp;ягодицы&raquo;</div>
                                <div className={styles.chose_list_price}>4790&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Курс &laquo;Идеальной попы 2.0&raquo;</div>
                                <div className={styles.chose_list_price}>6000&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Марафон &laquo;Убираем лишний жир&raquo;</div>
                                <div className={styles.chose_list_price}>7900&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Курс «Восстановление после родов»</div>
                                <div className={styles.chose_list_price}>7900&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Курс &laquo;Питание&raquo;</div>
                                <div className={styles.chose_list_price}>10000&nbsp;₽</div>
                            </div>
                        </div>
                    </div>

                    {/* <div className={styles.inf}>
                        <div className={styles.inf_item}>
                            <p>Старт тренировок</p>
                            <p>20 мая</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/calendar.png" alt="" />
                        </div>
                        <div className={styles.inf_item}>
                            <p>Доступ к&nbsp;программам:</p>
                            <p>6 месяцев</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/clock.png" alt="" />
                        </div>
                    </div> */}

                    {/* <div className={styles.total}>
                        <span>6&nbsp;790&nbsp;₽</span>
                        <em>7&nbsp;990&nbsp;₽</em>
                        <p>Курс для зала от&nbsp;Кати Усмановой</p>
                    </div>
                    
                    <div className={styles.start_list}>
                        <div className={styles.plus}>Плюс бонусы:</div>
                        <div className={styles.start_list_item}>
                            <p>+ 5&nbsp;гайдов в&nbsp;подарок</p>
                            <p></p>
                        </div>
                        <div className={styles.start_list_item}>
                            <p>+ чат с&nbsp;куратором на&nbsp;1&nbsp;месяц</p>
                            <p></p>
                        </div>
                        <div className={styles.start_list_item}>
                            <p>+ участие в&nbsp;конкурсе на&nbsp;лучшие результаты</p>
                            <p></p>
                        </div>
                        <div className={styles.start_list_item}>
                            <p>+ возможность выиграть 10&nbsp;000 рублей и&nbsp;iPhone 15&nbsp;Pro</p>
                            <p></p>
                        </div>
                    </div> */}

                    <div className="um2_start">
                        <SalesWidget hook={widget} id={window.lps_settings.forms.um2_id} buttonText="Оформить заказ" />
                    </div>
                    <p>Платите с&nbsp;любой карты&nbsp;— выпущенной российским или&nbsp;иностранным банком.</p>
                    {/* <div className={styles.start_new}>
                        <div className={styles.start_credit}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/tink_icon.png" alt="" />
                            <div className={styles.start_credit__title}>Рассрочка без&nbsp;процентов<br/> от&nbsp;699&nbsp;<span>₽</span>&nbsp;в&nbsp;месяц</div>
                            <div className={styles.start_credit__text}>
                                <p>Вы можете оформить заказ в&nbsp;рассрочку. На&nbsp;6&nbsp;месяцев без&nbsp;процентов и&nbsp;предварительного взноса (только&nbsp;для&nbsp;граждан&nbsp;РФ).</p>
                            </div>
                        </div>
                        <div className={styles.start_parts}>
                            <div className={styles.start_parts_mark}><img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/3/mark2.png" alt="" /></div>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/3/start_icon_black.png" alt="" />
                            <div className={styles.start_parts__title}>Оплатите Долями<br/> без&nbsp;банка, переплат и&nbsp;рисков</div>
                            <div className={styles.start_parts__text}>Не надо ждать до&nbsp;зарплаты — разделите платёж <strong>на&nbsp;4&nbsp;части от 1&nbsp;047,5&nbsp;<span>₽</span></strong>. Всего на&nbsp;6&nbsp;недель (только&nbsp;для&nbsp;граждан&nbsp;РФ).</div>
                            <div className={styles.start_parts_block}>
                                <div className={styles.start_parts_item}>
                                    <div className={styles.start_parts_number}><p>1</p></div>
                                    <p>от 1&nbsp;047,5&nbsp;<b>₽</b> <span>сегодня</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number2].join(' ')}><p>2</p></div>
                                    <p>от 1&nbsp;047,5&nbsp;<b>₽</b> <span>через 2&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number3].join(' ')}><p>3</p></div>
                                    <p>от 1&nbsp;047,5&nbsp;<b>₽</b> <span>через 4&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number4].join(' ')}><p>4</p></div>
                                    <p>от 1&nbsp;047,5&nbsp;<b>₽</b> <span>через 6&nbsp;недель</span></p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className={styles.pay}>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Тинькофф-рассрочка</div>
                                <div className={styles.pay_item_text}>Платите потом&nbsp;&mdash; первый платёж через 30&nbsp;дней <b>от&nbsp;665&nbsp;₽</b> в&nbsp;месяц. Без переплат, без процентов, до&nbsp;6&nbsp;месяцев. Только для граждан&nbsp;РФ.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_1.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>По QR-коду</div>
                                <div className={styles.pay_item_text}>Отсканируйте камерой телефона QR-код и&nbsp;перейдите по&nbsp;ссылке в&nbsp;приложение банка, чтобы подтвердить оплату. Удобно со&nbsp;SberPay.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_2.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Долями</div>
                                <div className={styles.pay_item_text}>Разделите платёж на&nbsp;4&nbsp;равные части <b>от&nbsp;997,5&nbsp;₽</b>. Всего на&nbsp;6&nbsp;недель. Без ожидания одобрения банка, переплат и&nbsp;рисков.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_3.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>PayPal</div>
                                <div className={styles.pay_item_text}>Введите только электронный адрес и&nbsp;пароль&nbsp;&mdash; не&nbsp;нужно вводить номер карты. Безопасный способ платы, доступный клиентам банков не&nbsp;РФ.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_6.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Картой банка&nbsp;РФ<br/> или зарубежного</div>
                                <div className={styles.pay_item_text}>Можете пользоваться своей картой, где&nbsp;бы вы&nbsp;ни&nbsp;находились. Для оплаты картой иностранного банка, выберите оплату через Stripe.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_4.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Наличными в&nbsp;терминалах</div>
                                <div className={styles.pay_item_text}>Оплатите ваш заказ наличными через ближайший терминал. Быстро и&nbsp;удобно, без использования кредитных карт и&nbsp;необходимости раскрывать личные данные.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_5.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
        </div>
        </>
    )
}

export default Start;