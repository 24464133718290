import Countdown from 'react-countdown';
import styles from './promo.module.css';

const Promo = ({scroll, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_block}>
                    <div className={styles.promo_pretitle}>Летний фитнес-режим ON <img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini/promo_btn.png" alt="" /></div>
                    <div className={styles.promo_title}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini/promo_logo.svg" alt=''/> <span>ФЕСТ</span></div>
                    <div className={styles.promo_icons}>
                        <div className={styles.promo_icon}>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini2/promo1.png" alt="" /></div>
                            <p>8&nbsp;спортивных программ Кати Усмановой</p>
                        </div>
                        <div className={styles.promo_icon}>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini2/promo2.png" alt="" /></div>
                            <p>Выгода 92%&nbsp;+ доступ на&nbsp;1&nbsp;год</p>
                        </div>
                        <div className={styles.promo_icon}>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini2/promo3.png" alt="" /></div>
                            <p>6&nbsp;бонусов, чтобы поддержать результат</p>
                        </div>
                    </div>
                    <div className={styles.promo_when}>Старт — сразу после оплаты</div>
                    <div className={styles.start_countdown}>
                        <p>ПРЕДЛОЖЕНИЕ<br/> СГОРИТ ЧЕРЕЗ:</p>
                        <div className={styles.start_countdown_timer}>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                    </div>
                    <div className={styles.promo_btn} onClick={scroll}><p>ЗАБРАТЬ ПРОГРАММЫ</p></div>
                </div>
            </div>
            {/* <div className={styles.promo_img}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini/promo_img.jpg" alt="" />
                <p>РОЗЫГРЫШ<br/> iPhone 14 PRO</p>
                <span>среди всех<br/> участниц</span>
            </div> */}
        </div>
    )
}

export default Promo;