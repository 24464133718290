import Countdown from 'react-countdown';
import styles from './promo.module.css';

const Promo = ({scroll, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_block}>
                    <div className={styles.promo_titles}>
                        <div className={styles.promo_pretitle}>только 24&nbsp;часа</div>
                        <div className={styles.promo_title}>СЧАСТЛИВЫЕ ЧАСЫ</div>
                        <div className={styles.promo_subtitle}>Сделайте свои ягодицы<br/> центром восхищения всех вокруг</div>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/woweffect/promo_model_mob.png" alt="" />
                    </div>
                    <ul>
                        <li>-&nbsp;5&nbsp;спортивных программ Кати Усмановой</li>
                        <li>-&nbsp;Выгода&nbsp;90%</li>
                        <li>-&nbsp;Доступ на&nbsp;1&nbsp;год</li>
                    </ul>
                    <div className={styles.promo_start}>Стартуем сразу после оплаты</div>
                    <div className={styles.promo_btns}>
                        <div className={styles.start_countdown}>
                            <p>Успей забрать программы<br/> по&nbsp;специальной цене!</p>
                            <div className={styles.start_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
                        <div className={styles.promo_btns2}>
                        <div className={styles.promo_btn} onClick={scroll}><p>ЗАБРАТЬ ПРОГРАММЫ</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Promo;