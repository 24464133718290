import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Slim from './components/Slim';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_01_Slim_tg = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    return (
      <>
        <DocumentTitle value='5 тренировок для похудения'/>
        <DocumentDescription value='5 тренировок для похудения' />
        <div className='onepage_fitmission'>
            <Slim />
            <Footer version={4} isColor={'#131313'} />
        </div>
      </>
    );
};