import styles from './author.module.css';

const Author = () => {
    return (
        <div className={styles.author}>
            <div className="container">
                <div className={styles.author_block}>
                    <div className={styles.author_img}>
                    </div>
                    <div className={styles.author_info}>
                        <div className={styles.author_pretitle}>В&nbsp;2016 ГОДУ КАТЯ ПЕРВАЯ НА&nbsp;РЫНКЕ</div>
                        <div className={styles.author_title}>ПРЕДЛОЖИЛА СЕГМЕНТИРОВАТЬ ТЕЛО&nbsp;— ЗАПУСТИЛА КУРС<br/> ИДЕАЛЬНЫХ ЯГОДИЦ</div>
                        <div className={styles.author_subtitle}>Уже&nbsp;более 300&nbsp;000&nbsp;участниц тренируются с&nbsp;Катей, ведь&nbsp;она:</div>
                        <ul className={styles.author_list}>
                            <li><b>Создатель GymTeam</b>&nbsp;— платформы с&nbsp;безлимитными тренировками экстра-класса от&nbsp;практикующих экспертов</li>
                            <li><b>Чемпионка России</b> и&nbsp;мира по&nbsp;жиму лёжа</li>
                            <li><b>Профессиональный фитнес-тренер</b> с&nbsp;опытом более&nbsp;15&nbsp;лет</li>
                            <li>Мама 2-х&nbsp;детей. Всего за&nbsp;100&nbsp;дней после первых родов <b>похудела на&nbsp;20&nbsp;кг и&nbsp;вернулась в&nbsp;прежнюю форму</b></li>
                            <li><b>Автор первых в&nbsp;России</b> масштабных марафонов стройности</li>
                            <li><b>Вице-чемпионка мира</b> и&nbsp;чемпионка России по&nbsp;фитнес-бикини</li>
                        </ul>
                        <div className={styles.author_imgmob}><img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/author.png" alt="" /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Author;