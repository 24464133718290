import styles from './promo.module.css';

const Promo = () => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_block}>
                    <div className={styles.promo_pretitle}><img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka/promo_icon_1.svg" alt="" /><span>Подведение итогов открытого голосования</span></div>
                    <div className={styles.promo_title}>МИСС<br/> САФАРИ 2023</div>
                    <div className={styles.promo_subtitle}>Вы выбрали победительниц для&nbsp;каждой номинации<br/> с&nbsp;самыми крутыми «До/После»</div>
                    <div className={styles.promo_who}>Поздравляем<br/> наших победительниц!</div>
                </div>
            </div>
        </div>
    )
}

export default Promo;