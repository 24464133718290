import styles from './gift.module.css';

const Gift = ({scroll}) => {
    return (
        <div className={styles.gift}>
            
                <div className={styles.gift_title}>Дарим огненные<br/> бонусы</div>
                <div className={styles.gift_subtitle}>за&nbsp;покупку фитнес-программ</div>

                <div className={styles.gift_block}>
                    <div className={styles.gift_item}>
                        <div className={styles.gift_slide}>
                            <div className={styles.gift_slide__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/summer/gift1.png" alt="" />
                            </div>
                            <div className={styles.gift_slide__info}>
                                <div className={styles.gift_slide__bef}>Сборник</div>
                                <div className={styles.gift_slide__text}>Чек-лист динамики веса и&nbsp;питания</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.gift_item}>
                        <div className={styles.gift_slide}>
                            <div className={styles.gift_slide__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/summer/gift2.png" alt="" />
                            </div>
                            <div className={styles.gift_slide__info}>
                                <div className={styles.gift_slide__bef}>Инструкция</div>
                                <div className={styles.gift_slide__text}>Cоздание тонкой талии с&nbsp;разбором мифов</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.gift_item}>
                        <div className={styles.gift_slide}>
                            <div className={styles.gift_slide__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/summer/gift3.png" alt="" />
                            </div>
                            <div className={styles.gift_slide__info}>
                                <div className={styles.gift_slide__bef}>Гайд</div>
                                <div className={styles.gift_slide__text}>Дисциплина: как превратить спорт в&nbsp;привычку и&nbsp;не&nbsp;сдаться на&nbsp;третий день</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.gift_item}>
                        <div className={styles.gift_slide}>
                            <div className={styles.gift_slide__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/summer/gift4.png" alt="" />
                            </div>
                            <div className={styles.gift_slide__info}>
                                <div className={styles.gift_slide__bef}>Путеводитель</div>
                                <div className={styles.gift_slide__text}>Про метаболизм</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.gift_item}>
                        <div className={styles.gift_slide}>
                            <div className={styles.gift_slide__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/summer/gift5.png" alt="" />
                            </div>
                            <div className={styles.gift_slide__info}>
                                <div className={styles.gift_slide__bef}>Сборник</div>
                                <div className={styles.gift_slide__text}>Вкусные завтраки на&nbsp;каждый день</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.gift_item}>
                        <div className={styles.gift_slide}>
                            <div className={styles.gift_slide__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/summer/gift6.png" alt="" />
                            </div>
                            <div className={styles.gift_slide__info}>
                                <div className={styles.gift_slide__bef}>Инструкция</div>
                                <div className={styles.gift_slide__text}>Как вернуться к&nbsp;тренировкам после перерыва</div>
                            </div>
                        </div>
                    </div>
            </div>

            <div className={styles.promo_btn} onClick={scroll}><p>ЗАБРАТЬ ПРОГРАММЫ И&nbsp;БОНУСЫ</p></div>
        </div>
        
    )
}

export default Gift;