import Countdown from 'react-countdown';
import { SalesWidget } from "@com.marathonium/web2-ui";
import styles from './start2.module.css';

const Start2 = ({date, renderer}) => {
    return (
        <div className={('scrollhere ') + styles.start}>
            <div className="container">
                <div className={styles.start_block}>
                    <div className={styles.start_pretitle}>Такого больше не&nbsp;будет!</div>
                    <div className={styles.start_title}>Заберите 10&nbsp;спортивных программ<br/> Кати Усмановой и&nbsp;создайте свою<br/> идеальную фигуру сами</div>
                    <div className={styles.start_subtitle}>Стоимость&nbsp;— <span>4&nbsp;190&nbsp;₽</span> вместо <i>73&nbsp;300&nbsp;₽</i></div>
                    <div className={styles.start_date}><div className={styles.start_date_b}></div>Доступ&nbsp;— 1&nbsp;год</div>
                    <div className={styles.start_countdown}>
                        <p>Спецпредложение сгорит через:</p>
                        <div className={styles.start_countdown_timer}>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                    </div>
                    <div className="perezagruzka_start">
                        <SalesWidget id={window.lps_settings.forms.meltingprices_id} />
                    </div>
                    <div className={styles.start_credit}>
                        <p>Платите с&nbsp;любой карты&nbsp;— выпущенной российским или&nbsp;иностранным банком.</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/inshape/tnkf.svg" alt="" />
                        <div className={styles.start_credit__title}>Рассрочка без&nbsp;процентов от&nbsp;669&nbsp;₽&nbsp;в&nbsp;месяц</div>
                        <div className={styles.start_credit__text}>
                            <p>Вы можете оформить заказ в&nbsp;рассрочку.<br/> На&nbsp;6&nbsp;месяцев без&nbsp;процентов и&nbsp;предварительного взноса (только&nbsp;для&nbsp;граждан&nbsp;РФ).</p>
                        </div>
                    </div>
                    <div className={styles.start_parts}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/pregnancy/start_icon.png" alt="" />
                        <div className={styles.start_parts__title}>Оплатите Долями<br/> без&nbsp;банка, переплат и&nbsp;рисков</div>
                        <div className={styles.start_parts__text}>Не надо ждать до&nbsp;зарплаты — разделите платёж <strong>на&nbsp;4&nbsp;части<br/> от&nbsp;1&nbsp;047,5&nbsp;₽</strong>. Всего на&nbsp;6&nbsp;недель.</div>
                        <div className={styles.start_parts_block}>
                            <div className={styles.start_parts_item}>
                                <div className={styles.start_parts_number}><p>1</p></div>
                                <p>от&nbsp;1&nbsp;047,5&nbsp;₽<br/> <span>сегодня</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number2].join(' ')}><p>2</p></div>
                                <p>от&nbsp;1&nbsp;047,5&nbsp;₽<br/> <span>через 2&nbsp;недели</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number3].join(' ')}><p>3</p></div>
                                <p>от&nbsp;1&nbsp;047,5&nbsp;₽<br/> <span>через 4&nbsp;недели</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number4].join(' ')}><p>4</p></div>
                                <p>от&nbsp;1&nbsp;047,5&nbsp;₽<br/> <span>через 6&nbsp;недель</span></p>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    )
}

export default Start2;