import { useState } from 'react';
import styles from './faq.module.css';

const Faq = ({scroll}) => {
    const faqs = [
        {question: '⏰ Тренировки проходят в какое-то определенное время?', answer: 'Нет, все уроки заранее записаны и будут открываться последовательно на 24 часа. Удобное время для занятия вы выбираете сами.'},
        {question: '🤸‍♀️ Я никогда не занималась. Подойдут ли мне фитнес-марафоны и курсы?', answer: 'Да! Все тренировки (кроме Курса по созданию идеальной попы 3.0) проходят в формате «повторяй за мной» — Катя Усманова подробно объясняет каждое упражнение, а затем выполняет его вместе с вами. Чтобы подобрать оптимальную нагрузку, ориентируйтесь на обозначения: light — для новичков, medium — средний уровень, hard — тяжелый.'},
        {question: '🥊 Какой инвентарь нужен для тренировок?', answer: 'Коврик, набор фитнес-резинок, разборные гантели и утяжелители — маст-хэв набор для тренировок, который можно купить в любом спортивном магазине. Ваш мини-спортзал поместится в шкаф, и хватит его на всю жизнь!'},
        {question: '🧐 Можно ли заменить инвентарь или обойтись без него?', answer: <div><p>Да. Есть программы, рассчитанные на тренировки без инвентаря, например, марафон стройности 1.0 или марафон плоского живота.</p><p>Если у вас еще нет инвентаря, но тренироваться уже очень хочется, используйте альтернативу: бутылки с водой или со средством для стирки разного объема, рюкзак с книгами. Подробнее об альтернативах можно узнать на страницах марафонов.</p></div>},
        {question: '🌎 Я живу не в России. Я смогу заниматься?', answer: 'Да. Марафоны и курсы доступны в любой точке мира. Вам понадобится смартфон, планшет или компьютер с доступом в Интернет.'},
        {question: '💸 Можно ли вернуть деньги, если не смогу или передумаю участвовать?', answer: <p>Да, в течение первых трех дней со старта вашего потока можно вернуть денежные средства в полном объеме, обратившись в поддержку <a href="mailto:support@gymteam.ru">support@gymteam.ru</a></p>}
    ];
    
    const [clicked, setClicked] = useState("0")

    const handleToggle = (index) => {
        if (clicked === index) {
            setClicked("0");
        } else {
            setClicked(index);
        }
    }

    return(
        <div className={styles.faq} >
          <div className="container">
            
            <div className={styles.faq_title}>ВОПРОСЫ И ОТВЕТЫ</div>
            <div className={styles.accordion}>
              {faqs.map((faq, index) => (
                <div key={faq.answer} className={clicked == index + 1 ? styles.active : ''}>
                  <p onClick={()=>handleToggle(index+1)} className={styles.question}>{faq.question} </p>
                  <p className={styles.answer}>{faq.answer}</p>
                </div>
              ))}
            </div>
            <button className={styles.faq_btn} onClick={scroll}><p>Выбрать программу <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/arrow_right.svg" alt="" /></p></button>
          </div>
        </div>
    );
};

export default Faq;