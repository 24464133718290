import { useState } from 'react';
import styles from './quiz.module.css';
import { useParams } from 'react-router-dom';

const Quiz = () => {
    const [count, setCount] = useState(0)
    const [prepare, setPrepare] = useState(false)
    const [list, setList] = useState([
        {id: 0, name: <>Какой у&nbsp;вас рост?</>, number: null},
        {id: 1, name: <>Какой у&nbsp;вас вес (кг)?</>, number: null},
        {id: 2, name: <>Какой у&nbsp;вас целевой вес (кг)?</>, number: null},
        {id: 3, name: <>Сколько вам лет?</>, text: <>Это необходимо для точного определения уровня вашей физической подготовки.<br/> Люди старшего возраста, как правило, имеют больше жировой ткани, чем молодые люди с&nbsp;таким&nbsp;же ИМТ.</>, number: null},
    ])

    const { q4, q5 } = useParams();

    function changeFunc(e, idx) {
        setList(prevState => 
            prevState.map(item => 
              item.id === idx 
                ? { ...item, number: e.target.value }
                : item
            )
        )
    }

    function next(e) {
        e.preventDefault()
        if(count + 1 < list.length) {
            e.preventDefault()
            setCount(count + 1)
        } else {
            setPrepare(true)
        }
    }

    for(let i = 0; i < 3; i++) {
        document.documentElement.style.cssText += `--number${i+1}: ${(Math.random() * 2) + 3}s;`;
    }

    return (
        <div className={styles.quiz}>
            <div className={`${styles.quiz_top} ${prepare && styles.quiz_topcenter}`}>
                {!prepare && <div className={count <= 0 ? styles.back : styles.back_active} onClick={()=>setCount(count - 1)}><img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/arrow.svg" alt="" /></div>}
                
                <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/logo.svg" alt="" />
            </div>
            <div className={styles.quiz_main}>
                {!prepare ? 
                <>
                {list.map((i, idx)=>{
                    return(
                        <>
                        {idx == count &&
                            <div className={styles.block}>
                            <p className={styles.name}>{i.name}</p>
                            {i.text && <p className={styles.text}>{i.text}</p>}
                            <input className={styles.input} type="number" value={i.number} onChange={(e) => changeFunc(e, idx)} />
                            <a href={`/l/quiz-test/step-1/price/${list[1].number}&${list[2].number}&${list[3].number}&${q4}&${q5}`} className={i.number ? styles.btn_active : styles.btn} onClick={(e)=>next(e)}>Далее</a>
                            </div>
                        }
                        </>
                    )
                })}
                </>
                :
                <>
                <div className={styles.prep_title}>Готовим ваш персональный<br/> план тренировок</div>
                <div className={styles.prep_imgs}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/var1.png" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/var4.png" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/var3.png" alt="" />
                </div>
                <div className={`${styles.prep_item} ${styles.prep_item1}`}>
                    <div className={styles.prep_item_info}>
                        <div className={styles.prep_item_title}>предпочтения...</div>
                        <div className={styles.prep_item_count}><span></span>%</div>
                    </div>
                    <div className={styles.prep_item_line}>
                        <div className={styles.prep_item_circle}></div>
                        <div className={styles.prep_item_bg}></div>
                    </div>
                </div>
                <div className={`${styles.prep_item} ${styles.prep_item2}`}>
                    <div className={styles.prep_item_info}>
                        <div className={styles.prep_item_title}>анализ тела...</div>
                        <div className={styles.prep_item_count}><span></span>%</div>
                    </div>
                    <div className={styles.prep_item_line}>
                        <div className={styles.prep_item_circle}></div>
                        <div className={styles.prep_item_bg}></div>
                    </div>
                </div>
                <div className={`${styles.prep_item} ${styles.prep_item3}`}>
                    <div className={styles.prep_item_info}>
                        <div className={styles.prep_item_title}>уровень активности...</div>
                        <div className={styles.prep_item_count}><span></span>%</div>
                    </div>
                    <div className={styles.prep_item_line}>
                        <div className={styles.prep_item_circle}></div>
                        <div className={styles.prep_item_bg}></div>
                    </div>
                </div>
                <a href={`/l/quiz-test/step-1/price/${list[1].number}&${list[2].number}&${list[3].number}&${q4}&${q5}`} className={styles.prep_btn}>Продолжить</a>
                </>
                }
                
            </div>            
        </div>
    )
}

export default Quiz;