import styles from './onepage.module.css';

const Slim = () => {
    return (
        <div className={`${styles.page} ${styles.page_slim}`}>
            <div className="container">
                <div className={styles.pretitle}>Бесплатно</div>
                <div className={styles.title}>5&nbsp;тренировок<br/> для&nbsp;похудения</div>
                <div className={styles.text}>Персональная методика Кати&nbsp;Усмановой,<br/> чтобы&nbsp;сбросить лишние килограмы<br/> и&nbsp;полюбить спорт</div>
                <div className={styles.block}>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/fitmission/onepage/slim1.png" alt="" />
                        </div>
                        <div className={styles.item_text}>
                            <b>Старт</b> сразу<br/> после подписки
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/fitmission/onepage/slim2.png" alt="" />
                        </div>
                        <div className={styles.item_text}>
                            <b>Продуманная система,</b><br/> нацеленная на&nbsp;результат
                        </div>
                    </div>
                </div>
                <div className={styles.btns}>
                    <div className={styles.btns_text}>Всего 5&nbsp;тренировок<br/> в&nbsp;домашних условиях<br/> до&nbsp;получения первого<br/> результата</div>
                    <a className={styles.btn} href="https://salebot.pro/md/weight_loss_tg" target='_blank'>получить бесплатно</a>
                </div>
            </div>
        </div>
    )
}

export default Slim;