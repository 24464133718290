import { useEffect, useState } from "react";
import { LoginWidget, checkUserInGroup, addUserToGroup, getUserCountByGroup, ShowForSegment, HideForSegment, checkUserInSegment, getUser } from "@com.marathonium/web2-ui";
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";

import './styles.css';
import styles from './prizes.module.css';
import Hidden from "./hidden";
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import Already from "./already";
import Footer from "../../components/Footer";


const prizes = [
    {
        id: 'fef9f025-d9ca-445b-a1cd-e14a26b61056',
        name: 'Денежный приз',
        description: <><p>Вы выиграли <b>4 000 рублей</b> на карту. Купите красивое платье или соблазнительное бельё, сходите на массаж или в салон красоты.</p></>,
        limit: window.lps_settings.limits.prize1,
    },
    {
        id: 'a06f929f-d25d-4e54-8b99-f896fc69602f',
        name: 'Кухонные электронные весы',
        description: <><p>Вы выиграли <b>кухонные электронные весы</b>. Это ваш незаменимый помощник, если вы поддерживаете дефицит питания или просто любите точные дозировки.</p></>,
        limit: window.lps_settings.limits.prize2,
    },
    {
        id: '58a75051-e7df-4218-af70-00c1abdd05d3',
        name: 'Apple Watch SE 2023',
        description: <><p>Вы выиграли <b>Apple Watch SE 2023</b>! Это мощное устройство для здорового образа жизни. Когда вы надеваете эти часы, они становятся настоящим партнёром по фитнесу, который учитывает каждое ваше движение и сообщает полезные данные о вашем здоровье.</p></>,
        limit: window.lps_settings.limits.prize3,
    },
    {
        id: 'b5da3a6c-e427-4eca-838f-b068b2aabc6f',
        name: 'Наушники Apple AirPods Max',
        description: <><p>Вы выиграли <b>наушники Apple AirPods Max</b>. Стильный аксессуар с нереальным качеством звука. Надевайте на тренировку, в дорогу или медитируйте с полным погружением.</p></>,
        limit: window.lps_settings.limits.prize4,
    },
    {
        id: '1b742470-e0e3-4839-a393-7860c9ed8e3c',
        name: 'Ролл для МФР',
        description: <><p>Вы выиграли <b>ролл для МФР</b>. Это массажный ролик для фитнеса, йоги или пилатеса — ваш новый фитнес-помощник для красивого тела. Он поможет расслабить мышцы, снять напряжение, проработать проблемные зоны и добавит видимый эффект вашим тренировкам.</p></>,
        limit: window.lps_settings.limits.prize5,
    },
    {
        id: 'fb8a8c86-21af-4b23-a60b-0ef36934838b',
        name: 'Беспроводная портативная колонка JBL Clip 4',
        description: <><p>Вы выиграли <b>беспроводную портативную колонку JBL Clip 4</b>. Это небольшая и компактная портативная колонка с высоким качеством звучания. Внешне она похожа на большой брелок и занимает мало места. Теперь вы сможете любое место превратить в вечеринку, сделать встречи с друзьями ярче и тренироваться дома под классный звук.</p></>,
        limit: window.lps_settings.limits.prize6,
    },
    {
        id: '492c1c24-91c8-4aad-b632-795520e9d877',
        name: 'Набор фитнес-резинок из 6 шт.',
        description: <><p>Вы выиграли <b>набор фитнес-резинок</b>. Ваш новый фитнес-помощник для красивого тела. Резинки для нагрузок разной интенсивности помогут разнообразить привычные тренировки и заниматься везде, где вам удобно. Набор настолько компактен, что не займет много места в рюкзаке или чемодане.</p></>,
        limit: window.lps_settings.limits.prize7,
    },
    {
        id: '7dbdf7d9-42d7-4d87-9b7e-7a05ebf90ee6',
        name: 'Фитнес-коврик',
        description: <><p>Вы выиграли <b>фитнес-коврик</b>. Он поможет вам провести тренировку максимально удобно и комфортно. Собирайтесь на тренировку с удовольствием.</p></>,
        limit: window.lps_settings.limits.prize8,
    },
    {
        id: 'b713deab-5be1-47a9-ad95-4982fffce4e5',
        name: 'Фен-стайлер Dyson',
        description: <><p>Вы выиграли <b>фен-стайлер Dyson</b>. Это мечта всех девушек! Мощный, но при этом тихий, невероятно удобный и качественный фен. Теперь вы сможете сушить волосы в 2 раза быстрее, делать шикарные укладки и всегда выглядеть отлично.</p></>,
        limit: window.lps_settings.limits.prize9,
    },
    {
        id: 'ef1e86a5-f8b7-4c7b-af73-e46faf6a648e',
        name: 'Электронный гриль Tefal Optigrill+ GC712D34',
        description: <><p>Вы выиграли <b>электронный гриль Tefal Optigrill+ GC712D34</b>. Считайте, что наняли профессионального шеф-повара на дом. Его сенсор определяет, сколько кусочков и какой толщины вы положили, и автоматически подбирает оптимальную температуру. Вам будет нужно только выбрать желаемый режим на дисплее.</p></>,
        limit: window.lps_settings.limits.prize10,
    },
    {
        id: '9f95375c-b4b9-4e94-b2d7-202a2618d5f5',
        name: 'Бонус «Детокс» с Катей Усмановой',
        description: <><p>Вы выиграли <b>марафон «Детокс» с Катей Усмановой</b>. Информация поможет пересмотреть свой рацион, понять, какие продукты вызывают тяжесть и как быстро очистить организм. Добавьте себе лёгкости и воздушности в самоощущении!</p></>,
        limit: window.lps_settings.limits.prize11,
    },
    {
        id: '9693bca6-1481-42bf-9b4d-179a0351b93b',
        name: 'Книга рецептов Кати Усмановой',
        description: <><p>Вы выиграли <b>книгу рецептов Кати Усмановой</b>. Это сборник уникальной информации с примерами рационов для разного диапазона калорий, подробными рецептами блюд, описанием бжу и калоража каждого блюда, лайфхаками по приготовлению еды и другой полезной информацией.</p></>,
        limit: window.lps_settings.limits.prize12,
    },
    {
        id: '2f81d681-875c-4b97-88f7-4889c888bcbe',
        name: 'Путеводитель по продуктам для сбалансированного питания',
        description: <><p>Вы выиграли <b>путеводитель по продуктам для сбалансированного питания</b>. Это отличный инструмент, чтобы разобраться, какие продукты действительно полезны и их можно включать в рацион для сбалансированного питания.</p></>,
        limit: window.lps_settings.limits.prize13,
    },
    {
        id: 'c7e8e06f-9af3-4a53-91f6-e17fe4423d72',
        name: 'Сборник «15 рецептов ПП-запеканок»',
        description: <><p>Вы выиграли <b>сборник «15 рецептов ПП-запеканок»</b>. Этот сборник станет вашим полноценным помощником на кухне и поможет питаться сытно и сбалансированно. Здесь вы найдёте рецепты на любой вкус.</p></>,
        limit: window.lps_settings.limits.prize14,
    },
]

export const L2024_01_Fitbox_2 = () => {
    const [modalActive, setModalActive] = useState(false)
    const [activePrize, setActivePrize] = useState({})
    const [isSelected, setIsSelected] = useState(false)
    const [alreadyShow, setAlreadyShow] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    function randomNumber(min, max) { 
        return Math.round(Math.random() * (max - min) + min);
    }

    useEffect(()=> {
        {prizes.map((i, idx)=>{
            checkUserInGroup(i.id).then(res => {
                // console.log(res == null)
                if(res !== null) {
                    // console.log(res)
                    setAlreadyShow(true)
                }
            })
            if(idx + 1 == prizes.length) {
                setTimeout(()=>{
                    setIsLoading(false)
                }, 2000)
            }
        })}
        
    }, [])
    
    function givePrize() {
        setIsSelected(true)
        
        checkUserInSegment(window.lps_settings.segments.segment_fitbox_prizes_secondwave).then(res => {
            // console.log(res)
        if (!res) {
            return false;
        }
            Promise.all(prizes.map((i,idx) => getUserCountByGroup(i.id))).then((values) => {

                const availablePrizes = prizes.filter((i, idx) => {
                    return values[idx] < i.limit;
                });
                
                if (availablePrizes.length === 0) {
                    return false;
                }
                
                const randomPriceIdx = randomNumber(0, availablePrizes.length - 1);
                const prize = availablePrizes[randomPriceIdx];

                addUserToGroup(prize.id).then((res)=>{
                    setModalActive(true)
                    setActivePrize(prize) 
                })                           

                return prize;
              })
            //   .then((prize) => console.log(prize));
        
      });
    }

    function Boxes() {
        return (
            <>
                {!modalActive ?
                                <>
                                    <div className={styles.title1}>ВАС ЖДЁТ ГАРАНТИРОВАННЫЙ&nbsp;ПРИЗ</div>
                                    <div className={styles.title2}>ПРИЗОВЫЕ БОКСЫ</div>
                                    <div className={styles.title3}>Прямо сейчас открывайте один из&nbsp;призовых<br/> боксов <b>и&nbsp;получайте свой подарок!</b></div>
                                    <div className={`${styles.prizes_block} ${isSelected && styles.prizes_block__active}`}>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/fitbox/box.png" alt="" />
                                        </div>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/fitbox/box.png" alt="" />
                                        </div>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/fitbox/box.png" alt="" />
                                        </div>
                                    </div>

                                    <div className={styles.ucan}>
                                        <div className={`${styles.promo_img} ${styles.promo_img_1}`}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_1.png" alt="" />
                                            <p>Apple Watch SE 2023</p>
                                        </div>
                                        <div className={`${styles.promo_img} ${styles.promo_img_2}`}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_2.png" alt="" />
                                            <p>Фен-стайлер Dyson</p>
                                        </div>
                                        <div className={`${styles.promo_img} ${styles.promo_img_6}`}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_6.png" alt="" />
                                            <p>Apple AirPods Max</p>
                                        </div>
                                        <div className={`${styles.promo_img} ${styles.promo_img_7}`}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_7.png" alt="" />
                                            <p>Электронный гриль</p>
                                        </div>
                                        <div className={`${styles.promo_img} ${styles.promo_img_8}`}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/boxes/ucan_8.png" alt="" />
                                            <p>Денежные призы</p>
                                        </div>
                                    </div>
                                    </>
                                    :
                                    <>
                                    <div className={styles.win}>
                                        <div className={styles.win_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/fitbox/box_opened.png" alt="" /></div>
                                        <div className={styles.win_name}>
                                            {activePrize.name}
                                        </div>
                                        <div className={styles.win_desc}>
                                            <p style={{marginBottom: 10}}><b>Поздравляем!</b></p>
                                            {activePrize.description}
                                        </div>
                                        <div className={styles.win_more}>Подробная информация о&nbsp;получении подарка у&nbsp;вас&nbsp;на&nbsp;почте.</div>
                                    </div>
                                    </>
                            }
            </>
        )
    }

    function LoginForm() {
        return (
          <>
            <Authorized>
                <HideForSegment id={window.lps_settings.segments.segment_fitbox_prizes_secondwave}>
                    <Hidden/>
                </HideForSegment>

                <ShowForSegment id={window.lps_settings.segments.segment_fitbox_prizes_secondwave}>
                
                {isLoading
                ? <div className={styles.loader_block}><div className={styles.loader}><div></div><div></div><div></div><div></div></div></div>
                : <>
                    {alreadyShow
                        ? <Already />
                        : <Boxes/>
                    }
                  </>
                }
                                
                </ShowForSegment>
            
            </Authorized>
            <Anonymous>
                <div className={styles.prizes_ghost_title}>Чтобы получить доступ к&nbsp;боксам нужно авторизоваться</div>
                <div className="velvet_prizes_login"><LoginWidget /></div>
            </Anonymous>
          </>
        );
      }

    return (
        <div className={styles.cursor}>
        <DocumentTitle value='Призовые боксы'/>
        <DocumentDescription value='' />
        <div className={styles.prizes}>
            <div className={styles.prizes_main}>
               <LoginForm />
            </div>
            
        </div>
        <Footer version={3} />
        </div>
    );
};