import Countdown from 'react-countdown';
import styles from './promo.module.css';

const Promo = ({scroll, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.header}>
                    <div className={styles.header_logo}><img src="https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/Fire.svg" alt="" /><span>Победительницы выбраны</span></div>
                </div>

                <div className={styles.main}>
                    <div className={styles.left_main}>
                        <div className={styles.main_top}>
                            <div className={styles.main_top_left}>
                                <div className={styles.mtl_1}>Мисс<br/> Стройность <span>2024</span></div>
                                {/* <div className={styles.mtl_2}>Участвуй в&nbsp;выборе победительниц конкурса на&nbsp;лучшие результаты ДО/ПОСЛЕ по&nbsp;Методу Усмановой</div> */}
                            </div>
                        </div>

                        <div className={styles.main_bottom}>
                            <div className={styles.main_bottom_left}>
                                {/* <img src="https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/promo_1.png" alt="" />
                                <div className={styles.mbl_1}>с&nbsp;5&nbsp;по&nbsp;15 июня</div>
                                <div className={styles.mbl_2}>голосуй и&nbsp;участвуй в&nbsp;выборе победительниц</div> */}
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/Medal_Ribbons_Star.png" alt="" />
                                <div className={styles.mbl_2}>Победительница получит путёвку<br/> в&nbsp;Турцию на&nbsp;двоих, а&nbsp;остальные&nbsp;&mdash;<br/> денежные призы!</div>
                            </div>
                            <div className={styles.cheked_voice}>
                                {/* <img src="https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/promo_1.png" alt="" />
                                <div className={styles.mbl_1}>с&nbsp;5&nbsp;по&nbsp;15 июня</div>
                                <div className={styles.mbl_2}>голосуй и&nbsp;участвуй в&nbsp;выборе победительниц</div> */}
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/heart_mess.png" alt="" />
                                <div className={styles.mbl_2}>Посмотрите, за кого <br/>вы&nbsp;отдали голоса</div>
                            </div>
                            {/* <div className={styles.main_bottom_right}>
                                <div className={styles.mbr}>
                                    <div className={styles.mbr_item}>
                                        <div className={styles.mbr_0}><img src="https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/promo_2.png" alt="" /></div>
                                        <div className={styles.mbr_1}>20&nbsp;финалисток</div>
                                        <div className={styles.mbr_2}>Катя Усманова и&nbsp;команда GymTeam отобрали девушек с&nbsp;лучшими результатами</div>
                                    </div>
                                    <div className={styles.mbr_item}>
                                        <div className={styles.mbr_0}><img src="https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/promo_3.png" alt="" /></div>
                                        <div className={styles.mbr_1}>19&nbsp;июня</div>
                                        <div className={styles.mbr_2}>мы&nbsp;объявим 3-х победительниц, которые получат путёвку в&nbsp;Дубай и&nbsp;встретятся с&nbsp;Катей Усмановой</div>
                                    </div>
                                </div>

                            
                                <div className={styles.main_bottom_right_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/promo_4.png" alt="" />
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {/* <div className={styles.top_fin}> 
                    </div> */}

                </div>
                {/* <div className={styles.title_banner}>
                    Победительницы конкурса
                </div> */}
                <div className={styles.victors}>
                    <div className={styles.banner}>
                        <div className={styles.image}></div>
                        <div className={styles.subtitle}></div>
                        


                    </div>
                </div>
                {/* <div className={styles.mob}>
                    <div className={styles.mob_left}>
                        <div className={styles.main_bottom_left}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/promo_1.png" alt="" />
                            <div className={styles.mbl_1}>с&nbsp;5&nbsp;по&nbsp;15 июня</div>
                            <div className={styles.mbl_2}>голосуй и&nbsp;участвуй в&nbsp;выборе победительниц</div>
                        </div>
                    </div>
                    <div className={styles.mob_right}>
                      
                    </div>
                </div>
                <div className={styles.mob2}>
                    <div className={styles.mob2_i}>
                        <div className={styles.mbr_0}><img src="https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/promo_2.png" alt="" /></div>
                        <div className={styles.mbr_1}>20&nbsp;финалисток</div>
                        <div className={styles.mbr_2}>Катя Усманова и&nbsp;команда GymTeam отобрали девушек с&nbsp;лучшими результатами</div>
                    </div>
                    <div className={styles.mob2_i}>
                        <div className={styles.mbr_0}><img src="https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/promo_3.png" alt="" /></div>
                        <div className={styles.mbr_1}>19&nbsp;июня</div>
                        <div className={styles.mbr_2}>мы&nbsp;объявим 3-х победительниц, которые получат путёвку в&nbsp;Дубай и&nbsp;встретятся с&nbsp;Катей Усмановой</div>
                    </div>
                </div> */}
            </div>
            <div className={styles.mob}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/mob2.png" alt="" />
                </div>
        </div>
        
    )
}

export default Promo;