import { useEffect } from "react";
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Faq from './components/Faq';
import Promo from './components/Promo';
import Footer from '../../components/Footer';
import Programs from './components/Programs';
import Start from './components/Start';
import Reviews from './components/Reviews';
import './style.css';
export const L2023_08_Bestfriends = () => {
    useEffect(() => {
        document.body.id = 'rasprozarka_2305'
        document.body.parentElement.id = 'htmlrasprozarka_2305'
    }, [])

    const scroll = (name) => {
        const section = document.querySelector( name ).offsetTop;
        window.scrollTo({top: section, behavior: "smooth"});
    };

    return (
        <>
        <DocumentTitle value='Признание в любви от GymTeam'/>
        <DocumentDescription value='От йоги до кроссфита, для дома и для зала. 49 программ и 1500 тренировок. Безлимитные занятия с лучшими тренерами в приложении GymTeam.' />
        <div className='bestfriends_23_08'>
            <Promo scroll={() => scroll('.sub_anchor')} />
            <Programs />
            <Start />
            <Reviews />
            <Faq />
            <Footer />
        </div>
        </>
    );
};