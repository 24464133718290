import { SalesWidget } from "@com.marathonium/web2-ui";
import { useInstance } from "@com.marathonium/web2-utils";
import styles from './start.module.css';
import { useState } from "react";

const Start = () => {
    const [isActiveForm, setIsActiveForm] = useState(false)
    const [widget] = useInstance();

    const changeFunc = () => {
        widget.submit()
        setIsActiveForm(true)
        setTimeout(()=>{
            const section = document.querySelector( '.bestfriends_subscribe__active' );
            section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        }, 200)
    }

    return (
        <div className={`${styles.start} sub_anchor`}>
            <div className="container">
                <div className={styles.start_pretitle}>Только 3&nbsp;дня для&nbsp;самых любимых</div>
                <div className={styles.start_title}>Годовой ДОСТУП<br/> К&nbsp;приложению и&nbsp;49&nbsp;программам<br/> С&nbsp;ВЫГОДОЙ&nbsp;50% <span><i>ЗА&nbsp;800&nbsp;₽/мес.</i> 414&nbsp;₽/мес.</span></div>
                {!isActiveForm && <div className={styles.start_btn} onClick={changeFunc}>забрать скидку 50%</div> }
                <div className={`bestfriends_subscribe ${isActiveForm && 'bestfriends_subscribe__active'}`}>
                    <SalesWidget  id={window.lps_settings.forms.bestfriends_id} hook={widget} />
                </div>
                <div className={styles.start_subtitle}>При оплате 4&nbsp;975&nbsp;руб. за&nbsp;год</div>
            </div>
        </div>
    );
};

export default Start;

