import { SalesWidget } from "@com.marathonium/web2-ui";
import Countdown from 'react-countdown';
import styles from './start.module.css';

const Start = ({date, renderer}) => {
    return (
        <div className={('scrollhere ') + styles.start}>
            <div className="container">
                <div className={styles.start_block}>
                    <div className={styles.start_title}>ЗАБИРАЙТЕ фитнес-программы <span>С&nbsp;ВЫГОДОЙ ДО&nbsp;80%</span> ПРЯМО СЕЙЧАС</div>
                    <div className={styles.start_countdown}>
                            <p>Ваша выгода сгорит через</p>
                            <div className={styles.start_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
                    {/* <div className={styles.start_dates}>
                        <div className={styles.start_date}><img src="https://gymteam.kinescopecdn.net/img/l/2023/restart/4/date1.png" alt="" /> Доступ к&nbsp;программам&nbsp;— 6&nbsp;месяцев</div>
                        <div className={styles.start_date}><img src="https://gymteam.kinescopecdn.net/img/l/2023/restart/4/date2.png" alt="" /> Старт&nbsp;— сразу после покупки</div>
                    </div> */}
                    
                    <div className="woweffect2_start">
                        <SalesWidget id={window.lps_settings.forms.woweffect2_id} />
                    </div>
                    <div className={styles.start_credit}>
                        <p>Платите с&nbsp;любой карты&nbsp;— выпущенной российским или&nbsp;иностранным банком.</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/inshape/tnkf.svg" alt="" />
                        <div className={styles.start_credit__title}>Рассрочка без&nbsp;процентов<br/> от&nbsp;582&nbsp;<span>₽</span>&nbsp;в&nbsp;месяц</div>
                        <div className={styles.start_credit__text}>
                            <p>Вы можете оформить заказ в&nbsp;рассрочку.<br/> На&nbsp;6&nbsp;месяцев без&nbsp;процентов и&nbsp;предварительного взноса (только&nbsp;для&nbsp;граждан&nbsp;РФ).</p>
                        </div>
                    </div>
                    <div className={styles.start_parts}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/pregnancy/start_icon.png" alt="" />
                        <div className={styles.start_parts__title}>Оплатите Долями<br/> без&nbsp;банка, переплат и&nbsp;рисков</div>
                        <div className={styles.start_parts__text}>Не надо ждать до&nbsp;зарплаты — разделите платёж <strong>на&nbsp;4&nbsp;части<br/> от&nbsp;872,5&nbsp;<span>₽</span></strong>. Всего на&nbsp;6&nbsp;недель.</div>
                        <div className={styles.start_parts_block}>
                            <div className={styles.start_parts_item}>
                                <div className={styles.start_parts_number}><p>1</p></div>
                                <p>от&nbsp;872,5&nbsp;<b>₽</b><br/> <span>сегодня</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number2].join(' ')}><p>2</p></div>
                                <p>от&nbsp;872,5&nbsp;<b>₽</b><br/> <span>через 2&nbsp;недели</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number3].join(' ')}><p>3</p></div>
                                <p>от&nbsp;872,5&nbsp;<b>₽</b><br/> <span>через 4&nbsp;недели</span></p>
                            </div>
                            <div className={styles.start_parts_item}>
                                <div className={[styles.start_parts_number, styles.start_parts_number4].join(' ')}><p>4</p></div>
                                <p>от&nbsp;872,5&nbsp;<b>₽</b><br/> <span>через 6&nbsp;недель</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Start;