import { addUserToGroup } from "@com.marathonium/web2-ui";
import styles from './nomination.module.css'

export const Nomination = ({nomination, texts}) => {
    return (
        <div className={styles.nomination}>
            <div className="container">
            <div className={styles.nomination_title}>Результаты открытого голосования в номинации <br/> 
            {texts === 1 && <span>Мисс Самый плоский животик</span> }
            {texts === 2 && <span>Мисс Идеальные ягодицы</span> }
            {texts === 3 && <span>Мисс Лучшая просушка</span> }
            {texts === 4 && <span>Мисс Королева стройности</span> }
            </div>
            
            <div className={styles.nomination_block}>
                {nomination.map((i, idx) => {
                    return(
                    <div className={styles.nomination_item} key={i.name}>
                        <div><img src={i.image} alt="" /></div>
                        <div className={styles.nomination_item__name}>{idx+1}. {i.name}</div>
                        <div>Вес: {i.weight}</div>
                        <div>Грудь: {i.chest}</div>
                        <div>Талия: {i.waist}</div>
                        <div>Бедра: {i.hips}</div>
                        <h2>НАБРАНО ГОЛОСОВ: {i.votes}</h2>
                    </div>
                    )
                })}
            </div>

            </div>
        </div>
    );
};