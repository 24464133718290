import { useState } from 'react';
import styles from './video.module.css';

const Video = () => {
    const [play, setPlay] = useState(false);
    const url = play ? "?autoplay=1" : '';
    return(
        <div className={styles.video}>
            {/* <div className="container"> */}
                {/* <div className={styles.video_title}>Смотрите эксклюзивное видео<br/> от&nbsp;Кати и&nbsp;узнайте, какой <span>главный приз</span> она подготовила для участниц!</div> */}

                <div className={styles.video_block}>
                    <div className={'rasprozharka_preview2' + (play ? ' hide' : '')} onClick={() => setPlay(true)}></div>
                    <iframe src={'https://kinescope.io/embed/fTZ5gu89BsdimBNrTEvGZf'+url} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowFullScreen></iframe>
                </div>
            {/* </div> */}
        </div>
    );
};

export default Video;