import styles from './courses.module.css';

const Courses = () => {
    return (
        <div className={styles.marafons}>
            <div className="container">
                <div className={styles.marafons_title}>ОНЛАЙН-КУРСЫ</div>
                <div className={styles.marafons_subtitle}>Выберите долгосрочный план работы над&nbsp;телом. Полное обновление: фигура, мышление, питание.</div>
                <div className={styles.marafons_block}>
                    <div className={styles.marafons_item}>
                        <div className={styles.marafons_item__img}>
                            <a href='/l/coursegym' target="_blank">
                                <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/course_8.jpg" alt="" />
                            </a>
                            <p>Курс для зала <span>НОВИНКА</span></p>
                        </div>
                        <div className={styles.marafons_item__info}>
                            <div className={styles.marafons_item__start}>СТАРТ {window.lps_settings.dates.mainlands}<br/> <span>7&nbsp;990 &#8381;</span></div>
                            {/* <div className={styles.marafons_item__level}>УРОВЕНЬ СЛОЖНОСТИ: ЛЕГКИЙ</div> */}
                            <ul>
                                <li><strong>Длительность:</strong> 5&nbsp;месяцев</li>
                                <li><strong>Инвентарь:</strong> Оборудование тренажерного зала</li>
                                <li><strong>Где заниматься:</strong> в&nbsp;зале</li>
                                <li><strong>График тренировок:</strong> 3&nbsp;раза в&nbsp;неделю</li>
                            </ul>
                            <p>Курс подойдёт тем, <strong>кому не&nbsp;хватает нагрузки в&nbsp;домашних тренировках</strong>. На&nbsp;курсе вы гармонично разовьёте все группы мышц: подтянутая спина, рельефный живот, идеальная попа.</p>
                            <a href='/l/coursegym' target="_blank" className={styles.marafons_btn}>Подробнее</a>
                        </div>
                    </div>
                    <div className={styles.marafons_item}>
                        <div className={styles.marafons_item__img}>
                            <a href='/l/afterpregnancy' target="_blank">
                                <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/courser_2.jpg" alt="" />
                            </a>
                            <p>Фитнес-восстановление<br/> после родов</p>
                        </div>
                        <div className={styles.marafons_item__info}>
                            <div className={styles.marafons_item__start}>СТАРТ {window.lps_settings.dates.mainlands}<br/> <span>7&nbsp;900 &#8381;</span></div>
                            <div className={styles.marafons_item__level}>УРОВЕНЬ СЛОЖНОСТИ: ЛЕГКИЙ</div>
                            <ul>
                                <li><strong>Длительность:</strong> 60&nbsp;дней</li>
                                <li><strong>Занимаемся:</strong> через день, дома или&nbsp;в&nbsp;зале</li>
                                <li><strong>Понадобится:</strong> коврик, 2&nbsp;гантели 2&nbsp;кг, фитнес-резинки с&nbsp;сопротивлением до&nbsp;5&nbsp;кг, по&nbsp;желанию&nbsp;– 2&nbsp;утяжелителя по&nbsp;1,5–3&nbsp;кг</li>
                            </ul>
                            <p>Разработана специально для молодых мам, но&nbsp;<strong>подойдёт всем, кто хочет вернуться в&nbsp;прежнюю форму</strong>. Щадящие комплексы помогут сбросить лишние килограммы, подтянуть мышцы и&nbsp;укрепить мышцы тазового дна. Включает рекомендации по&nbsp;питанию для&nbsp;кормящих мам.</p>
                            <a href='/l/afterpregnancy' target="_blank" className={styles.marafons_btn}>Подробнее</a>
                        </div>
                    </div>
                    {/* <div className={styles.marafons_item}>
                        <div className={styles.marafons_item__img}>
                            <a href="">
                                <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/courser_3.jpg" alt="" />
                            </a>
                            <p>Курс<br/> питания</p>
                        </div>
                        <div className={styles.marafons_item__info}>
                            <div className={styles.marafons_item__start}><a href="">САМОСТОЯТЕЛЬНОЕ ПРОХОЖДЕНИЕ <span>10&nbsp;000 &#8381;</span></a></div>
                            <p>Курс, который полностью заменит диетолога. Цель: научиться составлять рацион питания, избавиться от&nbsp;диетического мышления, похудеть и&nbsp;стать энергичнее. Материалы курса открываются сроком на&nbsp;1&nbsp;год.</p>
                            <div className={styles.marafons_btn}>Подробнее</div>
                        </div>
                    </div> */}
                    <div className={styles.marafons_item}>
                        <div className={styles.marafons_item__img}>
                            <a href='/l/pregnancy' target="_blank">
                                <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/courser_4.jpg" alt="" />
                            </a>
                            <p>Курс<br/> для&nbsp;беременных</p>
                        </div>
                        <div className={styles.marafons_item__info}>
                            <div className={styles.marafons_item__start}>СТАРТ {window.lps_settings.pregnancy.date}<br/> <span>7&nbsp;490 &#8381;</span></div>
                            <ul>
                                <li><strong>Длительность:</strong> 9&nbsp;месяцев</li>
                                <li><strong>Занимаемся:</strong> дома 2-3&nbsp;раза в&nbsp;неделю</li>
                                <li><strong>Понадобится:</strong> коврик, 2&nbsp;гантели 1-2&nbsp;кг, мяч для&nbsp;пилатеса, блоки для&nbsp;йоги</li>
                            </ul>
                            <p>Комплексная программа подготовки для&nbsp;будущих мам&nbsp;— с&nbsp;первого триместра до&nbsp;родов. Включает специальные тренировки, рекомендации по&nbsp;питанию для&nbsp;облегчения токсикоза и&nbsp;отёчности, советы психолога. В&nbsp;ней есть всё, чтобы&nbsp;облегчить роды, <strong>сохранить красоту тела и&nbsp;быстро вернуть прежнюю форму</strong>, наслаждаться материнством.</p>
                            <a href='/l/pregnancy' target="_blank" className={styles.marafons_btn}>Подробнее</a>
                        </div>
                    </div>
                    <div className={styles.marafons_item}>
                        <div className={styles.marafons_item__img}>
                            <a href='/l/fatburning' target="_blank">
                                <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/courser_5_2.jpg" alt="" />
                            </a>
                            <p>Курс<br/> Жиросжигающий</p>
                        </div>
                        <div className={styles.marafons_item__info}>
                            <div className={styles.marafons_item__start}>СТАРТ {window.lps_settings.dates.mainlands}<br/> <span>7&nbsp;000 &#8381;</span></div>
                            <ul>
                                <li><strong>Длительность:</strong> 45 дней</li>
                                <li><strong>Инвентарь:</strong> коврик, набор резинок от&nbsp;минимального сопротивления до&nbsp;максимального, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 1,5-3&nbsp;кг</li>
                                <li><strong>Где заниматься:</strong> дома</li>
                                <li><strong>Группы:</strong> умеренный, интенсивный и&nbsp;силовой классы</li>
                                <li><strong>График тренировок:</strong> 4&nbsp;раза в&nbsp;неделю</li>
                            </ul>
                            <p>Курс для тех, <strong>кто устал от&nbsp;неэффективных диет</strong> и&nbsp;никак не&nbsp;может избавиться от&nbsp;жира в&nbsp;проблемных зонах.</p>
                            <a href='/l/fatburning' target="_blank" className={styles.marafons_btn}>Подробнее</a>
                        </div>
                    </div>
                    <div className={styles.marafons_item}>
                        <div className={styles.marafons_item__img}>
                            <a href='/l/coursepop2' target="_blank">
                                <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/courser_6_2.jpg" alt="" />
                            </a>
                            <p>Курс идеальной<br/> попы 2.0</p>
                        </div>
                        <div className={styles.marafons_item__info}>
                            <div className={styles.marafons_item__start}>СТАРТ {window.lps_settings.dates.mainlands}<br/> <span>6&nbsp;000 &#8381;</span></div>
                            <ul>
                                <li><strong>Длительность:</strong> 6&nbsp;недель</li>
                                <li><strong>Инвентарь:</strong> коврик, штанга/бодибар от&nbsp;8&nbsp;кг, набор резинок от&nbsp;минимального сопротивления до&nbsp;максимального, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по&nbsp;1,5-3&nbsp;кг</li>
                                <li><strong>Где заниматься:</strong> дома или&nbsp;в&nbsp;зале</li>
                                <li><strong>Группы:</strong> классы массонабора</li>
                                <li><strong>График тренировок:</strong> 5&nbsp;раз&nbsp;в&nbsp;неделю</li>
                            </ul>
                            <p>Курс для&nbsp;тех, <strong>кто хочет заметно улучшить форму ягодиц</strong>, не&nbsp;считая калории.</p>
                            <a href='/l/coursepop2' target="_blank" className={styles.marafons_btn}>Подробнее</a>
                        </div>
                    </div>
                    <div className={styles.marafons_item}>
                        <div className={styles.marafons_item__img}>
                            <a href='/l/coursepop3' target="_blank">
                                <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/courser_7_2.jpg" alt="" />
                            </a>
                            <p>Курс идеальной<br/> попы 3.0</p>
                        </div>
                        <div className={styles.marafons_item__info}>
                            <div className={styles.marafons_item__start}>СТАРТ {window.lps_settings.dates.mainlands}<br/> <span>12&nbsp;000 &#8381;</span></div>
                            <ul>
                                <li><strong>Длительность:</strong> 3&nbsp;месяца</li>
                                <li><strong>Инвентарь:</strong> бодибар или штанга весом от&nbsp;8&nbsp;кг, гантели разборные до&nbsp;15&nbsp;кг, утяжелители по&nbsp;1,5-3&nbsp;кг, набор резинок от&nbsp;минимального сопротивления до&nbsp;максимального</li>
                                <li><strong>Где заниматься:</strong> дома или&nbsp;в&nbsp;зале</li>
                                <li><strong>Группы:</strong> классы массонабора</li>
                                <li><strong>График тренировок:</strong> 5&nbsp;раз&nbsp;в&nbsp;неделю</li>
                            </ul>
                            <p>Курс подойдёт тем, кому не&nbsp;хватает объёма в ягодицах, но&nbsp;есть проблемные зоны. На&nbsp;курсе <strong>вы&nbsp;создадите идеальную попу в&nbsp;2&nbsp;этапа</strong>: сначала наберёте мышечную массу, затем&nbsp;«отшлифуете» ягодицы до&nbsp;совершенства.</p>
                            <a href='/l/coursepop3' target="_blank" className={styles.marafons_btn}>Подробнее</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Courses;