import styles from './promo.module.css';

const Promo = ({scroll}) => {
    return (
        <div className={styles.promo}>
            <div className={styles.block}>
                <div className={styles.promo_logo}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bestfriends/2/logo.svg" alt="" /></div>
                <div className={styles.promo_title}>ОБЖИГАЮЩАЯ<br/> СКИДКА<br/> ОТ&nbsp;GYMTEAM</div>
                <div className={styles.promo_subtitle}>Горячее предложение для&nbsp;самых любимых<br/> Фитнес-приложение от&nbsp;Кати&nbsp;Усмановой  </div>
                <div className={styles.promo_btn} onClick={scroll}>забрать скидку&nbsp;50%</div>
                <div className={styles.promo_apps}>
                    <div className={styles.promo_apps__title}>Доступно</div>
                    <div className={styles.promo_apps__block}>
                        <div className={styles.promo_app}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bestfriends/2/app1.png" alt="" /></div>
                        <div className={styles.promo_app}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bestfriends/2/app2.png" alt="" /></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Promo;