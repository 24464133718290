import {DocumentTitle, DocumentDescription} from '../../../components/DocumentMeta';

import styles from './style.module.css';

export const L2023_06_Gymlink = () => {
    return (
        <>
            <DocumentTitle value='Актуальные предложения на фитнес-программы в GymTeam'/>
            <DocumentDescription value='' />
            <div className={styles.main}>
                <div className={styles.logo}><img src="https://gymteam.kinescopecdn.net/img/l/2023/gymlink/logo.png" alt="" /></div>
                <div className={styles.title}>Программы от&nbsp;йоги до&nbsp;кроссфита<br/> от&nbsp;практикующих экспертов.<br/> Для любого возраста.</div>
                <div className={styles.akc}>ТЕКУЩИЕ АКЦИИ:</div>
                <div className={styles.link}><a href="https://gymteam.ru/l/bikinifest?utm_source=ig&utm_medium=main&utm_campaign=bikinifest" target='_blank'>Фитнес-программы Екатерины Усмановой со&nbsp;скидкой&nbsp;94%</a></div>
                <div className={styles.link}><a href="https://gymteam.ru/l/stretching?utm_source=ig&utm_medium=main&utm_campaign=stretching" target='_blank'>Неделя бесплатной растяжки в&nbsp;GymTeam</a></div>
                <div className={styles.site}><a href="https://gymteam.ru/" target='_blank'>Перейти на&nbsp;основной сайт</a></div>
            </div>
        </>
    );
};