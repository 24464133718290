import { SalesWidget } from "@com.marathonium/web2-ui";
import { useInstance } from "@com.marathonium/web2-utils";
import Countdown from 'react-countdown';
import styles from './start.module.css';
import { useState } from "react";

const Start = ({date, renderer}) => {
    const [isModal1, setIsModal1] = useState(false)
    const [isModal2, setIsModal2] = useState(false)
    const [isModal3, setIsModal3] = useState(false)
    const [widget] = useInstance();
    const [widget2] = useInstance();
    const [widget3] = useInstance();

    function open(number) {
        number == 1 && widget.submit().then(()=>{
            setIsModal1(true)
        });
        number == 2 && widget2.submit().then(()=>{
            setIsModal2(true)
        });
        number == 3 && widget3.submit().then(()=>{
            setIsModal3(true)
        });
        
        // setTimeout(()=> {
        //     setIsModal(true)
        // }, 500)
    }

    function close(number) {
        setIsModal1(false)
        setIsModal2(false)
        setIsModal3(false)

        setTimeout(()=> {
            number == 1 && widget.reset();
            number == 2 && widget2.reset();
            number == 3 && widget3.reset();
        }, 500)
    }

    return (
        <>
        <div className={('scrollhere ') + styles.start}>
            <div className="container">
                <div className={styles.info_title} style={{textAlign: 'center', marginBottom: 30}}>Метод Усмановой</div>
                <div className={styles.info2_left}>
                        <p>Продажи закроются через:</p>
                        <Countdown date={Date.now() + date} renderer={renderer} />
                    </div>
                {/* <div className={styles.info}>
                    <div className={styles.info_left}>
                        <div className={styles.info_title}>Успейте записаться сейчас по&nbsp;самой выгодной цене<br/> для первого потока</div>
                        <div className={styles.info_subtitle}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow2.svg" alt="" /> Выберите свой тариф участия.<br/> Платите с&nbsp;любой карты&nbsp;&mdash; выпущенной российским<br/> или иностранным банком.</div>
                    </div>
                </div>
                <div className={styles.info2}>
                    <div className={styles.info2_left}>
                        <p>Продажи закроются через:</p>
                        <Countdown date={Date.now() + date} renderer={renderer} />
                    </div>
                    <div className={styles.info2_right}>
                            <div className={styles.info_right_t1}>стартуем</div>
                            <div>
                                <div className={styles.info_right_t2}>23</div>
                                <div className={styles.info_right_t3}>апреля</div>
                            </div>
                    </div>
                </div> */}
                
                <div className={styles.block}>
                    <div className={styles.block_item}>
                        <div className={styles.block_item_info}>
                            <div className={styles.block_item_title}>Тариф<br/> Базовый</div>
                            <div className={styles.block_item_text}>
                                <p>Доступ к&nbsp;программе на&nbsp;28&nbsp;дней</p>
                                <p>20&nbsp;фитнес-тренировок от&nbsp;Кати</p>
                                <p>4&nbsp;тренировки на&nbsp;укрепление спины от&nbsp;реабилитолога</p>
                                <p>Видео с&nbsp;рекомендациями &laquo;Как подобрать нагрузку и&nbsp;проходить программу для наилучшего результата&raquo;</p>
                                <p>5&nbsp;видеоуроков от&nbsp;нутрициолога на&nbsp;тему сбалансированного питания</p>
                                <p>12&nbsp;рецептов блюд (3&nbsp;завтрака, 3&nbsp;обеда, 3&nbsp;ужина, 3&nbsp;десерта)</p>
                                <p>Калькулятор КБЖУ</p>
                                <p>Дополнительный доступ к&nbsp;записям <span>на&nbsp;2&nbsp;месяца</span></p>
                                <ul>
                                    <li><b>Полезные гайды:</b></li>
                                    <li>гайд: как избавиться от&nbsp;жира на&nbsp;животе без кардио </li>
                                    <li>сборник: 17&nbsp;полезных привычек для красоты и&nbsp;здоровья</li>
                                    <li>инструкция: эффективные способы привить полезные привычки</li>
                                    <li>инструкция: как сохранить мотивацию заниматься спортом</li>
                                    <li>советы: как добавить активность в&nbsp;свою жизнь, если у&nbsp;вас сидячая работа</li>
                                    <li>советы: как подобрать инвентарь для домашних тренировок</li>
                                </ul>
                                <p>Дневник тренировок</p>
                                <p>5&nbsp;заставок на&nbsp;телефон от&nbsp;Кати</p>
                            </div>
                            <div className={styles.block_item_price}><span>8&nbsp;000&nbsp;₽</span> 6&nbsp;990&nbsp;₽</div>
                        </div>
                        <div className={styles.block_item_btn} onClick={() => open(1)}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                            <p>Выбрать тариф <br/>Базовый</p>
                        </div>
                    </div>

                    <div className={styles.block_item}>
                        <div className={styles.places}>Количество мест ограничено</div>
                        <div className={styles.block_item_info}>
                            <div className={styles.block_item_title}>Тариф<br/> Продвинутый</div>
                            <div className={styles.block_item_text}>
                                <p>Доступ к&nbsp;программе на&nbsp;28&nbsp;дней</p>
                                <p>20&nbsp;фитнес-тренировок от&nbsp;Кати</p>
                                <p>4&nbsp;тренировки на&nbsp;укрепление спины от&nbsp;реабилитолога</p>
                                <p>Видео с&nbsp;рекомендациями &laquo;Как подобрать нагрузку и&nbsp;проходить программу для наилучшего результата&raquo;</p>
                                <p>5&nbsp;видеоуроков от&nbsp;нутрициолога на&nbsp;тему сбалансированного питания</p>
                                <p>12&nbsp;рецептов блюд (3&nbsp;завтрака, 3&nbsp;обеда, 3&nbsp;ужина, 3&nbsp;десерта)</p>
                                <p>Калькулятор КБЖУ</p>
                                <p>Дополнительный доступ к&nbsp;записям <span>на&nbsp;4&nbsp;месяца</span></p>
                                <ul>
                                    <li><b>Полезные гайды:</b></li>
                                    <li>гайд: как избавиться от&nbsp;жира на&nbsp;животе без кардио </li>
                                    <li>сборник: 17&nbsp;полезных привычек для красоты и&nbsp;здоровья</li>
                                    <li>инструкция: эффективные способы привить полезные привычки</li>
                                    <li>инструкция: как сохранить мотивацию заниматься спортом</li>
                                    <li>советы: как добавить активность в&nbsp;свою жизнь, если у&nbsp;вас сидячая работа</li>
                                    <li>советы: как подобрать инвентарь для домашних тренировок</li>
                                </ul>
                                <p>Дневник тренировок</p>
                                <p>5&nbsp;заставок на&nbsp;телефон от&nbsp;Кати</p>
                                <p>Кураторская поддержка в&nbsp;чате</p>
                                <p>Участие в&nbsp;конкурсе на&nbsp;лучшие результаты до/после</p>
                                <p>Эфир с&nbsp;экспертом по&nbsp;здоровью на&nbsp;тему &laquo;Почему появляются боли в&nbsp;спине и&nbsp;как от&nbsp;них избавиться&raquo;</p>
                                <p>Эфир с&nbsp;психологом и&nbsp;health-коучем на&nbsp;тему &laquo;Психосоматика лишнего веса&raquo;</p>
                                <p>Эфир с&nbsp;нутрициологом на&nbsp;тему сбалансированного питания</p>
                            </div>
                            <div className={styles.block_item_price}><span>12&nbsp;000&nbsp;₽</span> 9&nbsp;990&nbsp;₽</div>
                        </div>
                        <div className={styles.block_item_btn} onClick={() => open(2)}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                            <p>Выбрать тариф <br/>Продвинутый</p>
                        </div>
                    </div>

                    <div className={styles.block_item}>
                        <div className={styles.places}>Количество мест ограничено</div>
                        <div className={styles.block_item_info}>
                            <div className={styles.block_item_title}>Тариф<br/> VIP</div>
                            <div className={styles.block_item_text}>
                                <p>Доступ к&nbsp;программе на&nbsp;28&nbsp;дней</p>
                                <p>20&nbsp;фитнес-тренировок от&nbsp;Кати</p>
                                <p>4&nbsp;тренировки на&nbsp;укрепление спины от&nbsp;реабилитолога</p>
                                <p>Видео с&nbsp;рекомендациями &laquo;Как подобрать нагрузку и&nbsp;проходить программу для наилучшего результата&raquo;</p>
                                <p>5&nbsp;видеоуроков от&nbsp;нутрициолога на&nbsp;тему сбалансированного питания</p>
                                <p>12&nbsp;рецептов блюд (3&nbsp;завтрака, 3&nbsp;обеда, 3&nbsp;ужина, 3&nbsp;десерта)</p>
                                <p>Калькулятор КБЖУ</p>
                                <p>Дополнительный доступ к&nbsp;записям <span>на&nbsp;6&nbsp;месяцев</span></p>
                                <ul>
                                    <li><b>Полезные гайды:</b></li>
                                    <li>гайд: как избавиться от&nbsp;жира на&nbsp;животе без кардио </li>
                                    <li>сборник: 17&nbsp;полезных привычек для красоты и&nbsp;здоровья</li>
                                    <li>инструкция: эффективные способы привить полезные привычки</li>
                                    <li>инструкция: как сохранить мотивацию заниматься спортом</li>
                                    <li>советы: как добавить активность в&nbsp;свою жизнь, если у&nbsp;вас сидячая работа</li>
                                    <li>советы: как подобрать инвентарь для домашних тренировок</li>
                                </ul>
                                <p>Дневник тренировок</p>
                                <p>5&nbsp;заставок на&nbsp;телефон от&nbsp;Кати</p>
                                <p>Кураторская поддержка в&nbsp;чате</p>
                                <p>Участие в&nbsp;конкурсе на&nbsp;лучшие результаты до/после</p>
                                <p>Эфир с&nbsp;экспертом по&nbsp;здоровью на&nbsp;тему &laquo;Почему появляются боли в&nbsp;спине и&nbsp;как от&nbsp;них избавиться&raquo;</p>
                                <p>Эфир с&nbsp;психологом и&nbsp;health-коучем на&nbsp;тему &laquo;Психосоматика лишнего веса&raquo;</p>
                                <p>Эфир с&nbsp;нутрициологом на&nbsp;тему сбалансированного питания</p>
                                <p><span className={styles.firsttitme}>ВПЕРВЫЕ</span> Эфир с&nbsp;Катей с&nbsp;ответами на&nbsp;вопросы</p>
                                <p><span className={styles.firsttitme}>ВПЕРВЫЕ</span> Чат с&nbsp;Катей и&nbsp;нутрициологом на&nbsp;28&nbsp;дней</p>
                                <p><span className={styles.firsttitme}>ВПЕРВЫЕ</span> Персональный куратор на&nbsp;время марафона</p>
                            </div>
                            <div className={styles.block_item_price}><span>50&nbsp;000&nbsp;₽</span> 39&nbsp;990&nbsp;₽</div>
                        </div>
                        <div className={styles.block_item_btn} onClick={() => open(3)}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                            <p>Выбрать тариф <br/>VIP</p>
                        </div>
                    </div>
                </div>
                
                {/* <div className={('metodpay_start ') + styles.new_pay}>
                    <SalesWidget id={window.lps_settings.forms.methodpay_id} />
                </div> */}

                <div className={styles.pay}>
                    <div className={styles.pay_item}>
                        <div className={styles.pay_item_top}>
                            <div className={styles.pay_item_top_text}>Рассрочка без процентов<br/> от&nbsp;1&nbsp;165&nbsp;₽ в&nbsp;месяц</div>
                            <div className={styles.pay_item_top_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/tink_icon.png" alt="" /></div>
                        </div>
                        <div className={styles.pay_item_info}>Вы&nbsp;можете оформить заказ в&nbsp;рассрочку: на&nbsp;6&nbsp;месяцев без процентов и&nbsp;предварительного взноса.<br/> Только для граждан&nbsp;РФ.</div>
                    </div>

                    <div className={styles.pay_item}>
                        <div className={styles.pay_item_top}>
                            <div className={styles.pay_item_top_text}>Оплатите Долями без банка, переплат и&nbsp;рисков</div>
                            <div className={styles.pay_item_top_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/3/start_icon_black.png" alt="" /></div>
                        </div>
                        <div className={styles.pay_item_info}>Не&nbsp;надо ждать до&nbsp;зарплаты&nbsp;&mdash; разделите платёж на&nbsp;4&nbsp;части от&nbsp;1&nbsp;747,5&nbsp;₽. Всего на&nbsp;6&nbsp;недель</div>
                    </div>
                </div>

               
            
                <div className={`${styles.modal} ${isModal1 && styles.modal_active}`}>
                    <div className={`${styles.modal_block} method_form`}>
                        <div className={styles.modal_close} onClick={() => close(1)}><img src="https://gymteam.kinescopecdn.net/img/l/2024/method/close2.svg" alt="" /></div>
                        <SalesWidget hook={widget} id={window.lps_settings.forms.method1_id} />
                    </div>
                </div>
                <div className={`${styles.modal} ${isModal2 && styles.modal_active}`}>
                    <div className={`${styles.modal_block} method_form`}>
                        <div className={styles.modal_close} onClick={() => close(2)}><img src="https://gymteam.kinescopecdn.net/img/l/2024/method/close2.svg" alt="" /></div>
                        <SalesWidget hook={widget2} id={window.lps_settings.forms.method2_id} />
                    </div>
                </div>
                <div className={`${styles.modal} ${isModal3 && styles.modal_active}`}>
                    <div className={`${styles.modal_block} method_form`}>
                        <div className={styles.modal_close} onClick={() => close(3)}><img src="https://gymteam.kinescopecdn.net/img/l/2024/method/close2.svg" alt="" /></div>
                        <SalesWidget hook={widget3} id={window.lps_settings.forms.method3_id} />
                    </div>
                </div>
            
            </div>
        </div>
        </>
    )
}

export default Start;