import { useState } from 'react';
import styles from './students.module.css';

const Students = ({scroll}) => {
    const [items, setItems] = useState([
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/3.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/6.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/1.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/2.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/4.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/5.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/7.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/8.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/9.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/10.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/11.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/12.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/13.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/14.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/15.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/16.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/17.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/18.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/19.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/20.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/21.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/22.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/23.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/24.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/25.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/26.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/27.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/28.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/29.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/30.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/3.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/6.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/1.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/2.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/4.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/5.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/7.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/8.jpg'},
    ]);

    const [items2, setItems2] = useState([
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/31.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/32.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/33.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/34.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/35.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/36.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/37.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/38.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/39.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/40.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/41.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/42.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/43.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/44.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/45.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/46.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/47.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/48.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/49.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/50.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/51.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/52.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/53.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/54.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/55.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/56.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/57.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/58.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/59.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/60.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/31.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/32.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/33.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/34.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/35.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/36.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/37.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/38.jpg'},
    ]);

    const [isModal, setIsModal] = useState(false)
    const [activeSlide, setActiveSlide] = useState(null)

    return (
        <div className={styles.students}>
            <div className="container">
                <div className={styles.students_pretitle}>Более&nbsp;80% учениц Кати довольны своими до/после&nbsp;—</div>
                <div className={styles.students_title}>это лучшее доказательство<br/> эффективности программ</div>
            </div>

            <div className={`${styles.students_row} ${styles.students_row1}`}>
                {items.map((item, idx) => 
                    <div className={styles.students_item} key={item.idx} onClick={() => (setActiveSlide(items[idx].src), setIsModal(true))}>
                        <img src={item.src} alt="" />
                    </div>
                )}
            </div>
            <div className={`${styles.students_row} ${styles.students_row2}`}>
                {items2.map((item, idx) => 
                    <div className={styles.students_item} key={item.idx} onClick={() => (setActiveSlide(items2[idx].src), setIsModal(true))}>
                        <img src={item.src} alt="" />
                    </div>
                )}
            </div>
            
            <div className={`${styles.modal} ${isModal && styles.modal_active}`}>
                <div className={styles.close_btn} onClick={()=>setIsModal(false)}>
                    <svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.1949 1.48389L1.33203 15.3468M1.33203 1.48389L15.1949 15.3468" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div className={styles.modal_img}>
                    <img src={activeSlide} alt="" />
                </div>
            </div>
            
            
        </div>
    )
}

export default Students;