import { LoginWidget, ShowForGroup, HideForGroup, addUserToGroup, getUser } from "@com.marathonium/web2-ui";
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";
import { useRef, useState } from "react";
import Countdown from 'react-countdown';
import {DocumentTitle, DocumentDescription} from '../../../components/DocumentMeta';
import {Swiper, SwiperSlide} from 'swiper/react'
import {Lazy, Navigation} from 'swiper'
import 'swiper/css'
import "swiper/css/grid";
import "swiper/css/navigation";

import styles from './style.module.css';
import Footer from "../../../components/Footer";
import Studentres from "./Studentres";
import { Redirect } from "../../../components/redirect/Redirect";

export const L2023_04_Culture = () => {

    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    const [phone, setPhone] = useState('')
    const getUserPhone = () => {
        getUser().then(res => {
            let phoneNumber = res.phone.split('')
            if(phoneNumber.length == 12) {
                let finalNumber = `${phoneNumber[0]}${phoneNumber[1]} ${phoneNumber[2]}${phoneNumber[3]}${phoneNumber[4]} ${phoneNumber[5]}${phoneNumber[6]}${phoneNumber[7]}-${phoneNumber[8]}${phoneNumber[9]}-${phoneNumber[10]}${phoneNumber[11]}`
                setPhone(finalNumber)
            } else {
                let finalNumber = `${phoneNumber[0]} ${phoneNumber[1]}${phoneNumber[2]}${phoneNumber[3]} ${phoneNumber[4]}${phoneNumber[5]}${phoneNumber[6]}-${phoneNumber[7]}${phoneNumber[8]}-${phoneNumber[9]}${phoneNumber[10]}`
                setPhone(finalNumber)
            }
        })
    }

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };

    const scroll = (name) => {
        const section = document.querySelector( name );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    function LoginForm() {
        return (
          <>
            <Authorized>                
                <HideForGroup id={window.lps_settings.user_groups.newgroup}>
                <div className={styles.promo}>
                    <div className="container">
                        <div className={styles.promo_count}>
                            <div className={styles.promo_count_title}>ДО ЗАКРЫТИЯ ПРЕДСПИСКА:</div>
                            <div className={styles.promo_count_count}><Countdown date={Date.now() + date} renderer={renderer} /></div>
                        </div>
                        <div className={styles.promo_pretitle}>Главное событие весны 2023</div>
                        <div className={styles.promo_title}><img src="https://gymteam.kinescopecdn.net/img/l/2023/singlepages/culture/promo_title.svg" alt="" /></div>
                        <div className={styles.promo_subtitle}>Новые тренировки на&nbsp;ягодицы<br/> от&nbsp;Кати Усмановой</div>
                        <div className={styles.promo_desc}><strong>Новый подход&nbsp;— не&nbsp;просто программа,</strong><br/> а&nbsp;конструктор для индивидуального результата<br/> для создания ЛЕГЕНДАРНОЙ попы «как&nbsp;у&nbsp;Кати»</div>
                        <div className={styles.promo_pink}>Впервые!</div>
                        <div className={styles.promo_text}>Качайте попу даже если у&nbsp;вас <strong>болят колени<br/> или замучил ПМС</strong></div>
                        <div className={styles.promo_btn} onClick={() => scroll('.scrollhere')}>Попасть в предсписок</div>
                    </div>
                </div>

                <div className={styles.video}>
                    <div className="container">
                        <div className={styles.video_pretitle}>Всего месяц назад </div>
                        <div className={styles.video_title}> Катя сняла новые<br/> тренировки для попы как 🌰</div>
                        {window.innerWidth < 768 
                        ?
                        <Swiper 
                            lazy={true}
                            navigation={{
                                prevEl: navigationPrevRef.current,
                                nextEl: navigationNextRef.current,
                            }}
                            loop
                            onSwiper={(swiper) => {
                                setTimeout(() => {
                                swiper.params.navigation.prevEl = navigationPrevRef.current
                                swiper.params.navigation.nextEl = navigationNextRef.current
                                
                                swiper.navigation.destroy()
                                swiper.navigation.init()
                                swiper.navigation.update()
                                })
                            }}
                            slidesPerView={1}
                            modules={[Navigation, Lazy]}
                            className={styles.swiper}
                        >
                            <div className={styles.slider_nav}>
                                <div ref={navigationPrevRef} className={styles.slider_btn_prev}><img src="https://gymteam.kinescopecdn.net/img/l/2023/singlepages/culture/arrow.svg" alt="" /></div>
                                <div ref={navigationNextRef} className={styles.slider_btn_next}><img src="https://gymteam.kinescopecdn.net/img/l/2023/singlepages/culture/arrow.svg" alt="" /></div>
                            </div>
                            <SwiperSlide>
                                <div className={styles.video_item}><iframe src="https://kinescope.io/embed/203773068" width="287" height="513" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowfullscreen></iframe></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.video_item}><iframe src="https://kinescope.io/embed/203773065" width="287" height="513" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowfullscreen></iframe></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.video_item}><iframe src="https://kinescope.io/embed/203773066" width="287" height="513" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowfullscreen></iframe></div>
                            </SwiperSlide>
                        </Swiper>
                        :
                        <div className={styles.video_block}>
                            <div className={styles.video_item}><iframe src="https://kinescope.io/embed/203773068" width="287" height="513" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowfullscreen></iframe></div>
                            <div className={styles.video_item}><iframe src="https://kinescope.io/embed/203773065" width="287" height="513" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowfullscreen></iframe></div>
                            <div className={styles.video_item}><iframe src="https://kinescope.io/embed/203773066" width="287" height="513" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowfullscreen></iframe></div>
                        </div>
                        }
                        
                    </div>
                </div>

                <div className={styles.infoblock}>
                    <div className="container">
                        <div className={styles.infoblock_pretitle}>И сейчас вам пора решить</div>
                        <div className={styles.infoblock_title}>Вы либо с&nbsp;нами, либо<br/> остаётесь без шикарной<br/> попы к&nbsp;лету&nbsp;🔥</div>
                        <div className={styles.infoblock_text}>Запишитесь в&nbsp;предсписок, чтобы первыми получить<br/> эксклюзивные условия на конструктор<br/> из <span>НОВЫХ</span> тренировок на ягодицы</div>
                        <div className={styles.infoblock_title2}>Только так вы получите:</div>
                        <div className={styles.infoblock_block}>
                            <div className={styles.infoblock_item}>
                                <div className={styles.infoblock_info}>
                                    <div className={styles.infoblock_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/singlepages/culture/icon1.png" alt="" /></div>
                                    <div className={styles.infoblock_name}><span>Самые низкие цены</span> на все тарифы</div>
                                </div>
                            </div>
                            <div className={styles.infoblock_item}>
                                <div className={styles.infoblock_info}>
                                    <div className={styles.infoblock_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/singlepages/culture/icon2.png" alt="" /></div>
                                    <div className={styles.infoblock_name}><span>Полный набор</span> крутейших бонусов для попы</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Studentres/>

                <div className={`scrollhere ${styles.main_login}`}>
                    <div className="container">
                        <div className={styles.main_login_title}>ХВАТИТ ВОСХИЩАТЬСЯ<br/> ЧУЖИМИ ЯГОДИЦАМИ,<br/> ПОРА НАКАЧАТЬ СВОИ</div>
                        <div className={styles.main_form}>
                            <HideForGroup id={window.lps_settings.user_groups.group_to}>
                                <div className={styles.main_form__text}>Нажмите на кнопку ниже, чтобы оставить заявку</div>
                                <div className={styles.main_reg_btn}  onClick={() => {getUserPhone(); addUserToGroup(window.lps_settings.user_groups.group_to);}}>НЕ ПРОПУСТИТЬ СТАРТ ПРОДАЖ</div>
                            </HideForGroup>
                            <ShowForGroup id={window.lps_settings.user_groups.group_to}>
                                <div className={styles.main_form__text}>Спасибо, ожидайте оповещения о&nbsp;старте продаж новых тренировок на&nbsp;ягодицы от&nbsp;Екатерины Усмановой</div>
                            </ShowForGroup>
                        </div>
                    </div>
                </div>
                <Footer/>
                </HideForGroup>

                {/* <ShowForGroup id={window.lps_settings.user_groups.legendary}>
                    <div className={`scrollhere ${styles.main_login}`}>
                        <div className="container">
                            <div className={styles.main_login_title}>ХВАТИТ ВОСХИЩАТЬСЯ<br/> ЧУЖИМИ ЯГОДИЦАМИ,<br/> ПОРА НАКАЧАТЬ СВОИ</div>
                            <div className={styles.main_form}>
                                <div className={styles.main_thanks}>Спасибо!</div>
                                <div className={styles.main_thanks_text}>Мы добавили вас <strong>{phone}</strong> в&nbsp;предсписок участниц</div>
                                <div className={styles.main_thanks_text2}>Подключите наших ботов-помощников, чтобы точно не&nbsp;пропустить уведомление о&nbsp;старте продаж</div>
                                <div className={styles.main_thanks_btns}>
                                    <a href="https://salebot.site/sherlok_pops_0_f" target="_blank" rel="noreferrer"><img src="https://gymteam.kinescopecdn.net/img/l/2023/singlepages/vk.svg" alt="" /> Подключить VK</a>
                                    <a href="https://salebot.site/sherlok_pops_1" target="_blank" rel="noreferrer"><img src="https://gymteam.kinescopecdn.net/img/l/2023/singlepages/tg.svg" alt="" /> Подключить TG</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </ShowForGroup> */}
                <ShowForGroup id={window.lps_settings.user_groups.newgroup}>
                    <Redirect url='/l/popculture'/>
                </ShowForGroup>
            </Authorized>
            <Anonymous>
                <div className={styles.promo}>
                    <div className="container">
                        <div className={styles.promo_count}>
                            <div className={styles.promo_count_title}>ДО ЗАКРЫТИЯ ПРЕДСПИСКА:</div>
                            <div className={styles.promo_count_count}><Countdown date={Date.now() + date} renderer={renderer} /></div>
                        </div>
                        <div className={styles.promo_pretitle}>Главное событие весны 2023</div>
                        <div className={styles.promo_title}><img src="https://gymteam.kinescopecdn.net/img/l/2023/singlepages/culture/promo_title.svg" alt="" /></div>
                        <div className={styles.promo_subtitle}>Новые тренировки на&nbsp;ягодицы<br/> от&nbsp;Кати Усмановой</div>
                        <div className={styles.promo_desc}><strong>Новый подход&nbsp;— не&nbsp;просто программа,</strong><br/> а&nbsp;конструктор для индивидуального результата<br/> для создания ЛЕГЕНДАРНОЙ попы «как&nbsp;у&nbsp;Кати»</div>
                        <div className={styles.promo_pink}>Впервые!</div>
                        <div className={styles.promo_text}>Качайте попу даже если у&nbsp;вас <strong>болят колени<br/> или замучил ПМС</strong></div>
                        <div className={styles.promo_btn} onClick={() => scroll('.scrollhere')}>Попасть в предсписок</div>
                    </div>
                </div>

                <div className={styles.video}>
                    <div className="container">
                        <div className={styles.video_pretitle}>Всего месяц назад </div>
                        <div className={styles.video_title}> Катя сняла новые<br/> тренировки для попы как 🌰</div>
                        {window.innerWidth < 768 
                        ?
                        <Swiper 
                            lazy={true}
                            navigation={{
                                prevEl: navigationPrevRef.current,
                                nextEl: navigationNextRef.current,
                            }}
                            loop
                            onSwiper={(swiper) => {
                                setTimeout(() => {
                                swiper.params.navigation.prevEl = navigationPrevRef.current
                                swiper.params.navigation.nextEl = navigationNextRef.current
                                
                                swiper.navigation.destroy()
                                swiper.navigation.init()
                                swiper.navigation.update()
                                })
                            }}
                            slidesPerView={1}
                            modules={[Navigation, Lazy]}
                            className={styles.swiper}
                        >
                            <div className={styles.slider_nav}>
                                <div ref={navigationPrevRef} className={styles.slider_btn_prev}><img src="https://gymteam.kinescopecdn.net/img/l/2023/singlepages/culture/arrow.svg" alt="" /></div>
                                <div ref={navigationNextRef} className={styles.slider_btn_next}><img src="https://gymteam.kinescopecdn.net/img/l/2023/singlepages/culture/arrow.svg" alt="" /></div>
                            </div>
                            <SwiperSlide>
                                <div className={styles.video_item}><iframe src="https://kinescope.io/embed/203773068" width="287" height="513" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowfullscreen></iframe></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.video_item}><iframe src="https://kinescope.io/embed/203773065" width="287" height="513" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowfullscreen></iframe></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.video_item}><iframe src="https://kinescope.io/embed/203773066" width="287" height="513" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowfullscreen></iframe></div>
                            </SwiperSlide>
                        </Swiper>
                        :
                        <div className={styles.video_block}>
                            <div className={styles.video_item}><iframe src="https://kinescope.io/embed/203773068" width="287" height="513" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowfullscreen></iframe></div>
                            <div className={styles.video_item}><iframe src="https://kinescope.io/embed/203773065" width="287" height="513" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowfullscreen></iframe></div>
                            <div className={styles.video_item}><iframe src="https://kinescope.io/embed/203773066" width="287" height="513" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowfullscreen></iframe></div>
                        </div>
                        }
                        
                    </div>
                </div>

                <div className={styles.infoblock}>
                    <div className="container">
                        <div className={styles.infoblock_pretitle}>И сейчас вам пора решить</div>
                        <div className={styles.infoblock_title}>Вы либо с&nbsp;нами, либо<br/> остаётесь без шикарной<br/> попы к&nbsp;лету&nbsp;🔥</div>
                        <div className={styles.infoblock_text}>Запишитесь в&nbsp;предсписок, чтобы первыми получить<br/> эксклюзивные условия на конструктор<br/> из <span>НОВЫХ</span> тренировок на ягодицы</div>
                        <div className={styles.infoblock_title2}>Только так вы получите:</div>
                        <div className={styles.infoblock_block}>
                            <div className={styles.infoblock_item}>
                                <div className={styles.infoblock_info}>
                                    <div className={styles.infoblock_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/singlepages/culture/icon1.png" alt="" /></div>
                                    <div className={styles.infoblock_name}><span>Самые низкие цены</span> на все тарифы</div>
                                </div>
                            </div>
                            <div className={styles.infoblock_item}>
                                <div className={styles.infoblock_info}>
                                    <div className={styles.infoblock_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/singlepages/culture/icon2.png" alt="" /></div>
                                    <div className={styles.infoblock_name}><span>Полный набор</span> крутейших бонусов для попы</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Studentres/>

                <div className={`scrollhere ${styles.main_login}`}>
                    <div className="container">
                        <div className={styles.main_login_title}>ХВАТИТ ВОСХИЩАТЬСЯ<br/> ЧУЖИМИ ЯГОДИЦАМИ,<br/> ПОРА НАКАЧАТЬ СВОИ</div>
                        <div className={styles.main_form}>
                            <div className={styles.main_form__text}>Авторизуйтесь, чтобы попасть<br/> в&nbsp;предсписок</div>
                            <div className={styles.main_form__text2}>Ваш номер телефона:</div>
                            <div className={styles.main_form__login}><LoginWidget /></div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Anonymous>
          </>
        );
      }

    return (
        <div>
            <DocumentTitle value='Новая программа от Екатерины Усмановой'/>
            <DocumentDescription value='Запишитесь в предсписок, чтобы не пропустить старт продаж новой программы тренировок на ягодицы от Екатерины Усмановой.' />
            <div className={styles.main}>
                <LoginForm onSuccess={getUserPhone()} />
            </div>
        </div>
    );
};