import styles from './reviews.module.css';

const Reviews = () => {
    return (
        <div className={styles.reviews}>
            <div className="container">
                <div className={styles.reviews_title}>«Прилив энергии,<br/> вдохновение, ощущение<br/> расслабленности»</div>
                <div className={styles.reviews_subtitle}>Вот что говорят пользователи GymTeam о&nbsp;наших тренировках</div>
            </div>
                <div className={`${styles.reviews_row} ${styles.reviews_row1}`}>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>Неожиданно не только понравилась физическая активность, но и эффект от тренировки сродни психотерапии 🥰</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Наталья Удалова</div>
                            <div className={styles.reviews_item__date}>27.12.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>лёгкость в теле и в глазах, расслабление, взгляд стал ровнее и плавнее буду чаще делать этот комплекс</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Irina</div>
                            <div className={styles.reviews_item__date}>09.10.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>Это что-то нереальное, как же это приятно, понятно, эффективно, тренер замечательный. Спасибо вам большое!</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Кожебекова Татьяна</div>
                            <div className={styles.reviews_item__date}>10.03.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>шикарно! очень нравится, что упражнения не сложные, но достаточно интенсивные! и без прыжков и насилия над собой)))</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Kate</div>
                            <div className={styles.reviews_item__date}>23.02.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>мне так хорошо , чувствую спокойствие в душе и хорошую работу в теле , без насилия.</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Светлана</div>
                            <div className={styles.reviews_item__date}>29.09.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>Неожиданно не только понравилась физическая активность, но и эффект от тренировки сродни психотерапии 🥰</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Наталья Удалова</div>
                            <div className={styles.reviews_item__date}>27.12.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>лёгкость в теле и в глазах, расслабление, взгляд стал ровнее и плавнее буду чаще делать этот комплекс</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Irina</div>
                            <div className={styles.reviews_item__date}>09.10.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>Это что-то нереальное, как же это приятно, понятно, эффективно, тренер замечательный. Спасибо вам большое!</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Кожебекова Татьяна</div>
                            <div className={styles.reviews_item__date}>10.03.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>шикарно! очень нравится, что упражнения не сложные, но достаточно интенсивные! и без прыжков и насилия над собой)))</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Kate</div>
                            <div className={styles.reviews_item__date}>23.02.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>мне так хорошо , чувствую спокойствие в душе и хорошую работу в теле , без насилия.</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Светлана</div>
                            <div className={styles.reviews_item__date}>29.09.2022</div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.reviews_row} ${styles.reviews_row2}`}>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>новые интересные упражнения! раньше таких нигде не встречала👍</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Светлана Борзова</div>
                            <div className={styles.reviews_item__date}>13.12.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>прекрасное занятия!Так же как и все которые ведёт этот тренер!</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Юлия</div>
                            <div className={styles.reviews_item__date}>05.12.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>Спасибо! Классные упражнения! Мне все понравилось в курсе</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Анастасия Хренова</div>
                            <div className={styles.reviews_item__date}>15.05.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>Легкость и расслабление в теле. Эмоции успокоились.</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Анна</div>
                            <div className={styles.reviews_item__date}>18.08.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>новые интересные упражнения! раньше таких нигде не встречала👍</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Светлана Борзова</div>
                            <div className={styles.reviews_item__date}>13.12.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>прекрасное занятия!Так же как и все которые ведёт этот тренер!</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Юлия</div>
                            <div className={styles.reviews_item__date}>05.12.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>новые интересные упражнения! раньше таких нигде не встречала👍</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Светлана Борзова</div>
                            <div className={styles.reviews_item__date}>13.12.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>прекрасное занятия!Так же как и все которые ведёт этот тренер!</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Юлия</div>
                            <div className={styles.reviews_item__date}>05.12.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>Спасибо! Классные упражнения! Мне все понравилось в курсе</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Анастасия Хренова</div>
                            <div className={styles.reviews_item__date}>15.05.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>Легкость и расслабление в теле. Эмоции успокоились.</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Анна</div>
                            <div className={styles.reviews_item__date}>18.08.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>новые интересные упражнения! раньше таких нигде не встречала👍</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Светлана Борзова</div>
                            <div className={styles.reviews_item__date}>13.12.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>прекрасное занятия!Так же как и все которые ведёт этот тренер!</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Юлия</div>
                            <div className={styles.reviews_item__date}>05.12.2022</div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.reviews_row} ${styles.reviews_row3}`}>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>🔥Восторг! впервые приобрела тренировки онлайн, не ожидала, что так эффективно пройдёт это вводное занятие! тренер огонь)</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Наталья</div>
                            <div className={styles.reviews_item__date}>11.04.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>классный тренер, хорошо все обьясняет, упражнения выполняются легко, приятно на него смотреть, в хорошей форме, и хорошо выглядит.</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Николай</div>
                            <div className={styles.reviews_item__date}>15.01.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>Супер! Все понравилось :) Заботливые комментарии и постоянный контроль дыхания сделали эту тренировку идеальной.</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Дарина</div>
                            <div className={styles.reviews_item__date}>11.08.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>классный тренер, хорошо все обьясняет, упражнения выполняются легко, приятно на него смотреть, в хорошей форме, и хорошо выглядит.</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Николай</div>
                            <div className={styles.reviews_item__date}>15.01.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>Тренер просто космический. Такая искренняя любовь ко всей планете. Вызывает добрую улыбку)</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Masha Lebed</div>
                            <div className={styles.reviews_item__date}>01.10.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>🔥Восторг! впервые приобрела тренировки онлайн, не ожидала, что так эффективно пройдёт это вводное занятие! тренер огонь)</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Наталья</div>
                            <div className={styles.reviews_item__date}>11.04.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>классный тренер, хорошо все обьясняет, упражнения выполняются легко, приятно на него смотреть, в хорошей форме, и хорошо выглядит.</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Николай</div>
                            <div className={styles.reviews_item__date}>15.01.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>Супер! Все понравилось :) Заботливые комментарии и постоянный контроль дыхания сделали эту тренировку идеальной.</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Дарина</div>
                            <div className={styles.reviews_item__date}>11.08.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>классный тренер, хорошо все обьясняет, упражнения выполняются легко, приятно на него смотреть, в хорошей форме, и хорошо выглядит.</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Николай</div>
                            <div className={styles.reviews_item__date}>15.01.2022</div>
                        </div>
                    </div>
                    <div className={styles.reviews_item}>
                        <div className={styles.reviews_item__text}>Тренер просто космический. Такая искренняя любовь ко всей планете. Вызывает добрую улыбку)</div>
                        <div className={styles.reviews_item__info}>
                            <div className={styles.reviews_item__name}>Masha Lebed</div>
                            <div className={styles.reviews_item__date}>01.10.2022</div>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default Reviews;