import styles from "./blocks.module.css";

const Blocks = ({ scroll }) => {
  return (
    <>
      <div className="container">
        <div className={styles.b1}>
          <div className={styles.b1_title}>
            Для крутого результата тебе
            <br /> понадобится <span>только 3&nbsp;предмета</span>
          </div>
          <div className={styles.b1_pretitle}>
            ТРЕНИРОВКИ ПЕРВОЙ НЕДЕЛИ ПРОХОДЯТ БЕЗ КАКОГО-ЛИБО ОБОРУДОВАНИЯ
          </div>
          <div className={styles.b1_block}>
            <div className={styles.b1_item}>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/um2/b1_1.png"
                alt=""
              />
              <div className={styles.b1_item_title}>Фитнес-резинка</div>
              <div className={styles.b1_item_text}>
                Кольцевые фитнес-резинки с&nbsp;сопротивлением medium. Можно
                заменить на&nbsp;эластичную ленту.
              </div>
            </div>
            <div className={styles.b1_item}>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/um2/b1_2.png"
                alt=""
              />
              <div className={styles.b1_item_title}>Утяжелители</div>
              <div className={styles.b1_item_text}>
                Утяжелители весом 1&ndash;2&nbsp;кг. Можно заменить
                на&nbsp;фитнес-резинку.
              </div>
            </div>
            <div className={styles.b1_item}>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/um2/b1_3.png"
                alt=""
              />
              <div className={styles.b1_item_title}>Гантели</div>
              <div className={styles.b1_item_text}>
                Наборные гантели или цельные гантели весом 2&ndash;3&nbsp;кг.
                Можно заменить на&nbsp;бутылки с&nbsp;водой.
              </div>
            </div>
          </div>
        </div>

        <div className={styles.b2}>
          <div className={styles.b2_title}>
            Получай ответы на&nbsp;свои вопросы и&nbsp;поддержку 28&nbsp;дней
          </div>
          <div className={styles.b2_subtitle}>
            При покупке программы ты&nbsp;получаешь доступ в&nbsp;чат
            с&nbsp;куратором и&nbsp;другими участниками программы
            на&nbsp;28&nbsp;дней. Наши кураторы на&nbsp;связи каждый день
            с&nbsp;9&nbsp;до&nbsp;21&nbsp;по&nbsp;Мск! Отвечают на&nbsp;вопросы
            участников, помогают разобраться в&nbsp;сложных ситуациях
            и&nbsp;мотивируют двигаться дальше.
          </div>
          <img
            src="https://gymteam.kinescopecdn.net/img/l/2024/um2/b2_3.png"
            alt=""
          />
          <img
            src="https://gymteam.kinescopecdn.net/img/l/2024/um2/b2_5.png"
            alt=""
          />
        </div>

        <div className={styles.b3}>
          <div className={styles.b3_left}>
            <div className={styles.b3_title}>
              Тренируйся и&nbsp;пересматривай
              <br /> материалы программы
              <br /> ещё 2&nbsp;месяца
              <br /> после завершения!
            </div>
            <div className={styles.b3_text}>
              Ты&nbsp;сделаешь крутой результат за&nbsp;28&nbsp;дней.
              А&nbsp;после
              <br /> окончания программы у&nbsp;тебя останется доступ
              <br /> к&nbsp;тренировкам, записям вебинаров и&nbsp;всем урокам
              <br /> ещё на&nbsp;2&nbsp;месяца, чтобы ты&nbsp;могла повторить
              любимые
              <br /> тренировки и&nbsp;закрепить результат.
            </div>
          </div>
          <div className={styles.b3_right}>
            <img
              src="https://gymteam.kinescopecdn.net/img/l/2024/um2/b3_2.png"
              alt=""
            />
          </div>
          <div className={styles.b3_btn}>
            <div className={styles.btn} onClick={scroll}>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg"
                alt=""
              />
              <p>
                Хочу на&nbsp;Метод
                <br /> Усмановой
              </p>
            </div>
          </div>
        </div>

        <div className={styles.b4}>
          <div className={styles.b4_pretitle}>Учавствуй в&nbsp;конкурсе!</div>
          <div className={styles.b4_title}>
            Покажи лучший результат и&nbsp;выиграй
            <br /> поездку в&nbsp;Дубай на&nbsp;двоих!
          </div>
          <div className={styles.b4_subtitle}>
            Наши кураторы отберут 20&nbsp;номинанток с&nbsp;лучшими
            результатами,
            <br /> а&nbsp;Катя Усманова лично выберет из&nbsp;них 3-х
            победительниц.
          </div>
          <div className={styles.b4_block}>
            <div className={styles.b4_item}>
              <div className={styles.b4_item_place}>1&nbsp;место</div>
              <div className={styles.b4_item_text}>
                Путевка
                <br /> в&nbsp;Дубай на&nbsp;двоих
              </div>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/um2/b4_4.png"
                alt=""
              />
            </div>
            <div className={styles.b4_item}>
              <div className={styles.b4_item_place}>2&nbsp;место</div>
              <div className={styles.b4_item_text}>100&nbsp;000&nbsp;₽</div>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/um2/b4_2.png"
                alt=""
              />
            </div>
            <div className={styles.b4_item}>
              <div className={styles.b4_item_place}>3&nbsp;место</div>
              <div className={styles.b4_item_text}>50&nbsp;000&nbsp;₽</div>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/um2/b4_3.png"
                alt=""
              />
            </div>
          </div>
        </div>

        {/* <div className={styles.b5}>
                <div className={styles.b5_title}><span>Как принять участие</span><br/> в&nbsp;конкурсе на&nbsp;лучшие результаты</div>
                <div className={styles.b5_block}>
                    <div className={styles.b5_img}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/um2/b5_1.jpg" alt="" />
                    </div>
                    <div className={styles.b5_text}>
                        <ul>
                            <li><span>01</span> Приобрети Метод Усмановой с&nbsp;выгодой&nbsp;28%</li>
                            <li><span>02</span> Подай заявку на&nbsp;участие и&nbsp;приложи свои фото &laquo;до&raquo; с&nbsp;17&nbsp;по&nbsp;23&nbsp;сентября. Предложение на&nbsp;участие и&nbsp;ссылку на&nbsp;анкету ты&nbsp;получишь на&nbsp;почту после старта программы.</li>
                            <li><span>03</span> Тренируйся по&nbsp;уникальной методике&nbsp;&mdash; у&nbsp;тебя впереди 28&nbsp;дней для получения крутого результата!</li>
                            <li><span>04</span> Заполни анкету и&nbsp;приложи фотографии &laquo;после&raquo; с&nbsp;11&nbsp;по&nbsp;14&nbsp;октября.</li>
                            <li><span>05</span> Следи за&nbsp;ходом открытого голосования с&nbsp;28&nbsp;октября по&nbsp;10&nbsp;ноября. Подсчитаем голоса и&nbsp;объявим победителей 13&nbsp;ноября.</li>
                        </ul>
                    </div>
                </div>
            </div> */}

        <div className={styles.b6}>
          <div className={styles.b6_title}>
            <span>Главный результат</span> после
            <br /> прохождения программы:
          </div>
          <div className={styles.b6_subtitle}>
            Создашь стройную и&nbsp;подтянутую фигуру, избавишься от&nbsp;болей
            <br /> в&nbsp;теле и&nbsp;почувствуешь себя молодой и&nbsp;здоровой.
            Твоё питание
            <br /> станет чистым, а&nbsp;кожа лица гладкой и&nbsp;свежей.
            Уверенность
            <br /> в&nbsp;себе и&nbsp;хорошая самооценка приложатся
            по&nbsp;умолчанию!
          </div>
          <div className={styles.b8_block}>
            <div className={styles.b8_left}>
              <img
                src="https://gymteam.kinescopecdn.net/img/l/2024/um2/b8_1.png"
                alt=""
              />
            </div>
            <div className={styles.b8_right}>
              <div className={styles.b8_title}>
                Боишься, что не&nbsp;получишь
                <br /> результат?
              </div>
              <div className={styles.b8_text}>
                Мы&nbsp;настолько уверены в&nbsp;программе, что вернём полную
                <br /> стоимость программы, если ты&nbsp;не&nbsp;увидишь
                <br /> результат в&nbsp;течение 2&nbsp;недель
              </div>
            </div>
          </div>
          <div className={styles.btn} onClick={scroll}>
            <img
              src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg"
              alt=""
            />
            <p>
              Хочу такой
              <br /> результат!
            </p>
          </div>
        </div>
      </div>

      {/* <div className={styles.b7}>
            <div className="container">
                <div className={styles.b7_title}><span>Главный результат</span><br/> после прохождения<br/> программы</div>
                <ul>
                    <li>Создашь стройную и&nbsp;подтянутую фигуру</li>
                    <li>Избавишься от&nbsp;болей в&nbsp;теле и&nbsp;почувствуешь<br/> себя молодой и&nbsp;здоровой</li>
                    <li>Твоё питание станет правильным, а&nbsp;кожа лица<br/> гладкой и&nbsp;свежей</li>
                </ul>
                <div className={styles.b7_text}>Уверенность в&nbsp;себе и&nbsp;хорошая самооценка<br/> приложатся по&nbsp;умолчанию!</div>
                <div className={styles.b7_block}>
                    <div className={styles.b7_block_title}>Боишься, что не&nbsp;получишь<br/> результат!</div>
                    <div className={styles.b7_block_text}>Мы&nbsp;настолько уверены в&nbsp;курсе, что вернём полную<br/> стоимость программы, если вы&nbsp;не&nbsp;увидите<br/> результат в&nbsp;течение 2&nbsp;недель</div>
                </div>
                <div className={styles.btn} onClick={scroll}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                    <p>Хочу такой<br/> результат!</p>
                </div>
            </div>
        </div> */}

      {/* <div className="container">
            <div className={styles.b8}>
                <div className={styles.b8_block}>
                    <div className={styles.b8_left}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/um2/b8_1.png" alt="" />
                    </div>
                    <div className={styles.b8_right}>
                        <div className={styles.b8_title}>Боишься, что не&nbsp;получишь<br/> результат!</div>
                        <div className={styles.b8_text}>Мы&nbsp;настолько уверены в&nbsp;курсе, что вернём полную<br/> стоимость программы, если вы&nbsp;не&nbsp;увидите<br/> результат в&nbsp;течение 2&nbsp;недель</div>
                    </div>
                </div>
            </div>
        </div> */}
    </>
  );
};

export default Blocks;
