import { useEffect, useState } from 'react';
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import { LoginWidget, checkUserInGroup, getUserCountByGroup } from "@com.marathonium/web2-ui";
import Footer from '../../components/Footer';
import Promo from './components/Promo';
import { Modal } from './components/Modal';
import { Nomination } from './components/Nomination';
import Info from './components/Info';
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";
import styles from './main.module.css';
import './style.css'

const nom1 = [
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/13_1.JPG',
        name: 'Анастасия',
        weight: '59 → 59,7',
        chest: '93 → 93',
        waist: '73 → 70',
        hips: '96 → 98',
        points: '59',
        place: '1 МЕСТО',
        id: 'aec93ecf-e685-493a-b94a-55ac62ab9a5e'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/3_1.JPG',
        name: 'Антонина',
        weight: '55 → 52',
        chest: '92 → 87',
        waist: '79 → 68',
        hips: '97 → 93',
        points: '55',
        place: '2 МЕСТО',
        id: '69d48d71-9a6d-4f4e-8835-c3708361d77e'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/4_1.JPG',
        name: 'Марина',
        weight: '63,8 → 62',
        chest: '93 → 87',
        waist: '81 → 74',
        hips: '101 → 98',
        points: '34',
        place: '3 МЕСТО',
        id: '83ea734d-4ecf-492b-8ea0-9d877488401e'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/6_1.JPG',
        name: 'Любовь',
        weight: '77 → 70',
        chest: '89 → 86',
        waist: '76 → 70',
        hips: '110 → 102',
        points: '32',
        id: '40d3879e-4300-499f-90a4-6404aaa21204'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/1_1.JPG',
        name: 'Елена',
        weight: '58 → 55,8',
        chest: '91 → 88',
        waist: '75 → 71',
        hips: '95 → 94',
        points: '20',
        id: '0f618f9b-eaad-476a-8059-84a9f2d94738'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/16_1.JPG',
        name: 'Яна',
        weight: '67,1 → 59,1',
        chest: '94 → 88',
        waist: '91 → 84',
        hips: '103 → 95',
        points: '18',
        id: 'a2570fd3-8886-49a3-8744-ec060e790dd7'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/8_1.JPG',
        name: 'Лейсан',
        weight: '59 → 64',
        chest: '89 → 80',
        waist: '68 → 63',
        hips: '85 → 83',
        points: '18',
        id: '371995e9-0ac6-45b1-a466-c0aa35d51cbf'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/2_1.JPG',
        name: 'Анастасия',
        weight: '55,5 → 53',
        chest: '87 → 83',
        waist: '71 → 63',
        hips: '94 → 89',
        points: '17',
        id: '31d02e0f-0b92-4c03-b197-e011c10c2269'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/15_1.JPG',
        name: 'Ирина',
        weight: '65 → 60,45',
        chest: '95 → 91',
        waist: '74 → 68,5',
        hips: '101 → 98',
        points: '13',
        id: 'bc8370e7-be02-4736-a5f8-c7013c66e8b6'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/17_1.JPG',
        name: 'Аманда',
        weight: '70,1 → 64,8',
        chest: '91 → 90',
        waist: '78 → 71',
        hips: '100 → 94',
        points: '12',
        id: 'b4a2baab-a27c-42fc-8543-ff06399431f8'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/11_1.JPG',
        name: 'Елена',
        weight: '60 → 55',
        chest: '99 → 89',
        waist: '90 → 73',
        hips: '105 → 93',
        points: '6',
        id: '960e4243-46d3-4d1a-bbd7-f5f30359fbb8'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/9_2.jpg',
        name: 'Юлия',
        weight: '62,5 → 58',
        chest: '90 → 88',
        waist: '87 → 74',
        hips: '103 → 100',
        points: '3',
        id: '70d6cd78-e583-4336-bb5d-639392a7c0a5'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/20_1.JPG',
        name: 'Анастасия',
        weight: '64 → 58',
        chest: '94 → 89',
        waist: '88 → 75',
        hips: '100 → 94',
        points: '6',
        id: '608f3445-0c91-40d8-a8ac-bda91f9996c2'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/18_1.JPG',
        name: 'Кристина',
        weight: '62 → 57',
        chest: '100 → 95',
        waist: '72 → 68',
        hips: '99 → 95',
        points: '3',
        id: 'e71c2361-ad44-4f32-9e51-8180e41a5b24'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/7_1.JPG',
        name: 'Наталия',
        weight: '57 → 54',
        chest: '88 → 84',
        waist: '72 → 67',
        hips: '94 → 92',
        points: '2',
        id: '5d602d0b-7fdf-4349-bbd6-2d18f91e0b06'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/5_1.JPG',
        name: 'Виктория',
        weight: '64 → 59',
        chest: '93 → 90',
        waist: '81 → 74',
        hips: '93 → 91',
        points: '1',
        id: '8c4df468-07cb-476e-b2b5-9ae6247a5fdf'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/10_1.JPG',
        name: 'Елизавета',
        weight: '53,6 → 53',
        chest: '84 → 82',
        waist: '69 → 72',
        hips: '91,5 → 92',
        points: '1',
        id: '8818ba80-8529-42d9-9856-b464885d2421'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/19_1.JPG',
        name: 'Дарья',
        weight: '72,2 → 69,5',
        chest: '92 → 91',
        waist: '80 → 70',
        hips: '104 → 101',
        points: '1',
        id: '3c8e91d0-182b-407c-9fce-9750c97130d2'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/12_1.JPG',
        name: 'Людмила',
        weight: '60 → 59,5',
        chest: '88 → 81',
        waist: '68 → 65',
        hips: '99 → 99',
        poinst: '0',
        id: '2f5fedc8-2613-4d22-b730-7212b1078607'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/14_1.JPG',
        name: 'Лиззат',
        weight: '52,6 → 48,9',
        chest: '80 → 76',
        waist: '74 → 64',
        hips: '88 → 86',
        points: '0',
        id: '4ced2908-e840-44e4-bee8-b8a85a7031d7'
    }
]
export const L2023_11_Missvelvet2023 = () => {
    const [isModal,  setIsModal] = useState(false)
    const [inGroupOne, setInGroupOne] = useState(null)
    const [inGroupTwo, setInGroupTwo] = useState(null)
    const [inGroupThree, setInGroupThree] = useState(null)
    const [inGroupFour, setInGroupFour] = useState(null)
    const [votesOne, setVotesOne] = useState([])
    const [votesTwo, setVotesTwo] = useState([])
    const [votesThree, setVotesThree] = useState([])
    const [votesFour, setVotesFour] = useState([])

    const [alreadyVote, setAlreadyVote] = useState(false)

    useEffect(() => {
        document.body.id = 'rasprozarka_2305'
        document.body.parentElement.id = 'htmlrasprozarka_2305'

        checkVote()

        // getVotes(nom1, 1)
    }, [])

    const checkVote = () => {
        Promise.all(nom1.map((i) => checkUserInGroup(i.id))).then((result) => {
            result.map((res) => {
                if(res) {
                    setAlreadyVote(true)
                }
            })
        })
    }

    const getVotes = (nomination, number) => {
        Promise.all(nomination.map((i) => getUserCountByGroup(i.id))).then((result) => {
            number === 1 && setVotesOne(result)
            number === 2 && setVotesTwo(result)
            number === 3 && setVotesThree(result)
            number === 4 && setVotesFour(result)
        })
    }

    function LoginForm() {
        return (
          <>
            <Authorized>
                <Info />
                <Nomination nomination={nom1} setIsModal={setIsModal} alreadyVote={alreadyVote} checkVote={checkVote} />
                {/* {!inGroupOne && <Nomination nomination={nom1} texts={1} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesOne} />}
                {!inGroupTwo && <Nomination nomination={nom2} texts={2} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesTwo} />}
                {!inGroupThree && <Nomination nomination={nom3} texts={3} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesThree} />}
                {!inGroupFour && <Nomination nomination={nom4} texts={4} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesFour} />}

                {inGroupOne && inGroupTwo && inGroupThree && inGroupFour && 
                <>
                <div className={styles.voted}>
                    <div className={styles.voted_title}>ОТКРЫТОЕ<br/> ГОЛОСОВАНИЕ ЗАВЕРШЕНО</div>
                    <div className={styles.voted_subtitle}>Спасибо вам за участие в выборе победительниц!</div>
                    <div className={styles.voted_subtitle}>Мы подведем итоги 4 сентября</div>
                </div>
                <Nomination nomination={nom1} texts={1} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesOne} />
                <Nomination nomination={nom2} texts={2} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesTwo} />
                <Nomination nomination={nom3} texts={3} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesThree} />
                <Nomination nomination={nom4} texts={4} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesFour} />
                </>
                } */}
                <div className={styles.oferta}>
                    <a href="https://usmanovateam.ru/bs_competition2023" target='_blank'>КОНКУРСНАЯ ОФЕРТА</a>
                </div>
            </Authorized>
            <Anonymous>
                <div className={styles.auth}>
                    <div className={styles.auth_title}>Чтобы учавстовать в&nbsp;голосовании<br/> нужно авторизоваться</div>
                    <div className={styles.auth_widget + ' auth_safaricontest'}><LoginWidget /></div>
                </div>
            </Anonymous>
            {isModal && <Modal />}
          </>
        );
    }

    return (
        <>
        <DocumentTitle value='Мисс Бархатный сезон 2023 - открытое голосование'/>
        <DocumentDescription value='Открытое голосование за номинанток конкурса «Мисс Бархатный сезон 2023». Тренировки по фитнес-программам Кати Усмановой' />
        <div className='missvelvet_2305'>
            <Promo />
            <LoginForm />
            {/* <div className={styles.done}>
                <div className={styles.done_block}>
                    <div className={styles.done_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/done.png" alt="" /></div>
                    <div className={styles.done_title}>ОТКРЫТОЕ ГОЛОСОВАНИЕ<br/> ЗАВЕРШЕНО</div>
                    <div className={styles.done_subtitle}>Мы подведем итоги 17&nbsp;ноября</div>
                    <div className={styles.done_subtitle2}>Спасибо вам за&nbsp;участие в&nbsp;выборе победительниц!</div>
                </div>
            </div> */}
            <Footer isColor={'#F4F5FA'} />
        </div>
        </>
    );
};