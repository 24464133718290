import { useEffect } from "react";
import { useQuery } from "@com.marathonium/web2-utils";

import rendero from "../../rendero";

const AUTH_WIDGET_INIT_URL_LS_KEY = "auth_widget_init_url";

export function getAuthWidgetInitUrl() {
  return localStorage.getItem(AUTH_WIDGET_INIT_URL_LS_KEY);
}

export function clearAuthWidgetInitUrl() {
  return localStorage.removeItem(AUTH_WIDGET_INIT_URL_LS_KEY);
}

export function AuthWidget({
  isOpen,
  onClose,
  modal,
  theme,
  align,
  title,
  subtitle,
  init_step,
  id_social_net,
  onSuccess,
  id_confirm,
  shouldSaveInitUrl,
}) {
  const q = useQuery();
  const idSocialNet = id_social_net || q.get("id_social_net");
  const idConfirm = id_confirm || q.get("id_confirm");
  const _isOpen = !!idSocialNet || isOpen;

  useEffect(() => {
    if (_isOpen && shouldSaveInitUrl) {
      localStorage.setItem(AUTH_WIDGET_INIT_URL_LS_KEY, window.location.href);
    }
  }, [_isOpen, shouldSaveInitUrl]);

  return rendero.render({
    module: "screen",
    type: "screen",
    props: {
      dataFn: "callWebRpc",
      source: "widget_login_by_email_v1",
      params: {
        params: {
          modal,
          theme,
          align,
          title,
          subtitle,
          init_step,
          id_social_net: idSocialNet,
          id_confirm: idConfirm,
          success_callback_exists: typeof onSuccess === "function",
        },
      },
      vars: { isOpen: _isOpen, onClose, onSuccess },
    },
  });
}
