import styles from './promo.module.css';

const Promo = () => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_pretitle}>🔥 ВЫ ВЫБРАЛИ ОГНЕННЫХ ПОБЕДИТЕЛЬНИЦ</div>
                <div className={styles.promo_title}>МИСС БАРХАТНЫЙ<br/> СЕЗОН 2023</div>
                <div className={styles.promo_info}>
                    <div className={styles.promo_info_left}>
                        <div className={styles.promo_info_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/promo_1.png" alt="" />
                        </div>
                    </div>
                    <div className={styles.promo_info_right}>
                        <div className={styles.promo_info_title}>ПОСМОТРИТЕ ЗА&nbsp;КОГО<br/> ОТДАЛИ ГОЛОСА</div>
                        {/* <div className={styles.promo_info_text}>Объявляем открытое голосование!</div> */}
                    </div>
                </div>
                {/* <div className={styles.promo_block}>
                    <div className={styles.promo_item}>
                        <div className={styles.promo_item_number}>1</div>
                        <div className={styles.promo_item_text}>Катя Усманова и&nbsp;команда<br/> GymTeam выбрали <b>20&nbsp;финалисток<br/> с&nbsp;самыми крутыми «До/После»</b></div>
                    </div>
                    <div className={styles.promo_item}>
                        <div className={styles.promo_item_number}>2</div>
                        <div className={styles.promo_item_text}>Вместе с&nbsp;вами мы определим<br/> <b>3-х победительниц</b> и&nbsp;подведём<br/> итоги уже 17&nbsp;ноября</div>
                    </div>
                    <div className={styles.promo_item}>
                        <div className={styles.promo_item_number}>3</div>
                        <div className={styles.promo_item_text}>Ваш голос поможет<br/> девушкам получить призы<br/> <b>до&nbsp;100&nbsp;000&nbsp;₽</b></div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Promo;