import { useState } from 'react';
import styles from './faq.module.css';

const Faq = () => {
    const faqs = [
        {question: 'Тренировки проходят в определенное время?', answer: 'Вы сможете заниматься тогда, когда вам удобно. Вам нужно только выделить 20-30 минут в течение дня или вечера и повторять упражнения за тренером.'},
        {question: 'Нужно ли покупать абонемент в фитнес-клуб или специальное оборудование?', answer: <><p>Программы рассчитаны  для тренировок дома или в зале. Вы можете сами выбирать, где вам удобнее заниматься</p><p>Для некоторых программ необходимо оборудование, которое вы сможете заменить подручными средствами.</p></>},
        {question: 'Cмогу ли я заниматься, если живу не в России?', answer: 'Да. Тренировки доступны в любой точке мира. Вам понадобится смартфон, планшет или компьютер с доступом в интернет. Вы также можете использовать Smart TV.'},
        {question: 'Я оплатила заказ, но мне не пришло письмо с доступом. Что делать?', answer: <><p>После оплаты мы отправим письмо со ссылкой на вход в личный кабинет. Если вы не нашли письмо даже в папке со спамом, проверьте, правильно ли вы указали адрес электронной почты при оплате.</p><p>Обратите внимание, что письмо со ссылкой на личный кабинет приходит в течение 24-х часов после оплаты.</p><p>Если не нашли письмо, то напишите в нашу службу поддержки: <a href="mailto:support@gymteam.ru" target="_blank">support@gymteam.ru</a></p></>},
        {question: 'Я оплатила, как получить доступ к тренировкам?', answer: <><p>Вы получите доступ к программам комплекта в личном кабинете GymTeam сразу после покупки.</p><p>Для доступа к программам комплекта вам нужно авторизоваться на сайте или в приложении GymTeam.</p><p>Важно! Авторизация происходит только по номеру телефона, который указали при оплате. Обратите внимание, что письмо со ссылкой на личный кабинет приходит в течение 24-х часов после оплаты.</p></>},
        {question: 'Могу я оплатить курс картой иностранного банка?', answer: <><p>Да, можете. Всё просто — в блоке с ценой надо нажать «Продолжить» и заполнить форму заказа актуальными данными. Потом нажать кнопку «Оплатить картой иностранного банка».</p><p>Откроется платёжная страница, вам надо выбрать удобный способ оплаты и нажать кнопку «Оплатить».</p></>},
        {question: 'Как установить мобильное приложение GymTeam?', answer: <><p>Прямые ссылки для установки:</p><p><a href="https://apps.apple.com/us/app/id1533404428" target='_blank'>IOS App</a></p><p><a href="https://play.google.com/store/apps/details?id=fit.gymteam.apps.android" target='_blank'>Android App</a></p></>},
        {question: 'Как войти в аккаунт GymTeam по номеру телефона через web-сайт?', answer: <><p>Чтобы войти на платформу, необходимо пройти регистрацию по номеру мобильного телефона. ВНИМАНИЕ! Обязательно введите тот номер телефона, который вы указали при покупке.</p><p>Выполните инструкцию ниже по шагам:</p><p>Шаг 1 - Откройте веб сайт <a href="https://gymteam.ru/" target='_blank'> www.gymteam.ru</a></p><p>Шаг 2 - Укажите номер телефона в международном формате.</p><p>Шаг 3 - Нажмите на кнопку «Получить код».</p><p>Шаг 4 - Получите входящий вызов.</p><p>Шаг 5 - Введите последние 4 цифры входящего номера телефона, и нажмите на кнопку «Войти».</p></>},
        {question: 'Как войти в аккаунт GymTeam по номеру телефона через мобильное приложение?', answer: <><p>ВНИМАНИЕ! Обязательно введите тот номер телефона, который вы указали при покупке.</p><p>Шаг 1 - Введите номер телефона в международном формате, затем нажмите «Подтвердить».</p><p>Шаг 2 - Подтвердите входящий вызов, кликнув на кнопку.</p><p>Шаг 3 - Запомните 4 последние цифры входящего номера телефона. ВНИМАНИЕ! Отвечать на звонок не нужно.</p><p>Шаг 4 - Введите 4 цифры в поле для ввода, далее приложение автоматически произведет вход.</p><p>Шаг 5 - Если вам не поступил входящий вызов, то нажмите на кнопку «Отправить SMS сообщение».</p><p>Шаг 6 - Получите входящее SMS сообщение с кодом для входа, и введите цифры.</p></>},
        {question: 'Как купить другие программы Екатерины Усмановой?', answer: <><p>Купить другие программы вы можете самостоятельно на сайте программ или напишите нашим менеджерам <a href='mailto:support@gymteam.ru' target='_blank'>support@gymteam.ru</a></p><p>Купить программы можно по этим ссылкам:</p><p><a href="https://gymteam.ru/l/universalpop/" target="_blank">Универсальные тренировки на ягодицы</a><br/><a href="https://gymteam.ru/l/coursepop3" target="_blank">Курс идеальной попы 3.0</a><br/><a href="https://gymteam.ru/l/removefat" target="_blank">Марафон Убираем лишний жир</a><br/><a href="https://gymteam.ru/l/afterpregnancy" target="_blank">Курс Восстановление после родов</a><br/><a href='https://gymteam.ru/l/pregnancy' target='_blank'>Курс для беременных</a></p></>}
    ];
    
    const [clicked, setClicked] = useState("0")

    const handleToggle = (index) => {
        if (clicked === index) {
            setClicked("0");
        } else {
            setClicked(index);
        }
    }

    return(
        <div className={styles.faq}>
          <div className="container">
            
            <div className={styles.faq_title}>Отвечаем на вопросы</div>
            <div className={styles.accordion}>
              {faqs.map((faq, index) => (
                <div key={index} id={`faqnumber${index}`} className={clicked == index + 1 ? styles.active : ''}>
                  <p onClick={()=>handleToggle(index+1)} className={styles.question}>{faq.question} </p>
                  <p className={styles.answer}>{faq.answer}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
    );
};

export default Faq;