import { LoginWidget } from "@com.marathonium/web2-ui";
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";
import { DocumentDescription, DocumentTitle } from "../../components/DocumentMeta";
import { Redirect } from "./redirect/Redirect";
// import { Footer } from "./footer/Footer";

import styles from './style.module.css';
import './style.css';


export const L2023_03_Softskill = () => {

    function LoginForm() {
        return (
          <>
            <DocumentTitle value='SoftSkills' />
            <DocumentDescription value='SoftSkills' />
            <Authorized>
                <Redirect url='/' />
            </Authorized>
            <Anonymous>
                <div className={styles.auth}>
                    <div className={styles.logo}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/softskills/auth/logo.svg" alt="" />
                    </div>

                    <div className={styles.auth_block}>
                        <div className={styles.auth_title}>АВТОРИЗуйтесь</div>
                        <div className={styles.auth_subtitle}>Чтобы пройти курс «Думай сам».<br/> Другие ваши покупки доступны <a href="https://hakamaton.ru/sales/control/userProduct/my" target="_blank">по ссылке</a>.</div>
                        <div className='skills_widget__2303'>
                            <LoginWidget />
                        </div>
                        <div className={styles.auth_agree}>
                            <input type="checkbox" id="agree" checked="checked" />
                            <label htmlFor="agree">При входе вы соглашаетесь с <a href="/l/legal/public" target="_blank">правилами пользования сервисом</a></label>
                        </div>
                    </div>

                    <div className={styles.auth_info}>
                        <div className={styles.auth_info_text}>Все программы Ирины Хакамада</div>
                        <div className={styles.auth_info_btn}><a href="https://hakamaton.ru/main-page" target="_blank">Открыть каталог</a></div>
                    </div>
                </div>
                {/* <Footer /> */}

            </Anonymous>
          </>
        );
      }

    return (
        <LoginForm />
    );
};