import { useEffect, useState } from 'react';
import Footer from '../../components/Footer';
import Start2 from './components/Start2';
import Promo2 from './components/Promo2';

import './style.css';

export const L2023_02_Meltingprices = () => {

    const [startDate, setStartDate] = useState('22 февраля')

    const scroll = (name) => {
        const section = document.querySelector( name );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };   
    
    useEffect(() => {
        document.body.id = 'perezagruzka_2302'
        document.body.parentElement.id = 'htmlperezagruzka_2302'
        setStartDate(window.lps_settings.perezagruzka.date)
    }, [])

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };

    return (
        <div className='perezagruzka_2302'>
            <Promo2 scroll={() => scroll('.scrollhere')} renderer={renderer} date={date} startDate={startDate}/>
            <Start2 renderer={renderer} date={date} />
            <Footer />
        </div>
    );
};