import { useEffect, useState } from "react";
import { LoginWidget, checkUserInGroup, addUserToGroup, getUserCountByGroup, ShowForSegment, HideForSegment, checkUserInSegment, getUser } from "@com.marathonium/web2-ui";
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";

import './styles.css';
import styles from './prizes.module.css';
import Hidden from "./hidden";
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import Already from "./already";

const prizes = [
    {
        id: '70b2fe39-0379-4d45-b7ab-684d3a4c6b96',
        name: 'Денежный приз',
        description: <><p>Вы выиграли <b>1 000 рублей</b> на карту, чтобы немного побаловать себя. Купите себе что-то приятное и сделайте вложение в своё настроение.</p></>,
        limit: window.lps_settings.limits.prize1,
        type: 2
    },
    {
        id: '9288c30f-82c1-4559-a9fa-c828eb1f8f5b',
        name: 'Набор фитнес-резинок из 6 шт.',
        description: <>Вы выиграли <b>набор фитнес-резинок</b>. Ваш новый фитнес-помощник для красивого тела. Резинки для нагрузок разной интенсивности <b>помогут разнообразить привычные тренировки</b> и заниматься везде, где вам удобно. Набор настолько компактен, что не займет много места в рюкзаке или чемодане.</>,
        limit: window.lps_settings.limits.prize2,
        type: 1
    },
    {
        id: '8e8bafd8-2ba6-4ea4-bf28-2caa8e44768c',
        name: 'Умные весы Futula Smart Scale 4',
        description: <><p>Вы выиграли <b>умные весы Futula Smart Scale 4</b>. Они помогут следить за фигурой с удовольствием. Смотрятся шикарно, точно измеряют вес и не только. Весы дают информацию о состоянии организма, основываясь на 10 важных показателях. Все параметры показываются и сохраняются в специальном приложении на смартфон, в котором можно увидеть свой прогресс.</p></>,
        limit: window.lps_settings.limits.prize3,
        type: 1
    },
    {
        id: '30963329-065d-4af5-9d24-52efa8a1081c',
        name: 'Путёвка в Турцию',
        description: <><p>Вы выиграли <b>путёвку в Турцию на двоих</b>. Шикарный отдых, который вы никогда не забудете. Насладитесь чудесными видами, вкусной едой, комфортным проживанием и невероятной атмосферой Турции. Это возможность восстановить силы для новых достижений и похвастаться фигурой под бархатным солнцем.</p></>,
        limit: window.lps_settings.limits.prize4,
        type: 1
    },
    {
        id: '13ebaf6b-639b-43dc-9052-e99721245a2b',
        name: 'Пароварка электрическая Tefal VC 145130',
        description: <><p>Вы выиграли <b>электрическую пароварку Tefal VC 145130</b>. Она поможет вам экономить время на приготовлении пищи каждый день и питаться правильно. Вам достаточно придумать, что вы будете есть и закинуть нужные продукты. А сэкономленное время лучше потратьте на себя или тренировку.</p></>,
        limit: window.lps_settings.limits.prize5,
        type: 1
    },
    {
        id: '3c6bf679-acef-4362-8d0d-6efdf7009628',
        name: 'Беспроводная портативная колонка JBL Clip 4',
        description: <><p>Вы выиграли <b>беспроводную портативную колонку JBL Clip 4</b>. Это небольшая и компактная портативная колонка с высоким качеством звучания. Внешне она похожа на большой брелок и занимает мало места. Теперь вы сможете любое место превратить в вечеринку, сделать встречи с друзьями ярче и тренироваться дома под классный звук.</p></>,
        limit: window.lps_settings.limits.prize6,
        type: 1
    },
    {
        id: '492e0f79-1c02-4ff6-be3d-fec779e51b32',
        name: 'Фитнес-рюкзак',
        description: <><p>Вы выиграли <b>фитнес-рюкзак</b>. Он поможет вам переносить все необходимое для тренировок и других активностей. В нём есть вместительные карманы, прочные материалы и внутреннее удобство. Собирайтесь на тренировку с удовольствием.</p></>,
        limit: window.lps_settings.limits.prize7,
        type: 1
    },
    {
        id: 'cb0508d7-d2bf-40cc-8e1f-3a4bf70e43c4',
        name: 'Ролл для МФР',
        description: <><p>Вы выиграли <b>ролл для МФР</b>. Это массажный ролик для фитнеса, йоги или пилатеса — ваш новый фитнес-помощник для красивого тела. Он поможет расслабить мышцы, снять напряжение, проработать проблемные зоны и добавит видимый эффект вашим тренировкам.</p></>,
        limit: window.lps_settings.limits.prize8,
        type: 1
    },
    {
        id: '5b4e1f28-66ee-4559-af7b-ee5debaf841c',
        name: 'Кухонные электронные весы',
        description: <><p>Вы выиграли <b>кухонные электронные весы</b>. Это ваш незаменимый помощник, если вы поддерживаете дефицит питания или просто любите точные дозировки.</p></>,
        limit: window.lps_settings.limits.prize9,
        type: 1
    },
    {
        id: 'e328807c-f079-4433-aa7e-daf3d6f068e4',
        name: 'Apple Watch SE 2022',
        description: <><p>Вы выиграли <b>Apple Watch SE 2022!</b> Это мощное устройство для здорового образа жизни. Когда вы надеваете эти часы, они становятся настоящим партнёром по фитнесу, который учитывает каждое ваше движение и сообщает полезные данные о вашем здоровье.</p></>,
        limit: window.lps_settings.limits.prize10,
        type: 1
    },
    {
        id: '3ae1ef65-5e11-4664-9237-1fa808d542ca',
        name: 'Бонус «Детокс» с Катей Усмановой',
        description: <><p>Вы выиграли <b>марафон «Детокс» с Катей Усмановой</b>. Информация поможет пересмотреть свой рацион, понять, какие продукты вызывают тяжесть и как быстро очистить организм. Добавьте себе лёгкости и воздушности в самоощущении!</p></>,
        limit: window.lps_settings.limits.prize11,
        type: 1
    },
    {
        id: '581cf4a1-e5d0-49f1-9a72-aeb3d7a9589e',
        name: 'План питания от Марины Макиши',
        description: <><p>Вы выиграли <b>«План питания» от Марины Макиши</b>. Набор уроков, лекций и рецептов от врача-диетолога Марины Макиши. Вы <b>разберетесь со своим питанием, научитесь относиться к еде осознанно</b>. Узнаете простые правила похудения, которые помогут на пути к телу мечты.</p></>,
        limit: window.lps_settings.limits.prize12,
        type: 1
    }
]

const prizes2 = [
    {
        id: '70b2fe39-0379-4d45-b7ab-684d3a4c6b96',
        name: 'Денежный приз',
        description: <><p>Вы выиграли <b>1 000 рублей</b> на карту, чтобы немного побаловать себя. Купите себе что-то приятное и сделайте вложение в своё настроение.</p></>,
        limit: window.lps_settings.limits.prize1,
        type: 2
    },
    {
        id: '9288c30f-82c1-4559-a9fa-c828eb1f8f5b',
        name: 'Набор фитнес-резинок из 6 шт.',
        description: <>Вы выиграли <b>набор фитнес-резинок</b>. Ваш новый фитнес-помощник для красивого тела. Резинки для нагрузок разной интенсивности <b>помогут разнообразить привычные тренировки</b> и заниматься везде, где вам удобно. Набор настолько компактен, что не займет много места в рюкзаке или чемодане.</>,
        limit: window.lps_settings.limits.prize2,
        type: 1
    },
    {
        id: '8e8bafd8-2ba6-4ea4-bf28-2caa8e44768c',
        name: 'Умные весы Futula Smart Scale 4',
        description: <><p>Вы выиграли <b>умные весы Futula Smart Scale 4</b>. Они помогут следить за фигурой с удовольствием. Смотрятся шикарно, точно измеряют вес и не только. Весы дают информацию о состоянии организма, основываясь на 10 важных показателях. Все параметры показываются и сохраняются в специальном приложении на смартфон, в котором можно увидеть свой прогресс.</p></>,
        limit: window.lps_settings.limits.prize3,
        type: 1
    },
    {
        id: '13ebaf6b-639b-43dc-9052-e99721245a2b',
        name: 'Пароварка электрическая Tefal VC 145130',
        description: <><p>Вы выиграли <b>электрическую пароварку Tefal VC 145130</b>. Она поможет вам экономить время на приготовлении пищи каждый день и питаться правильно. Вам достаточно придумать, что вы будете есть и закинуть нужные продукты. А сэкономленное время лучше потратьте на себя или тренировку.</p></>,
        limit: window.lps_settings.limits.prize5,
        type: 1
    },
    {
        id: '3c6bf679-acef-4362-8d0d-6efdf7009628',
        name: 'Беспроводная портативная колонка JBL Clip 4',
        description: <><p>Вы выиграли <b>беспроводную портативную колонку JBL Clip 4</b>. Это небольшая и компактная портативная колонка с высоким качеством звучания. Внешне она похожа на большой брелок и занимает мало места. Теперь вы сможете любое место превратить в вечеринку, сделать встречи с друзьями ярче и тренироваться дома под классный звук.</p></>,
        limit: window.lps_settings.limits.prize6,
        type: 1
    },
    {
        id: '492e0f79-1c02-4ff6-be3d-fec779e51b32',
        name: 'Фитнес-рюкзак',
        description: <><p>Вы выиграли <b>фитнес-рюкзак</b>. Он поможет вам переносить все необходимое для тренировок и других активностей. В нём есть вместительные карманы, прочные материалы и внутреннее удобство. Собирайтесь на тренировку с удовольствием.</p></>,
        limit: window.lps_settings.limits.prize7,
        type: 1
    },
    {
        id: 'cb0508d7-d2bf-40cc-8e1f-3a4bf70e43c4',
        name: 'Ролл для МФР',
        description: <><p>Вы выиграли <b>ролл для МФР</b>. Это массажный ролик для фитнеса, йоги или пилатеса — ваш новый фитнес-помощник для красивого тела. Он поможет расслабить мышцы, снять напряжение, проработать проблемные зоны и добавит видимый эффект вашим тренировкам.</p></>,
        limit: window.lps_settings.limits.prize8,
        type: 1
    },
    {
        id: '5b4e1f28-66ee-4559-af7b-ee5debaf841c',
        name: 'Кухонные электронные весы',
        description: <><p>Вы выиграли <b>кухонные электронные весы</b>. Это ваш незаменимый помощник, если вы поддерживаете дефицит питания или просто любите точные дозировки.</p></>,
        limit: window.lps_settings.limits.prize9,
        type: 1
    },
    {
        id: '3ae1ef65-5e11-4664-9237-1fa808d542ca',
        name: 'Бонус «Детокс» с Катей Усмановой',
        description: <><p>Вы выиграли <b>марафон «Детокс» с Катей Усмановой</b>. Информация поможет пересмотреть свой рацион, понять, какие продукты вызывают тяжесть и как быстро очистить организм. Добавьте себе лёгкости и воздушности в самоощущении!</p></>,
        limit: window.lps_settings.limits.prize11,
        type: 1
    },
    {
        id: '581cf4a1-e5d0-49f1-9a72-aeb3d7a9589e',
        name: 'План питания от Марины Макиши',
        description: <><p>Вы выиграли <b>«План питания» от Марины Макиши</b>. Набор уроков, лекций и рецептов от врача-диетолога Марины Макиши. Вы <b>разберетесь со своим питанием, научитесь относиться к еде осознанно</b>. Узнаете простые правила похудения, которые помогут на пути к телу мечты.</p></>,
        limit: window.lps_settings.limits.prize12,
        type: 1
    }
]

export const L2023_08_Velvetbox = () => {
    const [modalActive, setModalActive] = useState(false)
    const [activePrize, setActivePrize] = useState({})
    const [isSelected, setIsSelected] = useState(false)
    const [isRusPhone, setIsRusPhone] = useState(false)
    const [prizeType, setPrizeType] = useState(0)
    const [alreadyShow, setAlreadyShow] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    function randomNumber(min, max) { 
        return Math.round(Math.random() * (max - min) + min);
    }

    useEffect(()=>{
        getUser().then((res) => {
            if(res.phone.substr(0,2) == '+7' && res.phone.length == 12) {
                setIsRusPhone(true)
            }
        })
    }, [])

    useEffect(()=> {
        {prizes.map((i, idx)=>{
            checkUserInGroup(i.id).then(res => {
                if(res) {
                    // console.log(res)
                    setPrizeType(1)
                    setAlreadyShow(true)
                }
            })
            if(idx + 1 == prizes.length) {
                setTimeout(()=>{
                    setIsLoading(false)
                }, 2000)
            }
        })}
        
    }, [])
    
    function givePrize() {
        setIsSelected(true)
        
        checkUserInSegment(window.lps_settings.segments.segment_velvet).then(res => {
            // console.log(res)
        if (!res) {
            return false;
        }
        if(isRusPhone) {
            Promise.all(prizes.map((i,idx) => getUserCountByGroup(i.id))).then((values) => {
                const availablePrizes = prizes.filter((i, idx) => {
                    return values[idx] < i.limit;
                });
                
                if (availablePrizes.length === 0) {
                    return false;
                }
                
                const randomPriceIdx = randomNumber(0, availablePrizes.length - 1);
                const prize = availablePrizes[randomPriceIdx];
                
                setPrizeType(prize.type)
                setModalActive(true)
                addUserToGroup(prize.id);
                setActivePrize(prize)
      
                return prize;
              }).then((prize) => console.log(prize));
        } else {
            Promise.all(prizes2.map((i,idx) => getUserCountByGroup(i.id))).then((values) => {
                const availablePrizes = prizes2.filter((i, idx) => {
                    return values[idx] < i.limit;
                });
                
                if (availablePrizes.length === 0) {
                    return false;
                }
                
                const randomPriceIdx = randomNumber(0, availablePrizes.length - 1);
                const prize = availablePrizes[randomPriceIdx];
                
                setPrizeType(prize.type)
                setModalActive(true)
                addUserToGroup(prize.id);
                setActivePrize(prize)
      
                return prize;
              }).then((prize) => console.log(prize));
        }
        
      });
    }

    function Boxes() {
        return (
            <>
                {!modalActive ?
                                <>
                                    <div className={styles.title1}>ВАС ЖДЁТ ГАРАНТИРОВАННЫЙ&nbsp;ПРИЗ</div>
                                    <div className={styles.title2}>ПРИЗОВЫЕ БОКСЫ</div>
                                    <div className={`${styles.prizes_block} ${isSelected && styles.prizes_block__active}`}>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/boxes/box2.jpg" alt="" />
                                        </div>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/boxes/box2.jpg" alt="" />
                                        </div>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/boxes/box2.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className={styles.title3}>Вам нужно открыть один из&nbsp;боксов прямо<br/> сейчас, чтобы&nbsp;получить свой приз!</div>
                                    </>
                                    :
                                    <>
                                        <div className={styles.win_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/boxes/prize.jpg" alt="" /></div>
                                        <div className={styles.win_name}>
                                            {activePrize.name}
                                        </div>
                                        <div className={styles.win_title}>Поздравляю!</div>
                                        <div className={styles.win_desc}>
                                            {activePrize.description}
                                        </div>
                                        <div className={styles.win_more}>Подробная информация о&nbsp;получении подарка у&nbsp;вас&nbsp;на&nbsp;почте.</div>
                                    </>
                            }
            </>
        )
    }

    function LoginForm() {
        return (
          <>
            <Authorized>
                <HideForSegment id={window.lps_settings.segments.segment_velvet}>
                    <Hidden/>
                </HideForSegment>

                <ShowForSegment id={window.lps_settings.segments.segment_velvet}>
                
                {isLoading
                ? <div className={styles.loader_block}><div className={styles.loader}><div></div><div></div><div></div><div></div></div></div>
                : <>
                    {alreadyShow
                        ? <Already />
                        : <Boxes/>
                    }
                  </>
                }
                                
                </ShowForSegment>

            </Authorized>
            <Anonymous>
                <div className={styles.prizes_ghost_title}>Чтобы получить доступ к&nbsp;боксам Бархатного сезона нужно авторизоваться</div>
                <div className="velvet_prizes_login"><LoginWidget /></div>
            </Anonymous>
          </>
        );
      }

    return (
        <>
        <DocumentTitle value='Призовые боксы'/>
        <DocumentDescription value='' />
        <div className={`${styles.prizes} ${prizeType == 1 && styles.prizes_def} ${prizeType == 2 && styles.prizes_money}`}>
            <div className={styles.prizes_main}>
               <LoginForm />
            </div>
        </div>
        </>
    );
};