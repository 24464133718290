import styles from './preims.module.css';

const Preims = () => {
    return (
        <div className={styles.preims}>
            <div className="container">
                <div className={styles.preims_title}>Доверьте свои тренировки<br/> чемпионке фитнес-бикини и&nbsp;тренеру<br/> с&nbsp;15-летним опытом Кате Усмановой</div>
                <div className={styles.preims_block}>
                    <div className={styles.preims_left}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/preims2.png" alt="" />
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/preims2.png" alt="" />
                    </div>
                    <div className={styles.preims_right}>
                        <div>Катя доказала на&nbsp;личном примере и&nbsp;примере 300&nbsp;000&nbsp;учениц, что результат возможно получить без изматывающих тренировок, больших весов и&nbsp;жёстких диет</div>
                        <ul>
                            <li>Создатель платформы <strong>GymTeam</strong></li>
                            <li><strong>Вице-чемпионка мира</strong> и&nbsp;чемпионка России по&nbsp;фитнес-бикини</li>
                            <li><strong>Чемпионка России и&nbsp;мира по&nbsp;жиму лёжа</strong></li>
                            <li><strong>Автор первых в&nbsp;России</strong> масштабных марафонов по&nbsp;созданию идеальных ягодиц</li>
                            <li>Профессиональный фитнес-тренер <strong>с&nbsp;опытом более 15&nbsp;лет</strong></li>
                            <li>Мама 2-х детей. <strong>Всего за&nbsp;100 дней после первых родов похудела на&nbsp;20&nbsp;кг</strong> и&nbsp;вернулась в&nbsp;прежнюю форму</li>
                        </ul>
                    </div>
                </div>
                <div className={styles.preims_line}>
                    <div className={styles.preims_line__item}>
                        <div className={styles.preims_line__title}>>8 ЛЕТ</div>
                        <div className={styles.preims_line__text}>развиваем индустрию<br/> <strong>онлайн-фитнеса</strong></div>
                    </div>
                    <div className={styles.preims_line__item}>
                        <div className={styles.preims_line__title}>>10</div>
                        <div className={styles.preims_line__text}><strong>авторских программ</strong><br/> для&nbsp;занятий дома и&nbsp;в&nbsp;зале</div>
                    </div>
                    <div className={styles.preims_line__item}>
                        <div className={styles.preims_line__title}>>4 000 +</div>
                        <div className={styles.preims_line__text}><strong>учеников со&nbsp;всего мира</strong><br/> тренируются с&nbsp;нами каждый<br/> день</div>
                    </div>
                    <div className={styles.preims_line__item}>
                        <div className={styles.preims_line__title}>>300 000</div>
                        <div className={styles.preims_line__text}><strong>историй успеха,</strong> которые<br/> начались с&nbsp;мечты<br/> о&nbsp;шикарном теле</div>
                    </div>
                    <div className={styles.preims_line__item}>
                        <div className={styles.preims_line__title}>8 из 10</div>
                        <div className={styles.preims_line__text}>участниц получили<br/> <strong>желаемый результат</strong></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Preims;