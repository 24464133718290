import styles from "./author.module.css";

const Author = () => {
  return (
    <div className={styles.preims}>
      <div className="container">
        <div className={styles.preims_block}>
          <div className={styles.preims_left}>
            <img
              src="https://gymteam.kinescopecdn.net/img/l/2024/um2/author4.png"
              alt=""
            />
            <img
              src="https://gymteam.kinescopecdn.net/img/l/2024/um2/author4.png"
              alt=""
            />
          </div>
          <div className={styles.preims_right}>
            <div className={styles.preims_title}>
              Доверь создание стройного тела Кате Усмановой&nbsp;&mdash; она
              помогла уже более <span>280&nbsp;000</span> девушек достичь
              результата
            </div>
            <div className={styles.preims_subtitle}>
              За&nbsp;15&nbsp;лет тренерской карьеры Катя создала
              20+&nbsp;фитнес-курсов и&nbsp;записала 500+&nbsp;тренировок. Она
              доказала эффективность своей методики на&nbsp;собственном примере
              и&nbsp;многих тысяч кейсов своих учениц.
            </div>
            <ul>
              <li>
                Профессиональный фитнес-тренер с&nbsp;опытом более 15&nbsp;лет
              </li>
              <li>
                Вице-чемпионка и&nbsp;чемпионка России по&nbsp;фитнес-бикини
              </li>
              <li>
                Мама 2-х детей. Всего за&nbsp;100 дней после первых родов
                похудела на&nbsp;20&nbsp;кг и&nbsp;вернулась в&nbsp;прежнюю
                форму
              </li>
              <li>
                Автор первых в&nbsp;России масштабных марафонов стройности
              </li>
              <li>Чемпионка России и&nbsp;мира по&nbsp;жиму лёжа</li>
            </ul>
          </div>
        </div>
        <div className={styles.preims_line}>
          {/* <div className={styles.preims_line__item}>
                        <div className={styles.preims_line__title}>>10</div>
                        <div className={styles.preims_line__text}><strong>авторских программ</strong><br/> для&nbsp;занятий дома и&nbsp;в&nbsp;зале</div>
                    </div> */}
          <div className={styles.preims_line__item}>
            <div className={styles.preims_line__title}>>4 000 +</div>
            <div className={styles.preims_line__text}>
              <strong>учеников со&nbsp;всего мира</strong>
              <br /> тренируются с&nbsp;нами каждый
              <br /> день
            </div>
          </div>
          {/* <div className={styles.preims_line__item}>
                        <div className={styles.preims_line__title}>>280 000</div>
                        <div className={styles.preims_line__text}><strong>историй успеха,</strong> которые<br/> начались с&nbsp;мечты<br/> о&nbsp;шикарном теле</div>
                    </div> */}
          <div className={styles.preims_line__item}>
            <div className={styles.preims_line__title}>9 из 10</div>
            <div className={styles.preims_line__text}>
              участниц получили
              <br /> <strong>желаемый результат</strong>
            </div>
          </div>
          <div className={styles.preims_line__item}>
            <div className={styles.preims_line__title}>>8 ЛЕТ</div>
            <div className={styles.preims_line__text}>
              развиваем индустрию
              <br /> <strong>онлайн-фитнеса</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Author;
