import Countdown from 'react-countdown';
import styles from './promo2.module.css';

const Promo2 = ({scroll, date, renderer, startDate}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_block}>
                    <div className={styles.promo_pretitle}>только 24 часа</div>
                    <div className={styles.promo_title}>ПЛАВИМ ЦЕНЫ</div>
                    <div className={styles.promo_subtitle}>на ваш заказ</div>
                    <div className={styles.promo_icons}>
                        <div className={styles.promo_icon}>
                            <div className={styles.promo_icon_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka/2/promo1.png" alt="" /></div>
                            <p><strong>10&nbsp;спортивных<br/> программ</strong><br/> Кати Усмановой</p>
                        </div>
                        <div className={styles.promo_icon}>
                            <div className={styles.promo_icon_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka/2/promo2.png" alt="" /></div>
                            <p><strong>Доступ на&nbsp;год</strong></p>
                        </div>
                        <div className={styles.promo_icon}>
                            <div className={styles.promo_icon_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/perezagruzka/2/promo3.png" alt="" /></div>
                            <p><strong>Скидка 96%</strong></p>
                        </div>
                    </div>
                    <div className={styles.start_countdown}>
                        <p><span>Вы ещё не&nbsp;забрали свой заказ?</span></p>
                        <p>Закрытая продажа&nbsp;— это ваш шанс!</p>
                        <div className={styles.start_countdown_timer}>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                    </div>
                    <div className={styles.promo_btn} onClick={scroll}><p>ЗАБРАТЬ ПРОГРАММЫ</p></div>
                </div>
            </div>
            
        </div>
    )
}

export default Promo2;