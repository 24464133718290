import Countdown from 'react-countdown';
import styles from './promo.module.css';

const Promo = ({scroll, date, renderer}) => {
    return (
        <>
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_block}>
                    <div className={styles.promo_pretitle}>Специальное предложение для&nbsp;участниц<br/> фитнес-бота!</div>
                    <div className={styles.promo_title}>УПРУГАЯ ПОПА<br/> 1.0&nbsp;И&nbsp;2.0 <span><img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/promo_imgs.png" alt="" /></span></div>
                    <div className={styles.promo_subtitle}>Получите ягодицы с &nbsp;эффектом push-up<br/> всего за&nbsp;месяц домашних тренировок!</div>
                    
                    <div className={styles.promo_btns}>
                        <div className={styles.promo_btn} onClick={scroll}><p>ЗАБРАТЬ ПРОГРАММЫ</p></div>
                        <div className={styles.promo_countdown}>
                            <p>До закрытия<br/> спецпредложения</p>
                            <div className={styles.promo_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
                    </div>

                    <div className={styles.promo_points}>
                        <div className={styles.promo_points_item}>
                            <div className={styles.promo_points_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/promo_1.png" alt="" /></div>
                            <div className={styles.promo_points_title}>Лучшие марафоны</div>
                            <div className={styles.promo_points_text}>для&nbsp;проработки ягодиц</div>
                        </div>
                        <div className={styles.promo_points_item}>
                            <div className={styles.promo_points_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/promo_2.png" alt="" /></div>
                            <div className={styles.promo_points_title}>Выгода 85%</div>
                            <div className={styles.promo_points_text}>на&nbsp;комплект из&nbsp;2&nbsp;фитнес-программ</div>
                        </div>
                        <div className={styles.promo_points_item}>
                            <div className={styles.promo_points_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/promo_3.png" alt="" /></div>
                            <div className={styles.promo_points_title}>Старт сразу</div>
                            <div className={styles.promo_points_text}>после оплаты</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.promo_circle}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/foryou/promo_circle.png" alt="" />
            </div>
        </div>
        </>
    )
}

export default Promo;