import { useEffect, useRef, useState } from 'react';
import styles from './faq.module.css';

const Faq = () => {
    const faqs = [
        {question: 'Подойдут ли мне тренировки?', answer: <div><p>Тренировки подойдут вам, если у вас нет медицинских противопоказаний к спортивным нагрузкам, но:</p><ul><li>есть лишние кг, от которых нужно избавиться</li><li>есть проблемные зоны</li><li>вы хотите нарастить мышечную массу, создать рельеф тела</li></ul><p>Спортивная нагрузка предназначена для возраста от 12 лет, верхней границы нет. Расчет дневной нормы калорийности питания прописан для человека от 18 лет. Ориентируйтесь на своё состояние, медицинские противопоказания и работу с весами, адекватную для вашего возраста.</p></div>},
        {question: 'Тренировки проходят в определённое время?', answer: 'Вы сможете заниматься тогда, когда вам удобно. Вам нужно только выделить до 30 минут в течение дня или вечера и повторять упражнения за тренером.'},
        {question: 'Нужно ли покупать абонемент в фитнес-клуб или специальное оборудование?', answer: <div><p>Программы рассчитаны  для тренировок дома. Вы можете сами выбирать, где вам удобнее заниматься.</p><p>Для некоторых программ необходимо оборудование, которое вы сможете заменить подручными средствами.</p></div>},
        {question: 'Как мне считать калории', answer: <div><p>Для вашего удобства мы сделали калькулятор КБЖУ, который учтет вашу активность и фитнес цели.
        Чтобы воспользоваться калькулятором, перейдите по ссылке <a href="https://gymteam.ru/l/calculator/" target='_blank'>https://gymteam.ru/l/calculator/</a></p></div>},
        {question: 'Cмогу ли я заниматься, если живу не в России?', answer: <div><p>Да. Тренировки доступны в любой точке мира. Вам понадобится смартфон, планшет или компьютер с доступом в интернет. Вы также можете использовать Smart TV.</p></div>},
        {question: 'Я оплатила заказ, но мне не пришло письмо с доступом. Что делать?', answer: <div><p>После оплаты мы отправим письмо со ссылкой на вход в личный кабинет. Если вы не нашли письмо даже в папке со спамом, проверьте, правильно ли вы указали адрес электронной почты при оплате.</p><p>Обратите внимание, что письмо со ссылкой на личный кабинет приходит в течение 24-х часов после оплаты.</p><p>Если не нашли письмо, то напишите в нашу службу поддержки: <a href="mailtosupport@gymteam.ru">support@gymteam.ru</a></p></div>},
        {question: 'Я оплатила, как получить доступ к тренировкам?', answer: <div><p>Вы получите доступ к программам комплекта в личном кабинете GymTeam сразу после покупки.</p><p>Для доступа к программам комплекта вам нужно авторизоваться на сайте или в приложении GymTeam.</p><p>Важно! Авторизация происходит только по номеру телефона, который указали при оплате. Обратите внимание, что письмо со ссылкой на личный кабинет приходит в течение 24-х часов после оплаты.</p></div>},
        {question: 'Могу я оплатить курс картой иностранного банка?', answer: <div><p>Да, можете. Всё просто — в блоке с ценой надо нажать «Продолжить» и заполнить форму заказа актуальными данными. Потом нажать кнопку «Оплатить картой иностранного банка».</p><p>Откроется платёжная страница, вам надо выбрать удобный способ оплаты и нажать кнопку «Оплатить».</p></div>},
        {question: 'Как установить мобильное приложение GymTeam?', answer: <div><p>Прямые ссылки для установки:</p><p><a href="https://apps.apple.com/us/app/id1533404428" target='_blank'>IOS App</a></p><p><a href="https://play.google.com/store/apps/details?id=fit.gymteam.apps.android" target='_blank'>Android App</a></p></div>},
        {question: 'Как войти в аккаунт GymTeam по номеру телефона через web-сайт?', answer: <div><p>Чтобы войти на платформу, необходимо пройти регистрацию по номеру мобильного телефона. ВНИМАНИЕ! Обязательно введите тот номер телефона, который вы указали при покупке.</p><p>Выполните инструкцию ниже по шагам:</p><p>Шаг 1 - Откройте веб сайт <a href="https://gymteam.ru/" target='_blank'> www.gymteam.ru</a></p><p>Шаг 2 - Укажите номер телефона в международном формате.</p><p>Шаг 3 - Нажмите на кнопку «Получить код».</p><p>Шаг 4 - Получите входящий вызов.</p><p>Шаг 5 - Введите последние 4 цифры входящего номера телефона, и нажмите на кнопку «Войти».</p></div>},
        {question: 'Как войти в аккаунт GymTeam по номеру телефона через мобильное приложение?', answer: <div><p>ВНИМАНИЕ! Обязательно введите тот номер телефона, который вы указали при покупке.</p><p>Шаг 1 - Введите номер телефона в международном формате, затем нажмите «Подтвердить».</p><p>Шаг 2 - Подтвердите входящий вызов, кликнув на кнопку.</p><p>Шаг 3 - Запомните 4 последние цифры входящего номера телефона. ВНИМАНИЕ! Отвечать на звонок не нужно.</p><p>Шаг 4 - Введите 4 цифры в поле для ввода, далее приложение автоматически произведет вход.</p><p>Шаг 5 - Если вам не поступил входящий вызов, то нажмите на кнопку «Отправить SMS сообщение».</p><p>Шаг 6 - Получите входящее SMS сообщение с кодом для входа, и введите цифры.</p></div>},
        {question: 'Как купить другие программы Екатерины Усмановой?', answer: <div><p>Купить другие программы вы можете самостоятельно на главной странице или напишите нашим менеджерам <a href='mailto:support@gymteam.ru' target='_blank'>support@gymteam.ru</a></p><p>Купить программы можно по этой ссылке:</p><p><a href='https://gymteam.ru/l/coursegym/' target='_blank'>Курс для зала</a><br/><a href='https://gymteam.ru/l/pregnancy/' target='_blank'>Курс для беременных</a><br/><a href='https://gymteam.ru/l/universalpop/' target='_blank'>Универсальные тренировки на ягодицы</a><br/><a href='https://gymteam.ru/l/fatburning/' target='_blank'>Жиросжигающий курс</a><br/><a href='https://gymteam.ru/l/afterpregnancy/' target='_blank'>Курс Восстановление после родов</a><br/><a href='https://gymteam.ru/l/coursepop2/' target='_blank'>Курс Идеальной попы 2.0</a><br/><a href='https://gymteam.ru/l/coursepop3/' target='_blank'>Курс Идеальной попы 3.0</a><br/><a href='https://gymteam.ru/l/nutrition/' target='_blank'>Курс Питание</a><br/><a href='https://gymteam.ru/l/marafonstr1/' target='_blank'>Марафон Стройность 1.0</a><br/><a href='https://gymteam.ru/l/marafonabs/' target='_blank'>Марафон Плоского живота</a><br/><a href='https://gymteam.ru/l/anticellulite/' target='_blank'>Марафон Антицеллюлитный</a><br/><a href='https://gymteam.ru/l/removefat/' target='_blank'>Марафон Убираем лишний жир</a></p></div>}
    ];

    const [clicked, setClicked] = useState("0")

    const handleToggle = (index) => {
        if (clicked === index) {
            setClicked("0");
        } else {
            setClicked(index);
        }
    }

    const ref = useRef(null)

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    function scrollHandler() {
        const scolling = window.scrollY;
        const block1 = document.querySelector('.new__b1').offsetHeight;
        const block2 = document.querySelector('.new__b2').offsetHeight;
        const block3 = document.querySelector('.new__b3').offsetHeight;
        const block4 = document.querySelector('.new__b4').offsetHeight;
        const block5 = document.querySelector('.new__b5').offsetHeight;
        const block6 = document.querySelector('.new__b6').offsetHeight;
        const block7 = document.querySelector('.new__b7').offsetHeight;
        const fullSize = block1 + block2 + block3 + block4 + block5 + block6 + block7;
        const res = scolling - fullSize;
        if(Math.sign(res) == -1) {
            ref.current.style.transform = `translateY(${Math.abs(res)/6}px)`
        } else {
            ref.current.style.transform = `translateY(-${Math.abs(res)/5}px)`
        }
    }

    return(
        <div className={styles.faq}>
          <div className="container">
            <div className={styles.faq_block}>
              <div className={styles.faq_left}>
                <div className={styles.faq_titles}>
                  <div>
                    {/* <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/faq.png" alt="" /> */}
                  </div>
                  <div className={styles.faq_title}>Отвечаем<br/> на&nbsp;вопросы</div>
                </div>
              </div>
              <div className={styles.faq_right}>
                <div className={styles.accordion}>
                  {faqs.map((faq, index) => (
                    <div key={faq.answer} className={clicked == index + 1 ? styles.active : ''}>
                      <p onClick={()=>handleToggle(index+1)} className={styles.question}>{faq.question} </p>
                      <p className={styles.answer}>{faq.answer}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

export default Faq;