import styles from './about.module.css';

const About = () => {
    return (
        <div className={styles.about}>
            <div className="container">
                <div className={styles.about_block}>
                    <div className={styles.about_left}>
                        <div className={styles.about_title}>Катя Усманова&nbsp;— </div>
                        <div className={styles.about_subtitle}>По онлайн-программам Кати Усмановой ежедневно тренируются 4&nbsp;000&nbsp;девушек со&nbsp;всего мира.</div>
                        <div className={styles.about_info}>
                            <p>Участницы выбирают формат онлайн-тренировок, потому&nbsp;что:</p>
                            <ul>
                                <li><span>нет времени на&nbsp;фитнес-клуб</span></li>
                                <li><span>не хватает самодисциплины и&nbsp;мотивации</span></li>
                                <li><span>нет понимания, как&nbsp;правильно тренироваться и&nbsp;питаться</span></li>
                                <li><span>не с&nbsp;кем оставить ребёнка</span></li>
                                <li><span>онлайн-тренировки экономят время на&nbsp;дорогу в&nbsp;спортзал и&nbsp;обратно</span></li>
                                <li><span>домашние тренировки дешевле, чем&nbsp;занятия с&nbsp;тренером оффлайн</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.about_right}>
                        <div className={styles.about_items}>
                            <div className={styles.about_item}>
                                <div className={styles.about_item__info}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/about_1.png" alt="" />
                                    <p>Авторские<br/> фитнес-программы<br/> для&nbsp;разных целей и&nbsp;условий</p>
                                </div>
                            </div>
                            {/* <div className={styles.about_item}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/about_2.png" alt="" />
                                <p>КУРС ЛЮБВИ<br/> К&nbsp;СЕБЕ И&nbsp;ЗДОРОВОЙ<br/> САМООЦЕНКИ</p>
                            </div> */}
                            <div className={styles.about_item}>
                                <div className={styles.about_item__info}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/about_3.png" alt="" />
                                    <p>ПОДРОБНОЕ<br/> АНКЕТИРОВАНИЕ<br/> и&nbsp;поддержка 24/7</p>
                                </div>
                            </div>
                            <div className={styles.about_item}>
                                <div className={styles.about_item__info}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/usmanova_mainpage/about_4.png" alt="" />
                                    <p>КОМАНДА ПРОФЕССИОНАЛОВ: ТРЕНЕР,<br/> ПСИХОЛОГ, ДИЕТОЛОГ, СПОРТИВНЫЙ ВРАЧ</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;