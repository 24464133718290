import Countdown from 'react-countdown';
import styles from './block4.module.css';

const Block4 = ({scroll, renderer}) => {
    return (
        <div className={styles.block4}>
            <div className="container">
               {/* <div className={styles.b1}>
                    <div className={styles.b1_1}>Выиграй AirPods Max!</div>
                    <div className={styles.b1_2}>29&nbsp;мая в&nbsp;12:00 МСК</div>
                    <div className={styles.b1_3}>проведём розыгрыш среди всех, кто<br/> купит программы <b>в&nbsp;течение 15&nbsp;минут</b></div>
                    <div className={styles.b1_mob}><img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/prize2.png" alt="" /></div>
                    <div className={styles.b1_4}>Но&nbsp;и&nbsp;это ещё не&nbsp;всё!</div>
                    <div className={styles.b1_5}>Каждый день мы&nbsp;рандомно<br/> выбираем и&nbsp;<span>дарим по&nbsp;10&nbsp;000&nbsp;₽</span><br/> двум купившим программы</div>
               </div>

               <div className={styles.t1}>Как проходит розыгрыш<br/> денежных призов?</div>
               <div className={styles.b2}>
                    <div className={styles.b2_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/b2_1.png" alt="" />
                        <p>Ты&nbsp;оформляешь покупку<br/> пакета программ по&nbsp;акции</p>
                    </div>
                    <div className={styles.b2_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/b2_2.png" alt="" />
                        <p>Каждый день мы&nbsp;выбираем<br/> 2-х победителей с&nbsp;помощью<br/> генератора случайных чисел</p>
                    </div>
                    <div className={styles.b2_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/b2_3.png" alt="" />
                        <p>В&nbsp;течение 24&nbsp;часов<br/> с&nbsp;победителем связывается<br/> наш менеджер и&nbsp;сообщает,<br/> как получить 10&nbsp;000&nbsp;₽</p>
                    </div>
               </div> */}

               <div className={styles.sticky}>
                    <div className={styles.sticky_left}>
                        <div className={styles.sticky_sticky}>
                            <div className={styles.s1}>Бонус 2</div>
                            <div className={styles.s2}>5&nbsp;полезных гайдов</div>
                            <div className={styles.s3}>С&nbsp;ними вы&nbsp;научитесь тратить меньше времени на&nbsp;готовку, снизите потребление сахара и&nbsp;сделаете тренировки эффективнее</div>
                            <div className={styles.promo_btn} onClick={scroll}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                                <p>Получить программы и&nbsp;бонусы</p>
                            </div>
                            <div className={styles.s4}>Успейте забрать набор программ<br/> и&nbsp;получить ВСЕ бонусы</div>
                            <div className={styles.timer1}>
                                <Countdown date={Date.now() + 900000} renderer={renderer} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.sticky_right}>
                        <div className={styles.sticky_item}>
                            <span>сборник</span>
                            <p>Путеводитель<br/> по&nbsp;программам набора<br/> фитнес-программ</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/gift_1.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Гайд</span>
                            <p>Книга рецептов Кати<br/> Усмановой</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/gift_2.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>гайд</span>
                            <p>Инструкция по&nbsp;созданию<br/> тонкой талии</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/gift_3.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Инструкция</span>
                            <p>19&nbsp;правил прогресса<br/> в&nbsp;спорте</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/gift_4.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <p>Трекер снижения веса</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/gift_5.png" alt="" />
                        </div>
                    </div>
                    <div className={styles.st_mob}>
                        <div className={styles.s4}>Успейте забрать набор программ<br/> и&nbsp;получить ВСЕ бонусы</div>
                            <div className={styles.timer1}>
                                <Countdown date={Date.now() + 900000} renderer={renderer} />
                            </div>
                        <div className={styles.promo_btn} onClick={scroll}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                            <p>Получить программы и&nbsp;бонусы</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Block4;