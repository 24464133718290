import styles from './block2.module.css';

const Block2 = ({scroll}) => {
    return (
        <div className={styles.block2}>
            <div className="container">
                <div className={styles.title}>Ваши результаты после<br/> прохождения программ</div>
                <div className={styles.block}>
                    <div className={styles.item}>
                        <div className={styles.number}>#1</div>
                        <div className={styles.text}>Избавитесь<br/> от&nbsp;лишнего веса</div>
                        <div className={styles.img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/res1.png" alt="" />
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.number}>#2</div>
                        <div className={styles.text}>Создадите стройную<br/> и&nbsp;подтянутую фигуру</div>
                        <div className={styles.img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/res2.png" alt="" />
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.number}>#3</div>
                        <div className={styles.text}>Накачаете округлую<br/> и&nbsp;соблазнительную<br/> попу</div>
                        <div className={styles.img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/res3.png" alt="" />
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.number}>#4</div>
                        <div className={styles.text}>Подтянете животик<br/> и&nbsp;сделаете его более<br/> плоским</div>
                        <div className={styles.img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/res4.png" alt="" />
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.number}>#5</div>
                        <div className={styles.text}>Полюбите спорт,<br/> появится больше<br/> энергии и&nbsp;сил</div>
                        <div className={styles.img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/res5.png" alt="" />
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.number}>#6</div>
                        <div className={styles.text}>Будете нравиться себе<br/> в&nbsp;зеркале и&nbsp;сможете<br/> носить любимую<br/> одежду</div>
                        <div className={styles.img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/res6.png" alt="" />
                        </div>
                    </div>
                </div>

                <div className={styles.promo_btn} onClick={scroll}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                    <p>Хочу забрать программы</p>
                </div>
            </div>
        </div>
    )
}

export default Block2;