import { useEffect } from 'react';
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';

import styles from './style.module.css';
import './style.css'
import Promo from './components/Promo';
import New from './components/New';
import Preims from './components/Preims';
import Trainings from './components/Trainings';
import Start from './components/Start';
import Faq from './components/Faq';
import Footer from '../../components/Footer';
import Reviews from './components/Reviews';
import Video from './components/Video';
import New2 from './components/New2';

export const L2024_05_Rasprozharka_up = () => {

    useEffect(() => {
        document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll = () => {
        const section = document.querySelector( '.scrollhere' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };

    return (
        <>
        <DocumentTitle value='ПОДПИСКА НА 50+ СПОРТИВНЫХ ПРОГРАММ GYMTEAM С ВЫГОДОЙ ДО 58%'/>
        <DocumentDescription value='ПОДПИСКА НА 50+ СПОРТИВНЫХ ПРОГРАММ GYMTEAM С ВЫГОДОЙ ДО 58%' />
        <div className={styles.stretchweek}>
            <Promo date={date} renderer={renderer} scroll={scroll} />
            <Video />
            <Trainings scroll={scroll} />
            <New />
            <Preims />
            <Reviews />
            <New2 />
            <Start date={date} renderer={renderer} />
            <Faq scroll={scroll} />
            <Footer />
        </div>
        </>
    );
};