import styles from './prizes.module.css';

const Hidden = () => {
    return (
        <div className={styles.prizes_hide}>
            <div className={styles.prizes_hide_pretitle}>Для того, чтобы открыть один из&nbsp;боксов</div>
            <div className={styles.prizes_hide_title}>оплатите пакет фитнес-программ</div>
            <div className={styles.prize_hide_images}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/boxes/box2.jpg" alt="" />
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/boxes/box2.jpg" alt="" />
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/boxes/box2.jpg" alt="" />
            </div>
            <div className={styles.prizes_hide__btn}><a href="/l/velvet"><p>ЗАБРАТЬ ПРОГРАММЫ</p></a></div>
        </div>
    )
}

export default Hidden;

