import styles from './new.module.css';

const New = () => {
    return(
        <div className={styles.new}>
            <div className="container">
                <div className={styles.new1}>
                    <div className={styles.new_title}><p>Мы&nbsp;настолько уверены в&nbsp;курсе, что вернём полную стоимость программы,</p></div>
                    <div className={styles.new_subtitle}><img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow2.svg" alt="" /> если вы&nbsp;не&nbsp;увидите результат в&nbsp;течение 2&nbsp;недель</div>
                </div>
            </div>
        </div>
    );
};

export default New;