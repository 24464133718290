import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Gym from './components/Gym';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_01_Gym_tg = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    return (
      <>
        <DocumentTitle value='5 тренировок для зала'/>
        <DocumentDescription value='5 тренировок для зала' />
        <div className='onepage_fitmission'>
            <Gym />
            <Footer version={6} isColor={'#131313'} />
        </div>
      </>
    );
};