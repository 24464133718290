import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Promo from './components/Promo';
import Programs from './components/Programs';
import Reserve from './components/Reserve';
import Students from './components/Students';
import Start from './components/Start';
import Faq from './components/Faq';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_07_Um2_up = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll = () => {
        const section = document.querySelector( '.choose' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    const scroll2 = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    const renderer = ({hours, minutes, seconds, completed}) => {
      let hourzero = '';
      let minuteszero = '';
      let secondszero = '';
      if(hours<10) {hourzero = '0'}
      if(minutes<10) {minuteszero = '0'}
      if(seconds<10) {secondszero = '0'}
      return (
          <div>
              <div><p>{minuteszero}{minutes}</p> <span>мин.</span></div>
              <div><p>{secondszero}{seconds}</p> <span>сек.</span></div>
          </div>
      );
    };

    const renderer2 = ({hours, minutes, seconds, completed}) => {
      let hourzero = '';
      let minuteszero = '';
      let secondszero = '';
      if(hours<10) {hourzero = '0'}
      if(minutes<10) {minuteszero = '0'}
      if(seconds<10) {secondszero = '0'}
      return (
          <div>
              <div><p>{minuteszero}{minutes}</p> <span>:</span></div>
              <div><p>{secondszero}{seconds}</p> </div>
          </div>
      );
    };
    
    return (
      <>
        <DocumentTitle value='Выгода до 92% на фитнес-набор для участниц Метода Усмановой'/>
        <DocumentDescription value='Только для участниц Метода Усмановой - выгода до 92% на любой из 3 наборов фитнес-программ' />
        <div className='um2'>
              <Promo scroll={scroll} renderer={renderer}/>
              <Programs scroll={scroll2} />
              {/* <Reserve scroll={scroll2} renderer={renderer2} /> */}
              <Students />
              <Start renderer={renderer2} />
              <Faq />
              <Footer isColor={'#F6FAFF'} />
        </div>
      </>
    );
};