import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { SalesWidget } from "@com.marathonium/web2-ui";
import { useInstance } from "@com.marathonium/web2-utils";
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import styles from './style.module.css';
import './style.css';
import { useParams } from 'react-router-dom';

export const L2024_08_Quiz1_price = () => {
    const [chose, setChose] = useState(0)
    const [widget] = useInstance();
    const [date, setDate] = useState(Date.now() + 600000)

    const { q1, q2, q3, q4, q5 } = useParams();

    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    // let nextMidnight = new Date();
    // nextMidnight.setHours(24, 0, 0, 0);
    // const now = new Date();
    // const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                {/* <div>{hourzero}{hours} <span>:</span></div> */}
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value=''/>
        <DocumentDescription value='' />
        <div className={styles.main}>
          <div className={styles.logo}>
            <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/logo.svg" alt="" />
          </div>

          <div className={styles.block}>

            <div className={styles.b1}>
              <div className={styles.i1}>
                <div className={styles.i1_title}>Сейчас</div>
                <img src={q5 == 1 ? 'https://gymteam.fit/quiz/pics/w-1.jpg' : q5 == 2 ? 'https://gymteam.fit/quiz/pics/w-2.jpg' : q5 == 3 ? 'https://gymteam.fit/quiz/pics/w-3.jpg' : 'https://gymteam.fit/quiz/pics/w-4.jpg'} alt="" />
                <div className={styles.st1}>Процент жира <span>{q5 == 1 ? '10-19%' : q5 == 2 ? '20-29%' : q5 == 3 ? '30-39%' : '>40%'}</span></div>
                <div className={styles.st1_1}>Мышечная масса</div>
                <ul>
                  <li className={styles.activeli}></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
              <div className={styles.i1}>
                <div className={styles.i1_title}>После программы</div>
                <img src={q5 == 1 ? 'https://gymteam.fit/quiz/pics/w-1.jpg' : q5 == 2 ? 'https://gymteam.fit/quiz/pics/w-1.jpg' : q5 == 3 ? 'https://gymteam.fit/quiz/pics/w-2.jpg' : 'https://gymteam.fit/quiz/pics/w-2.jpg'} alt="" />
                <div className={styles.st1}>Процент жира <span>{q5 == 1 ? '10-19%' : q5 == 2 ? '10-19%' : q5 == 3 ? '20-29%' : '20-29%'}</span></div>
                <div className={styles.st1_1}>Мышечная масса</div>
                <ul>
                  <li className={styles.activeli}></li>
                  <li className={styles.activeli}></li>
                  <li className={styles.activeli}></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>

            <div className={styles.b2}>Это не&nbsp;является гарантией или обещанием результатов.</div>

            <div className={styles.b3}>
              <div className={styles.b3_left}>
                <div className={styles.countdown}>
                  <p><span>Скидка 60%</span> доступна&nbsp;в&nbsp;течение:</p>
                  <div className={styles.countdown_timer}>
                      <Countdown date={date} renderer={renderer} />
                  </div>
                </div>
              </div>
              <div className={styles.b3_right}>
                <div className={styles.btn1} onClick={scroll}>Получить план</div>
              </div>
            </div>

            {q1 && q2 && q3 ?
            <>
            <div className={styles.b4}>Ваш персональный план тренировок:</div>

            <div className={styles.b5}>
              <div className={styles.i5}><p>Ваш уровень</p><span>{q4 == 1 ? 'Начинающий' : q4 == 2 ? 'Средний' : 'Продвинутый' }</span></div>
              <div className={styles.i5}><p>Ваш возраст</p><span>{q3}</span></div>
              <div className={styles.i5}><p>Ваш вес</p><span>{q1} кг</span></div>
              <div className={styles.i5}><p>Целевой вес</p><span>{q2} кг</span></div>              
            </div>
            </>
            : null
            }
            

            <div className={'scrollhere ' + styles.b6}>Достигайте своих целей с&nbsp;вашим персонализированным планом тренировок!</div>

            <div className={styles.b7}>
              <div className={`${styles.i7} + ${chose == 0 && styles.i7_active}`} onClick={()=>{widget.resetOrder(); widget.selectByIndex(0); setChose(0)}}>
                <div className={styles.i7_left}>
                  <p>1-недельный план</p>
                  <span><em>1733&nbsp;₽</em> 693&nbsp;₽ в&nbsp;неделю</span>
                </div>
                <div className={styles.i7_right}>
                  <p>99,0&nbsp;₽</p>
                  <p>247,5&nbsp;₽</p>
                  <span>в&nbsp;день</span>
                </div>
              </div>
              <div className={styles.i7_top}>САМЫЙ ПОПУЛЯРНЫЙ</div>
              <div className={`${styles.i7} + ${chose == 1 && styles.i7_active}`} onClick={()=>{widget.resetOrder(); widget.selectByIndex(1); setChose(1)}}>
                <div className={styles.i7_left}>
                  <p>4-недельный план</p>
                  <span><em>3867&nbsp;₽</em> 1574&nbsp;₽ в&nbsp;месяц</span>
                </div>
                <div className={styles.i7_right}>
                  <p>49,5&nbsp;₽</p>
                  <p>124,8&nbsp;₽</p>
                  <span>в&nbsp;день</span>
                </div>
              </div>
              <div className={`${styles.i7} + ${chose == 2 && styles.i7_active}`} onClick={()=>{widget.resetOrder(); widget.selectByIndex(2); setChose(2)}}>
                <div className={styles.i7_left}>
                  <p>12-недельный план</p>
                  <span><em>6859&nbsp;₽</em> 2744&nbsp;₽ за&nbsp;3&nbsp;месяца</span>
                </div>
                <div className={styles.i7_right}>
                  <p>29,5&nbsp;₽</p>
                  <p>73,8&nbsp;₽</p>
                  <span>в&nbsp;день</span>
                </div>
              </div>
            </div>

            <div className="quiz_start">
              <SalesWidget hook={widget} mandatoryFields={'only_phone'} id={window.lps_settings.forms.quiz_2_id}/>
            </div>

            <div className={styles.b10}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/gar.png" alt="" />
                <p><b>100% гарантия возврата денег</b></p>
                <p>Мы&nbsp;абсолютно уверены в&nbsp;эффективности наших тренировок и&nbsp;гарантируем результат. Если вы&nbsp;не&nbsp;заметите прогресса за&nbsp;30&nbsp;дней, то&nbsp;мы&nbsp;вернем деньги. Нужно выполнить минимум 20&nbsp;тренировок с&nbsp;момента оплаты.</p>
            </div>

            <div className={styles.b8}>
              <ul>
                <li><b>Это подписка с&nbsp;автоматическим продлением.</b></li>
                <li>Сегодня GymTeam спишет с&nbsp;вас {chose == 0 ? '693' : chose == 1 ? '1574' : '2744'} ₽.</li>
                <li>Вы&nbsp;можете отменить подписку в&nbsp;любое время через настройки сервиса GymTeam или обратившись в&nbsp;службу поддержки по&nbsp;адресу <a href="mailto:support@gymteam.ru">support@gymteam.ru</a>.</li>
              </ul>
            </div>

            <div className={styles.b6}>Вот что говорят пользователи GymTeam:</div>

            <div className={styles.b9}>
              <div className={styles.i9}>
                <div className={styles.i9_top}>
                  <div className={styles.i9_top_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/w1.jpg" alt="" />
                  </div>
                  <div className={styles.i9_top_text}>
                    <p>Наталья</p>
                    <span>2&nbsp;недели тренировок с&nbsp;GymTeam</span>
                  </div>
                </div>
                <div className={styles.i9_middle}>🔥Восторг! впервые приобрела тренировки онлайн, не&nbsp;ожидала, что так эффективно пройдёт это вводное занятие! тренер огонь)</div>
                <div className={styles.i9_bottom}>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star.svg" alt="" />
                </div>
              </div>
              <div className={styles.i9}>
                <div className={styles.i9_top}>
                  <div className={styles.i9_top_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/w2.jpg" alt="" />
                  </div>
                  <div className={styles.i9_top_text}>
                    <p>Аделина Кочеткова</p>
                    <span>4&nbsp;недели тренировок с&nbsp;GymTeam</span>
                  </div>
                </div>
                <div className={styles.i9_middle}>Разнообразие упражнений, а&nbsp;также интерфейс приложения крайне приятны. Мне действительно нравится открывать это приложение каждый день. За&nbsp;две недели я&nbsp;сделала больше вариаций упражнений, чем за&nbsp;целый год с&nbsp;другим популярным фитнес-приложением.</div>
                <div className={styles.i9_bottom}>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star.svg" alt="" />
                </div>
              </div>
              <div className={styles.i9}>
                <div className={styles.i9_top}>
                  <div className={styles.i9_top_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/w3.jpg" alt="" />
                  </div>
                  <div className={styles.i9_top_text}>
                    <p>Маргарита Егорова</p>
                    <span>2&nbsp;недели тренировок с&nbsp;GymTeam</span>
                  </div>
                </div>
                <div className={styles.i9_middle}>Я&nbsp;использовала и&nbsp;пробовала много приложений, но&nbsp;в&nbsp;этом есть все тренировки, которые мне нужны. Если вы&nbsp;никогда раньше не&nbsp;занимались и&nbsp;чувствуете себя неуверенно, это определенно отличный способ начать тренировки дома.</div>
                <div className={styles.i9_bottom}>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star.svg" alt="" />
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </>
    );
};