import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Start from './components/Start';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_01_Fitmission3_sms = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value='Фитмиссиия – фитнес-программы Кати Усмановой под ваши цели и розыгрыш призов'/>
        <DocumentDescription value='Выгода до 92% на комплект фитнес-программ под ваши цели от Кати Усмановой и розыгрыш призов' />
        <div className='fitmission_2024'>
            <Start renderer={renderer} date={date} />
            <Footer version={3} isColor={'#000000'} />
        </div>
      </>
    );
};