import React, { useEffect } from 'react';
import './style.css';
import { SalesWidget } from "@com.marathonium/web2-ui";
import { useInstance } from "@com.marathonium/web2-utils";
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_04_Usmanovatest = () => {

    const [widget] = useInstance();

    useEffect(() => {
       
        const intervalId = setInterval(() => {
            widget.selectByIndex(0);
            document.querySelector('.usmanovatest').classList.remove('hidden')
        }, 100)
        
        return () => clearInterval(intervalId);
    }, [])

    
    return (
      <>
        <div className='usmanovatest hidden'>
            <SalesWidget hook={widget} id={window.lps_settings.forms.usmanovatest_id} />
        </div>

        <Footer version={3} isColor={'#000000'} />
      </>
    );
};