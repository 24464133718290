import React, { useEffect } from 'react';
import './style.css';
import Footer from '../../components/Footer';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
const Start = React.lazy(() => import('./components/Start'));

export const L2023_12_Marafon_sms = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value='Акция ЗАЖИГАЙ! - марафон «Стройность 1.0» с выгодой 50% и чат с куратором'/>
        <DocumentDescription value='Акция ЗАЖИГАЙ! - культовая программа Кати Усмановой «Стройность 1.0» с выгодой 50%, чат с поддержкой куратора и доступ на 3 месяца!' />
        <div className='asd chp4'>
            <Start renderer={renderer} date={date} />
            <Footer version={2} isColor={'#000000'} />
        </div>
      </>
    );
};