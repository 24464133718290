import { useEffect, useState } from 'react';
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import { LoginWidget, checkUserInGroup, getUserCountByGroup } from "@com.marathonium/web2-ui";
import Footer from '../../components/Footer';
import Promo from './components/Promo';
import { Modal } from './components/Modal';
import { Nomination } from './components/Nomination';
import Info from './components/Info';
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";
import styles from './main.module.css';
import './style.css'

const nom1 = [
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/10_1.JPG',
        name: 'Елена',
        weight: '60 → 59,4',
        chest: '90 → 86',
        waist: '71 → 74',
        hips: '89 → 93',
        points: '65',
        place: '1 МЕСТО',
        id: '3ed821db-3bb9-46f3-8e17-0d14693ed8cd'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/4_1.JPG',
        name: 'Полина',
        weight: '59 → 56,5',
        chest: '83 → 82',
        waist: '70 → 64',
        hips: '95 → 94',
        points: '58',
        place: '2 МЕСТО',
        id: '102573c4-0780-4da5-97cf-2f93469b9815'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/3_1.JPG',
        name: 'Екатерина',
        weight: '48,5 → 49,6',
        chest: '83 → 85',
        waist: '65 → 62',
        hips: '93 → 93',
        points: '27',
        place: '3 МЕСТО',
        id: 'f8f7daba-64e7-4a37-93b3-9e4eef9d774d'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/8_1.JPG',
        name: 'Мария',
        weight: '56 → 57',
        chest: '89 → 87',
        waist: '66 → 64',
        hips: '97 → 97',
        points: '23',
        place: '4 МЕСТО',
        id: '70b45232-36c9-40d8-8025-167750aa1d01'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/2_1.JPG',
        name: 'Cветлана',
        weight: '61,5 → 60',
        chest: '86 → 87',
        waist: '70 → 67',
        hips: '97 → 99',
        points: '15',
        place: '5 МЕСТО',
        id: '0f6bf838-0814-45b8-ba16-cbaf3edba47d'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/11_1.JPG',
        name: 'Айгуль',
        weight: '56,4 → 55,9',
        chest: '86 → 81',
        waist: '68 → 67',
        hips: '93 → 95',
        points: '14',
        place: <>&nbsp;</>,
        id: 'f0d80ca3-dbea-47c5-9ae1-df53896d3a75'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/13_1.JPG',
        name: 'Элеонора',
        weight: '64 → 59,9',
        chest: '100 → 97',
        waist: '86 → 77',
        hips: '104 → 102',
        points: '10',
        id: '40c50c6c-33fc-4ce7-b1d9-6caf285cb24c'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/14_1.JPG',
        name: 'Наталья',
        weight: '57,7 → 58',
        chest: '83 → 86',
        waist: '67 → 65',
        hips: '94 → 90',
        points: '10',
        id: '31128255-6569-400c-b632-1a096bc36e41'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/7_1.JPG',
        name: 'Галина',
        weight: '55 → 52',
        chest: '89 → 89',
        waist: '72 → 65',
        hips: '91 → 90',
        points: '9',
        id: '07c20b58-a942-412d-8144-4cd83b42370e'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/5_1.JPG',
        name: 'Анна',
        weight: '41 → 42',
        chest: '78 → 78',
        waist: '60 → 59',
        hips: '83 → 85',
        points: '9',
        id: 'ed44a3d7-5a74-416f-9868-b56f5c7e33a3'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/6_1.JPG',
        name: 'Екатерина',
        weight: '56,1 → 55',
        chest: '85 → 84',
        waist: '66 → 64',
        hips: '95 → 96',
        points: '7',
        id: '8ae300f7-ca97-4051-b08a-82d2314219e6'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/9_1.JPG',
        name: 'Анна',
        weight: '83 → 73',
        chest: '94 → 90',
        waist: '83 → 70',
        hips: '110 → 102',
        points: '6',
        id: '90b3baf3-9672-4bba-8b73-4d29c5a753c9'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/16_1.JPG',
        name: 'Лариса',
        weight: '57 → 60',
        chest: '85,5 → 87',
        waist: '64,5 → 64',
        hips: '91,5 → 95',
        points: '3',
        id: '7326df5d-a55f-4c8a-a62c-3b8fa1aa8284'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/15_1.JPG',
        name: 'Юнна',
        weight: '57 → 56',
        chest: '90 → 90',
        waist: '69 → 66,5',
        hips: '97 → 93,5',
        points: '2',
        id: '166dc651-a55b-4670-aaf7-b2166f61bb15'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/1_1.JPG',
        name: 'Инга',
        weight: '61 → 61.8',
        chest: '84 → 85',
        waist: '74 → 72',
        hips: '96 → 97',
        points: '1',
        id: 'e41719d8-f16d-44c7-84a3-34ed381ed819'
    },
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/12_1.JPG',
        name: 'Наталья',
        weight: '49 → 51',
        chest: '85 → 85',
        waist: '69 → 69',
        hips: '88 → 92',
        id: 'b058bf5a-9e91-4694-9c38-fe323e2166a3',
        points: '1'
    }
]
export const L2023_11_Misshot = () => {
    const [isModal,  setIsModal] = useState(false)
    const [inGroupOne, setInGroupOne] = useState(null)
    const [inGroupTwo, setInGroupTwo] = useState(null)
    const [inGroupThree, setInGroupThree] = useState(null)
    const [inGroupFour, setInGroupFour] = useState(null)
    const [votesOne, setVotesOne] = useState([])
    const [votesTwo, setVotesTwo] = useState([])
    const [votesThree, setVotesThree] = useState([])
    const [votesFour, setVotesFour] = useState([])

    const [alreadyVote, setAlreadyVote] = useState(false)

    useEffect(() => {
        document.body.id = 'rasprozarka_2305'
        document.body.parentElement.id = 'htmlrasprozarka_2305'

        checkVote()

        // getVotes(nom1, 1)
    }, [])

    const checkVote = () => {
        Promise.all(nom1.map((i) => checkUserInGroup(i.id))).then((result) => {
            result.map((res) => {
                if(res) {
                    setAlreadyVote(true)
                }
            })
        })
    }

    const getVotes = (nomination, number) => {
        Promise.all(nomination.map((i) => getUserCountByGroup(i.id))).then((result) => {
            number === 1 && setVotesOne(result)
            number === 2 && setVotesTwo(result)
            number === 3 && setVotesThree(result)
            number === 4 && setVotesFour(result)
        })
    }

    function LoginForm() {
        return (
          <>
            <Authorized>
                <Info />
                <Nomination nomination={nom1} setIsModal={setIsModal} alreadyVote={alreadyVote} checkVote={checkVote} />
                {/* {!inGroupOne && <Nomination nomination={nom1} texts={1} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesOne} />}
                {!inGroupTwo && <Nomination nomination={nom2} texts={2} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesTwo} />}
                {!inGroupThree && <Nomination nomination={nom3} texts={3} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesThree} />}
                {!inGroupFour && <Nomination nomination={nom4} texts={4} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesFour} />}

                {inGroupOne && inGroupTwo && inGroupThree && inGroupFour && 
                <>
                <div className={styles.voted}>
                    <div className={styles.voted_title}>ОТКРЫТОЕ<br/> ГОЛОСОВАНИЕ ЗАВЕРШЕНО</div>
                    <div className={styles.voted_subtitle}>Спасибо вам за участие в выборе победительниц!</div>
                    <div className={styles.voted_subtitle}>Мы подведем итоги 4 сентября</div>
                </div>
                <Nomination nomination={nom1} texts={1} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesOne} />
                <Nomination nomination={nom2} texts={2} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesTwo} />
                <Nomination nomination={nom3} texts={3} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesThree} />
                <Nomination nomination={nom4} texts={4} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesFour} />
                </>
                } */}
                <div className={styles.oferta}>
                    <a href="https://usmanovateam.ru/misshot_competition2023" target='_blank'>КОНКУРСНАЯ ОФЕРТА</a>
                </div>
            </Authorized>
            <Anonymous>
                <div className={styles.auth}>
                    <div className={styles.auth_title}>Чтобы учавстовать в&nbsp;голосовании<br/> нужно авторизоваться</div>
                    <div className={styles.auth_widget + ' auth_safaricontest'}><LoginWidget /></div>
                </div>
            </Anonymous>
            {isModal && <Modal />}
          </>
        );
    }

    return (
        <>
        <DocumentTitle value='Мисс Бархатный сезон 2023 - открытое голосование'/>
        <DocumentDescription value='Открытое голосование за номинанток конкурса «Мисс Бархатный сезон 2023». Тренировки по фитнес-программам Кати Усмановой' />
        <div className='missvelvet_2305'>
            <Promo />
            <LoginForm />
            {/* <div className={styles.done}>
                <div className={styles.done_block}>
                    <div className={styles.done_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/done.png" alt="" /></div>
                    <div className={styles.done_title}>ОТКРЫТОЕ ГОЛОСОВАНИЕ<br/> ЗАВЕРШЕНО</div>
                    <div className={styles.done_subtitle}>Мы подведем итоги 17&nbsp;ноября</div>
                    <div className={styles.done_subtitle2}>Спасибо вам за&nbsp;участие в&nbsp;выборе победительниц!</div>
                </div>
            </div> */}
            <Footer isColor={'#F4F5FA'} />
        </div>
        </>
    );
};