import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation} from 'swiper'
import 'swiper/css'
import "swiper/css/grid";
import "swiper/css/navigation";

import styles from './new2.module.css';
import { useRef } from 'react';

const New2 = () => {

    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    return (
        <div className={styles.new}>
            <div className="container">
                <div className={styles.t1}>Забирай бонусы при&nbsp;оплате</div>
                <div className={styles.block}>
                    <div className={styles.block_info}>
                        <div className={styles.block_pretitle}>Бонус 1</div>
                        <div className={styles.block_title}>Аудиокурс от&nbsp;Кати Усмановой: &laquo;Женсовет&raquo;&nbsp;<br/> &mdash; <span>20&nbsp;женских советов на&nbsp;каждый день</span></div>
                    </div>
                    <div className={styles.block_img}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/up/new_bg.png" alt="" />
                    </div>
                </div>

                <div className={styles.block_2}>
                    <div className={styles.block2_pretitle}>Бонус 2</div>
                    <div className={styles.block2_title}>По 3&nbsp;рецепта завтраков, обедов, ужинов, полдников<br/> и&nbsp;десертов <span>для сбалансированного питания</span><br/> от&nbsp;нашего нутрициолога</div>

                <div className={styles.slider_block}>
                    <Swiper 
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    loop
                    onSwiper={(swiper) => {
                        setTimeout(() => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current
                        swiper.params.navigation.nextEl = navigationNextRef.current
                        
                        swiper.navigation.destroy()
                        swiper.navigation.init()
                        swiper.navigation.update()
                        })
                    }}
                    slidesPerView={1}
                    breakpoints={{
                        992: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                    }}
                    modules={[Navigation]}
                    className={styles.slider}
                >
                    <SwiperSlide className={styles.new_slider}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/up/gift_1.png" alt="" />
                        <p>Сборник завтраков</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.new_slider}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/up/gift_2.png" alt="" />
                        <p>Сборник обедов</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.new_slider}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/up/gift_3.png" alt="" />
                        <p>Сборник ужинов</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.new_slider}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/up/gift_4.png" alt="" />
                        <p>Сборник десертов</p>
                    </SwiperSlide>
                    <SwiperSlide className={styles.new_slider}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/up/gift_5.png" alt="" />
                        <p>Сборник полдников</p>
                    </SwiperSlide>
                </Swiper>
                <div className={styles.slider_nav}>
                        <div ref={navigationPrevRef} className={styles.slider_btn_prev}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/up/arrow.svg" alt="" />
                        </div>
                        <div ref={navigationNextRef} className={styles.slider_btn_next}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/up/arrow.svg" alt="" />
                        </div>
                    </div>
                </div>
                </div>
            </div>                
        </div>
    )
}

export default New2;