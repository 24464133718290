import Video from '../../05-rasprozharka2/components/Video';
import styles from './second.module.css';

const Second = () => {
    return (
        <div className={styles.second}>
            <div className={styles.line}>
                <div className={styles.line_text}>
                    <p>выгода 87%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 87%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 87%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 87%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 87%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 87%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 87%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 87%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 87%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 87%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 87%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 87%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 87%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                </div>
            </div>
        
            <div className="container">
                {/* <div className={styles.block}>
                    <div className={styles.item}>
                        <div className={styles.item_t1}>дарим по&nbsp;10&nbsp;000&nbsp;<span>₽</span></div>
                        <div className={styles.item_t2}>Каждый день дарим по&nbsp;10&nbsp;000 рублей<br/> двум купившим&nbsp;&mdash; выбираем рандомно!</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_t1}>стайлер dyson</div>
                        <div className={styles.item_t2}>23&nbsp;мая разыграем фен-<br/>стайлер Dyson среди<br/> всех купивших</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_t1}>5&nbsp;ГАЙДОВ</div>
                        <div className={styles.item_t2}>Дарим 5&nbsp;полезных гайдов<br/> при покупке</div>
                    </div>
                </div> */}

                {/* <Video /> */}

                {/* <div className={styles.block2}>
                    <div className={styles.block2_title}><span>Готова преобразить свое тело?</span><br/> Тогда выбирай 1&nbsp;из&nbsp;3&nbsp;комплектов программ<br/> Кати Усмановой с&nbsp;выгодой до&nbsp;87%</div>
                    <div className={styles.block2_subtitle}>Не&nbsp;упусти шанс уже этим летом выглядеть стройнее<br/> и&nbsp;увереннее&nbsp;&mdash; начинай сейчас!</div>
                </div> */}
            </div>
        
        </div>
        
    )
}

export default Second;