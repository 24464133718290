import styles from './chance.module.css';

const Chance = () => {
    return (
        <div className={styles.chance}>
            <div className={styles.chance_title}>ЧТО ВЫ МОЖЕТЕ УСПЕТЬ<br/> ЭТИМ ЛЕТОМ?</div>
            <div className={styles.chance_block}>
                <div className={styles.chance_item}>
                    <div className={styles.chance_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/summer/chance1.png" alt="" /></div>
                    <div className={styles.chance_name}>Похудеть минимум на&nbsp;1&nbsp;размер</div>
                </div>
                <div className={styles.chance_item}>
                    <div className={styles.chance_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini2/chance2.jpg" alt="" /></div>
                    <div className={styles.chance_name}>Почувствовать лёгкость в&nbsp;теле</div>
                </div>
                <div className={styles.chance_item}>
                    <div className={styles.chance_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini/chance3.jpg" alt="" /></div>
                    <div className={styles.chance_name}>Ловить на&nbsp;себе восторженные взгляды</div>
                </div>
                <div className={styles.chance_item}>
                    <div className={styles.chance_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini2/chance4.jpg" alt="" /></div>
                    <div className={styles.chance_name}>Сменить слитный купальник на&nbsp;бикини</div>
                </div>
                <div className={styles.chance_item}>
                    <div className={styles.chance_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/summer/chance5.png" alt="" /></div>
                    <div className={styles.chance_name}>Уменьшить объём бёдер, живота и&nbsp;рук</div>
                </div>
                <div className={styles.chance_item}>
                    <div className={styles.chance_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/bikini2/chance6.jpg" alt="" /></div>
                    <div className={styles.chance_name}>Избавиться от&nbsp;целлюлита</div>
                </div>
            </div>
            <div className={styles.chance_more}>ПЛЯЖНЫЙ СЕЗОН <span>УЖЕ НАЧАЛСЯ</span></div>
        </div>
    )
}

export default Chance;