import styles from './programs.module.css';

const Programs = ({scroll}) => {
    return (
        <div className={styles.programs}>
            <div className="container">
                
                <div className={styles.t1}>Мы&nbsp;собрали для вас набор<br/> <span>программ на&nbsp;всё тело:</span></div>
                <div className={styles.st1}>похудеть, убрать живот, избавиться от&nbsp;целлюлита, сделать попу<br/> округлой, а&nbsp;все тело стройным и&nbsp;подтянутым</div>
                <div className={styles.dts}>
                    <div className={styles.dts_item}>
                        <div className={styles.dts_item_img_1}><img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/sprites/svg.svg" alt="" /></div>
                        <div className={styles.dts_item_text}>Доступ&nbsp;&mdash; 9&nbsp;месяцев</div>
                    </div>
                    <div className={styles.dts_item}>
                        <div className={styles.dts_item_img_2}><img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/sprites/svg.svg" alt="" /></div>
                        <div className={styles.dts_item_text}>Старт&nbsp;&mdash; сразу после оплаты</div>
                    </div>
                </div>
            </div>
                <div className={styles.programs_block}>
                    <div className={styles.programs_item}>
                            <div className={styles.programs_info}>
                                <div className={styles.programs_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/1.jpg" alt="" />
                                    <div className={styles.programs_img__info}>
                                        <div className={styles.programs_tags}>
                                            <div className={styles.programs_tag}>Похудеть</div>
                                            <div className={styles.programs_tag}>Придать телу рельеф</div>
                                        </div>
                                        <div className={styles.programs_img__info_title}><p>МАРАФОН</p> <span>СТРОЙНОСТЬ 1.0</span></div>
                                        <div className={styles.programs_img__info_price}>5 000 <span>₽</span></div>
                                    </div>
                                </div>
                                <div className={styles.programs_text}>
                                    <ul>
                                        <li><b>Длительность:</b> 21&nbsp;день</li>
                                        <li><b>Без инвентаря</b></li>
                                        <li><b>График тренировок:</b> ежедневно</li>
                                    </ul>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="p1" name="p1" />
                                        <label htmlFor="p1">Для кого подойдёт <p>Марафон для&nbsp;новичков и&nbsp;тех, кто уже обладает некоторым опытом. <b>Программа поможет быстро похудеть</b> и&nbsp;сформировать привычку правильно питаться и&nbsp;регулярно заниматься фитнесом.</p></label>
                                    </div>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn2" name="pn2" />
                                        <label htmlFor="pn2">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Похудеешь минимум на&nbsp;1&nbsp;размер</li>
                                                <li>Подтянешь руки, живот, попу и&nbsp;ноги</li>
                                                <li>Улучшишь самочувствие и&nbsp;повысишь уровень энергии</li>
                                                <li>Разберешься в&nbsp;системе сбалансированного питания</li>
                                                <li>Сделаешь фитнес своей привычкой</li>
                                            </ul>
                                            <p>Марафон предназначен для того, чтобы достичь конкретных результатов в&nbsp;короткие сроки, следуя структурированной программе тренировок и&nbsp;рационального питания.</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/2.jpg" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}><p>МАРАФОН</p> <span>Упругой попы 1.0</span></div>
                                    <div className={styles.programs_img__info_price}>5 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 21&nbsp;день</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, 2&nbsp;утяжелителя по&nbsp;1,5-3&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p5" name="p5" />
                                    <label htmlFor="p5">Для кого подойдёт <p>Вы&nbsp;хотите добавить объём и&nbsp;тонус ягодицам, заметно улучшить форму в&nbsp;короткий срок.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn6" name="pn6" />
                                        <label htmlFor="pn6">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Подтяните и&nbsp;укрепите мышцы ягодиц и&nbsp;ног</li>
                                                <li>Избавитесь от&nbsp;целлюлита</li>
                                            </ul>
                                            <p>Марафон поможет заметно улучшить форму ягодиц, избавиться от&nbsp;целлюлита, сделать ноги подтянутыми.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/5.jpg" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Укрепить пресс</div>
                                        <div className={styles.programs_tag}>Избавиться от животика</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}><p>МАРАФОН</p> <span>Плоского живота</span></div>
                                    <div className={styles.programs_img__info_price}>5 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 21&nbsp;день</li>
                                    <li><b>Без инвентаря</b></li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p9" name="p9" />
                                    <label htmlFor="p9">Для кого подойдёт <p>Марафон направлен на&nbsp;глубокую проработку мышц пресса <b>для создания красивого рельефа на&nbsp;животике</b>.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn10" name="pn10" />
                                        <label htmlFor="pn10">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Уменьшишь объемы талии и&nbsp;живота</li>
                                                <li>Повысишь мышечный тонус всего тела и&nbsp;подтянешь мышцы пресса</li>
                                                <li>Снизишь процент жира в&nbsp;области живота</li>
                                                <li>Улучшишь осанку и&nbsp;усилите поддержку спины</li>
                                                <li>Повысишь общую выносливость организма и&nbsp;уровень энергии</li>
                                                <li>Приобретешь знания в&nbsp;области питания и&nbsp;тренировок, которые будут полезны всегда</li>
                                            </ul>
                                            <p>Этот марафон подходит как начинающим, так и&nbsp;опытным спортсменам, так как программы могут быть адаптированы под разные уровни физической подготовки.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/3.jpg" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Похудеть</div>
                                        <div className={styles.programs_tag}>Избавиться от дряблости</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}><p>МАРАФОН</p> <span>Антицеллюлитный</span></div>
                                    <div className={styles.programs_img__info_price}>7 500 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 30&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, 2&nbsp;утяжелителя по&nbsp;1,5-3&nbsp;кг. Для массажа потребуются вакуумная банка, скребок гуаша или обычная столовая ложка</li>
                                    <li><b>График тренировок:</b> через день</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p11" name="p11" />
                                    <label htmlFor="p11">Для кого подойдёт <p>Марафон для тех, кто хочет <b>избавиться от&nbsp;целлюлита и&nbsp;дряблости кожи</b>, изменить качество тела. Внутри кроме тренировок техники самомассажа.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn12" name="pn12" />
                                        <label htmlFor="pn12">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Избавишься от&nbsp;целлюлита и&nbsp;улучшишь текстуру кожи</li>
                                                <li>Повысишь тонус и&nbsp;упругость кожи</li>
                                                <li>Подчеркнешь контуры тела и&nbsp;сформируешь более стройную и&nbsp;подтянутую фигуру</li>
                                                <li>Освоишь техники самомассажа для улучшения кровообращения и&nbsp;лимфодренажа </li>
                                                <li>Повысишь уверенность в&nbsp;себе и&nbsp;улучшите самочувствие</li>
                                            </ul>
                                            <p>Марафон создан для того, чтобы избавиться от&nbsp;целлюлита и&nbsp;повысить качество тела. Внутри программы&nbsp;&mdash; не&nbsp;только тренировки, но&nbsp;и&nbsp;обучение эффективным техникам самомассажа. Это делает марафон комплексным и&nbsp;эффективным средством для изменения качества тела.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/og/6.jpg" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}><span>Универсальные тренировки на&nbsp;ягодицы</span></div>
                                    <div className={styles.programs_img__info_price}>4 790 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 10&nbsp;тренировок</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 1,5-3&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p21" name="p21" />
                                    <label htmlFor="p21">Для кого подойдёт <p>Курс представляет собой конструктор из&nbsp;10&nbsp;тренировок, которые вы можете комбинировать под физическую подготовку, самочувствие и&nbsp;даже настроение.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn22" name="pn22" />
                                        <label htmlFor="pn22">Результаты после программы 
                                            <div>
                                            <ul>
                                                <li>Укрепишь и&nbsp;подтянете мышцы ягодиц</li>
                                                <li>Улучшишь форму и&nbsp;контуры ягодиц</li>
                                                <li>Повысишь выносливость и&nbsp;уровень энергии</li>
                                                <li>Улучшишь самочувствие и&nbsp;повысите энергию</li>
                                            </ul>
                                            <p>Марафон представляет собой уникальный конструктор из&nbsp;10&nbsp;тренировок на&nbsp;ягодицы, которые можно комбинировать в&nbsp;соответствии с&nbsp;вашими потребностями, физической подготовкой и&nbsp;настроением. Он&nbsp;разработан для того, чтобы обеспечить максимальную эффективность и&nbsp;удобство тренировок.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                </div>

                {/* <div className={styles.pr1}>
                    <div className={styles.pr1_1}>5&nbsp;390&nbsp;₽</div>
                    <div className={styles.pr1_2}>57&nbsp;300&nbsp;₽</div>
                    <div className={styles.pr1_3}><img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/blank.png" alt="" /><p>-91%</p></div>
                </div>
                
                <div className={styles.pr2}>
                    <div className={styles.promo_btn} onClick={scroll}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                        <p>Забрать комплект программ</p>
                    </div>
                    <div className={styles.pr3}>
                        <div className={styles.pr3_1}>Осталось мест<br/> по&nbsp;суперцене:</div>
                        <div className={styles.pr3_2}>{window.lps_settings.other.count}</div>
                    </div>
                </div> */}
        
        </div>
    )
}

export default Programs;