import styles from './programs.module.css';

const Programs1 = ({scroll}) => {
    return (
        <div className={styles.programs}>
            <div className="container">
                <div className={styles.programs_titles}>
                    <div className={styles.programs_title}>ЗАБЕРИТЕ КОМПЛЕКТ ФИТНЕС-ПРОГРАММ</div>
                    <div className={styles.programs_prices}>
                        <div className={styles.programs_oldprice}>32&nbsp;500&nbsp;<span>₽</span></div>
                        <div className={styles.programs_newprice}>3&nbsp;390&nbsp;<span>₽</span></div>
                    </div>
                </div>
                <div className={styles.programs_subtitle}>Кати Усмановой на&nbsp;специальных условиях выходного дня</div>
                <div className={styles.programs_date1}>Старт тренировок&nbsp;— сразу после оплаты</div>
                <div className={styles.programs_date2}>Доступ&nbsp;— 1&nbsp;год</div>
            <div className={styles.programs_block}>
                <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/programs/program_1.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info__text}>
                                <div className={styles.programs_info__tags}>
                                    <div className={styles.programs_info__tag}>Похудеть</div>
                                    <div className={styles.programs_info__tag}>Придать телу рельеф</div>
                                </div>
                                <p><strong>Длительность:</strong> 21&nbsp;день</p>
                                <p><strong>Без инвентаря</strong></p>
                                <p><strong>Где заниматься:</strong> дома</p>
                                <p><strong>График тренировок:</strong> ежедневно</p>
                                <p>Марафон для новичков и&nbsp;тех, кто уже обладает некоторым опытом. <span>Программа поможет быстро похудеть</span> и&nbsp;сформировать привычку правильно питаться и&nbsp;регулярно заниматься фитнесом.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/programs/program_2.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info__text}>
                                <div className={styles.programs_info__tags}>
                                    <div className={styles.programs_info__tag}>Накачать ягодицы</div>
                                </div>
                                <p><strong>Длительность:</strong> 21&nbsp;день</p>
                                <p><strong>Инвентарь:</strong> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по&nbsp;1,5-3&nbsp;кг</p>
                                <p><strong>Где заниматься:</strong> дома или в зале</p>
                                <p><strong>График тренировок:</strong> ежедневно</p>
                                <p>Марафон идеально подойдёт новичкам и&nbsp;тем, кто делал длительный перерыв в&nbsp;тренировках. <span>Вы сможете сделать ягодицы округлыми и упругими.</span></p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/programs/program_4.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info__text}>
                                <div className={styles.programs_info__tags}>
                                    <div className={styles.programs_info__tag}>Укрепить пресс</div>
                                    <div className={styles.programs_info__tag}>Избавиться от животика</div>
                                </div>
                                <p><strong>Длительность:</strong> 21&nbsp;день</p>
                                <p><strong>Без инвентаря</strong></p>
                                <p><strong>Где заниматься:</strong> дома</p>
                                <p><strong>График тренировок:</strong> ежедневно</p>
                                <p>Марафон направлен на&nbsp;проработку мышц пресса <span>для создания красивого рельефа на&nbsp;животике,</span> в&nbsp;уроки включены рекомендации по&nbsp;питанию.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/programs/program_5.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info__text}>
                                <div className={styles.programs_info__tags}>
                                    <div className={styles.programs_info__tag}>Избавиться от дряблости</div>
                                    <div className={styles.programs_info__tag}>Похудеть</div>
                                </div>
                                <p><strong>Длительность:</strong> 30&nbsp;дней</p>
                                <p><strong>Инвентарь:</strong> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по&nbsp;1,5-3&nbsp;кг. Для&nbsp;массажа потребуются вакуумная банка, скребок гуаша или&nbsp;обычная столовая ложка</p>
                                <p><strong>Где заниматься:</strong> дома</p>
                                <p><strong>График тренировок:</strong> через день</p>
                                <p>Марафон для тех, кто хочет <span>избавиться от&nbsp;целлюлита и&nbsp;сделать фигуру более подтянутой.</span> Внутри кроме тренировок техники самомассажа.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/programs/program_8.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info__text}>
                                <div className={styles.programs_info__tags}>
                                    <div className={styles.programs_info__tag}>Похудеть</div>
                                    <div className={styles.programs_info__tag}>Наладить питание</div>
                                </div>
                                <p><strong>Длительность:</strong> 45&nbsp;дней</p>
                                <p><strong>Инвентарь:</strong> без инвентаря</p>
                                <p><strong>График тренировок:</strong> ежедневно</p>
                                <p>Курс позволяет научиться <strong>самостоятельно создавать рацион питания</strong>, сбалансированного по&nbsp;белкам, жирам углеводам. Через 1,5&nbsp;месяца вам не&nbsp;понадобится диетолог.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className={styles.programs_block2}>
                    <div className={styles.programs_right}>
                        <div className={styles.programs_right__title}><span>Дарим мега-полезные бонусы</span> за&nbsp;покупку пакета программ</div>
                        <div className={styles.programs_mob_model}><img src="https://gymteam.kinescopecdn.net/img/l/2023/woweffect/programs_2.png" alt="" /></div>
                        <ul>
                            <li><p>Инструкция</p><span>Фитнес в&nbsp;путешествии</span></li>
                            <li><p>Трекер</p><span>Трекер изменения пропорций тела</span></li>
                            <li><p>Инструкция</p><span>Как питаться до&nbsp;и&nbsp;после тренировки</span></li>
                            <li><p>Инструкция</p><span>Как снять боль в&nbsp;мышцах после тренировок»</span></li>
                        </ul>
                        <div className={styles.program_btn} onClick={scroll}>ЗАБРАТЬ КОМПЛЕКТ</div>
                    </div>
                    <div className={styles.programs_left2}></div>
                </div> */}
            </div>
        </div>
    )
}

export default Programs1;