import styles from './onepage.module.css';

const Gym = () => {
    return (
        <div className={`${styles.page} ${styles.page_gym}`}>
            <div className="container">
                <div className={styles.pretitle}>Бесплатно</div>
                <div className={styles.title}>5&nbsp;тренировок<br/> для&nbsp;зала</div>
                <div className={styles.text}>Личный тренер на&nbsp;экране телефона&nbsp;—<br/> чемпионка фитнес-бикини Катя&nbsp;Усманова</div>
                <div className={styles.block}>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/fitmission/onepage/gym1.png" alt="" />
                        </div>
                        <div className={styles.item_text}>
                            <b>Старт</b> сразу<br/> после подписки
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/fitmission/onepage/gym2.png" alt="" />
                        </div>
                        <div className={styles.item_text}> 
                            <b>Упражнения</b><br/> на&nbsp;всё тело
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/fitmission/onepage/gym3.png" alt="" />
                        </div>
                        <div className={styles.item_text}> 
                            <b>Отработка</b><br/> правильной техники
                        </div>
                    </div>
                </div>
                <div className={styles.btns}>
                    <div className={styles.btns_text}>Для тех, кто готов <b>идти<br/> в&nbsp;зал за&nbsp;превосходной<br/> фигурой</b></div>
                    <a className={styles.btn} href="https://salebot.pro/md/gymcourse_tg" target='_blank'>получить бесплатно</a>
                </div>
            </div>
        </div>
    )
}

export default Gym;