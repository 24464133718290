import styles from './promo.module.css';

const Promo = () => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_pretitle}>🔥 Победительницы выбраны</div>
                <div className={styles.promo_title}>Мисс<br/> Фит<span>Миссия</span> <p><b>2024</b></p></div>
                <div className={styles.promo_info}>
                    <div className={styles.promo_info_left}>
                        <div className={styles.promo_info_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/missfitmission/done.png" alt="" />
                        </div>
                    </div>
                    <div className={styles.promo_info_right}>
                        <div className={styles.promo_info_title}>Посмотрите, за кого<br/> вы отдали голоса</div>
                        {/* <div className={styles.promo_info_text}>Объявляем открытое голосование!</div> */}
                    </div>
                </div>
                <div className={styles.promo_win}>
                    <div className={styles.promo_win_img}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/place_1.png" alt="" />
                    </div>
                    <div className={styles.promo_win_text}>Первое место в&nbsp;каждой из&nbsp;трех номинаций получит по&nbsp;100.000&nbsp;рублей!</div>
                </div>
            </div>
        </div>
    )
}

export default Promo;